.DocumentsModal {
  padding: 24px;

  .blockWrapper {
    display: flex;
    flex-flow: column;

    > h3 {
      color: #2782ff;
    }

    > div {
      display: flex;
      justify-content: space-between;
    }

    .sectionTitle {
      padding-bottom: 16px;
      font-weight: bold;
      font-size: 14px;
    }

    .close {
      margin-right: 24px;
      border: none;
      background: none;
      cursor: pointer;
      font-weight: bold;
      color: #999999;
    }

    .downloadAll {
      height: 32px;
      border: none;
      background: none;
      cursor: pointer;
      color: #0074ff;
      text-align: left;
      padding-top: 16px;

      & > span {
        font-size: 12px !important;
      }
    }

    .dropZone {
      border: solid 2px #2782ff;
      cursor: pointer;
      border-style: dashed;
      border-radius: 4px;
      display: flex;
      align-items: center;
      text-align: center;

      .addFile {
        color: #2782ff;
        text-decoration: underline;
      }

      > div {
        color: #999999;
        font-size: 13px;
        padding: 32px 24px;
        width: 100%;
      }
    }

    .fileSelectedContainer {
      align-items: center;
      padding-top: 16px;
      justify-content: flex-start;
    }
  }

  .blockWrapper:first-child {
    flex-direction: revert;
    justify-content: space-between;
    cursor: pointer;
  }

  .blockWrapper:not(:last-child) {
    padding-bottom: 32px;
  }

  .categoriesWrapper {
    flex-flow: column;
    display: flex;
    justify-content: space-between;
  }

  .categoryWrapper {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px #eaeaf1;
    padding: 10px;
    color: #999999;
    font-size: 14px;

    button {
      border: none;
      background: none;
      cursor: pointer;
      margin-left: 12px;
    }

    .fileWrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;

      > h3 {
        width: 30%;
      }

      > p {
        width: 50%;
      }

      > div {
        width: 20%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .categoryWrapper:first-child {
    justify-content: normal;
    font-weight: bold;
    color: #000000;

    p:first-child {
      width: 30%;
    }
  }

  .categoryWrapper:not(:first-child) {
    flex-direction: column;
  }

  .submitContainer {
    justify-content: flex-end !important;
    padding-top: 24px;

    .submit {
      background: #0074ff;
      border-color: #0074ff;
      width: 160px;
      height: 36px;
    }
  }

  .fileSelected {
    border: solid 1px #ddd;
    padding: 6px;
    color: #182e4c;
    margin-right: 16px;
    font-size: 12px;
    font-weight: 500;

    svg {
      margin-left: 8px;
    }
  }
}
