.portalCustomLabels {
  width: 100%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;

  > header {
    width: 100%;
    font-size: 16px;
    text-align: center;
  }

  > div.labelsList {
    width: 50%;
    min-width: 500px;
    margin: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > div {
      padding: 10px;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #ccc;

      &:last-of-type {
        border-bottom: none;
      }

      > input {
        border: 1px solid #ccc;
        margin-top: 5px;
        border-radius: 5px;
        padding: 5px;
        width: 300px;
      }
    }
  }

  > div.actions {
    width: 500px;
    display: flex;
    justify-content: flex-end;
    margin: auto;
  }
}
