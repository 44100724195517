@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.list {
  margin-top: 2rem;
  margin-left: 2rem;
  gap: 1rem;
}

.fields {
  width: 100%;
  height: 100%;
  background: #fafafa;

  > div.content {
    padding: 20px;
    gap: 20px;
    @include espace-rh-caption-regular;

    > div {
      margin: 0 15px;
    }
  }
}

.field {
  justify-content: space-between;
}

.title {
  @include espace-rh-caption-bold;
  padding-bottom: $skui-spacing-xs;
}
