@import "../../../../assets/designSystem";

.searchSelectFilter {
  position: relative;

  .searchSelectFilterContainer {
    z-index: 2;
    background: $plainText-onDark-default;
    padding: $skui-spacing-xxs $skui-spacing-xs 0 $skui-spacing-xs;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    .searchInput {
      padding: $skui-spacing-xs;
      margin-bottom: $skui-spacing-s;
    }

    .results {
      margin-left: $skui-spacing-m;
    }

    .empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 0.8125rem;
      color: #69798c;
      padding: 0 10px;
      margin: $skui-spacing-m 0;
    }
  }
}
