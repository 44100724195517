@import "../../assets/designSystem";

.Stepper {
  display: flex;
  margin: 1rem 0;
  color: $plainText-onLight-lighter;

  .step {
    display: flex;
    flex-grow: 1;
    position: relative;
    gap: 0.5rem;

    .dot {
      width: 1.5rem;
      height: 1.5rem;
      font-size: 0.75rem;
      font-weight: 600;
      border-radius: 0.75rem;
      background: $greyscale-light;
      text-align: center;
      color: $plainText-onLight-lighter;
      display: flex;
      justify-content: center;
      align-items: center;

      &:before,
      &:after {
        content: "";
        position: absolute;
        background: $greyscale-light;
        z-index: 0;
      }
    }

    &:first-child {
      .dot:before {
        display: none;
      }
    }
    &:last-child {
      .dot:after {
        display: none;
      }
    }

    &.current {
      color: $plainText-onLight-default;
      .dot {
        background: $action-onLight-default;
        color: $plainText-onDark-default;
        &:before {
          background: $action-onLight-default;
        }
      }
    }
    &.done {
      .dot {
        background: $surface-light;
        border: 2px solid $action-onLight-default;
        color: $action-onLight-default;
        &:before,
        &:after {
          background: $action-onLight-default;
        }
      }
    }

    &:not(.disabled) {
      cursor: pointer;
      &:focus {
        outline: none;
        .dot {
          box-shadow: 0 0 0 6px $surface-light, 0 0 0 8px $action-onLight-default;
        }
      }
      &:hover,
      &:focus {
        .dot {
          transition: all ease-in-out 100ms;
          outline: 4px solid $action-onLight-lightest;
        }
      }
      &:not(.current):not(.done):hover,
      &:not(.current):not(.done):focus {
        .dot {
          background: $surface-light;
        }
      }
      &:hover {
        color: $plainText-onLight-default;
      }
      label:hover {
        cursor: pointer;
      }
    }

    &.disabled {
      &:not(.done) {
        .dot {
          color: $plainText-onDark-darker;
          background-color: $greyscale-lighter;
        }
      }
      &.done {
        .dot {
          border-color: $action-onLight-lightest;
          svg {
            color: $action-onLight-lightest;
          }
        }
      }

      label {
        opacity: 0.3;
      }
    }
  }

  &.horizontal {
    flex-direction: row;
    width: 100%;

    .step {
      min-width: 6rem;
      flex-direction: column;
      align-items: center;

      .dot {
        &:before,
        &:after {
          height: 0.25rem;
          top: 0.625rem;
          width: calc(50% - 0.75rem);
        }
        &:before {
          left: 0;
        }
        &:after {
          right: 0;
        }
      }
    }
  }

  &.vertical {
    display: inline-flex;
    flex-direction: column;

    .step {
      min-height: 4rem;
      flex-direction: row;
      padding: 1rem 0;

      .dot {
        &:before,
        &:after {
          width: 0.25rem;
          left: 0.625rem;
        }
        &:before {
          top: 0;
          height: 1rem;
        }
        &:after {
          bottom: 0;
          height: calc(50% - 0.5rem);
        }
      }
    }
  }

  &.espace-rh {
    // Stepper/Label/HR
    @include espace-rh-font;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    .step.current {
      font-weight: 600;
    }
  }
  &.espace-collab {
    // Stepper/Label/Collab
    @include espace-collab-font;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    .step.current {
      font-weight: 700;
    }
  }
}
