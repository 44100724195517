.dropZone {
  background: #f9f9fb;
  border: 1.5px dashed #b1b6c6;
  box-sizing: border-box;
  border-radius: 8px;

  height: 160px;

  > div {
    height: 100%;
    width: 100%;
    overflow: hidden;

    > div.icon {
      width: 50px;
      margin: 10px auto 0px auto;
    }

    > .description {
      color: #7b89b2;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      /* or 150% */

      text-align: center;
    }

    > .addFileButton {
      margin: 8px auto 0 auto;
      width: 139px;
    }

    > .cannotDropFile {
      font-size: 14px;
      line-height: 16px;
      // text-align: center;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #7b89b2;
    }
  }
}
