@import "@skillup/ui";

h4.mainTitle {
    @include espace-rh-h4;
    color: $plainText-onLight-lighter
}

.gridContainer {
  flex: 1;
  padding: $skui-spacing-m;
  .grid {
    margin-bottom: $skui-spacing-xs;
    margin-top: $skui-spacing-xs;
  }
  .filters {
    display: flex;
    justify-content: space-between;
    margin-top: $skui-spacing-xs;
  }
  .TooltipCell {
    overflow: hidden;
    text-overflow: ellipsis;
    @include espace-rh-body2-regular;
  }
}


