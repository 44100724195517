@import "../../assets/colors";
@import "../../assets/mixins";

.UserWithAvatar {
  padding: 0.815rem 1.25rem;
  align-items: center;
  justify-content: space-between;

  .left {
    align-items: center;

    .meta {
      margin-left: 0.938rem;

      span.fullName {
        margin-bottom: 0.125rem;
        font-size: 0.875rem;
        line-height: 1.21;
        letter-spacing: 0.15px;
        font-weight: bold;
        color: $b-w-black;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 9.125rem;
      }

      span.subtitle {
        font-size: 0.75rem;
        font-weight: 500;
        color: $bluey-grey;
        line-height: 1.25;
        letter-spacing: 0.15px;
      }
    }
  }

  .right {
    padding: 0.25rem;

    .notifications {
      flex-shrink: 0;
      width: 1rem;
      height: 1rem;
      background-color: $secondary-red;
      border-radius: 50%;

      .count {
        font-size: 0.625rem;
        font-weight: bold;
        color: white;
        user-select: none;
        text-align: center;
      }
    }
  }

  &.loading {
    .left {
      .avatar {
        display: none;
      }

      .meta {
        span.fullName {
          margin-bottom: 0.5625rem;
        }

        span.fullName,
        span.subtitle {
          display: block;
          background-color: #ebebeb;
          height: 0.8125rem;
          width: 9.625rem;
        }
      }
    }

    .right {
      .notifications .count {
        display: none;
      }
    }
  }
}
