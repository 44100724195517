@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.SidePanel {
  background: linear-gradient(180deg, #f3f3f3, #fff);
  box-shadow: 0 0 20px rgba(40, 139, 255, 0.3);
  flex-grow: 0;
  flex-shrink: 0;
  height: 100vh;
  overflow: auto;
  width: 440px;
  position: absolute;
  z-index: 100;
  right: 0;
  top: 0;

  > header {
    align-items: center;
    display: flex;
    height: 50px;
    justify-content: flex-end;

    > button {
      align-items: center;
      background: none;
      border: none;
      display: flex;
      justify-content: center;
      margin-right: 15px;
      padding: 0 0 0 10px;

      > svg {
        display: block;
        cursor: pointer;
      }
    }
  }

  > .traineesLists {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 5px 10px 0 rgba(40, 139, 255, 0.19);
    margin: 0 15px 20px;
    padding: 20px;
    position: relative;

    > h2 {
      color: #9aa7b9;
      margin-bottom: 1em;
      text-transform: uppercase;
      font-size: 0.9em;
    }

    > div.actions {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
    }

    > .addTrainee {
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 10px;

      > span {
        display: none;
      }

      &:hover {
        > span {
          display: block;
        }
      }
    }
    
    > .manageUsersButton {
      margin-top: $skui-spacing-m;
      display: flex;
      flex-direction: row-reverse;
    }

    > ul:last-of-type {
      margin-bottom: 0;
    }

    > ul {
      list-style: none;
      margin-bottom: 20px;
      max-height: 250px;
      overflow-y: auto;

      h3 {
        text-transform: uppercase;
        font-size: 0.95em;
      }

      > .traineeGroup {
        display: flex;
        > h3 {
          flex: 1;
        }
        > button {
          color: $blue;
          background: none;
          border: none;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      > li {
        color: #555;
        font-size: 12px;
        font-weight: 500;
        margin-top: 5px;
        padding-left: 10px;

        > span {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }

  .referenceBlock {
    color: $text-on-light-lighter;
    display: flex;
    margin: 0.9375rem;
    align-items: center;
    font-size: 0.75rem;
    .reference {
      font-weight: 300;
      margin-right: 0.4rem;
    }
  }

  section {
    background-color: #fff;
    border-radius: $skui-spacing-xs;
    box-shadow: $panel-shadow-below;
    margin: 0 $skui-spacing-s $skui-spacing-m;
    padding: $skui-spacing-s;

    .header {
      justify-content: space-between;
      h2 {
        @include espace-rh-h5;
      }
    }

    &.participants {
      h3 {
        margin-bottom: $skui-spacing-xxs;
      }
      button.link {
        @include espace-rh-caption-link;
        cursor: pointer;
        color: $action-onLight-darkest;
        border: none;
        outline: inherit;
        background-color: inherit;
      }
    }

    span > button span {
      font-weight: 600;
    }

    ul {
      list-style: none;
      margin-bottom: 20px;
      max-height: 250px;
      overflow-y: auto;
      li {
        @include espace-rh-caption-regular;
        color: $text-on-light-lighter;
        margin-top: $skui-spacing-xxs;
        span {
          color: $text-on-light-default;
        }
      }
    }

    h3 {
      @include espace-rh-caption-bold;
      color: $text-on-light-lighter;
      margin-top: $skui-spacing-s;
    }
  }
}
