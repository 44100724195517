.addGroupModal {
  padding: 20px;
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .actions {
    display: flex;
    justify-content: center;
  }
}
