@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.CancelModal {
  width: 36rem;

  .content > div {
    display: flex;
    flex-direction: column;
    gap: $skui-spacing-s;
  }
  .warnList {
    list-style: inside;
  }
}
