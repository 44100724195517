@import "uiAssets/ColorsVars.scss";

.TrainingScore {
  align-items: center;
  display: flex;
  flex-direction: row;

  > .note {
    color: $blue;
    display: block;
    font-size: 16px;
    font-weight: 700;
    margin-left: 10px;
  }

  > .totalPossible {
    color: #666;
    display: block;
    font-size: 13px;
    margin-left: 3px;
  }

  > .reviewsCount {
    color: #999;
    display: block;
    font-size: 13px;
    margin-left: 10px;
  }
}
