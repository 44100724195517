@import "../../assets/colors";
@import "../../assets/mixins";

button.Button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
  height: 2.688rem;
  border-radius: 4px;
  user-select: none;
  border: 1px solid transparent;

  span.label {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.21;
    letter-spacing: 0.15px;
    text-align: center;
  }

  &:hover {
    cursor: pointer;
  }

  &.hasIcon {
    padding-left: 1.75rem;

    span.label {
      margin-left: 0.425rem;
    }
  }

  &.primary {
    box-shadow: 0 4px 4px 0 rgba(0, 136, 198, 0.15);
    background-color: $primary-blue;
    border-color: $primary-blue;

    span.label {
      color: white;
    }

    &:hover {
      background-color: $secondary-blue;
      border-color: $secondary-blue;
    }
  }

  &.secondary {
    border-color: $pale-lilac;
    background-color: $light-grey;

    span.label {
      color: $b-w-black;
    }

    &:hover {
      background-color: $bluey-grey;
      border-color: $bluey-grey;

      span.label {
        color: white;
      }
    }
  }

  &.danger {
    background-color: $secondary-red;

    span.label {
      color: white;
    }

    &:hover {
      background-color: $hover-red;
      border-color: $hover-red;
    }
  }

  &.readOnly {
    border-color: $pale-lilac;
    background-color: $light-grey;
    box-shadow: none;

    span.label {
      color: $bluey-grey;
    }

    &:hover {
      cursor: not-allowed;
      border-color: $pale-lilac;
      background-color: $light-grey;
    }
  }

  &.fullWidth {
    width: 100%;
  }
}
