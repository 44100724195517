@import "@skillup/ui";

.PortalNeeds {
  display: flex;
  padding-top: $skui-spacing-xs;
  padding-left: $skui-spacing-l;
  .title {
    @include espace-rh-h3;
    color: $plainText-onLight-lighter;
    line-height: 1rem;
  }
  .subtitle {
    @include espace-rh-caption-regular;
    color: $plainText-onLight-lighter;
  }
  section {
    margin-top: $skui-spacing-xxl;
  }
}
