@import "uiAssets/ColorsVars.scss";

.AddUserModal {
  padding: 20px;
  display: flex;
  flex-direction: column;

  footer.actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;

    > .backButton {
      background: #fff;
      border: 1px solid #bdbdbd;
      margin-right: 10px;
      color: #979797;
    }
  }
}
