@import "../../assets/designSystem";

.Status {
  display: inline-flex;
  align-items: center;
  padding: 0.125rem;
  &.espace-rh {
    @include espace-rh-caption-regular;
    border-radius: 0.125rem;
  }
  &.espace-collab {
    @include espace-collab-caption-regular;
    border-radius: 1rem;
  }
  &:not(.darkMode) {
    font-weight: 400;
    border: 1px solid transparent;
    &.espace-rh {
      @include espace-rh-caption-regular;
    }
    &.espace-collab {
      @include espace-collab-caption-regular;
    }
    &.done {
      background-color: $greyscale-light;
      color: $plainText-onLight-default;
    }
    &.progress {
      background-color: $action-onLight-lightest;
      color: $action-onLight-darkest;
      svg {
        color: $action-onLight-default;
      }
    }
    &.info {
      background-color: $action-onLight-lightest;
      color: $action-onLight-darkest;
      svg {
        color: $action-onLight-default;
      }
    }
    &.success {
      background-color: $status-success-lightest;
      color: $status-success-darker;
    }
    &.warning,
    &.time {
      background-color: $status-warning-lightest;
      color: $status-warning-darker;
      border: 1px solid $status-warning-default;
    }
    &.error {
      background-color: $status-error-lightest;
      color: $status-error-darker;
      border: 1px solid $status-error-default;
    }
  }
  &.darkMode {
    background-color: $surface-dark-darker;
    color: $surface-light;
    font-weight: 600;
    &.espace-rh {
      @include espace-rh-caption-bold;
    }
    &.espace-collab {
      @include espace-collab-caption-bold;
    }
    &.done {
      color: $plainText-onDark-darker;
      svg {
        color: $greyscale-medium;
      }
    }
    &.progress {
      color: $action-onLight-lighter;
      svg {
        color: $action-onLight-default;
      }
    }
    &.success {
      color: $status-success-lighter;
      svg {
        color: $status-success-default;
      }
    }
    &.warning,
    &.time {
      color: $status-warning-lighter;
      border: 2px solid $status-warning-default;
      svg {
        color: $status-warning-default;
      }
    }
    &.error {
      color: $status-error-lighter;
      border: 2px solid $status-error-default;
      svg {
        color: $status-error-default;
      }
    }
  }
  svg {
    margin-left: 0.25rem;
  }
  .label {
    margin-left: 0.375rem;
    margin-right: 0.375rem;
    font-size: 0.75rem;
    line-height: 1.125rem;
    white-space: nowrap;
  }
}
