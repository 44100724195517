.CollectionDashboard {
  overflow: auto;

  .splits {
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;

    > div {
      background-color: #fff;
      border: 2px solid #eee;
      border-radius: 5px;
      display: flex;
      flex-basis: calc(50% - 20px);
      flex-wrap: wrap;
      margin: 10px;
      padding: 8px 10px;

      > h3 {
        flex-shrink: 0;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 10px;
        width: 100%;
      }

      > svg {
        display: block;
        margin-right: 10px;
      }

      > ul {
        padding-top: 5px;

        > li {
          align-items: center;
          display: flex;
          font-size: 14px;
          list-style: none;

          > div {
            border-radius: 100px;
            height: 10px;
            margin-right: 10px;
            width: 10px;
          }

          > span {
            display: block;
          }

          > span:first-of-type {
            flex-grow: 1;
            font-weight: 400;
            margin-right: 15px;
          }

          > span:last-of-type {
            color: #696969;
          }
        }
      }

      .others {
        margin-top: 5px;
        width: 100%;
      }
    }
  }

  .tables {
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;

    > div {
      background-color: #fff;
      border: 2px solid #eee;
      border-radius: 5px;
      flex-basis: calc(50% - 20px);
      margin: 10px;
      padding: 8px 10px;

      > h3 {
        flex-shrink: 0;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 10px;
        width: 100%;
      }

      > ul {
        display: block;
        padding-top: 5px;
        width: 100%;

        > li {
          align-items: center;
          display: flex;
          font-size: 14px;
          list-style: none;
          margin-bottom: 2px;

          > span {
            background-color: #fff;
            display: block;
            flex-grow: 1;
            padding: 5px;
          }

          > span:first-of-type {
            font-weight: 400;
            margin-right: 2px;
          }

          > span:last-of-type {
            color: #696969;
            flex-grow: 0;
            text-align: right;
            width: 100px;
          }
        }

        > li:nth-child(2n) {
          > span {
            background-color: #fafafa;
          }
        }
      }
    }
  }
}
