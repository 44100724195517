@import "@skillup/ui";

.ChooseSummonTypeModal {
  width: 32rem;
  padding-top: $skui-spacing-xxs;
  padding-bottom: $skui-spacing-xxs;

  .radios {
    display: flex;
    flex-direction: column;
    gap: $skui-spacing-xxs;
    align-self: stretch;
  }

  .warning {
    white-space: pre-wrap;
    margin-bottom: $skui-spacing-s;
  }

  .radioForm {
    @include espace-rh-body2-regular;
  }
}
