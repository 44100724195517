@import "@skillup/ui";

.UserHeader {
  background-color: $surface-dark;
  display: flex;
  align-items: center;
  padding: $skui-spacing-s $skui-spacing-3xl;
  @media (max-width: 640px) {
    padding: 0;
  }

  .container {
    background-color: $surface-dark-darker;
    color: $plainText-onDark-default;
    display: flex;
    flex-direction: row;
    gap: $skui-spacing-l;
    padding: $skui-spacing-s $skui-spacing-m;
    flex-grow: 0;
    flex-shrink: 1;
    border-radius: 0.5rem;
    @media (max-width: 640px) {
      gap: $skui-spacing-m;
      flex-grow: 1;
      flex-shrink: 0;
      border-radius: 0;
    }

    .groups {
      display: flex;
      flex-direction: row;
      gap: $skui-spacing-xxl;
      flex-grow: 0;
      flex-shrink: 1;
      @media (max-width: 640px) {
        flex-direction: column;
        gap: $skui-spacing-s;
      }
      .group {
        @include espace-rh-body1-regular;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .label {
          color: $plainText-onDark-darker !important;
          margin-bottom: $skui-spacing-xxs;
        }
      }
    }
  }
}
