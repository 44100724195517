@import "../../assets/designSystem";

.DropDownCheckbox {
  position: relative;
  border: 1px solid rgba(105, 121, 140, 0.2);
  border-radius: 4px;

  .activeContainer {
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-radius: 4px;
    user-select: none;
    padding: 8px;

    h2.title {
      @include espace-rh-body1-regular;
      font-size: 16px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-right: 4px;
    }

    &:hover {
      cursor: pointer;
    }

    svg {
      color: $plainText-onLight-lighter;
    }
  }

  .DropDownContainer {
    z-index: 21;
    position: absolute;
    top: 45px;
    background: white;
    padding: 8px;
    width: 100%;
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
    border: 1px solid rgba(105, 121, 140, 0.2);
    border-radius: 4px;

    span {
      font-size: 12px;
    }

    .searchInput {
      padding: 5px;
      font-size: 12px;
      margin-bottom: 8px;
    }

    .selectAll {
      border-bottom: solid 1px #ccc;
      padding-bottom: 6px;
      margin-bottom: 6px;
    }

    .dropDownLine {
      margin-bottom: 8px;

      &.collab {
        padding-bottom: 5px;
        margin-bottom: 5px;
        border-bottom: solid 1px #eaeaf1;
      }

      &:last-child {
        margin-bottom: 0;

        &.collab {
          padding-bottom: 0px;
          margin-bottom: 0px;
          border-bottom: none;
        }
      }
    }

    .empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 0.8125rem;
      color: #69798c;
      padding: 0 10px;
      margin: 2rem 0;

      img {
        padding-bottom: 2rem;
      }
    }
  }
}
