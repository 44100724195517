.FilterModal {
  background: #fff;
  border-radius: 5px;
  box-shadow: rgba(40, 139, 255, 0.3) 0 0 20px;
  overflow: visible;
  padding-bottom: 5px;
  padding-top: 5px;
  position: absolute;
  top: 45px;
  width: 270px;
  z-index: 99;

  .separator {
    border-bottom: solid 1px #ddd;
  }

  > button {
    background: none;
    border: none;
    opacity: 0;
    padding: 15px;
    position: absolute;
    right: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 2;
    cursor: pointer;
  }

  .sortLink {
    cursor: pointer;
    padding: 10px 20px;
    width: 100%;

    &:hover {
      background-color: #f6f6f6;
    }
  }

  .sortLink:last-of-type {
    margin-bottom: 10px;
  }

  .containerFilters {
    margin: 20px;
  }

  .titleSortLink {
    margin: 10px 20px 0 20px;
    display: flex;
    flex-flow: row wrap;

    > div {
      font-weight: 500;
      width: 100%;
      margin-top: 0;
    }
  }

  > div {
    left: -100px;
    opacity: 0;
    transform: translateX(-10%);
    transition: transform 0.2s ease-in, opacity 200ms ease-in;

    &.visible {
      opacity: 1;
      transform: translateX(0);

      h3 {
        font-weight: 500;
      }

      > .deleteColumn {
        border-top: solid 1px #ddd;
        margin-top: 12px;
        padding-top: 8px;
      }
    }

    > div {
      > button {
        display: inline-block !important;
        margin: 5px 10px 10px 0 !important;
      }

      > div {
        > div:first-of-type {
          margin: 15px 0 5px;
        }

        .numberFilter {
          display: flex;
          margin-bottom: 20px;

          > div {
            margin: 0 10px 0 0 !important;
            width: 115px;
          }

          > label {
            flex-grow: 1;
            margin: 0 !important;
          }
        }
      }
    }

    .deleteContainer {
      display: flex;
      justify-content: center;

      > button {
        margin: 0;
      }
    }
  }

  &:after {
    border: 10px solid transparent;
    border-bottom-color: #fff;
    content: "";
    display: block;
    height: 20px;
    margin-left: -5px;
    position: absolute;
    right: 22px;
    top: -20px;
    width: 20px;
  }

  &.isHiddenByLeftScroll:after {
    right: auto;
    left: 44px;
  }
}
