@import "@skillup/ui";

.inputLine {
  margin-bottom: $skui-spacing-xs;

  .label {
    @include espace-rh-components-input-label;
    color: $plainText-onLight-lighter;
  }

  .input {
    width: 100%;
    @include espace-rh-components-input-value;
    color: $plainText-onLight-default;
    margin-bottom: 10px;
  }

  &.inline {
    display: inline-block;
    .label {
      display: inline-flex;
    }
    .input {
      display: inline-flex;
      width: 100%;
      &.inputDate {
        width: 200px;
      }
    }
  }
}
