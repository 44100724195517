@import "../../assets/colors";

.Toast {
  width: 22.5rem;
  min-height: 3.5rem;
  border-radius: 6px;
  box-shadow: 7px 2px 21px 0 rgba(47, 56, 74, 0.06);

  .leftContainer {
    padding: 0.5rem;
    border-radius: 6px 0 0 6px;
    justify-content: center;
  }

  .children {
    padding: 0.5rem 0.75rem;
    font-size: 0.75rem;
  }

  .closeContainer {
    padding: 0.5rem;

    .closeIcon {
      transform: rotate(45deg);
      cursor: pointer;
    }
  }

  &.error {
    background-color: $status-red;

    .leftContainer {
      background-color: $secondary-red;
    }

    .children {
      color: $secondary-red;
    }
  }

  &.info {
    background-color: $status-violet;

    .leftContainer {
      background-color: $primary-blue;
    }

    .children {
      color: $primary-blue;
    }
  }

  &.success {
    background-color: $status-green;

    .leftContainer {
      background-color: $secondary-green;
    }

    .children {
      color: $secondary-green;
    }
  }

  &.warning {
    background-color: $status-orange;

    .leftContainer {
      background-color: $primary-yellow;
    }

    .children {
      color: $primary-yellow;
    }
  }

  &.exited {
    transform: translate3d(0, 0, 0);
  }

  &.entering {
    transform: translate3d(120%, 0, 0);
  }

  &.entered {
    transform: translate3d(0, 0, 0);
  }

  &.exiting {
    transform: translate3d(120%, 0, 0);
  }
}
