@import "@skillup/ui";

.container {
  overflow: hidden !important;
  contain: content;
}

.form {
  width: 100%;
}

.optionLabelInput {
  max-width: 16rem;
  width: 100%;
}

.optionDescriptionInput {
  width: 100%;
}

.content {
  overflow: auto;
  height: 100%;
  padding: $skui-spacing-l;
  padding-bottom: 8rem;
}

.stickyFooter {
  position: fixed;
  z-index: 10;
  box-shadow: $panel-shadow-above;
  background: $surface-light;
  right: 0;
  bottom: 0;
  left: 0;
}
