@import "uiAssets/ColorsVars.scss";

.massEditDropDown {
  flex-grow: 1;

  .dropDown {
    background: #fff;
    border: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    position: relative;
    letter-spacing: -0.25px;
    margin: 0 5px;

    &.open {
      .options {
        display: flex;
      }
    }

    .mainSelector {
      align-items: center;
      display: flex;
      height: 30px;

      p.selectedOption {
        flex-grow: 1;
        padding-left: 10px;
      }

      .iconWrapper {
        align-items: center;
        display: flex;
        width: 25px;
      }
    }

    .options {
      align-items: center;
      background: #fff;
      border: 1px solid #ccc;
      flex-direction: column;
      height: auto;
      position: absolute;
      top: 30px;
      width: 100%;
      z-index: 1001;

      .option {
        align-items: center;
        display: flex;
        height: 30px;
        padding: 0 10px;
        width: 100%;

        &:hover {
          background: $blue;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }
}
