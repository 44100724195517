@import "@skillup/ui";

.ActionsModal {
  &__Content {
    width: 35rem;
  }

  .Action {
    border: 1px;
    border-color: $border-on-light;
    background: $greyscale-lighter;
    gap: 1rem;
    border-radius: $skui-spacing-xxs;
    margin-bottom: 1rem;

    &__Label {
      padding-bottom: $skui-spacing-xxs;
    }

    &__Select {
      width: 100%;
    }
  }
}