.TableOfContent {
  width: 205px;
  max-height: calc(100vh - 3.5rem);
  overflow-y: auto;

  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
