@import "../../assets/designSystem";
@import "../../assets/mixins";

.ModalBackground {
  // Same in valid css
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  // backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;

  @include isMobile {
    position: fixed;
  }
}

.InnerModal {
  background: white;
  display: flex;
  flex-direction: column;

  min-width: 22.5rem;
  max-width: 75rem;
  max-height: 90vh;

  &.hasBorders {
    border-top: 1px solid $border-onLight;
    border-bottom: 1px solid $border-onLight;
  }

  &.isMobile {
    min-width: 20.5rem;
    max-width: calc(100vw - 2rem);
    max-height: calc(100vh - 2rem);
  }

  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  border: 1px solid $border-onLight;

  &.collab {
    > header {
      @include espace-collab-modal-title;
      @include isMobile {
        @include espace-collab-caption-bold;
      }
    }
  }

  &.rh {
    > header {
      @include espace-rh-modal-title;
      color: $plainText-onLight-default;
      @include isMobile {
        @include espace-rh-caption-bold;
      }
    }
  }

  > header {
    width: 100%;
    max-height: 5.75rem;

    padding: $skui-spacing-s $skui-spacing-m;
    @include isMobile {
      padding: $skui-spacing-s;
    }

    .title {
      // Displays itself at the top of the modal
      align-self: flex-start;
      display: flex;

      > h1 {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      // avoid the close button shadow effect to be on the title when hovering
      > span {
        margin-left: $skui-spacing-xs;
        align-self: start;
      }
    }
    .subheader {
      margin-top: $skui-spacing-xs;
    }
  }

  .content {
    // takes all the remaining space of the modal
    display: flex;
    position: relative;
    overflow: hidden;
    height: auto;
    background-color: white;

    &.dialog {
      width: 22.5rem;
      max-width: 22.5rem;

      &.isMobile {
        width: 100%;
        max-width: 20.5rem;
      }
    }
  }

  .contentColumns {
    overflow: hidden;
  }

  .scrollableLayer {
    position: relative;
    display: flex;
    flex: 1;
    overflow: hidden;

    .scroller {
      padding: 0 $skui-spacing-m;
      @include isMobile {
        padding: 0 $skui-spacing-s;
      }
      // Vertically scrollable only
      overflow-x: hidden;
      overflow-y: auto;
      scroll-behavior: smooth;
      border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
      width: 100%;

      &.noPadding {
        padding: 0;
      }

      > div {
        // reveal padded content. For dev / test / debug only
        &.debug {
          background: #ff0099;
          opacity: 20%;
        }
      }
    }

    &.scrollTop {
      &::before {
        z-index: 1;
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: -4px;
        height: 4px;
        box-shadow: $panel-shadow-below;
      }
    }

    &.scrollBottom {
      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -4px;
        height: 4px;
        box-shadow: $panel-shadow-above;
      }
    }
  }

  > footer {
    width: 100%;
    height: 3.125rem;
    padding: $skui-spacing-s $skui-spacing-m;
    @include isMobile {
      padding: $skui-spacing-s;
    }

    // Places itself at the bottom of the modal
    align-self: flex-end;

    // Puts actions on the right, centered vertically.
    display: flex;
    flex-direction: row;
    // justify-content: flex-end;
    align-items: center;
    justify-content: flex-start;

    .filler {
      flex: 1;
    }

    .optional {
      justify-self: flex-start;
      flex: 1;
    }

    .cancel,
    .primary {
      margin-left: $skui-spacing-xs;
    }
  }
}
