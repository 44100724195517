.schedules {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding: 10px 20px;

  > button {
    margin-right: 15px;
    height: 30px;
  }
}
