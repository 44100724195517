@import "../../assets/designSystem";

.InnerSidePanel {
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 20;
  height: 100%;
  display: flex;
  max-width: 400px;
  position: absolute;
  background: white;
  flex-direction: column;
  box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.1);

  &.isMobile {
    min-width: 20.5rem;
    max-width: 100vw;
    max-height: 100vh;
  }

  &.collab {
    > header {
      background-color: $surface-light-darker;
      border-bottom: 1px solid rgba(105, 121, 140, 0.2);

      .title {
        @include espace-collab-h3;
        color: $plainText-onLight-default;
      }

      .subtitle {
        @include espace-collab-body1-regular;
        color: $plainText-onLight-lighter;
      }

      .customContent {
        @include espace-collab-body1-regular;
        color: $plainText-onLight-lighter;
      }
    }
  }

  &.rh {
    > header {
      background-color: $surface-dark;

      .title {
        @include espace-rh-h3;
        color: $plainText-onDark-default;
      }

      .subtitle {
        @include espace-rh-body1-regular;
        color: $plainText-onDark-darker;
      }

      .customContent {
        @include espace-rh-body1-regular;
        color: $plainText-onDark-darker;
      }
    }
  }

  > header {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: $skui-spacing-s $skui-spacing-s $skui-spacing-s $skui-spacing-l;

    .title,
    .subtitle {
      height: auto;
      margin-right: $skui-spacing-l;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    > .chips {
      gap: $skui-spacing-xs;
      display: flex;
      margin-bottom: $skui-spacing-xs;

      > div > div > span {
        max-width: 6rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    > .title {
      margin-bottom: $skui-spacing-xs;
    }

    > .subtitle {
      margin-top: $skui-spacing-s;
    }

    .customContent {
      margin-bottom: $skui-spacing-s;
      overflow: auto;
      max-height: 15rem;
    }

    > .actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 0px;
      gap: 10px;
      margin-top: $skui-spacing-s;

      > span > button { 
        max-width: 100%;

          > div {
          overflow: hidden;

          > span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    > .close {
      position: absolute;
      top: $skui-spacing-s;
      right: $skui-spacing-s;
      width: $skui-spacing-m;
      height: $skui-spacing-m;

      &.darkMode {
        & svg {
          fill: $plainText-onDark-default;
        }
      }
    }
  }

  .content {
    display: flex;
    position: relative;
    overflow: hidden;
    height: auto;
    flex: 1;
    background-color: white;

    .scroller {
      overflow-x: hidden;
      overflow-y: auto;
      scroll-behavior: smooth;
      width: 100%;

      .contentWrapper {
        padding: $skui-spacing-s $skui-spacing-s 0 $skui-spacing-l;
        width: 100%;
      }

      .alertContainer {
        background-color: $greyscale-darker;
        color: $plainText-onDark-default;
        width: 100%;
        padding: $skui-spacing-s;
        display: flex;
        gap: $skui-spacing-s;


        .alertMessage {
          flex: 1;
          line-height: 1.5rem;
        }
      }

      > div {
        color: $plainText-onLight-default;

        &.debug {
          background: #ff0099;
          opacity: 20%;
        }
      }
    }

    &.scrollTop {
      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: -$skui-spacing-xxs;
        height: $skui-spacing-xxs;
        box-shadow: $panel-shadow-below;
      }
    }

    &.scrollBottom {
      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -$skui-spacing-xxs;
        height: $skui-spacing-xxs;
        box-shadow: $panel-shadow-above;
      }
    }
  }

  footer {
    @include espace-rh-caption-regular;
    color: $plainText-onLight-lighter;
    padding: $skui-spacing-s $skui-spacing-s $skui-spacing-s $skui-spacing-l;

    .lastUpdatedAvatar {
      margin-right: $skui-spacing-xs;
    }
  }
}
