.checkbox {
  margin: 8px 0;
}

.folderWarning {
  font-size: 12px;
  padding: 0 4px 4px 4px;
  color: #333;
}

.toggle {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  div {
    flex-shrink: 0;
  }

  label {
    margin-left: 10px;
    font-size: 12px;
  }
}
