@import "uiAssets/ColorsVars.scss";

.SelectFilters {
  max-height: 250px;
  overflow-y: auto;

  > .containerCheckbox {
    align-items: flex-start;
    display: flex;
    margin-bottom: 8px;

    > div {
      cursor: pointer;
    }

    > div:last-child {
      > span {
        margin-left: 8px;
      }
    }

    > .checkbox {
      align-items: center;
      background: #fff;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      left: 0;
      padding-top: 0;
      position: sticky;
      transition: background 0.2s ease-out;
      user-select: none;
      width: 20px;
      z-index: 100;

      > div {
        align-items: center;
        border: 2px solid #ccc;
        border-radius: 2px;
        display: flex;
        height: 18px;
        justify-content: center;
        width: 18px;

        > svg {
          pointer-events: none;
        }
      }

      &.selected {
        > div {
          border-color: $blue;

          > svg {
            stroke: $blue !important;
          }
        }
      }
    }
  }
}
