@import "uiAssets/ColorsVars.scss";

.PostponeModal {
  height: auto;
  padding: 15px 35px 25px;

  > p {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .buttons {
    display: flex;
  }
}
