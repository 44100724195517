@import "@skillup/ui";

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div > :nth-child(2) {
    margin: 0;
  }

  > div > :nth-last-child(1) {
    margin: 0;
  }

  > div > button {
    margin: 0 $skui-spacing-xs;
  }

  .toggle {
    display: flex;
  }
}
