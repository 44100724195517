.updateValidityModal {
  padding: 1rem 1rem 1rem 1.5rem;
  padding-left: 1.5rem;

  .actions {
    display: flex;
    justify-content: flex-end;

    padding: 1.5rem 1rem 0.5rem 1rem;

    > div {
      margin-left: 1.5rem;
    }
  }
}
