@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.Campaign {
  background-color: $greyscale-lighter;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  overflow: hidden;

  > .loaderContainer {
    display: flex;
    flex: 1;
  }

  .Wrapper {
    overflow-y: auto;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;

    > main {
      padding: 0 $skui-spacing-l;
      max-width: 100%;
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: stretch;
      margin-bottom: 0.5rem;

      > .summary {
        display: flex;
      }

      .interviewsTable {
        display: flex;
        flex: 1;
        flex-direction: column;
      }
    }
    > h5 {
      @include espace-rh-h5;
      color: $plainText-onLight-lighter;
    }
  }
}

.title {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.mainTitle {
  .titleContainer {
    display: flex;

    .tooltip {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.5rem;
      white-space: initial;
    }

    .lockIcon {
      height: 1rem;
      color: white;
    }
  }
  span {
    position: relative;
    top: -1px;
  }
}
small {
  margin-left: 0.5rem;
}
