@import "@skillup/ui";

.manageCoordinatorsModal {
  width: 45rem;
  
  .content {
    padding-bottom: $skui-spacing-s;

    & > div {
      overflow-y: hidden;
    }

    .searchbar {
      width: 100%;
    }

    .columnsText {
      margin-left: $skui-spacing-m;
      
      li::marker {
        list-style-position: inside;
        color: $plainText-onLight-default
      }
    }
  }
}
