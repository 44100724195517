@import "../../assets/designSystem";

.SkillsList {
  .categoryName {
    @include espace-rh-caption-bold;
    margin: $skui-spacing-xs 0;
  }

  .categoriesContainer {
    margin-left: $skui-spacing-s;
  }

  .generatedCategory {
    margin-left: 0;
  }
}
