@import "@skillup/ui";

.tracking {
  .globalFilters {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1.25rem;

    .statusFilter {
      width: 175px;
      > div:first-of-type {
        height: 100%;
        border-radius: 0.125rem;
        cursor: pointer;
      }
    }
  }
}
