@import "@skillup/ui";

.addUserModal {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  padding: $skui-spacing-s;

  .buttonsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .button {
      margin-right: $skui-spacing-xs;
      box-sizing: border-box;

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}
