@import "@skillup/ui";

.inputLine {
  margin-bottom: $skui-spacing-xs;

  .label {
    @include espace-rh-components-input-label;
    color: $plainText-onLight-lighter;
  }

  .input {
    @include espace-rh-components-input-value;
    color: $plainText-onLight-default;

    // Force the ascendance over local CSS being to precise
    > div[role="button"] > span {
      font-weight: normal;
    }
  }

  .radioBox {
    .radioBoxElement {
      margin-top: $skui-spacing-xs;
    }
  }
}
