@import "../../assets/designSystem";

.Chip {
  display: inline-flex;
  height: 1.625rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 1.25rem;

  background: $greyscale-light;
  color: $plainText-onLight-default;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0.625rem;
    margin-left: 0.375rem;
    background: $decoration-blue-lighter;
    border: 1px solid $decoration-blue-base;
    box-sizing: border-box;

    svg {
      fill: $decoration-blue-darker;
    }
  }

  .textContainer {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    margin-left: 0.375rem;

    * {
      margin-right: 0.375rem;
    }
  }

  .dismissButton {
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    width: 1rem;
    height: 1rem;
    margin-right: 0.375rem;

    color: $plainText-onLight-lighter;
    background: transparent;

    &:hover {
      color: $plainText-onLight-default;
      background: $surface-light;
    }

    &:focus {
      color: $plainText-onLight-default;
      background: $surface-light;
      outline: $action-onLight-default solid 1px;
    }
  }

  &.espace-collab {
    .textContainer .label {
      @include espace-collab-caption-regular;
    }

    .textContainer .value {
      @include espace-collab-caption-bold;
    }
  }

  &.espace-rh {
    .textContainer .label {
      @include espace-rh-caption-regular;
    }

    .textContainer .value {
      @include espace-rh-caption-bold;
    }
  }

  &.darkMode {
    background: $greyscale-darker;
    color: $plainText-onDark-default;

    .dismissButton {
      color: $plainText-onDark-darker;
      background: transparent;

      &:hover {
        color: $plainText-onDark-default;
        background: $surface-dark;
      }

      &:focus {
        color: $plainText-onDark-default;
        background: $surface-dark;
        outline: $action-onLight-default solid 1px;
      }
    }
  }
}
