@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.Training {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 15px auto;
  max-width: 750px;
  overflow: hidden;
  transition: transform 0.2s ease-out, box-shadow 0.2s ease-out;

  &.selected {
    /* border: 2px solid #069; */
    border: 2px solid $blue;
  }

  &.classic:hover {
    box-shadow: rgba(40, 139, 255, 0.5) 0 5px 18px;
    transform: scale(1.025);
  }

  &.lightMode {
    > header {
      > div {
        cursor: auto;
      }
    }
  }

  .trainingLink {
    display: block;
  }

  > header {
    align-items: stretch;
    box-shadow: rgba(0, 0, 0, 0.05) 0 3px 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 50px;
    position: relative;
    z-index: 9;

    > .logo {
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: 40px;
      flex-grow: 0;
      flex-shrink: 0;
      margin-right: 5px;
      padding-bottom: 0;
      width: 100px;
    }

    > div {
      color: #000;
      cursor: pointer;
      display: block;
      flex-grow: 1;
      min-height: 65px;
      overflow: hidden;
      padding-bottom: 10px;

      > .title {
        color: $blue;
        display: block;
        font-size: 18px;
        font-weight: 700;
        padding-top: 10px;
        text-decoration: none;
      }

      > .reference {
        color: $plainText-onLight-lighter;
        font-size: 0.85rem;
      }

      > .organizationLink {
        color: #333;
        display: inline-block;
        font-size: 12px;
        font-style: italic;
        margin: 5px 0;
      }

      > .descriptionText {
        font-size: 12px;
        margin: 5px 0;
        overflow: hidden;
        padding-right: 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      > .tags {
        > span {
          background: #eee;
          border-radius: 3px;
          display: inline-block;
          font-size: 10px;
          margin: 0 3px 3px 0;
          padding: 2px 5px;
          text-transform: uppercase;

          &.cpf {
            background: $contrastColor;
            color: #fff;
          }

          &.certification {
            background: $blue;
            color: #fff;
          }
        }
      }
    }

    > .actions {
      align-items: stretch;
      border-left: 1px solid #ddd;
      display: flex;
      flex-direction: column;
      flex-grow: 0;
      flex-shrink: 0;
      padding-bottom: 0;
      width: 40px;

      > button {
        background: none;
        border: none;
        border-bottom: 1px solid #ddd;
        color: $blue;
        display: block;
        font-size: 24px;
        height: calc(100%);
        line-height: 0;
        position: relative;
        transition: background 0.2s ease-out, color 0.2s ease-out;
        width: 100%;

        > span {
          background: rgba(0, 0, 0, 0.75);
          border-radius: 5px;
          color: #fff;
          font-size: 12px;
          line-height: 12px;
          opacity: 0;
          padding: 6px 10px;
          pointer-events: none;
          position: absolute;
          right: 45px;
          top: 50%;
          transform: translateY(-50%) translateX(-50px);
          transition: opacity 0.2s ease-out, transform 0.2s ease-out;
          white-space: pre;

          &:after {
            border: 5px solid transparent;
            border-left-color: rgba(0, 0, 0, 0.75);
            content: "";
            display: block;
            margin-top: -5px;
            position: absolute;
            right: -10px;
            top: 50%;
          }
        }

        &:first-of-type {
          &:hover {
            background: $blue;
            color: #fff;

            > svg {
              fill: #fff !important;
            }

            > span {
              opacity: 1;
              transform: translateY(-50%) translateX(0);
            }
          }
        }

        &:last-of-type {
          border-bottom: none;
          > svg {
            &:hover {
              stroke: "#fff";
            }
          }
        }
      }
    }
  }

  > .summary {
    align-items: stretch;
    background: $mainColorL;
    box-shadow: rgba(0, 0, 0, 0.075) 0 3px 6px;
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 5;

    > div {
      align-items: center;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      font-size: 12px;
      padding: 10px 15px;

      > svg {
        margin-right: 5px;
      }

      > span {
        align-items: center;
        background: #999;
        border-radius: 20px;
        color: #fff;
        display: flex;
        font-size: 10px;
        margin-left: 5px;
        padding: 2px 6px;

        > svg {
          margin-left: 5px;
        }
      }
    }
  }

  > .data {
    padding-top: 5px;

    > h3 {
      color: $blue;
      font-size: 16px;
      margin: 10px 0;
      padding: 0 15px;
    }

    > p {
      border-bottom: 1px dashed $blue;
      font-size: 12px;
      line-height: 22px;
      padding: 0 15px 15px;
      text-align: justify;
      white-space: pre-line;
    }

    > .nextSessionLinks {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      padding: 0 15px 10px;

      > .nextSessionLink {
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 5px;
        color: #666;
        display: flex;
        font-size: 12px;
        margin-bottom: 5px;
        padding: 3px 5px;
        text-decoration: none;

        > svg {
          margin-right: 5px;
        }
      }
    }

    > nav {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      padding: 15px 15px 10px;
      > a,
      > button {
        display: block !important;
        margin: 0 5px 5px 0 !important;
      }
    }
  }
}
