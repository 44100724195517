@import "@skillup/ui";

.habilitationSidePanel {
  display: flex;
  flex-flow: column;
  height: 100vh;
  header {
    flex: 0 1 auto;
  }
  > div {
    flex: 1 1 auto;
  }

  .headerBottomTools {
    margin-top: $skui-spacing-xs;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .headerDropDown {
      margin-top: $skui-spacing-s;
      display: flex;
      justify-content: flex-end;
    }
  }

  .habilitationContainer {
    @include espace-rh-font;
    color: $plainText-onLight-default;
    padding: $skui-spacing-s;
    padding-left: $skui-spacing-l;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    height: 100%;
    justify-content: space-between;

    .habilitationContent {
      display: flex;
      flex-direction: column;
      justify-content: stretch;

      .habilitationContentSection {
        margin-bottom: $skui-spacing-m;

        .habilitationContentValue {
          margin-top: $skui-spacing-xs;
        }
      }
    }

    .buttonsWrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;

      button {
        font-size: 1rem;
      }
    }
  }
}
