@import "@skillup/ui";

.NewUserModal {
  padding: 20px;
  display: flex;
  flex-direction: column;

  .label {
    @include espace-rh-caption-regular;
    margin-bottom: 2px;

    &::after {
      content: "*";
      color: red;
      margin-left: 3px;
    }
  }
  .select {
    width: 100%;
  }

  div.perimetersBlock,
  div.roleBlock {
    margin-top: 15px;
  }

  footer.actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;

    > .backButton {
      background: #fff;
      border: 1px solid #bdbdbd;
      margin-right: 10px;
      color: #979797;
    }
  }
}
