.OpenRows {
  .title {
    font-weight: bold;
    text-decoration: underline;
    font-size: 18px;
    padding: 8px;
  }

  .field {
    padding: 4px 8px;

    > span:first-child {
      font-weight: bold;
    }
  }

  .dropDown {
    padding: 8px;
  }

  .dropZone {
    align-items: center;
    border: 3px dashed #999 !important;
    border-radius: 7px;
    color: #666;
    font-size: 13px;
    font-weight: 700;
    margin: 0 auto 10px;
    max-width: 100%;
    text-align: center;
    user-select: none;

    > div {
      align-items: center;
      cursor: pointer;
      display: flex;
      height: 100px !important;
      justify-content: center;
      width: 100%;
    }
  }
}
