@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.interval {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  @include espace-rh-body1-regular;
  color: $plainText-onLight-default;

  .dates {
    margin-right: $skui-spacing-s;
  }
}

.error {
  border: 1px solid $status-error-default !important;
}

.interval > div > input {
  width: 4.4375rem;
  height: $skui-spacing-xl;
  @include espace-rh-body1-regular;
  color: $plainText-onLight-default;
  text-align: center;
  padding: $skui-spacing-xs 0;
  margin: $skui-spacing-xs;
  border: 1px solid $border-onLight;
  border-radius: $skui-spacing-xxs;
}
