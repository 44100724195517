@import "@skillup/ui";

.habilitations {
  .globalFilters {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
    margin-bottom: 1.25rem;
  }
  .dataTable {
    width: 100%;
    thead svg {
      height: 16px;
      width: 16px;
    }
  }

  .noHabilitations {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 100%;
    // perimeter choice: 2.5rem height + 1.25rem margin
    // 224 + 60 => 284
    height: calc(100vh - 284px);
    > svg {
      width: 21rem;
      height: 21rem;
      opacity: 0.6;
      margin-bottom: 1.125rem;
    }

    p {
      @include espace-rh-body1-regular;
      max-width: 40rem;
      color: $plainText-onLight-default;
      text-align: center;
    }
  }
}
