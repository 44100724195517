.page {
  background: #fff;
  margin-bottom: 15px;
  padding: 12px;
  align-items: center;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:last-of-type {
    margin-bottom: 0;
  }

  .actionsRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    margin-top: 5px;
    .selected {
      background-color: orange;
      > span {
        color: "#192f4b";
      }
    }

    svg {
      cursor: pointer;
    }

    > button {
      height: 25px;
      background: transparent;
      border: none;
      padding: 5px;
      box-shadow: none;
    }

    .movePage {
      button {
        width: 30px;
        height: 25px;
        background: transparent;
        border: none;
        padding: 0;
        box-shadow: none;
        &:first-of-type {
          margin-bottom: 3px;
        }
      }
    }
  }
}
