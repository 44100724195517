@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.CancelOrPostponeModal {
  width: 36rem;

   .content label {
        margin-top: $skui-spacing-xs;
        margin-bottom: $skui-spacing-xs;
    }
    
    > header {
      padding: $skui-spacing-s $skui-spacing-m 0 $skui-spacing-m;
    }
  gap: $skui-spacing-xs;
}

