.clickable {
  cursor: cell;
  flex-grow: 1;
  height: 100%;
}

.tooltip {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-height: auto;
}
