.updateStateModal {
  padding: 1rem 1.5rem 1rem 1.5rem;

  .input {
    width: 100%;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    padding-top: 2rem;

    > div {
      margin-left: 1.5rem;
    }
  }
}
