@import "uiAssets/ColorsVars.scss";

.PortalOnboarding {
  align-items: flex-start;
  display: flex;
  justify-content: space-around;
  padding: 10px;

  > div {
    border-radius: 7px;
    box-shadow: $bigBoxShadow;
    overflow: hidden;
    width: calc(50% - 20px);

    > h2 {
      background: #999;
      color: #fff;
      display: block;
      font-size: 18px;
      font-weight: 300;
      margin-bottom: 5px;
      margin-top: 20px;
      padding: 6px 10px;

      &:first-of-type {
        margin-top: 10px;
      }
    }

    &:before {
      background: $gradientBlue;
      border-radius: 7px 7px 0 0;
      content: "";
      display: block;
      height: 10px;
      width: 100%;
    }

    > header {
      box-shadow: $bigBoxShadow;
      color: $blue;
      font-size: 24px;
      font-weight: 100;
      padding: 10px 15px;
    }

    &:first-of-type {
      > .contents {
        background: #f6f6f6;
        border-radius: 7px;
        color: #333;
        font-family: monospace;
        margin: 10px;
        padding: 10px;
        white-space: pre-wrap;
      }

      > .mediaList {
        > div {
          align-items: flex-start;
          background: #f6f6f6;
          border-radius: 7px;
          display: flex;
          margin: 10px;
          padding: 10px;

          > div {
            display: flex;
            flex-grow: 1;
            flex-wrap: wrap;
            justify-content: flex-end;

            > input {
              background: #fff;
              border: none;
              border-radius: 5px;
              display: block;
              flex-grow: 1;
              font-family: monospace;
              padding: 5px 8px;
              width: 100%;
            }

            > button {
              margin: 10px 0 0 10px !important;
            }
          }

          > img,
          > a {
            background: #fff;
            border-radius: 5px;
            display: block;
            margin-right: 10px;
            width: 100px;
          }

          > a {
            align-items: center;
            color: #999;
            display: flex;
            font-size: 10px;
            height: 50px;
            justify-content: center;
            text-decoration: none;
            text-transform: uppercase;
          }
        }
      }

      > .dropZone {
        align-items: center;
        border: 3px dashed #999 !important;
        border-radius: 7px;
        color: #666;
        font-size: 13px;
        font-weight: 700;
        margin: 0 auto 10px;
        max-width: 100%;
        text-align: center;
        user-select: none;

        > div {
          align-items: center;
          cursor: pointer;
          display: flex;
          height: 100px !important;
          justify-content: center;
          width: 100%;
        }
      }
    }

    &:last-of-type {
      > nav {
        background: #f6f6f6;
        border-radius: 7px;
        margin: 10px;

        > h3 {
          border-bottom: 1px solid #ccc;
          padding: 7px 10px;

          &:last-of-type {
            border-bottom: none;
          }
        }
      }

      > section {
        background: #f6f6f6;
        border-radius: 7px;
        margin: 10px;
        padding: 1px;

        img {
          border-radius: 7px;
          display: block;
          margin: auto;
          max-width: 100%;
          width: 300px;
        }

        p,
        h2,
        h3 {
          margin: 10px 13px;
        }

        h2,
        h3 {
          color: $blue;
        }
      }
    }
  }
}
