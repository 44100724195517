@import "@skillup/ui";

.reglementaire {
  .addLinkedButton {
    margin: 2rem 3rem;
  }
}

.modifyHabilitation {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  padding: $skui-spacing-m;

  .buttonsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .button {
      margin-right: $skui-spacing-xs;
      box-sizing: border-box;

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .deleteButtonWrapper {
    margin-top: $skui-spacing-xl;
    margin-bottom: $skui-spacing-xl;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

.alert {
  padding: 0.875rem 1rem;
}
