@import "../../assets/colors";

.RadioForm {
  align-items: flex-start;

  .line {
    margin-bottom: 0.562rem;
    margin-right: 0.625rem;

    &:last-of-type {
      margin-bottom: 0;
      margin-right: 0;
    }
  }
}
