@import "@skillup/ui";

.Summary {
  width: 100%;

  min-height: 200px;
  background-color: $surface-dark;
  color: $plainText-onDark-default;
  padding: $skui-spacing-s;
  border-radius: 0.5rem;
  margin-top: $skui-spacing-s;
  margin-bottom: $skui-spacing-l;

  .header,
  .main {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 0.875rem;
  }

  .header {
    margin-bottom: $skui-spacing-l;
    label.title {
      @include espace-rh-h4;
    }
    .dateInput {
      padding-top: $skui-spacing-xs;
    }
  }

  .main {
    .title {
      @include espace-rh-h6;
      p {
        color: $plainText-onDark-darker;
      }
    }
    .progressBar {
      padding-top: $skui-spacing-xs;
      grid-column: span 2;
    }
  }
}

.Alert {
  margin-bottom: $skui-spacing-s;
}
