.Tag {
  border-radius: 10px;
  flex-shrink: 0;
  padding: 0.125rem 0.625rem;

  span.label {
    font-size: 0.625rem;
    font-weight: 500;
    white-space: nowrap;
    letter-spacing: 0.15px;
    user-select: none;
    color: white;

    &::first-letter {
      text-transform: capitalize;
    }
  }
}
