@import "@skillup/ui";

.DuplicationModal {
  width: 40%;
  .content {
    gap: $skui-spacing-s;
    padding: $skui-spacing-s 0;
  }

  footer {
    height: auto;

    button {
      span {
        white-space: break-spaces;
      }
    }
  }
}
