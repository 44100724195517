@import "@skillup/ui";

.overClosed {
  border: 1px solid $border-onLight;
  animation: blinker 0.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
    background-color: $action-onDark-lightest;
  }
}

.highlight {
  background-color: $action-onDark-lightest;
}

.DragOverlay {
  position: absolute;
  background-color: $decoration-yellow-lightest;
  border: 1px solid $decoration-yellow-base;
  border-radius: 2px;
  box-shadow: $elevation-actionableCard-hover;
  margin-left: $skui-spacing-s;
  margin-top: $skui-spacing-s;
  height: fit-content !important;
  min-width: 19rem;
  width: fit-content !important;
  pointer-events: none;
}
.Structure {
  @include espace-rh-body1-regular;
  gap: $skui-spacing-xs;
  color: $plainText-onLight-default;
  .ellipsisBlock {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: auto 0;
  }
  .emptyChildLabel {
    @include espace-rh-caption-italic;
    color: $plainText-onLight-placeholder;
  }
  .emptySubSectionTitle {
    @include espace-rh-body2-italic;
    color: $plainText-onLight-placeholder;
  }
  .emptySectionTitle {
    @include espace-rh-body1-italic;
    color: $plainText-onLight-placeholder;
  }
  .section {
    @include espace-rh-body1-bold;
    padding-top: $skui-spacing-xs;
    padding-bottom: $skui-spacing-xs;
    .drag-indicator {
      padding-right: $skui-spacing-xs;
    }
    .no-drag-indicator {
      padding-right: $skui-spacing-l;
    }
    .no-errors {
      margin-right: 3px;
    }
    &:focus {
      background-color: $action-onDark-lightest;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .section:not(:hover),
  .section:not(.displayMenuOnHover) {
    .menu-icons,
    .drag-indicator {
      visibility: hidden;
    }
  }

  .section--immutable--whenDragging {
    @include espace-rh-body1-bold;
    opacity: 0.3;
    cursor: not-allowed !important;
    button {
      cursor: not-allowed !important;
    }
    .section,
    .subsection,
    .child {
      cursor: not-allowed !important;
    }
  }
  .section__pages {
    gap: $skui-spacing-xs;
  }
  .displayMenuOnHover.section:hover:not(.is-dragging):not(:focus) {
    background-color: $effects-hover-onLight;
  }
  .section--selected {
    background-color: $action-onDark-lightest;
  }

  .subsection {
    padding-top: $skui-spacing-xs;
    padding-bottom: $skui-spacing-xs;
    @include espace-rh-body2-bold;
    .drag-indicator {
      padding-right: $skui-spacing-xxl;
    }
    .errors {
      padding-right: calc($skui-spacing-xxl - 3px);
    }
    &:focus {
      background-color: $action-onDark-lightest;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .subsection:not(:hover),
  .subsection:not(.displayMenuOnHover) {
    .menu-icons,
    .drag-indicator {
      visibility: hidden;
    }
  }
  .displayMenuOnHover.subsection:hover:not(.is-dragging):not(:focus) {
    background-color: $effects-hover-onLight;
  }

  .child {
    padding-top: $skui-spacing-xxs;
    padding-bottom: $skui-spacing-xxs;
    @include espace-rh-caption-regular;
    .drag-indicator {
      padding-right: 3rem;
    }
    .no-drag-indicator {
      padding-right: 4.55rem;
    }
    &:focus {
      background-color: $action-onDark-lightest;
    }
    &:hover {
      cursor: pointer;
    }
    .error-icon {
      width: 1.1rem;
      height: 1.1rem;
      margin-right: $skui-spacing-xs;
    }
    .no-error-icon {
      padding-left: calc(1.6rem + 3px);
    }
  }
  .child:not(:hover),
  .child:not(.displayMenuOnHover) {
    .menu-icons,
    .drag-indicator {
      visibility: hidden;
    }
  }

  .displayMenuOnHover.child:hover:not(.is-dragging):not(:focus) {
    background-color: $effects-hover-onLight;
  }

  .drag-indicator {
    padding-left: $skui-spacing-xs;
    & button {
      cursor: grab;
    }
  }

  .dropdown-button {
    margin-top: $skui-spacing-xxs;
  }
  .menu-icons {
    padding-left: $skui-spacing-xs;
    padding-right: $skui-spacing-s;
  }
  .arrow,
  .child-icon {
    padding-right: $skui-spacing-xs;
  }
  .arrow {
    svg {
      fill: $plainText-onLight-default !important;
    }
  }
  .child-icon,
  .error-icon {
    align-items: center;
    vertical-align: middle;
    display: flex;
    > span {
      display: flex;
    }
    svg {
      fill: $plainText-onLight-lighter !important;
    }
  }
  .error-icon {
    color: $status-error-default;
  }
  .error-icon-structural-item {
    width: 1.1rem;
    height: 1.1rem;
    margin-top: 0.125rem;
    margin-right: $skui-spacing-xs;
  }
  .error {
    border-left: 3px solid var(--status-status-error-default, #ff3d33);
    &.highlight {
      background-color: $status-error-lightest;
    }
  }

  .empty-droppable {
    height: $skui-spacing-l;
    border: 1px dashed $plainText-onLight-default;
  }
  .dragging {
    background-color: $greyscale-lighter;
    border: 2px dashed $border-onLight;
  }
  .dragging > * {
    visibility: hidden;
  }
  .hide {
    display: none;
  }
}
