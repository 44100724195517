@import "../../../assets/designSystem";

.DashboardCardTable {
  .table,
  .card {
    .thead {
      &.withScroll {
        padding-right: 1rem; // scrollbar size
      }
    }
    .tbody {
      max-height: calc(29px * 8);
      overflow-y: auto;
      overflow-x: visible;
      width: fit-content;
      @media (min-width: 992px) {
        width: 100%;
      }
    }
    .tr {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(var(--col-count), 1fr);
      &.firstLabel {
        padding: 0.25rem 0;
        .td:first-child,
        .th:first-child {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          min-width: 9rem;
          max-width: 10rem;
          @media (min-width: 768px) {
            min-width: 20rem;
            max-width: initial;
            text-overflow: initial;
            overflow: visible;
            &.S,
            &.M {
              min-width: 9rem;
            }
          }
        }
      }
    }
    .td,
    .th {
      color: $plainText-onLight-default;
      padding: 0.25rem 1rem;
      text-align: left;
      min-width: 9rem;
      margin: auto 0;
      &.left {
        text-align: left;
        justify-content: flex-start;
      }
      &.right {
        text-align: right;
        justify-content: flex-end;
      }
      &.center {
        text-align: center;
        justify-content: center;
      }
      &.fill {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        align-self: stretch;
      }
      &:not(.fill) {
        white-space: normal;
      }
      &.bold {
        font-weight: bold !important;
      }
      &.singleRow {
        border-right: 1px solid $border-onLight;
        height: 100%;
        padding: 0.5rem;
        &:last-child {
          border-right: none;
        }
      }
    }
    .th {
      align-items: center;
      color: $plainText-onLight-lighter;
      &.singleRow {
        color: $plainText-onLight-lighter;
      }
    }
    .td {
      &.singleRow {
        color: $decoration-blue-darker;
      }
      &.highlight {
        background-color: $decoration-yellow-lighter;
      }
    }
  }

  .card {
    .tr {
      display: flex;
      flex-direction: column;
      padding: 0.5rem;
      &.highlight {
        background-color: $decoration-yellow-lighter;
      }
    }
    .cells {
      display: flex;
      flex-wrap: wrap;
    }
    .td {
      display: inline-block;
      padding: 0;
      min-width: initial;
      width: initial;
      color: $plainText-onLight-lighter;
      &.singleRow {
        &.highlight {
          padding: 0.25rem 0.5rem;
        }
      }
    }
    .total {
      &.bold {
        font-weight: bold !important;
      }
    }
    .th {
      padding: 0;
      min-width: initial;
      width: initial;
      color: $plainText-onLight-default;
    }
  }

  &.espace-rh {
    .table .tr .th {
      @include espace-rh-caption-bold;
    }
    .table .tr .td,
    .card .tr .total {
      @include espace-rh-body2-regular;
      &.singleRow {
        @include espace-rh-h2;
      }
    }
    .card .tr .th {
      @include espace-rh-body1-regular;
    }
    .card .tr .td {
      @include espace-rh-caption-regular;
    }
  }

  &.espace-collab {
    .table .tr .th {
      @include espace-collab-caption-bold;
    }
    .table .tr .td,
    .card .tr .total {
      @include espace-collab-body2-regular;
      &.singleRow {
        @include espace-collab-h2;
      }
    }
    .card .tr .th {
      @include espace-collab-body1-regular;
    }
    .card .tr .td {
      @include espace-collab-caption-regular;
    }
  }
}
