@import "@skillup/ui";

.noReview {
  padding: 16px;
  @include espace-rh-body2-regular;
}

.reviewContainer {
  padding: 16px;
  display: flex;
  flex-flow: column;

  .info {
    @include espace-rh-body2-regular;
    margin-bottom: 10px;
  }

  .cancelButton {
    margin: 16px auto auto;
  }
}
