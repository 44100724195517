@import "../../assets/colors";

.FilterShortcut {
  height: 2rem;
  align-items: center;
  padding: 0 0.75rem;
  border-radius: 100px;
  border: 1px solid $bluey-grey;
  user-select: none;
  flex-shrink: 0;

  .iconContainer {
    margin-right: 0.625rem;
  }

  span.label {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: 0.15px;
    color: $slate;
    white-space: nowrap;
  }

  &.active {
    border-color: $secondary-blue;
    background-color: rgba($secondary-blue, 0.2);

    span.label {
      font-weight: bold;
      color: $secondary-blue;
    }
  }

  &:hover {
    cursor: pointer;
    border-color: $secondary-blue;
    background-color: rgba($secondary-blue, 0.2);

    span.label {
      color: $secondary-blue;
    }
  }
}
