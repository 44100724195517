@import "uiAssets/ColorsVars.scss";

.Filters {
  background: $mainColorL;
  border-right: none;
  flex-grow: 1;
  flex-shrink: 0;

  @media (min-width: 768px) {
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }

  > .showHideFilters {
    @media (min-width: 768px) {
      display: none;
    }

    button {
      background: none;
      border: none;
      display: block;
      margin: 0 auto;
      outline: none;
      padding: 10px;
      width: 100%;
    }
  }

  > .filters {
    display: none;
    padding: 0 20px 15px;
    padding-right: 20px;
    width: 100%;

    @media (min-width: 768px) {
      display: block;
      margin: 0 0 0 auto;
      width: 300px;
    }

    &.show {
      display: block;
    }

    > .currentFiltersContainer {
      background: #fff;
      border-radius: 7px;
      box-shadow: rgba(40, 139, 255, 0.3) 0 3px 12px;
      margin-top: 15px;
      padding: 10px 15px;

      > h2 {
        color: $blue;
        font-size: 20px;
        font-weight: 100;
        margin-bottom: 15px;
      }

      > h3 {
        margin-bottom: 5px;
      }

      > div > button {
        display: inline-block !important;
        margin: 0 5px 5px 0 !important;
      }
    }

    > h2 {
      color: $blue;
      font-size: 16px;
      font-weight: 700;
      margin-top: 18px;
    }

    > h3 {
      color: #333;
      font-size: 13px;
      font-weight: 700;
      margin-bottom: 10px;
      margin-top: 20px;
    }

    > a.addFilterLink {
      align-items: center;
      color: #333;
      display: flex;
      flex-direction: row;
      font-size: 13px;
      justify-content: space-between;
      margin-bottom: 5px;
      text-decoration: none;
      text-transform: capitalize;
      width: 100%;

      > div {
        align-items: center;
        display: flex;
        flex-direction: row;

        > div {
          align-items: center;
          background: #fff;
          border: 1px solid #ccc;
          border-radius: 3px;
          display: flex;
          flex-shrink: 0;
          height: 14px;
          justify-content: center;
          margin-right: 10px;
          width: 14px;
        }
      }

      > span {
        color: #999;
      }
    }

    > button {
      background: none;
      border: none;
      color: $blue;
      font-size: 13px;
      margin-top: 5px;
      text-align: left;
    }
  }
}
