@import "@skillup/ui";

.collaborators {
  background-color: $surface-light-darker;
  padding: $skui-spacing-m $skui-spacing-l;
  width: 100%;
  .filters {
    padding-bottom: $skui-spacing-l;
    .filter {
      margin-right: $skui-spacing-m;
      .inputFilter {
        display: flex;
        height: 42px;
        input {
          width: 245px;
          border-color: $border-onLight;
          font-size: $skui-spacing-s;
          &::placeholder {
            font-style: italic;
          }
        }
        svg {
          fill: $plainText-onLight-lighter;
          height: $skui-spacing-m;
          width: $skui-spacing-m;
          margin-left: -$skui-spacing-l;
        }
      }
    }
    @media (min-width: 624px) {
      display: flex;
    }
  }
}
