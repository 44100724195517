@import "../../../assets/designSystem";

.TextArea {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: inherit;

  .editor {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: inherit;

    &:hover {
      > div:nth-child(1),
      > div:nth-child(3),
      > div:nth-child(4) {
        border-color: $greyscale-medium;
      }
    }

    > div:nth-child(1) {
      // toolbar
      border-color: $border-onLight;
      border-top-left-radius: $skui-spacing-xxs;
      border-top-right-radius: $skui-spacing-xxs;

      button {
        height: 1.5rem;
        margin-left: 0;
        margin-right: $skui-spacing-xxs;
        &:hover {
          background-color: $effects-hover-onLight;
          svg path {
            fill: $plainText-onLight-default;
          }
          &::after {
            border-top-color: $plainText-onLight-default !important;
            border-bottom-color: $plainText-onLight-default !important;
          }
        }
        svg {
          height: 1rem !important;
          width: 1rem !important;
          margin: $skui-spacing-xxs;
          path {
            fill: $plainText-onLight-lighter;
          }
        }
        &::after {
          border-top-color: $plainText-onLight-lighter !important;
          border-bottom-color: $plainText-onLight-lighter !important;
        }
      }
    }

    // > div:nth-child(2) {
    //   border-color: $border-onLight;
    // }

    > div:nth-child(3) {
      // content wrapper
      border-color: $border-onLight;
      overflow-y: auto;
      > div,
      > span {
        padding: $skui-spacing-xs $skui-spacing-s !important;
        @include espace-rh-components-input-value;
        color: $plainText-onLight-default;
        min-height: 4rem;
        input::placeholder {
          color: $plainText-onLight-placeholder;
        }
      }
    }

    > div:nth-child(4) {
      // second toolbar (footer)
      border-color: $border-onLight;
      border-bottom-left-radius: $skui-spacing-xxs;
      border-bottom-right-radius: $skui-spacing-xxs;
    }
  }

  &.empty {
    .editor {
      > div:nth-child(3) {
        > span {
          color: $plainText-onLight-placeholder;
          font-style: italic;
        }
      }
    }
  }

  &.fitParentHeight {
    .editor {
      > div:nth-child(3) {
        flex-grow: 1;
        overflow-y: auto;
      }
    }
  }

  &.error {
    .editor {
      &:hover {
        > div:nth-child(1),
        > div:nth-child(3),
        > div:nth-child(4) {
          border-color: $status-error-darker;
        }
      }
      > div:nth-child(1),
      > div:nth-child(3),
      > div:nth-child(4) {
        border-color: $status-error-default;
        background-color: $status-error-lightest;
        > span,
        > div {
          color: $status-error-darker;
        }
      }
    }
  }

  &.espace-collab {
    .editor {
      > div:nth-child(3) {
        > span,
        > div {
          @include espace-collab-components-input-value;
        }
      }
    }
  }

  &.espace-rh {
    .editor {
      > div:nth-child(3) {
        > span,
        > div {
          @include espace-rh-components-input-value;
        }
      }
    }
  }

  ul,
  ol {
    padding-left: 20px;
  }

  font-size: 14px;

  p,
  h2,
  h3,
  ul,
  li {
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
  }

  h2 {
    font-size: 20px;
    font-weight: 500;
  }

  h3 {
    font-size: 18px;
    font-weight: 500;
  }

  strong {
    font-weight: 600;
  }
}

:global {
  @import "~froala-editor/css/froala_style.min";
  // toolbar
  @import "~froala-editor/css/froala_editor.pkgd.min";
  // for plugins
  @import "~froala-editor/css/plugins/code_view.min";

  .fr-tooltip {
    padding: $skui-spacing-xxs $skui-spacing-xs;
    border-radius: 4px;
    background-color: $surface-dark-darker;
    color: $plainText-onDark-default;
    @include espace-rh-caption-regular;
  }

  // toolbar border line
  .fr-newline {
    margin: 0 !important;
  }

  .fr-wrapper {
    ::selection {
      color: $plainText-onLight-default !important;
      background-color: $action-onLight-lightest !important;
    }
  }

  .fr-box {
    &.espace-rh {
      .fr-placeholder {
        // force to override style props
        font-family: "Noto Sans Display", "Helvetica Neue", sans-serif !important;
        font-size: 1rem !important;
        line-height: 1.5rem !important;
      }
    }
    &.espace-collab {
      .fr-placeholder {
        // force to override style props
        font-family: "Noto Sans Display", "Helvetica Neue", sans-serif !important;
        font-size: 1rem !important;
        line-height: 1.5rem !important;
      }
    }
  }
}
