@import "@skillup/ui";

.PortalFunctionnalities {
  @include espace-collab-h6;
  color: $plainText-onLight-lighter;
  padding-top: $skui-spacing-xs;
  padding-left: $skui-spacing-l;
  .toggleItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: $skui-spacing-xs;
  }
  .portalSpecifics {
    margin-top: $skui-spacing-l;
  }
  .infoText {
    max-width: 660px;
    margin-top: $skui-spacing-s;
  }
}
