.loader {
  height: "100vh";
}

.relative {
  position: relative;
}

.layout {
  display: flex;
  width: 100vw;
  height: 100vh;

  .contentLayout {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    overflow: hidden;

    .childrenLayout {
      display: flex;
      flex: 1;
      overflow: auto;
    }
  }
}

.layoutV1 {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  flex: 1;
}
