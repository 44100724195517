@import "uiAssets/ColorsVars.scss";

.FindUser {
  display: flex;
  flex-direction: column;

  span.label {
    font-size: 12px;
    color: $textColor;
    margin-bottom: 3px;
  }

  .searchInput {
    display: flex;
    flex-direction: column;

    div[class^="results"] {
      width: 100%;
    }
  }

  .content {
    display: flex;
    width: 100%;
    background: $borderColorLight;
    border-radius: 5px;
    padding: 8px 12px;
    min-height: 50px;

    &.hasError {
      background: $errorL;

      .left {
        .icon {
          fill: $errorD !important;
        }

        .meta {
          .name {
            color: $errorD;
          }

          .email {
            color: $error;
          }
        }
      }

      .right {
        span.edit {
          color: $error;

          &:hover {
            color: $errorD;
          }
        }
      }
    }

    .left {
      display: flex;
      align-items: center;
      flex-grow: 1;

      .icon {
        margin-right: 12px;
      }

      .meta {
        display: flex;
        flex-direction: column;

        .name {
          font-weight: 500;
          color: $headerAltColor;
          font-size: 13px;
        }

        .email {
          font-size: 11px;
          color: $smallTextColor;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;

      span.edit {
        font-size: 11px;
        color: $blue;
        text-decoration: underline;

        &:hover {
          cursor: pointer;
          color: $mainColorD;
        }
      }
    }
  }
}
