@import "@skillup/ui";

.PortalAppearence {
  padding: $skui-spacing-s $skui-spacing-l;
  .title {
    @include espace-rh-h3;
    color: $plainText-onLight-lighter;
  }
  .dropZone {
    @include espace-rh-h6;
    user-select: none;
    margin-top: $skui-spacing-xs;
    color: $plainText-onLight-lighter;
    justify-content: center;
    text-align: center;
    height: 200px;
    max-width: 800px;
    border: 2px dashed $border-onLight !important;
    border-radius: 7px;
    svg path {
      opacity: 0.65;
    }
    :hover {
      color: $plainText-onLight-default;
      svg path {
        opacity: 1;
      }
    }

    > div {
      align-items: center;
      cursor: pointer;
      display: flex;
      justify-content: center;
      height: 100%;
    }
  }
}
