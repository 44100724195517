@import "../../assets/designSystem";

.TableOfContentSubItem {
  .button {
    padding: $skui-spacing-xxs $skui-spacing-xs $skui-spacing-xxs $skui-spacing-s;
    display: flex;
    align-items: center;

    border-left: 1px solid transparent;
    outline: 2px solid transparent;
    outline-offset: 1px;

    text-decoration: none;
    font-size: 0.75rem;

    &:active:not(.disabled),
    &.active {
      border-left: 2px solid;
    }
    &:focus {
      border-radius: 2px;
    }
    &.disabled {
      opacity: 0.3;
      cursor: initial;
    }
  }
  &:not(.darkMode) {
    .button {
      border-color: $border-onLight;
      color: $plainText-onLight-lighter;
      &:hover:not(.disabled) {
        color: $plainText-onLight-default;
      }
      &:active:not(.disabled),
      &.active {
        color: $plainText-onLight-default;
        border-color: $action-onLight-default;
      }
      &:focus-visible:not(.disabled),
      &:active {
        outline-color: $action-onLight-default;
      }
    }
  }
  &.darkMode {
    .button {
      border-color: $border-onDark;
      color: $plainText-onDark-darker;
      &:hover:not(.disabled) {
        color: $plainText-onDark-default;
      }
      &:active:not(.disabled),
      &.active {
        color: $plainText-onDark-default;
        border-color: $action-onDark-default;
      }
      &:focus-visible:not(.disabled),
      &:active:not(.disabled) {
        border-color: $border-onDark;
        outline-color: $plainText-onDark-default;
      }
    }
  }
}
