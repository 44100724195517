@import "@skillup/ui";
@import "uiAssets/ColorsVars.scss";

.dashboardPanel {
  width: 100%;
  gap: $skui-spacing-s;

  > .title {
    @include espace-rh-h4;
    color: $plainText-onLight-lighter;
  }

  > .cards {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    overflow: hidden;
    width: 100%;
    gap: $skui-spacing-s;
  }

  .detailedIndicators {
    grid-template-columns: 1fr 1fr;
  }

  .keyIndicators {
    grid-template-columns: repeat(auto-fit, minmax(min(16rem), 1fr));
  }

  .placeholder {
    @include espace-rh-caption-bold;
    color: $placeholder-onLight;
    svg {
      width: 22rem;
    }
  }
}
