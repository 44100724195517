@import "../../assets/colors";

.Details {
  .header {
    border-radius: 4px;
    border: 1px solid $avatar-background;
    height: 2.875rem;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.25rem;
    cursor: pointer;
    user-select: none;

    span.title {
      font-size: 1rem;
      font-weight: 500;
      color: $bluey-grey;
    }
  }

  &.open {
    .header {
      border-radius: 4px 4px 0 0;
      border-bottom-style: dashed;
    }
  }

  .content {
    border-radius: 0 0 4px 4px;
    border-bottom: 1px solid $avatar-background;
    border-left: 1px solid $avatar-background;
    border-right: 1px solid $avatar-background;
    padding: 1.25rem;
  }
}
