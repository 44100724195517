.wrapper {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.3s ease-out;
  &.show {
    grid-template-rows: 1fr;
  }
}

.inner {
  overflow: hidden;
  grid-row: 1;
}
