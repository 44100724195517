@import "@skillup/ui";

.closeCampaign {
  @include espace-rh-body1-regular;

  display: flex;
  flex-direction: column;
  justify-content: stretch;
  padding: $skui-spacing-s $skui-spacing-m;
  padding-top: 0;

  .message {
    margin-top: $skui-spacing-xs;
    > ul {
      margin-left: $skui-spacing-m;
    }
  }

  .information {
    margin-top: $skui-spacing-s;
  }

  .warning {
    margin: $skui-spacing-s 0;
    color: $status-error-darker;
    font-weight: 600;
  }

  .confirmClose > span {
    font-size: 1rem;
  }

  .closeCampaignLoading {
    margin-top: $skui-spacing-m;
  }

  .buttonsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: $skui-spacing-l;

    .button {
      margin-right: $skui-spacing-xs;
      box-sizing: border-box;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
