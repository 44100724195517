.SkillsBlock {
  .select {
    width: 100%;
  }

  > h3 {
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  .draggable {
    max-height: 25vh;
    overflow-y: scroll;
  }
}
