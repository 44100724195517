$primary-blue: #0088c6;
$secondary-blue: #5fc3d7;
$primary-orange: #ff974a;
$secondary-orange: #ffb229;
$secondary-green: #0eaf85;
$primary-yellow: #fdbe00;
$secondary-red: #ff6760;
$secondary-violet: #2668f6;
$secondary-blue-cpf: #164194;
$status-orange: #fef8e7;
$status-green: #eeffed;
$status-red: #feece7;
$status-blue: #cce7f4;
$status-violet: #eff4ff;
$modality-individuel: #eec751;
$modality-digital-learning: #9bbfc4;
$modality-blended-learning: #aeb261;
$modality-presentiel: #5b96ce;
$avatar-background: #e6f3fe;
$avatar-initials: #0683f9;
$b-w-black: #192f4b;
$slate: #535d6d;
$pale-lilac: #eaeaf1;
$bluey-grey: #9aa7b8;
$light-grey: #fafafa;
$hover-red: #ca433d;
$hover-orange: #b74d01;
$secondary-yellow: #f4cc0c;

// Design system colors
$border-on-light: rgba(105, 120, 138, 0.2);
$border-on-dark: rgba(255, 255, 255, 0.4);
$action-blue: #0078ae;
$action-blue-hover: #005b85;
$text-on-light-default: #1a304d;
$text-on-light-lighter: #69798c;
