@import "@skillup/ui";

.searchInput {
  width: fit-content;

  input {
    width: 255px;
    border-color: $border-onLight;
    font-size: $skui-spacing-s;
    &::placeholder {
      font-style: italic;
    }
  }
  svg {
    fill: $plainText-onLight-lighter;
    height: $skui-spacing-m;
    width: $skui-spacing-m;
    margin-left: -$skui-spacing-l;
  }
}

.skillArchivedModal,
.skillConfirmArchiveModal {
  width: 35rem;

  .modalSearch {
    display: flex;
    margin-bottom: $skui-spacing-l;
    min-width: 15rem;

    .searchInput {
      width: 100%;

      input {
        width: 100%;
      }
      svg {
        margin-left: 0;
      }
    }
  }

  .modalArchiveBtn {
    display: inline;
  }
}

// This is custom style that should be normally in the DSSidePanel component in skillup-ui
// In the DSSidePanel component we should have something like:
// <DSSidePanel.Content>
//   <DSSidePanel.Content.Row>
//     <DSSidePanel.Content.Label text='title' />
//     <DSSidePanel.Content.Value value='value' /> or <DSSidePanel.Content.Value>{__JSX__}</DSSidePanel.Content.Value>
//   </DSSidePanel.Content.Row>
// </DSSidePanel.Content>
//
// Temporary solution until we have the DSSidePanel component in skillup-ui:

.skillSidePanel {
  .skillContainer {
    display: flex;
    flex-direction: column;
    justify-content: stretch;

    .skillContent {
      display: flex;
      flex-direction: column;
      justify-content: stretch;

      .skillContentRow {
        margin-bottom: 24px;
      }

      .skillContentRow > div:nth-child(1) {
        margin-bottom: 8px;
      }

      .skillContentRow .skillContentValue {
        @include espace-rh-components-input-value;
      }

      .skillContentRow .skillContentLink {
        @include espace-rh-body1-link;
        &:hover {
          cursor: pointer;
        }
      }

      .skillContentRow .skillContentPlaceholder {
        @include espace-rh-body1-italic;
        color: $placeholder-onLight;
      }

      .skillContentRow > div:nth-child(3) {
        display: none;
      }
    }
  }
}
