@import "uiAssets/ColorsVars.scss";

.ColumnResizer {
  background-color: transparent;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  transform: translateX(-50%);
  width: 100%;
  z-index: 9;

  > div:first-of-type {
    background-color: $blue;
    height: 43px;
    width: 6px;
  }

  > div:last-of-type {
    background-color: $blue;
    height: 100%;
    margin-left: -3px;
    width: 1px;
  }
}
