@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.selectPlan > div {
  background-color: $surface-dark;
  border: 1px solid #ffffff5c;
  > div > div {
    color: #fff;
  }
}

.AccountTrainingScheduleView {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;

  .loadingPlaceholder {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
  }

  .navContainer {
    display: flex;
    flex-direction: column;
    flex: 0;
    margin: $skui-spacing-s;

    nav {
      margin: $skui-spacing-s 0;
    }

    .searchContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .betaMessage {
      font-size: 0.75rem;
      color: $slate;
    }
  }

  .dataTableWrapper {
    position: relative;
    display: flex;
    flex-basis: 100%;
    flex-grow: 1;
    overflow: hidden;
    margin-top: 20px;

    .dataTable {
      z-index: 1;
      border-top: 1px solid #deedff;
      flex-grow: 1;
      overflow: hidden;

      &:not(.dataTableLoading) {
        flex-basis: 100%;
      }
    }
  }
}
