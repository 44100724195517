.DashboardCardGauge {
  overflow-x: visible;
}

.cardContent {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
