@import "../../assets/designSystem";

.Property {
  display: flex;
  align-items: center;
  color: $plainText-onLight-default;
  svg {
    width: 14px;
    height: 14px;
    fill: $plainText-onLight-lighter;
  }
  .label {
    margin-left: 6px;
  }
  &.darkMode {
    color: $plainText-onDark-default;
    svg {
      fill: $plainText-onDark-darker;
    }
  }
  &.espace-rh {
    @include espace-rh-caption-regular;
  }
  &.espace-collab {
    @include espace-collab-caption-regular;
  }
}
