@import "uiAssets/ColorsVars.scss";

.fields {
  width: 100%;
  height: 100%;
  background: #fafafa;

  > div.content {
    height: inherit;
    padding: 20px;
    display: flex;
    justify-content: left;

    > div {
      margin: 0 15px;
    }

    > div.list {
      display: flex;
      flex-direction: column;
      width: 300px;
      height: 100%;
      border: 1px solid #ccc;

      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 10px;
        border-bottom: 1px solid #ccc;
        background: #eee;

        > h3 {
          font-size: 16px;
          font-weight: 500;
          text-align: left;
          flex-grow: 1;
          color: #333;
        }
      }

      > .content {
        overflow: auto;

        > input {
          padding: 5px 10px;
          border: 0;
          border-bottom: 1px solid #aaa;
          width: 100%;
          position: sticky;
          top: 0;
        }

        > h4 {
          font-size: 14px;
          font-weight: 500;
          text-align: left;
          padding: 10px 10px 0;
        }

        > ul {
          list-style: none;
          padding: 10px 15px;

          > li {
            height: 40px;
            cursor: pointer;
            padding: 10px;
            display: flex;
            justify-content: left;
            align-items: center;
            border: 1px solid #888;
            margin: 5px 0;
            border-radius: 5px;
            background: #eee;
            color: #222;

            &:hover,
            &.selected {
              color: #fff;
              background: #009;
            }
          }
        }
      }
    }

    > div.schedules {
      width: 300px;
      height: 90%;
      border: 1px solid blueviolet;

      > h3 {
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        width: 100%;
        padding: 10px;
        border-bottom: 1px solid blueviolet;
      }

      > ul {
        list-style: none;
        padding: 15px;

        > li {
          min-height: 40px;
          padding: 5px 10px;
          border: 1px solid #888;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          margin: 5px 0;

          &.selected {
            background: $success;
            color: #fff;
            font-weight: 500;
          }
        }
      }
    }
  }
}
