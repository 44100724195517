@import "@skillup/ui";

.CompletionRate {
  background-color: $surface-dark;
  color: $plainText-onDark-default; // white;
  font-size: 1.125rem;
  border-radius: 0.5rem;

  .Title {
    //styleName: HR/Body 1/$espace-rh-body1-bold;
    font-family: Noto Sans Display;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }

  .Caption {
    //styleName: HR/Caption/$espace-rh-caption-bold;
    font-family: Noto Sans Display;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    color: $plainText-onDark-darker;
  }

  .Numeric {
    font-family: Noto Sans Display;
    font-size: 40px;
    font-weight: 600;
    line-height: 40px;
    text-align: left;
  }

  .Ordinal {
    color: $plainText-onDark-default;
    font-size: 12px;

    .Bold {
      font-weight: bold;
    }
  }

  .Message {
    font-family: 'Noto Sans Display';
    font-style: italic;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    /* or 150% */
    display: flex;
    align-items: flex-end;

    color: #FFFFFF;
  }

  .Label {
    text-overflow: ellipsis;
    max-width: 10rem;
    display: inline-block;
    overflow: hidden;
  }

  .HelpTooltip {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    background-color: $surface-dark-darker;
    border-radius: 50%;

    svg {
      fill: $plainText-onDark-darker;
      margin: auto;
    }
  }
}
