@import "@skillup/ui";

.CancelModal {
  max-width: 39rem;
  p {
    @include espace-rh-body1-regular;
    color: $text-on-light-default;
  }
  .groupTextInput {
    margin-top: $skui-spacing-s;
  }
  .attendeeNotificationAlert {
    margin: $skui-spacing-s 0;
  }
  footer {
    margin-bottom: $skui-spacing-xs;
  }
}