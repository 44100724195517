@import "uiAssets/ColorsVars.scss";

.CompareBlock {
  align-items: center;
  display: flex;

  > .info {
    background: #fff;
    border-radius: 4px;
    margin-right: 10px;
    padding: 5px;
    width: 320px;

    h5 {
      color: #888;
      font-size: 11px;
    }

    h3 {
      color: #333;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    h4 {
      color: #666;
      font-size: 12px;
    }
  }

  > svg {
    margin-right: 10px;
  }
}
