@import "@skillup/ui";

.createOrManageNoteWrapper {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  padding: $skui-spacing-m;

  .createOrManageNote {
    .inputLine {
      margin-bottom: $skui-spacing-xs;

      .label {
        @include espace-rh-components-input-label;
        color: $plainText-onLight-lighter;
      }

      .input {
        @include espace-rh-components-input-value;
        color: $plainText-onLight-default;
        width: 100%;
        ::placeholder {
          font-style: italic;
        }
      }
    }
  }

  .buttonsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .button {
      margin-right: $skui-spacing-xs;
      box-sizing: border-box;

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}
