@import "../../assets/colors";

$border_radius: 4px;

.TextDropdown {
  position: relative;
  background-color: white;
  user-select: none;
  width: 100%;

  .activeItemContainer {
    border-radius: $border_radius;
    border: 1px solid $pale-lilac;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    font-size: 0.875rem;
    letter-spacing: 0.15px;
    line-height: 1.21;
    z-index: 3;

    &.onError {
      border-color: $secondary-red;
    }

    &.isOpen {
      box-shadow: 0 5px 12px 4px rgba(20, 20, 78, 0.03);
      border-radius: $border_radius $border_radius 0 0;
      border-bottom: none;

      &.itemsAbove {
        border-radius: 0 0 $border_radius $border_radius;
      }
    }

    &.readOnly {
      &:hover {
        cursor: not-allowed;
      }
    }

    &.small {
      padding: 0 0.625rem;

      span.placeholder,
      span.label {
        margin: 0.625rem 0;
        font-size: 0.75rem;
      }

      .searchContainer input.searchInput {
        padding: 0.625rem 0.5rem;
        font-size: 0.75rem;
      }
    }

    .iconsContainer {
      align-items: center;

      .clearBtn {
        margin-right: 0.5rem;
        background-color: $light-grey !important;
        display: none;

        &.visible {
          display: flex;
        }

        .clearIcon > path {
          fill: $bluey-grey !important;
        }

        &:hover {
          cursor: pointer;
          background-color: $pale-lilac !important;

          .clearIcon > path {
            fill: $slate !important;
          }
        }
      }
    }

    .searchContainer {
      align-items: center;
      margin-right: 0.5rem;

      input.searchInput {
        width: 100%;
        padding: 1rem 0.5rem;
        border: none;
        background: none;
        font-size: 0.875rem;
        letter-spacing: 0.15px;
        line-height: 1.21;
        color: $b-w-black;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: 500;

        &::placeholder {
          color: $bluey-grey;
          font-weight: normal;
        }
      }
    }

    &:hover {
      cursor: pointer;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid $pale-lilac;
    }

    span.placeholder {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: $bluey-grey;
      font-weight: normal;
      margin: 1rem 0;
    }

    span.label {
      color: $b-w-black;
      font-weight: 500;
      margin: 1rem 0;
    }
  }

  .itemsDropdown {
    position: absolute;
    width: 100%;
    top: 100%;
    box-shadow: 0 5px 12px 4px rgba(20, 20, 78, 0.03);
    z-index: 2;
    border: 1px solid $pale-lilac;
    border-top: none;
    border-radius: 0 0 $border_radius $border_radius;
    background-color: white;

    &.itemsAbove {
      border: 1px solid #eaeaf1;
      border-bottom: none;
      top: unset;
      bottom: 100%;
      border-radius: $border_radius $border_radius 0 0;
    }

    .item {
      align-items: center;
      justify-content: space-between;
      padding: 1rem;

      &.hidden {
        display: none;
      }

      &.small {
        padding: 0.625rem;

        span.label {
          font-size: 0.75rem;
        }
      }

      span.label {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 0.875rem;
        letter-spacing: 0.15px;
        color: $b-w-black;
        line-height: 1.21;
        font-weight: 500;
      }

      &:hover {
        cursor: pointer;
        background-color: $light-grey;
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid $pale-lilac;
      }
    }
  }
}
