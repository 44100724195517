@import "uiAssets/ColorsVars.scss";

.PortalSettings {
  margin: auto;
  max-width: 700px;
  padding: 30px;
  width: 100%;

  > form {
    width: 100%;
  }

  > section {
    background: #f6f6f6;
    border-radius: 9px;
    margin: 20px 0;
    padding: 15px 20px;
    width: 100%;

    > h2 {
      border-bottom: 1px solid $blue;
      color: $blue;
      font-size: 18px;
      font-weight: 300;
      letter-spacing: 0.5px;
      margin-bottom: 20px;
    }

    > img {
      border-radius: 7px;
      display: block;
      margin: 0 auto 20px;
      max-width: 100%;
      width: 400px;
    }

    > div {
      align-items: center;
      border: 3px dashed #999 !important;
      border-radius: 7px;
      color: #666;
      display: flex;
      font-size: 12px;
      font-weight: 700;
      height: 100px !important;
      justify-content: center;
      margin: 0 auto 20px;
      max-width: 100%;
      padding: 20px 30px;
      text-align: center;
      user-select: none;
      width: 400px !important;
    }
  }

  .dropZone {
    align-items: center;
    border: 3px dashed #999 !important;
    border-radius: 7px;
    color: #666;
    font-size: 13px;
    font-weight: 700;
    margin: 0 auto 10px;
    max-width: 100%;
    text-align: center;
    user-select: none;

    > div {
      align-items: center;
      cursor: pointer;
      display: flex;
      height: 100px !important;
      justify-content: center;
      width: 100%;
    }
  }
}
