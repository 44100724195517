@import "../../assets/colors";

.StatusBadge {
  padding: 0.375rem 0.5625rem 0.375rem 0.375rem;
  border-radius: 4px;
  max-width: 11.25rem;
  align-items: center;

  &.loading {
    width: 11.5rem;
    height: 1.8125rem;
    padding: 0 !important;
    border-radius: 0 !important;

    .stateIcon,
    span.label {
      display: none;
    }
  }

  span.label {
    font-size: 0.75rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-left: 0.375rem;
    white-space: nowrap;
    position: relative;
    top: 1px;
  }

  &.pending {
    background-color: $status-orange;

    span.label {
      color: $primary-yellow;
    }
  }

  &.done {
    background-color: $avatar-background;

    span.label {
      color: $primary-blue;
    }
  }
}
