@import "uiAssets/ColorsVars.scss";

.header {
  width: 100%;
  padding: 0 0 10px 0;
  align-items: stretch;
  box-shadow: 0 4px 10px rgb(0 0 0 / 10%);
  color: $blue;
  font-size: 17px;
  position: relative;
  margin-bottom: 20px;

  .topLine {
    width: 100%;
    height: 10px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    background: $gradientBlue;
  }

  p {
    text-align: center;
    padding-top: 8px;
  }

  .back {
    position: absolute;
    cursor: pointer;
    top: 20px;
    left: 10px;
    width: 15px;
    height: 15px;
  }
}
