@import "uiAssets/ColorsVars.scss";

.CampaignsList {
  flex: 1;
  background-color: #fafafa;
  overflow: auto;

  main.container {
    padding: 5% 15%;

    h1.pageTitle {
      color: $blue;
      font-size: 18px;
      margin-bottom: 35px;
      text-align: center;
    }

    header.actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 35px;

      .tabs {
        flex-shrink: 0;
      }

      .searchInput {
        width: 18.75rem;
      }
    }

    h2 {
      color: $blue;
      font-size: 16px;
      margin: 50px auto 10px;
      max-width: 800px;
      width: calc(100% - 30px);
    }

    section.Item {
      background-color: #f6f6f6;
      border: 1px solid #eee;
      border-radius: 5px;
      color: #666;
      display: flex;
      font-size: 15px;
      justify-content: space-between;
      margin-bottom: 23px;
      padding: 20px 30px;
      text-decoration: none;

      &.noRunningCampaign {
        justify-content: center;
        padding: 15px 30px;
      }

      .details {
        flex-grow: 1;
        flex-shrink: 0;

        h4.name {
          font-weight: 700;
          margin-bottom: 8px;
        }

        ul.props {
          li.meta {
            color: #999;
            display: block;
            font-size: 12px;
            margin-bottom: 4px;
          }
        }
      }

      .actions {
        align-items: center;
        display: flex;
        flex-grow: 0;
        flex-shrink: 0;
        justify-content: center;
      }
    }
  }
}
