@import "@skillup/ui";

.modal{
  width: 35rem;
  max-width: 35rem;

  .content {

    .contentWrapper {
      display: flex;
      flex-direction: column;
      gap: $skui-spacing-m;
      padding-bottom: $skui-spacing-s;

      .warningList {
        @include espace-rh-body1-regular;
        color: $plainText-onLight-default;
        margin-left: 1.5em;
      }
    }
  }

}
