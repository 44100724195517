@import "../../assets/colors";

.Toggle {
  position: relative;
  width: 2.265rem;
  height: 1.25rem;
  border-radius: 2rem;
  border: 1px solid $bluey-grey;
  background-color: white;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  .switch {
    position: absolute;
    top: 0;
    left: 0;
    flex-shrink: 0;
    width: 1.105rem;
    height: 1.105rem;
    border-radius: 50%;
    user-select: none;
    outline: none;
    background-color: $b-w-black;
    transition: 0.2s;
  }

  &.active {
    border-color: $b-w-black;
    background-color: $b-w-black;

    .switch {
      transform: translate(1.03rem);
      background-color: white;
    }
  }

  &.disabled {
    &:hover {
      cursor: not-allowed;
    }

    .switch {
      background-color: $bluey-grey;
    }

    &.active {
      border-color: $bluey-grey;
      background-color: $bluey-grey;

      .switch {
        background-color: white;
      }
    }
  }
}
