.sidePanelConfiguration {
  > div.schedules {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    padding: 10px 20px;

    > button {
      margin-right: 15px;
      height: 30px;
    }
  }

  > div.configuration {
    width: 100%;
    min-width: 600px;
    margin: auto;
    padding: 30px;
    position: relative;

    > h3 {
      font-size: 18px;
    }

    > button {
      height: 30px;
    }

    > button:first-of-type {
      position: absolute;
      top: 0;
      right: 15px;
    }

    > button:nth-of-type(2) {
      position: absolute;
      top: 0;
      right: 150px;
    }

    > div.dragDrop {
      display: flex;
      justify-content: flex-start;
      max-height: 650px;
      padding-top: 20px;

      > .sections {
        display: flex;
        overflow: auto;
        margin-left: 15px;
        padding: 0 15px;
      }
    }
  }
}

div.section {
  overflow: hidden;
  margin-right: 15px;
  min-width: 250px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  padding: 0 10px;
  background-color: #e2e1e0;
  position: relative;

  > h3 {
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    padding: 10px 0;
  }

  > .remove {
    position: absolute;
    top: 3px;
    right: 3px;
    cursor: pointer;
  }

  > .list {
    overflow: auto;
    height: calc(100% - 41px);
    padding: 0 10px;

    > .field {
      display: flex;
      flex-direction: column;
      background: #fff;
      border-radius: 10px;
      padding: 10px;
      margin-bottom: 7px;
      box-shadow: 0 1px 0 rgba(9, 30, 66, 0.25);
      align-items: flex-start;

      > div:first-of-type {
        background: #344563;
        color: #fff;
        width: auto;
        height: 17px;
        line-height: 16px;
        padding: 0 8px;
        border-radius: 4px;
        font-size: 11px;
        font-weight: 500;
      }

      > div:nth-of-type(2) {
        font-size: 15px;
      }
    }
  }
}
