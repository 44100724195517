@import "@skillup/ui";

.container {
  padding: $skui-spacing-s;
  p {
    @include espace-rh-body1-regular;
    color: $plainText-onLight-lighter;
    margin-bottom: $skui-spacing-s;
  }
}

.permissions {
  @include espace-rh-body1-regular;
  color: $plainText-onLight-lighter;
  display: flex;
  padding: $skui-spacing-s;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: $skui-spacing-s;
  flex: 1 0 0;
  align-self: stretch;
}
