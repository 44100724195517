@import "@skillup/ui";

.TemplateRelatedCampaigns {
  p {
    @include espace-rh-body1-regular;
    color: $plainText-onLight-default;
  }
  .list {
    align-items: flex-start;
  }
  .list__item {
    padding-top: $skui-spacing-xs;
    max-width: 80%;
    a {
      @include espace-rh-body1-link;
      color: $action-onLight-darkest;
      padding-right: $skui-spacing-xs;
      &:hover {
        color: $action-onLight-darker;
      }
    }
    .icon {
      color: $plainText-onLight-lighter;
      vertical-align: text-bottom;
      font-size: 0.75rem;
      margin-bottom: 0.125rem;
    }
  }
}
