@import "../../../../assets/designSystem";

.skillPopover {
  width: 480px;
  border: 1px solid rgba(105, 121, 140, 0.2);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 4px;

  .skillContainer {
    display: flex;
    flex-direction: column;
    gap: $skui-spacing-xs;
    padding: $skui-spacing-xs;
    background-color: $surface-light;
    border-bottom: 1px solid $greyscale-medium;

    .skillName {
      font-weight: 600;
      line-height: 1.125rem;
      font-size: 0.75rem;
      color: $plainText-onLight-default;
      overflow: hidden;

      &:first-letter {
        text-transform: uppercase;
      }
    }

    .skillDescription {
      font-weight: 400;
      line-height: 1.125rem;
      font-size: 0.75rem;
      color: $plainText-onLight-lighter;

      &:first-letter {
        text-transform: uppercase;
      }
    }

    .content {
      font-weight: 400;
      line-height: 1.125rem;
      font-size: 0.75rem;
      color: $plainText-onLight-lighter;
      display: flex;
      flex-direction: column;
      padding-left: $skui-spacing-s;

      .levelName {
        font-weight: 600;
        line-height: 1.125rem;
        font-size: 0.75rem;
        color: $plainText-onLight-default;
      }

      div:first-letter {
        text-transform: uppercase;
      }
    }
  }

  .evaluationContainer {
    display: flex;
    flex-direction: column;
    gap: $skui-spacing-xs;
    padding: $skui-spacing-xs;
    background-color: $greyscale-lighter;

    .evaluationTitle {
      font-weight: 600;
      line-height: 1.125rem;
      font-size: 0.75rem;
      color: $plainText-onLight-lighter;
    }

    .content {
      font-weight: 400;
      line-height: 1.125rem;
      font-size: 0.75rem;
      color: $plainText-onLight-lighter;
      display: flex;
      flex-direction: column;
      gap: $skui-spacing-xs;
      padding-left: $skui-spacing-s;

      .evaluatedLevel {
        color: $plainText-onLight-default;
        .levelName {
          font-weight: 600;
        }
      }

      .commentSection {
        display: flex;
        align-items: center;

        .comment {
          font-weight: 400;
          font-size: 0.75rem;
          color: $plainText-onLight-default;
          line-height: inherit;
          background-color: #fff;
          padding: $skui-spacing-xs;
          margin-left: $skui-spacing-xs;
          border-radius: $skui-spacing-xxs;
        }
      }
    }
  }
}
