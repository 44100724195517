@import "@skillup/ui";

.preparationManagerCard {
  background-color: $greyscale-lighter;
  border-radius: 0.25rem;
  border: 1px solid $greyscale-light;
}

.coordinatorCard {
  border-radius: 0.25rem;
  border: 1px solid $greyscale-light;
}
