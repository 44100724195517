.input {
  display: flex;
  flex-direction: column;
  max-width: 36rem;
  margin: auto;
  margin-bottom: 1rem;
}

.small {
  min-width: 16.875rem;
}
