@import "uiAssets/ColorsVars.scss";

.Toggle {
  display: inline-block;

  .lbl {
    background: #ddd;
    border-radius: 100px;
    cursor: pointer;
    display: block;
    height: 11px;
    margin: 10px;
    position: relative;
    transition: all 0.3s ease;
    width: 27px;

    &:after {
      background: #fff;
      border-radius: 100px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
      content: "";
      display: block;
      height: 16px;
      left: -2px;
      position: absolute;
      top: -3px;
      transition: all 0.3s ease;
      width: 16px;
    }
  }

  .cbx:checked ~ label {
    background: rgba(39, 130, 255, 0.533);
  }

  .cbx:checked ~ label:after {
    background: #2782ff;
    left: 12px;
  }

  .cbx:disabled ~ label {
    background: #d5d5d5;
    pointer-events: none;
  }

  .cbx:disabled ~ label:after {
    background: #bcbdbc;
  }

  .cntr {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .hidden {
    display: none;
  }
}
