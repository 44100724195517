@import "@skillup/ui";

// General form in table child
.table__general {
  padding: $skui-spacing-s $skui-spacing-s $skui-spacing-xs;
  gap: $skui-spacing-s;
}

.table__general__description {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.table__general_button--underline {
  text-decoration: underline;
  color: $action-onLight-darkest;
  button {
    font-weight: normal !important;
  }
}

.table__addColumnButton {
  padding: $skui-spacing-xs $skui-spacing-s;
}

// Column styles
.table__column__content {
  margin: $skui-spacing-xs $skui-spacing-s;
  border: 1px solid $greyscale-dark;
}

.table__column__content__header {
  padding: $skui-spacing-xs;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  color: $surface-light;
  background-color: $greyscale-dark;

  button {
    color: $surface-light !important;
    border-color: $surface-light !important;
  }
  svg {
    fill: $surface-light !important;
  }
}
.table__column__content__header__buttons {
  display: flex;
  gap: $skui-spacing-xs;
  span {
    display: flex;
  }
}

.table__column__content__form {
  padding: $skui-spacing-xs;
  gap: $skui-spacing-xs;
  align-items: stretch;
  align-self: stretch;
}

.table__column__content__form__select__tooltip { // [CSB] changes disabled
  display: flex;
}

.table__column__content__form__select { // [CSB] changes disabled
  flex: 1;
}

.table__column--dropdown__choices {
  gap: $skui-spacing-xxs;
}

.table__column--dropdown__choices__item {
  gap: $skui-spacing-xs;
}

.table__column--dropdown__choices__item__input {
  flex: 1;
}

.table__column--dropdown__choices__item__deleteButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

// Permissions style

.table__permissions {
  padding: $skui-spacing-s;
}

.table__permissions__assistiveText {
  color: $plainText-onLight-lighter;
  @include espace-rh-body1-regular;
}

.table__column__permission {
  padding: $skui-spacing-s;
}

.table__column__permissions__actionChoice {
  padding: $skui-spacing-xxs 0;
}

.table__column__permission__actionLabel {
  color: $plainText-onLight-lighter;
  @include espace-rh-components-input-label;
}
