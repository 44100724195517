@import "@skillup/ui";

.warning {
  margin-top: 0.25rem;
  color: $secondary-red;
}

.employeeList,
.managerList {
  .sectionTitleWrapper {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    flex: 1;

    .sectionTitle {
      border-radius: 4px;
      padding: 5px;
      font-size: 0.7rem;
      font-weight: bold;
      background: rgba(42, 100, 149, 0.3);
    }

    .subSectionWrapper {
      font-size: 0.75rem;
      font-weight: bold;
      color: black;
    }
  }

  .informationBlockWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .informationBlockLabel {
      font-weight: 600;
      text-overflow: ellipsis;
      margin-right: 20px;
      font-size: 0.7rem;
    }

    .informationBlockInput {
      flex: 1;
      width: auto;
      font-weight: 600;
      font-size: 0.75rem;
    }
  }

  .listElement {
    margin: 4px 0;
  }

  .addButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .add {
      font-size: 0.8rem;
      color: $primary-blue;
      cursor: pointer;
      text-decoration: underline;
      font-weight: bold;
      margin: 0 5px;
    }
  }
}

.personalisableFieldBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .valueInput,
  .labelInput {
    display: flex;
    font-weight: 500;
    font-size: 0.75rem;
    margin-left: 10px;
    padding: 1.05rem 0.625rem;
    width: auto;

    &:first-child {
      margin-left: 0;
    }
  }

  .valueInput {
    flex: 1;
  }

  .dropDownType {
    display: flex;
    flex: 1;
    margin-left: 10px;
    width: auto;

    .activeItemContainer {
      z-index: 2;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex: 1;

      & + div {
        z-index: 3;
      }
    }
  }

  .delete {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 10px;
  }
}
