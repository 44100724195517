@import "../../../../assets/designSystem";

.selectFilter {
  position: relative;

  .activeContainer {
    justify-content: space-between;
    align-items: center;
    background-color: $plainText-onDark-default;
    border-radius: 4px;
    user-select: none;
    padding: $skui-spacing-xs;

    &:hover {
      cursor: pointer;
    }
  }

  .selectFilterContainer {
    z-index: 2;
    background: $plainText-onDark-default;
    padding: $skui-spacing-xxs $skui-spacing-xs 0 $skui-spacing-xs;
    width: 100%;
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: auto;

    span {
      font-size: 1rem;
    }

    .searchInput {
      padding: $skui-spacing-xs;
      margin-bottom: $skui-spacing-s;
    }

    .selectAll {
      padding-bottom: $skui-spacing-xs;
      margin-bottom: $skui-spacing-xs;
    }

    .options {
      &.all {
        margin-left: $skui-spacing-m;
      }
    }

    .searchSelectLine {
      display: flex;
      margin-bottom: $skui-spacing-xs;

      &.collab {
        padding-bottom: $skui-spacing-xxs;
        margin-bottom: $skui-spacing-xxs;
        border-bottom: solid 1px #eaeaf1;
      }

      &:last-child {
        margin-bottom: 0;

        &.collab {
          padding-bottom: 0;
          margin-bottom: 0;
          border-bottom: none;
        }
      }
    }

    .empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 0.8125rem;
      color: #69798c;
      padding: 0 10px;
      margin: $skui-spacing-m 0;
    }
  }
}
