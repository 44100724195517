@import "../../assets/designSystem";

.ToggleButtonGroup {
  position: relative;
  display: inline-block;
  border-radius: $skui-spacing-xxs;
  padding: 2px;
  background-color: $decoration-blue-lighter;
  &.darkMode {
    background-color: $surface-dark-darker;
  }

  .indicator {
    position: absolute;
    transition: all ease-in-out 100ms;
    border-radius: $skui-spacing-xxs;
    height: 30px;
    box-shadow: 1px 1px 3px rgba(0, 118, 173, 0.25);

    background: $surface-light;
    &.darkMode {
      background: $action-onDark-default;
    }
  }

  .button {
    border: none;
    background-color: transparent;
    cursor: pointer;

    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    padding: $skui-spacing-xxs $skui-spacing-xs;
    text-decoration: none;
    transition: all ease-in-out 200ms;
    white-space: nowrap;

    border-radius: $skui-spacing-xxs;

    color: $action-onLight-darker;
    &.darkMode {
      color: $plainText-onDark-darker;
    }

    &.icon {
      line-height: 1.25;
      &.active {
        font-weight: 100;
      }
    }
    .tooltip {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:disabled {
      opacity: 0.3;
      cursor: default;
    }
    &:focus-visible {
      outline: 2px solid $action-onLight-default;
      outline-offset: 2px;
      &.darkMode {
        outline: 2px solid $plainText-onDark-default;
      }
    }
    &.active {
      color: $action-onLight-darkest;
      font-weight: 600;
      &.darkMode {
        color: $plainText-onLight-default;
      }
    }
    &:not(.active) {
      // padding: $skui-spacing-xxs 0.625rem;
      &:hover {
        color: $action-onLight-darkest;
        background: $effects-hover-onLight;
        &.darkMode {
          color: $action-onDark-lighter;
          background: $effects-hover-onDark;
        }
      }
    }
  }

  &.espace-collab {
    button {
      @include espace-collab-font;
    }
  }

  &.espace-rh {
    button {
      @include espace-rh-font;
    }
  }
}
