@import "../../../assets/designSystem";

.DashboardCardView {
  margin: 0 auto;
  max-width: 75rem;
  display: grid;
  grid-gap: 2rem;

  grid-template-columns: repeat(1, 1fr);
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  .cardWrapper {
    min-width: 15rem;
    width: 100%;
    grid-auto-rows: max-content;
    display: flex;
    border-radius: 4px;

    transition: background-color 350ms ease;
    &.M {
      grid-column: span 1;
      @media (min-width: 768px) {
        grid-column: span 2;
      }
    }
    &.L {
      grid-column: span 1;
      @media (min-width: 768px) {
        grid-column: span 2;
      }
      @media (min-width: 1024px) {
        grid-column: span 3;
      }
    }
  }
}
