@import "../../assets/designSystem";

.NavigationTabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  padding-left: $skui-spacing-l;
  &.spacingLeft {
    padding-left: $skui-spacing-3xl;
  }
  @media (max-width: 768px) {
    padding-left: $skui-spacing-xs;
    justify-content: space-between;
    &.spacingLeft {
      padding-left: $skui-spacing-m;
    }
    &.moreThan3 {
      justify-content: flex-start;
      flex-wrap: nowrap;
      overflow-x: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        height: 0;
      }
    }
  }

  .spacer {
    flex-grow: 1;
  }

  .tab {
    border: none;
    background-color: transparent;
    text-decoration: none;
    height: 3.5rem;
    margin-right: $skui-spacing-s;
    padding-top: $skui-spacing-xs;
    @media (max-width: 768px) {
      margin-right: $skui-spacing-xxs;
      flex-grow: 1;
    }
    cursor: pointer;
    &.disabled {
      cursor: default;
      opacity: 0.3;
    }
    &:active {
      border-radius: 0.25rem;
      box-shadow: none;
      outline-offset: 0.125rem;
      color: $plainText-onDark-default;
    }
    .content {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      margin: 0 $skui-spacing-xs;
      border-bottom: 0.25rem solid transparent;
      &.active,
      &:hover:not(.disabled) {
        color: $plainText-onDark-default;
      }
    }
  }

  &.darkMode {
    background-color: $surface-dark;
    .tab {
      &:hover:not(.disabled),
      &:active {
        background-color: $effects-hover-onDark;
      }
      &:active {
        outline: 0.125rem solid $action-onDark-default;
        .content {
          border-bottom-color: $border-onDark;
        }
      }
      .content {
        color: $plainText-onDark-default;
        &.active {
          color: $plainText-onDark-default;
          border-bottom-color: $action-onDark-default;
        }
        &:hover:not(.active):not(.disabled) {
          border-bottom-color: $border-onDark;
        }
      }
    }
  }

  &:not(.darkMode) {
    background-color: $surface-light;
    .tab {
      &:hover:not(.disabled),
      &:active {
        background-color: $effects-hover-onLight;
      }
      &:active {
        outline: 0.125rem solid $action-onLight-darker;
        .content {
          border-bottom-color: $border-onLight;
        }
      }
      .content {
        color: $plainText-onLight-default;
        &.active {
          color: $plainText-onLight-default;
          border-bottom-color: $action-onLight-default;
        }
        &:hover:not(.active):not(.disabled) {
          color: $plainText-onLight-default;
          border-bottom-color: $border-onLight;
        }
      }
    }
  }

  &.espace-rh {
    .tab .content {
      @include espace-rh-body2-regular;
      &.active {
        font-weight: 600;
      }
    }
  }

  &.espace-collab {
    .tab .content {
      @include espace-collab-body2-regular;
      &.active {
        font-weight: 600;
      }
    }
  }
}
