@import "@skillup/ui";

.NotAvailableInNarrowView {
  @include espace-rh-body1-bold;
  background-color: $surface-dark-darker;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  span {
    margin-top: $skui-spacing-l;
    text-align: center;
    padding: 0 2rem;
    color: white;
  }
}
