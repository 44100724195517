@import "../../assets/designSystem";

.NavigationSubItem {
  padding: $skui-spacing-xxs 0 $skui-spacing-xxs $skui-spacing-xl;
  @media (max-width: 768px) {
    padding-left: $skui-spacing-xxl;
  }
  .button {
    width: 100%;
    text-decoration: none;
    padding: $skui-spacing-xxs $skui-spacing-xs $skui-spacing-xxs $skui-spacing-s;
    display: flex;
    align-items: center;
    text-align: left;
    border-radius: 8px 0px 0px 8px;
    cursor: pointer;
    border: 2px solid transparent;
    border-right: none;
    @media (max-width: 768px) {
      padding-left: $skui-spacing-m;
    }
  }
  &.darkMode {
    .button {
      color: $plainText-onDark-darker;
      &:hover {
        color: $plainText-onDark-default;
      }
      &:active,
      &.active {
        background-color: $surface-light;
        color: $plainText-onLight-default;
      }
      &:focus {
        border-color: $action-onDark-default;
      }
    }
  }
  &:not(.darkMode) {
    .button {
      color: $plainText-onLight-lighter;
      &:hover {
        color: $plainText-onLight-default;
      }
      &:active,
      &.active {
        background-color: $decoration-blue-lighter;
        color: $plainText-onLight-default;
      }
      &:focus {
        border-color: $action-onLight-default;
      }
    }
  }
}
