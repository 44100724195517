@import "@skillup/ui";

.container {
  padding: $skui-spacing-s;

  .bottomContainer {
    display: flex;
    flex-wrap: wrap;

    .subSection {
      flex: 1;

      &:first-child {
        margin-right: $skui-spacing-l;
      }

      .labelCollab {
        margin: $skui-spacing-s 0;
      }

      .dataCollab {
        .personalisableFieldBlock {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;

          .fieldBlockTooltip {
            display: flex;
            flex: 1;
            .labelInput {
              display: flex;
              flex: 1;
              margin: $skui-spacing-xxs;
              min-width: 0;
  
              > div {
                flex: 1;
              }
            }

            .delete {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              margin-left: 0.625rem;
            }
          }
        }

        .buttons {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: $skui-spacing-xxs;

          span {
            white-space: normal;
          }

          .firstButton {
            margin-bottom: $skui-spacing-xs;
          }
        }
      }
    }
  }
}
