@import "../../assets/designSystem";

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.Dropdown {
  display: inline-flex !important;
  align-self: center;
  &Item {
    border: none;
    background-color: transparent;
    padding: $skui-spacing-xs $skui-spacing-s;
    .wrapper {
      display: flex;
      align-items: center;
      .leftSide {
        display: flex;
        width: 100%;
        max-width: calc(35rem - 2 * #{$skui-spacing-s});
        max-height: 4.0625rem;
        align-items: center;
        .label {
          color: $plainText-onLight-default;
          text-align: left;
          overflow: hidden;
          white-space: pre-line;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .icon {
          color: $plainText-onLight-default;
          display: flex;
          align-items: center;
          width: $skui-spacing-s;
          margin-right: $skui-spacing-s;
        }
      }

      .loading {
        width: $skui-spacing-s;
        height: $skui-spacing-s;
        border: solid 0.125rem #c9ced4;
        border-radius: 50%;
        border-right-color: transparent;
        border-bottom-color: transparent;
        -webkit-transition: all 0.5s ease-in;
        -webkit-animation-name: rotate;
        -webkit-animation-duration: 1s;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;

        transition: all 0.5s ease-in;
        animation-name: rotate;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
    }
    &:hover:not(.disabled),
    &:focus:not(.disabled),
    &.focus:not(.disabled) {
      background: $effects-hover-onLight;
    }
    &:focus:not(.disabled),
    &.focus:not(.disabled) {
      outline: 1px solid $action-onLight-default;
      outline-offset: 1px;
      border-radius: 2px;
    }
    &.disabled {
      .label {
        opacity: 0.3;
      }
    }
    &:not(.disabled) {
      cursor: pointer;
    }
  }

  &Divider {
    border-top: $border-onLight;
    margin: $skui-spacing-xs 0;
  }

  &Title {
    padding: $skui-spacing-xs $skui-spacing-s;
    color: $plainText-onLight-lighter;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.125rem;
  }

  &.espace-rh {
    .label {
      @include espace-rh-label;
    }
    .DropdownTitle {
      @include espace-rh-font;
    }
  }

  &.espace-collab {
    .label {
      @include espace-collab-label;
    }
    .DropdownTitle {
      @include espace-collab-font;
    }
  }
}

.DropdownMenu {
  max-width: 35rem;
  display: flex;
  flex-direction: column;
  padding: $skui-spacing-xs 0;

  background: #ffffff;
  border: 1px solid $border-onLight;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 4px;

  &.espace-rh {
    .label {
      @include espace-rh-label;
    }
    .DropdownTitle {
      @include espace-rh-font;
    }
  }

  &.espace-collab {
    .label {
      @include espace-collab-label;
    }
    .DropdownTitle {
      @include espace-collab-font;
    }
  }
}
