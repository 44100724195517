@import "../../assets/designSystem";

.NavigationSideNav {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 13rem;
  width: 13rem;
  height: 100vh;
  overflow: hidden;

  &.hide {
    display: none;
  }

  .scrollable {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      width: 0;
    }
  }

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 80vw;
    width: 80vw;
    z-index: 22;

    &:not(.hide) {
      transition: 0.5s;
      transform: translateX(0);
    }
    &.hide {
      display: initial;
      transition: 0.5s;
      transform: translateX(-100%);
      opacity: 0;
    }
  }

  // .closeButton {
  //   width: 100%;
  //   display: flex;
  //   justify-content: flex-end;
  //   padding: $skui-spacing-s;
  // }

  .buttons {
    display: flex;
    padding: $skui-spacing-s;
    justify-content: space-between;
    width: 100%;
  }

  .header {
    display: flex;
    flex-direction: column;
    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 6rem;

      > div {
        height: auto;
        width: auto;
      }
      .backgroundLogo {
        height: 100%;
        background: white;
        border: 0.5rem solid white;
        border-radius: 0.5rem;
        max-width: 8rem;
        max-height: 4rem;
      }
    }
  }

  .primary {
    margin-bottom: auto;
  }

  .secondary {
    margin-bottom: $skui-spacing-s;
  }

  &.darkMode {
    background-color: $surface-dark-darker;
  }
  &:not(.darkMode) {
    background-color: $surface-light;
  }

  &.espace-rh {
    @include espace-rh-caption-bold;
    @media (max-width: 768px) {
      @include espace-rh-components-sidenav-mobile;
    }
  }

  &.espace-collab {
    @include espace-collab-caption-bold;
    @media (max-width: 768px) {
      @include espace-collab-components-sidenav-mobile;
    }
  }
}

/****************
  * Overlay
  ****************/
.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $surface-scrim;
  z-index: 21;
  @media (max-width: 768px) {
    display: initial;
    &.hide {
      display: none;
    }
  }
}
