@import "uiAssets/ColorsVars.scss";

.TrainingResultsLoadingView {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media (min-width: 768px) {
    align-items: flex-start;
    flex-direction: row;
  }

  > aside {
    background: $mainColorL;
    flex-grow: 0;
    flex-shrink: 0;
    height: 30px;
    margin-bottom: 10px;
    width: 100%;

    @media (min-width: 768px) {
      border-right: 1px solid #ddd;
      height: 100%;
      margin-bottom: 0;
      width: 300px;
    }
  }

  > main {
    flex-grow: 1;
    flex-shrink: 1;

    > header {
      border-bottom: 1px solid #ddd;
      padding: 20px 30px;

      .heading {
        background: $mainColorL;
        height: 50px;
        margin-bottom: 20px;
        max-width: 780px;
      }

      > nav {
        align-items: center;
        display: flex;
        max-width: 780px;

        > svg {
          flex-grow: 0;
          flex-shrink: 0;
          margin-right: 10px;
        }

        > div {
          background: $mainColorL;
          flex-grow: 1;
          height: 30px;
          min-width: 30px;
        }
      }
    }

    > div {
      background: $mainColorL;
      border: 1px solid #ddd;
      border-radius: 5px;
      height: 175px;
      margin: 30px;
      max-width: 750px;
    }
  }
}
