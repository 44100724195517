@import "uiAssets/ColorsVars.scss";

.TextInput {
  display: block;
  margin: 15px 0;
  padding-top: 0;
  position: relative;
  transition: padding 200ms ease-out;

  > span {
    display: block;
    font-size: 13px;
    z-index: 9;

    &.label {
      font-size: 14px;
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 15px;
      transition: top 200ms ease-out, font-size 200ms ease-out;
      > span {
        color: $error;
      }
    }

    &.bottom {
      text-align: right;
    }
  }

  .description {
    position: relative;
    top: 35px;
    transition: top 200ms ease-out, font-size 200ms ease-out;
    font-size: 12px;
    margin: 5px 0 10px;
    white-space: pre-line;
  }

  > div {
    cursor: text;
    perspective: 1000px;
    position: relative;

    > .underline {
      bottom: -2px;
      height: 3px;
      left: 0;
      position: absolute;
      transition: width 300ms ease-out;
      width: 0;
    }

    > input,
    > textarea {
      appearance: none;
      border: none;
      border-radius: 5px 5px 0 0;
      display: block;
      font-size: 14px;
      letter-spacing: 0.5px;
      opacity: 0;
      padding: 10px 20px;
      position: relative;
      resize: none;
      transform: rotateX(90deg);
      transform-origin: 50% 100%;
      transition: opacity 200ms ease-out, padding 200ms ease-out, transform 200ms ease-out;
      width: 100%;
      &.right {
        padding: 10px 20px 10px 0px;
      }
    }

    > .textAreaDuplicate {
      left: 0;
      letter-spacing: 0.5px;
      padding: 15px 20px;
      position: absolute;
      top: 0;
      visibility: hidden;
      width: 100%;
      z-index: -1;
    }

    > input:-webkit-autofill {
      box-shadow: 0 0 0 1000px #f6f6f6 inset !important;
    }

    > .iconContainer {
      display: flex;
      height: 100%;
      left: 14px;
      opacity: 0;
      position: absolute;
      top: 0;
      transform: rotateX(90deg);
      transform-origin: 50% 100%;
      transition: transform 200ms ease-out, opacity 200ms ease-out;
      width: 20px;
      z-index: 99;

      > .icon {
        display: block;
        height: 100%;
        margin: auto;
        width: 100%;
      }

      &.right {
        right: 14px;
        left: unset;
      }
    }
  }

  &.opened {
    padding-top: 17px;

    > span.label {
      font-size: 12px;
      top: 0;
    }

    > div {
      > .underline {
        width: 100%;
      }

      > input,
      > textarea {
        opacity: 1;
        padding: 15px 20px;
        transform: rotateX(0deg);
      }

      > .iconContainer {
        opacity: 1;
        transform: rotateX(0);
      }
    }

    .description {
      top: 0;
      font-size: 12px;
    }
  }

  &.hasIcon {
    > div > .textAreaDuplicate {
      padding: 15px 20px 15px 50px;
    }

    > div > input,
    > div > textarea {
      padding: 10px 20px 10px 50px;
    }
    &.hasDefaultValue {
      > div > input,
      > div > textarea {
        padding: 15px 20px 15px 50px;
      }
      &.small {
        > div > input,
        > div > textarea {
          padding: 10px 20px 10px 45px;
        }
      }
    }
  }

  &.right {
    > div > .textAreaDuplicate {
      padding: 15px 50px 15px 20px;
    }

    > div > input,
    > div > textarea {
      padding: 10px 50px 10px 20px;
    }
    &.hasDefaultValue {
      > div > input,
      > div > textarea {
        padding: 15px 50px 15px 20px;
      }
      &.small {
        > div > input,
        > div > textarea {
          padding: 10px 45px 10px 20px;
        }
      }
    }
  }

  &.small {
    > div {
      > .underline {
        height: 2px;
      }

      > input,
      > textarea {
        font-size: 12px;
      }

      > .iconContainer {
        > .icon {
          height: 75%;
          width: 75%;
        }
      }
    }
  }

  .error {
    color: $error;
    font-size: 12px;
    margin-top: 14px;
    display: flex;
    vertical-align: middle;
  }
}
