@import "uiAssets/ColorsVars.scss";

.PortalLists {
  .draggedItem {
    background: red !important;
    font-size: 25px;
  }

  .card {
    width: auto;
    height: auto;
    background: #fff;
    box-shadow: 0 0 0 0.0625rem rgba(0, 0, 0, 0.02),
      0 0.0625rem 0.25rem 0.0625rem rgba(0, 0, 0, 0.06);
    border-radius: 7px;
    margin: 20px;

    > header {
      display: flex;
      align-items: center;
      border-radius: 7px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      padding: 8px 15px;
      background: #f5f5f5;
      color: #4c4c4c;
      font-weight: 600;
      border-bottom: 1px solid #e5e5e5;
      font-size: 14px;

      > div {
        padding-top: 5px;
      }
    }

    > div.content {
      display: flex;
      flex-wrap: wrap;
      padding: 20px;
    }
  }

  .groups {
    > header {
      justify-content: space-between;
    }

    > div.content {
      > div.group {
        color: $blue;
        background: #fff;
        border: $blue;
        border: 1px solid $blue;
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 4px;
        padding: 5px 10px;
        cursor: pointer;
        margin-right: 10px;
        margin-top: 5px;
        margin-bottom: 5px;

        &.active {
          background: $blue;
          color: #fff;
        }

        &:not(.active) {
          &:hover {
            background: $blue;
            color: #fff;
          }
        }

        > div {
          justify-content: center;
          display: flex;
          padding-left: 10px;
          cursor: pointer;
        }
      }

      > div.addGroup {
        color: $blue;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-top: 5px;

        > input {
          width: 0;
          transition: width 0.5s ease-out;
        }

        &.visible {
          > input {
            width: 250px;
          }
        }
      }
    }
  }

  .settings {
    > header > svg {
      margin-right: 8px;
    }
    .toggle {
      display: flex;
      align-items: center;

      div {
        flex-shrink: 0;
      }

      label {
        margin-left: 10px;
      }
    }
  }
}
