@import "@skillup/ui";

.unavailableDashboard {
  gap: $skui-spacing-l;
  padding: $skui-spacing-l;
  align-items: center;

  width: 100%;

  > pre {
    @include espace-rh-caption-regular;

    text-align: center;
    color: $placeholder-onLight;
    font-size: 16px;
    line-height: 24px;
  }
}
