.fieldsSettings {
  width: 330px;
  height: auto;
  position: absolute;
  right: 40px;
  background: #fff;
  z-index: 2;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 13px;

  > h3 {
    font-size: 15px;
    font-weight: 500;
    padding: 10px 5px;
    text-align: center;
    width: 100%;
  }

  > div.separator {
    height: 1px;
    width: 20%;
    margin: 10px auto;
    background: #ccc;
  }

  > div.selectedFields {
    width: 100%;
    padding: 0 15px;
    max-height: 300px;
    overflow: auto;

    > div.fieldCheckBox {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 3px;

      > svg {
        margin-right: 5px;
      }
    }
  }

  > div.fieldsPool {
    padding: 0 15px;

    > div.searchInput {
      > input {
        height: 33px;
        padding: 5px;
        margin-bottom: 10px;
        font-size: 13px;
      }
    }

    > div.list {
      height: 300px;
      overflow: auto;

      > div.fieldLabel {
        cursor: pointer;
        align-items: center;
        padding: 3px;
        padding-left: 21px;
      }
    }
  }
}
