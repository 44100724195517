@import "@skillup/ui";

.searchInput {
  width: fit-content;

  input {
    width: 255px;
    border-color: $border-onLight;
    font-size: $skui-spacing-s;
    &::placeholder {
      font-style: italic;
    }
  }
  svg {
    fill: $plainText-onLight-lighter;
    height: $skui-spacing-m;
    width: $skui-spacing-m;
    margin-left: -$skui-spacing-l;
  }
}

.actionArchivedModal {
  width: 35rem;

  .modalSearch {
    display: flex;
    margin-bottom: $skui-spacing-l;
    min-width: 15rem;

    .searchInput {
      width: 100%;

      input {
        width: 100%;
      }
      svg {
        margin-left: 0;
      }
    }
  }

  .status-indicator {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    margin-right: 11px;
  }
}