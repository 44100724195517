@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.histogramLabel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > .name {
    @include espace-rh-caption-bold;
    color: $plainText-onLight-lighter;
  }
  > .value {
    @include espace-rh-body1-bold;
    color: $plainText-onLight-default;
  }
}

.xLabel {
  @include espace-rh-caption-bold;
  color: $plainText-onLight-lighter;
}
.horizontalLabel {
  text-align: center;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-height: 1lh;
}
.yLabel {
  @include espace-rh-caption-bold;
  color: $plainText-onLight-lighter;
}

.footer {
  > * {
    color: $plainText-onLight-lighter;
    fill: $plainText-onLight-lighter;
  }

  .sortActionTooltip {
    color: $plainText-onDark-default;
  }

  > .pagination {
    @include espace-rh-caption-regular;

    justify-content: flex-end;
    align-items: center;
    gap: $skui-spacing-xs;

    .disabled {
      color: $plainText-onLight-lighter;
    }
  }
}

.defaultTooltip {
  padding: $skui-spacing-s;
  border-radius: $skui-spacing-s;
  background-color: $surface-light;

  opacity: 0.9;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  border-radius: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: $skui-spacing-xs;

  > .name {
    @include espace-rh-caption-bold;
    color: $plainText-onLight-lighter;
  }
  > .value {
    @include espace-rh-h3;
    color: $plainText-onLight-default;
  }
}
