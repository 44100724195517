@import "../../assets/designSystem";

.Select {
  display: inline-block;
  color: $plainText-onLight-default;
  min-width: 15rem;
  width: 100%;

  &.customWidth {
    min-width: initial;
  }

  &.espace-rh {
    @include espace-rh-components-input-value;
    .missingItems {
      @include espace-rh-components-input-help-text;
    }
  }
  &.espace-collab {
    @include espace-collab-components-input-value;
    .missingItems {
      @include espace-collab-components-input-help-text;
    }
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .missingItems {
    margin-right: $skui-spacing-s;
    color: $plainText-onLight-default;
    cursor: pointer;
    &.darkMode {
      color: $plainText-onDark-default;
    }
  }

  .placeholder {
    color: $plainText-onLight-placeholder;
    font-style: italic;
    cursor: pointer;

    &.darkMode {
      color: $plainText-onDark-darker;
    }
  }

  .chip {
    margin-right: $skui-spacing-xs;
  }
}

.checkbox {
  width: 100%;
  min-height: 2rem;
  flex-grow: 1;
  padding: $skui-spacing-xs $skui-spacing-s;
  cursor: pointer;
  &.withAll {
    margin-left: $skui-spacing-s;
  }
}

.createLabel {
  color: #0066ff;
}

.selectItem {
  &.espace-rh {
    @include espace-rh-components-input-value;
    .missingItems {
      @include espace-rh-components-input-help-text;
    }
  }
  &.espace-collab {
    @include espace-collab-components-input-value;
    .missingItems {
      @include espace-collab-components-input-help-text;
    }
  }
}
