@import "uiAssets/ColorsVars.scss";

.AccountCompanySwitch {
  align-items: flex-start;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  overflow: auto;

  .wrapper {
    max-width: 700px;
    margin-top: 110px;
  }

  h1 {
    color: #26e;
    font-size: 28px;
    margin-bottom: 0;
    text-align: center;
  }

  h5 {
    color: #aaa;
    font-size: 10px;
    text-align: center;
    margin-bottom: 50px;
  }

  hr {
    margin: 20px 0;
  }

  h2 {
    color: #26e;
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;
  }

  .quickAccess {
    button {
      background: 0;
      border: 1px solid #d3d3d3;
      border-radius: 5px;
      margin-bottom: 10px;
      margin-right: 10px;
      outline: 0;
      padding: 10px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }
      &:hover,
      &.active {
        background: $blue;
        color: #fff;
      }
    }
  }

  .search {
    margin-top: 10px;

    input {
      border: 1px solid #d3d3d3;
      border-radius: 5px;
      margin-bottom: 10px;
      outline: 0;
      padding: 10px;
      width: 100%;
    }

    label {
      display: block;
    }
  }
}
