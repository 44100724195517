// Spacing Tokens
$skui-spacing-none: 0;
$skui-spacing-xxs: 0.25rem;
$skui-spacing-xs: 0.5rem;
$skui-spacing-s: 1rem;
$skui-spacing-m: 1.5rem;
$skui-spacing-l: 2rem;
$skui-spacing-xl: 2.5rem;
$skui-spacing-xxl: 3rem;
$skui-spacing-3xl: 3.5rem;
$skui-spacing-jumbo: 4rem;

$spacing-map: (
  none: $skui-spacing-none,
  xxs: $skui-spacing-xxs,
  xs: $skui-spacing-xs,
  s: $skui-spacing-s,
  m: $skui-spacing-m,
  l: $skui-spacing-l,
  xl: $skui-spacing-xl,
  xxl: $skui-spacing-xxl,
  3xl: $skui-spacing-3xl,
  jumbo: $skui-spacing-jumbo,
);

// Design System Colors
// Penser à ajouter la color dans ColorExport.scss pour qu'elle apparaisse dans le storybook
$espace-rh-text: black;
$espace-rh-text-darkMode: white;

// Plain text
$plainText-onLight-lighter: #69798c;
$plainText-onLight-default: #1a304d;
$plainText-onDark-default: white;
$plainText-onDark-darker: #c1cfdc;
$plainText-onLight-placeholder: rgba(26, 48, 77, 0.5);

// Placeholder
$placeholder-onLight: #9aa7b8;

// Surface
$surface-light: #ffffff;
$surface-light-darker: #fafafa;
$surface-dark: #0c5d88;
$surface-dark-darker: #0a4d71;
$surface-scrim: rgba(0, 0, 0, 0.6);

// Border
$border-onLight: rgba(105, 121, 140, 0.2);
$border-onDark: rgba(255, 255, 255, 0.4);

// Action
$action-notification: #ff3d33;
$action-onLight-lightest: #c0e2f1;
$action-onLight-lighter: #7ec2e2;
$action-onLight-default: #0088c7;
$action-onLight-darker: #0076ad;
$action-onLight-darkest: #00547a;
$action-onDark-lightest: #ffdb80;
$action-onDark-lighter: #ffc21a;
$action-onDark-default: #ffbb00;
$action-onDark-darker: #f6b604;
$action-onDark-darkest: #e1a809;

// Status
$status-error-lightest: #fdeded;
$status-error-lighter: #fad3d1;
$status-error-default: #ff3d33;
$status-error-darker: #db0b00;
$status-success-lightest: #d9f7f0;
$status-success-lighter: #9eead6;
$status-success-default: #0eaf84;
$status-success-darker: #005223;
$status-warning-lightest: #fcf1e8;
$status-warning-lighter: #f8d9c4;
$status-warning-default: #fe9d58;
$status-warning-darker: #b74d01;

// Effects
$effects-hover-onLight: rgba(193, 207, 220, 0.3);
$effects-hover-onDark: rgba(5, 38, 56, 0.5);

// Greyscale
$greyscale-lighter: #f6f7f8;
$greyscale-light: #e5e8eb;
$greyscale-medium: #c9ced4;
$greyscale-dark: #909aa7;
$greyscale-darker: #1a304d;

// Decoration
$decoration-blue-lightest: #f3fbfc;
$decoration-blue-lighter: #ceedf3;
$decoration-blue-base: #60c3d7;
$decoration-blue-darker: #4490a7;
$decoration-yellow-darker: #b28600;
$decoration-yellow-base: #ffbb00;
$decoration-yellow-lighter: #feedb4;
$decoration-yellow-lightest: #fffbec;
$decoration-red-darker: #ad4a48;
$decoration-red-base: #f4645d;
$decoration-red-lighter: #fcd2cf;
$decoration-red-lightest: #fef4f3;
$decoration-green-darker: #6b9627;
$decoration-green-base: #99cd32;
$decoration-green-lighter: #e1f0c1;
$decoration-green-lightest: #f8fbf0;
$decoration-pink-darker: #b375a7;
$decoration-pink-base: #fe9fd8;
$decoration-pink-lighter: #ffe0f2;
$decoration-pink-lightest: #fff7fc;
$decoration-orange-darker: #b24a00;
$decoration-orange-base: #ff6600;
$decoration-orange-lighter: #ffd1b3;
$decoration-orange-lightest: #fff4ec;

/* Decoration - background & text patterns */

// Decoration background colors*/
$decoration-pink-background: #f5c2cb;
$decoration-salmon-background: #f2a481;
$decoration-gold-background: #f9d849;
$decoration-peach-background: #fae5ba;
$decoration-tan-background: #cdb591;
$decoration-forest-background: #60b177;
$decoration-lime-background: #a6ec99;
$decoration-sky-background: #97cce8;
$decoration-steel-background: #b4c3dc;
$decoration-turquoise-background: #bceced;
$decoration-plum-background: #d4c0d6;
$decoration-violet-background: #e087e8;
$decoration-misty-background: #fae5e2;
$decoration-silver-background: #c0c0c0;
$decoration-grey-background: #dcdcdc;
// Decoration text/value colors*/
$decoration-pink-text: #b11521;
$decoration-salmon-text: #870100;
$decoration-gold-text: #b61900;
$decoration-peach-text: #b92e01;
$decoration-tan-text: #070101;
$decoration-forest-text: #022906;
$decoration-lime-text: #016401;
$decoration-sky-text: #063584;
$decoration-steel-text: #203269;
$decoration-turquoise-text: #076469;
$decoration-plum-text: #542f5a;
$decoration-violet-text: #5a0278;
$decoration-misty-text: #b92e24;
$decoration-silver-text: #2f2f2f;
$decoration-grey-text: #4f4f4f;
// Decoration surface/value colors*/
$decoration-pink-surface: #FDF3F5;
$decoration-salmon-surface: #FCEDE6;
$decoration-gold-surface: #FEF7DB;
$decoration-peach-surface: #FEFAF1;
$decoration-tan-surface: #F5F0E9;
$decoration-forest-surface: #DFEFE4;
$decoration-lime-surface: #EDFBEB;
$decoration-sky-surface: #EAF5FA;
$decoration-steel-surface: #F0F3F8;
$decoration-turquoise-surface: #F2FBFB;
$decoration-plum-surface: #F6F2F7;
$decoration-violet-surface: #F9E7FA;
$decoration-misty-surface: #FEFAF9;
$decoration-silver-surface: #F2F2F2;
$decoration-grey-surface: #F8F8F8;
// Decoration text colors map
$decorationTextColors: (
  "Pink": $decoration-pink-text,
  "Salmon": $decoration-salmon-text,
  "Gold": $decoration-gold-text,
  "Peach": $decoration-peach-text,
  "Tan": $decoration-tan-text,
  "Forest": $decoration-forest-text,
  "Lime": $decoration-lime-text,
  "Sky": $decoration-sky-text,
  "Steel": $decoration-steel-text,
  "Turquoise": $decoration-turquoise-text,
  "Plum": $decoration-plum-text,
  "Violet": $decoration-violet-text,
  "Misty": $decoration-misty-text,
  "Silver": $decoration-silver-text,
  "Grey": $decoration-grey-text,
);
// Decoration background colors map
$decorationBackgroundColors: (
  "Pink": $decoration-pink-background,
  "Salmon": $decoration-salmon-background,
  "Gold": $decoration-gold-background,
  "Peach": $decoration-peach-background,
  "Tan": $decoration-tan-background,
  "Forest": $decoration-forest-background,
  "Lime": $decoration-lime-background,
  "Sky": $decoration-sky-background,
  "Steel": $decoration-steel-background,
  "Turquoise": $decoration-turquoise-background,
  "Plum": $decoration-plum-background,
  "Violet": $decoration-violet-background,
  "Misty": $decoration-misty-background,
  "Silver": $decoration-silver-background,
  "Grey": $decoration-grey-background,
);
// Decoration background colors map
$decorationSurfaceColors: (
  "Pink": $decoration-pink-surface,
  "Salmon": $decoration-salmon-surface,
  "Gold": $decoration-gold-surface,
  "Peach": $decoration-peach-surface,
  "Tan": $decoration-tan-surface,
  "Forest": $decoration-forest-surface,
  "Lime": $decoration-lime-surface,
  "Sky": $decoration-sky-surface,
  "Steel": $decoration-steel-surface,
  "Turquoise": $decoration-turquoise-surface,
  "Plum": $decoration-plum-surface,
  "Violet": $decoration-violet-surface,
  "Misty": $decoration-misty-surface,
  "Silver": $decoration-silver-surface,
  "Grey": $decoration-grey-surface,
);
// Decoration colors mixin
@mixin decoration-colors {
  @each $value in $decorationBackgroundColors {
    &.#{nth($value, 1)} {
      background-color: list.nth($value, 2);
    }
  }
  @each $value in $decorationTextColors {
    &.#{nth($value, 1)} {
      color: list.nth($value, 2);
    }
  }
}

@mixin decoration-background-color {
  @each $value in $decorationBackgroundColors {
    &.#{nth($value, 1)} {
      background-color: list.nth($value, 2);
    }
  }
}

@mixin decoration-surface-color {
  @each $value in $decorationSurfaceColors {
    &.#{nth($value, 1)} {
      background-color: list.nth($value, 2);
    }
  }
}

@mixin decoration-text-color {
  @each $value in $decorationTextColors {
    &.#{nth($value, 1)} {
      color: list.nth($value, 2);
    }
  }
}

@mixin decoration-border-color {
  @each $value in $decorationBackgroundColors {
    &.#{nth($value, 1)} {
      border-color: list.nth($value, 2);
    }
  }
}

@mixin decoration-fill-color {
  @each $value in $decorationBackgroundColors {
    &.#{nth($value, 1)} {
      fill: list.nth($value, 2);
    }
  }
}

// Brand
$brand-blue: #60c3d7;

// Elevations (box-shadow)
$elevation-modal: 0px 4px 16px rgba(0, 0, 0, 0.08);
$elevation-menu: 0px 4px 16px rgba(0, 0, 0, 0.08);
$elevation-nonActionableCard: 0px 0px 2px rgba(12, 92, 136, 0.15);
$elevation-actionableCard: 0px 2px 4px 0px rgba(12, 92, 136, 0.15);
$elevation-actionableCard-hover: 2px 4px 8px rgba(12, 92, 136, 0.2);
$elevation-dataTable: 0px 0px 2px rgba(12, 92, 136, 0.15);
$elevation-datePicker: 0px 0px 2px rgba(12, 92, 136, 0.15);
$elevation-listItem: 0px 2px 4px rgba(12, 92, 136, 0.15);
$elevation-listItem-hover: 2px 4px 8px rgba(12, 92, 136, 0.2);
$elevation-alert: 0px 2px 4px rgba(12, 92, 136, 0.15);

// Panel shadows (box-shadow)
$panel-shadow-below: 0px 4px 4px rgba(0, 0, 0, 0.1);
$panel-shadow-above: 0px -4px 4px rgba(0, 0, 0, 0.1);
$panel-shadow-right: 4px 0px 4px rgba(0, 0, 0, 0.1);
$panel-shadow-left: -4px 0px 4px rgba(0, 0, 0, 0.1);

// Typography
@font-face {
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 400;
  src: local("Noto Sans Display"),
    url("~assets/fonts/NotoSansDisplay-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 500;
  src: local("Noto Sans Display"),
    url("~assets/fonts/NotoSansDisplay-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 700;
  src: local("Noto Sans Display"), url("~assets/fonts/NotoSansDisplay-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 600;
  src: local("Noto Sans Display"),
    url("~assets/fonts/NotoSansDisplay-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  src: local("DM Sans"), url("~assets/fonts/DMSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  src: local("DM Sans"), url("~assets/fonts/DMSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  src: local("DM Sans"), url("~assets/fonts/DMSans-Bold.ttf") format("truetype");
}

@mixin espace-rh-font {
  font-family: "Noto Sans Display", "Helvetica Neue", sans-serif;
}

@mixin espace-collab-font {
  font-family: "DM Sans", "Helvetica Neue", sans-serif;
}

@mixin espace-rh-h1 {
  @include espace-rh-font;
  font-weight: 600;
  line-height: 1.25;
  font-size: 2rem;
}

@mixin espace-collab-h1 {
  @include espace-collab-font;
  font-weight: 500;
  line-height: 1.25;
  font-size: 2rem;
}

@mixin espace-rh-h2 {
  @include espace-rh-font;
  font-weight: 600;
  line-height: 1.25;
  font-size: 1.75rem;
}

@mixin espace-collab-h2 {
  @include espace-collab-font;
  font-weight: 500;
  line-height: 1.25;
  font-size: 1.75rem;
}

@mixin espace-rh-h3 {
  @include espace-rh-font;
  font-weight: 600;
  line-height: 1.25;
  font-size: 1.5rem;
}

@mixin espace-collab-h3 {
  @include espace-collab-font;
  font-weight: 500;
  line-height: 1.25;
  font-size: 1.5rem;
}

@mixin espace-rh-h4 {
  @include espace-rh-font;
  font-weight: 600;
  line-height: 1.25;
  font-size: 1.25rem;
}

@mixin espace-collab-h4 {
  @include espace-collab-font;
  font-weight: 500;
  line-height: 1.25;
  font-size: 1.25rem;
}

@mixin espace-rh-h5 {
  @include espace-rh-font;
  font-weight: 600;
  line-height: 1.6875rem;
  font-size: 1.125rem;
}

@mixin espace-collab-h5 {
  @include espace-collab-font;
  font-weight: 500;
  line-height: 1.6875rem;
  font-size: 1.125rem;
}

@mixin espace-rh-h6 {
  @include espace-rh-font;
  font-weight: 600;
  line-height: 1.5rem;
  font-size: 1rem;
}

@mixin espace-collab-h6 {
  @include espace-collab-font;
  font-weight: 700;
  line-height: 1.5rem;
  font-size: 1rem;
}

@mixin espace-rh-body1-regular {
  @include espace-rh-font;
  font-weight: 400;
  line-height: 1.5rem;
  font-size: 1rem;
}

@mixin espace-collab-body1-regular {
  @include espace-collab-font;
  font-weight: 400;
  line-height: 1.5rem;
  font-size: 1rem;
}

@mixin espace-rh-body1-bold {
  @include espace-rh-font;
  font-weight: 600;
  line-height: 1.5rem;
  font-size: 1rem;
}

@mixin espace-collab-body1-bold {
  @include espace-collab-font;
  font-weight: 700;
  line-height: 1.5rem;
  font-size: 1rem;
}

@mixin espace-rh-body1-link {
  @include espace-rh-font;
  font-weight: 400;
  line-height: 1.5rem;
  font-size: 1rem;
  text-decoration: underline;
}

@mixin espace-rh-body1-italic {
  @include espace-rh-font;
  font-weight: 400;
  line-height: 1.5rem;
  font-size: 1rem;
  font-style: italic;
}

@mixin espace-collab-body1-italic {
  @include espace-collab-font;
  font-weight: 400;
  line-height: 1.5rem;
  font-size: 1rem;
  font-style: italic;
}

@mixin espace-collab-body1-link {
  @include espace-collab-font;
  font-weight: 400;
  line-height: 1.5rem;
  font-size: 1rem;
  text-decoration: underline;
}

@mixin espace-rh-body2-regular {
  @include espace-rh-font;
  font-weight: 400;
  line-height: 1.3125rem;
  font-size: 0.875rem;
}

@mixin espace-collab-body2-regular {
  @include espace-collab-font;
  font-weight: 400;
  line-height: 1.3125rem;
  font-size: 0.875rem;
}

@mixin espace-rh-body2-bold {
  @include espace-rh-font;
  font-weight: 600;
  line-height: 1.3125rem;
  font-size: 0.875rem;
}

@mixin espace-collab-body2-bold {
  @include espace-collab-font;
  font-weight: 700;
  line-height: 1.3125rem;
  font-size: 0.875rem;
}

@mixin espace-rh-body2-link {
  @include espace-rh-font;
  font-weight: 400;
  line-height: 1.3125rem;
  font-size: 0.875rem;
  text-decoration: underline;
}

@mixin espace-collab-body2-link {
  @include espace-collab-font;
  font-weight: 400;
  line-height: 1.3125rem;
  font-size: 0.875rem;
  text-decoration: underline;
}

@mixin espace-rh-body2-italic {
  @include espace-rh-font;
  font-weight: 400;
  line-height: 1.3125rem;
  font-size: 0.875rem;
  font-style: italic;
}

@mixin espace-collab-body2-italic {
  @include espace-collab-font;
  font-weight: 400;
  line-height: 1.3125rem;
  font-size: 0.875rem;
  font-style: italic;
}

@mixin espace-rh-caption-regular {
  @include espace-rh-font;
  font-weight: 400;
  line-height: 1.125rem;
  font-size: 0.75rem;
}

@mixin espace-collab-caption-regular {
  @include espace-collab-font;
  font-weight: 400;
  line-height: 1.125rem;
  font-size: 0.75rem;
}

@mixin espace-rh-caption-bold {
  @include espace-rh-font;
  font-weight: 600;
  line-height: 1.125rem;
  font-size: 0.75rem;
}

@mixin espace-collab-caption-bold {
  @include espace-collab-font;
  font-weight: 700;
  line-height: 1.125rem;
  font-size: 0.75rem;
}

@mixin espace-rh-caption-link {
  @include espace-rh-font;
  font-weight: 400;
  line-height: 1.125rem;
  font-size: 0.75rem;
  text-decoration: underline;
}

@mixin espace-collab-caption-link {
  @include espace-collab-font;
  font-weight: 400;
  line-height: 1.125rem;
  font-size: 0.75rem;
  text-decoration: underline;
}

@mixin espace-rh-caption-italic {
  @include espace-rh-font;
  font-weight: 400;
  line-height: 1.125rem;
  font-size: 0.75rem;
  font-style: italic;
}

@mixin espace-collab-caption-italic {
  @include espace-collab-font;
  font-weight: 400;
  line-height: 1.125rem;
  font-size: 0.75rem;
  font-style: italic;
}

@mixin espace-collab-button {
  @include espace-collab-font;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
}

@mixin espace-collab-button-small {
  @include espace-collab-font;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1.25rem;
}

@mixin espace-rh-button {
  @include espace-rh-font;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
}

@mixin espace-rh-button-small {
  @include espace-rh-font;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.25rem;
}

@mixin espace-rh-bold-caption {
  @include espace-rh-font;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.125rem;
}

@mixin espace-rh-components-input-label {
  @include espace-rh-font;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.125rem;
}

@mixin espace-collab-components-input-label {
  @include espace-collab-font;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.125rem;
}

@mixin espace-rh-components-input-value {
  @include espace-rh-font;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
}

@mixin espace-collab-components-input-value {
  @include espace-collab-font;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
}

@mixin espace-rh-components-input-placeholder {
  @include espace-rh-components-input-value;
  font-style: italic;
}

@mixin espace-collab-components-input-placeholder {
  @include espace-collab-components-input-value;
  font-style: italic;
}

@mixin espace-rh-components-input-help-text {
  @include espace-rh-font;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.25rem;
}

@mixin espace-collab-components-input-help-text {
  @include espace-collab-font;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.25rem;
}

@mixin espace-rh-components-card-title {
  @include espace-rh-font;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.6875rem;
}

@mixin espace-collab-components-card-title {
  @include espace-collab-font;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.6875rem;
}

@mixin espace-rh-label {
  @include espace-rh-font;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
}

@mixin espace-collab-label {
  @include espace-collab-font;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
}

@mixin espace-rh-value {
  @include espace-rh-font;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5;
}

@mixin espace-collab-value {
  @include espace-collab-font;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5;
}

@mixin espace-rh-components-modal-title {
  @include espace-rh-font;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.875rem;
}

@mixin espace-rh-components-sidenav-mobile {
  @include espace-rh-font;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
}

@mixin espace-rh-rating-grade {
  @include espace-rh-font;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
}

@mixin espace-rh-rating-reviews {
  @include espace-rh-font;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.5rem;
}

@mixin espace-collab-components-sidenav-mobile {
  @include espace-collab-font;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
}

@mixin espace-collab-header-pageTitle {
  @include espace-collab-font;
  font-weight: 500;
  line-height: 1.25;
  font-size: 1.25rem;
}

@mixin espace-rh-header-pageTitle {
  @include espace-rh-font;
  font-weight: 600;
  line-height: 1.25;
  font-size: 1.25rem;
}

@mixin espace-collab-header-parentPageTitle {
  @include espace-collab-font;
  font-weight: 500;
  line-height: 1;
  font-size: 0.75rem;
}

@mixin espace-rh-header-parentPageTitle {
  @include espace-rh-font;
  font-weight: 600;
  line-height: 1;
  font-size: 0.75rem;
}

@mixin espace-rh-modal-title {
  @include espace-rh-font;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.875rem;
}

@mixin espace-collab-modal-title {
  @include espace-collab-font;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.875rem;
}

@mixin espace-collab-rating-grade {
  @include espace-collab-font;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
}

@mixin espace-collab-rating-reviews {
  @include espace-collab-font;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.5rem;
}

@mixin espace-collab-filters {
  @include espace-collab-font;
}

@mixin espace-rh-filters {
  @include espace-rh-font;
}
