.Recap {
  padding: 40px 40px;
  height: 350px;
  left: 0px;
  background: #fefefe;
  width: 100%;

  > p {
    font-size: 13px;
    margin-bottom: 15px;

    &.warning {
      padding-left: 28px;
      margin-top: 10px;
      color: #d00;
      font-size: 12px;
    }
  }

  .warningBox {
    background: #fff3cd;
    border-left: 3px solid #856404;
    color: #856404;
    margin: 20px 0;
    padding: 10px;
  }

  > div.block {
    padding: 20px;
    padding-bottom: 10px;
    border: 1px solid #ccc;
    &:first-of-type {
      margin-bottom: 20px;
    }
    > h3 {
      font-size: 16px;
      color: #888;
      margin-bottom: 5px;
    }

    > p {
      font-size: 13px;
      margin-bottom: 8px;
    }
    > ul {
      display: flex;
      list-style: none;
      width: 100%;
      max-height: 150px;
      overflow: auto;
      flex-wrap: wrap;
      padding: 10px 0px;
      padding-bottom: 0;

      > li {
        background: #eee;
        font-size: 13px;
        font-weight: 500;
        width: auto;
        height: auto;
        margin-bottom: 10px;
        margin-right: 15px;
        text-align: center;
        padding: 3px 10px;
        border-radius: 5px;
      }
    }
  }
  > .divider {
    width: 100%;
    background: #eee;
    height: 1px;
    margin: 10px 0px 15px 0px;
  }
}
