@import "../../../assets/designSystem";

div.VerticalDivider {
  display: inline-block;
  width: 1px;
  height: 100%;
  border-left: 1px solid;
  border-color: $border-onLight;

  &.on-dark {
    border-color: $border-onDark;
  }

  @each $key, $value in $spacing-map {
    &.ml-#{$key} {
      margin-left: $value;
    }

    &.mr-#{$key} {
      margin-right: $value;
    }
  }
}
