@import "../../assets/designSystem";
@import "../../assets/mixins";

.Pagination {
  &.espace-rh {
    @include espace-rh-body2-regular;
  }
  &.espace-collab {
    @include espace-collab-body2-regular;
  }
  label {
    margin: 0; // ensure no override from parent
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: $skui-spacing-xs;

  color: $plainText-onLight-default;
  &.darkMode {
    color: $plainText-onDark-default;
  }
  &.column {
    flex-direction: column;
  }
  @include isMobile {
    flex-direction: column;
  }

  .PaginationLeft {
    display: flex;
    align-items: center;
    .rowsPerPage {
      margin-left: $skui-spacing-xxs;
    }
  }
  .PaginationRight {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    column-gap: $skui-spacing-l;
    @media screen and (max-width: 362px) {
      column-gap: $skui-spacing-xs;
    }
    row-gap: 0.5rem;
    .navigationBtns {
      display: flex;
      align-items: center;
      column-gap: $skui-spacing-xs;
    }
    .navigationInput {
      display: flex;
      align-items: center;
    }
    input {
      width: 75px;
      font-size: 1rem;
      margin-left: $skui-spacing-xs;
      margin-right: $skui-spacing-xs;
      padding: 0.375rem $skui-spacing-xs;
      text-align: right;
    }
  }
}
