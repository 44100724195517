@import "@skillup/ui";

.Reference {
  max-height: 100%;
  display: flex;
  overflow: hidden;
  color: $plainText-onLight-lighter;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.9375rem;
}
