@import "uiAssets/ColorsVars.scss";

.DateInput_fangStroke {
  display: none;
}

.SingleDatePicker {
  .SingleDatePickerInput {
    display: flex;
    align-items: center;
    background-color: $borderColorLight;
    border-radius: 5px;
    padding: 8px 12px;
    min-height: 50px;

    .SingleDatePickerInput_calendarIcon {
      margin-left: 0;
      padding: 0;
      margin-right: 12px;
    }

    .DateInput {
      background: transparent;
      display: flex;
      flex-grow: 1;

      .DateInput_input {
        background-color: transparent;
        border-bottom: none;
        padding: 0;
        color: $textColor;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}
