@import "uiAssets/ColorsVars.scss";

button.dropDownItem {
  background: none;
  border: none;
  border-bottom: 1px solid #eee;
  display: block;
  font-size: 13px;
  padding: 15px 20px;
  text-align: left;
  width: 100%;
  cursor: pointer;

  &:hover {
    background: $gradientBlue;
    color: #fff;
  }

  &.disabled {
    background: #eee;
    color: #999;
    cursor: not-allowed;
  }

  &:first-of-type {
    border-radius: 7px 7px 0 0;
  }

  &:last-of-type {
    border-bottom: none;
    border-radius: 0 0 7px 7px;
  }
}

.confirmationModal {
  padding: 20px;

  .modalText {
    font-size: 12;
  }

  .buttonWrapper {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}
