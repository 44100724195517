@import "../../../../assets/designSystem";

.SkillLevel {
  width: 100%;
  display: flex;
  gap: $skui-spacing-xxs;

  .level {
    position: relative;
    background: $greyscale-light;
    border-radius: 0.125rem;
    height: $skui-spacing-xs;
    flex-grow: 1;

    &.reached {
      background: $action-onLight-default;
    }

    .expectedLevel {
      &.expected {
        position: absolute;
        top: -8px;
        right: -3px;
        border-top: solid 7px $decoration-red-darker;
        border-left: solid 4px rgba(0, 0, 0, 0);
        border-right: solid 4px rgba(0, 0, 0, 0);
      }
    }
  }
}
