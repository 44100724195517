@import "../../../assets/designSystem";

.placeholder {
  color: $plainText-onLight-placeholder;
  font-style: italic;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.darkMode {
    color: $plainText-onDark-darker;

    > div {
      color: $plainText-onLight-default
    }
  }
}

.missingItems {
  margin-right: $skui-spacing-s;
  color: $plainText-onLight-default;
  cursor: pointer;
  &.darkMode {
    color: $plainText-onDark-default;
  }
}

.chip {
  margin-right: $skui-spacing-xs;
  max-width: 100%;
  > div {
    overflow: hidden;
    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.valueContainer {
  > div {
    color: $plainText-onLight-default;
  }
}
