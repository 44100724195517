@import "uiAssets/ColorsVars.scss";

.Activity {
  list-style: none;
  padding: 25px 20px 15px;

  > div.logs {
    > .noActivityPlaceholder {
      color: #333;
      font-style: italic;
      padding: 10px 0;
      text-align: center;
    }
    > li {
      margin-bottom: 5px;

      &.activityDate {
        > header {
          > div:first-of-type {
            background-color: #ddd;
            width: 10px;
            height: 10px;
            margin-left: 8px;
          }

          > div:last-of-type {
            color: #999;
            font-size: 12px;
            font-style: italic;
            text-transform: capitalize;
          }
        }

        > div {
          min-height: 6px;
        }
      }

      > header {
        align-items: center;
        display: flex;
        margin-bottom: 5px;

        > div:first-of-type {
          align-items: center;
          border-radius: 50px;
          display: flex;
          flex-grow: 0;
          flex-shrink: 0;
          height: 26px;
          justify-content: center;
          margin-right: 12px;
          overflow: hidden;
          width: 26px;

          > svg {
            display: block;
            opacity: 0.9;
          }
        }

        > div:last-of-type {
          flex-grow: 1;

          > p:last-of-type {
            > span {
              color: #555;
              font-size: 12px;
            }
          }
        }
      }

      > div {
        min-height: 14px;
        padding-left: 35px;
        position: relative;

        &.hasMessage {
          display: inline-block;
          min-width: 250px;
        }

        &:before {
          background: #ddd;
          content: "";
          display: block;
          height: 100%;
          left: 12px;
          position: absolute;
          width: 1px;
        }

        > div {
          margin-left: 10px;
          padding: 10px 0 20px;

          > p {
            background: #e2ecf3;
            border-radius: 7px;
            color: #3b4a5f;
            font-weight: 500;
            padding: 10px 50px 10px 13px;

            &:before {
              color: #3b4a5f;
              content: "”";
              font-size: 40px;
              font-weight: 600;
              opacity: 0.3;
              position: absolute;
              right: 10px;
              text-transform: uppercase;
              top: 10px;
            }
          }
          &:before {
            border: 8px solid transparent;
            border-bottom-color: #e2ecf3;
            content: "";
            display: block;
            height: 15px;
            left: 50px;
            position: absolute;
            top: 5px;
            transform: rotate(-135deg);
            width: 15px;
          }
        }
      }

      &:last-of-type > div:before {
        display: none;
      }
    }
  }
}
