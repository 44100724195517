@import "@skillup/ui";

.FieldForm {
  height: 100%;

  .FormContent {
    flex: 1;
    overflow-y: auto;
    padding: $skui-spacing-s $skui-spacing-s $skui-spacing-xs $skui-spacing-l;

    .OptionsLabel > div {
      font-size: $skui-spacing-s;
      line-height: $skui-spacing-l;
    }
  }

  .FormFooter {
    height: fit-content;
    padding: 1rem 0.5rem;

    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
  }

  .Alert {
    margin-bottom: $skui-spacing-xs;
  }
}

.sidepanel {
  & > div > div > div {
    height: 100%;

    > div {
      height: 100%;
      padding: 0 !important;

      > div > div {
        padding: $skui-spacing-s $skui-spacing-s $skui-spacing-xs $skui-spacing-l;
      }
    }
  }

  .dragableOptions {
    .hiddenContainer {
      overflow: hidden;
      height: 100%;
      padding: 1px;
      transition: height 0.5s linear;
      &.hide {
        height: 0;
      }
    }
  }
}

.DeleteOptionModal {
  label:last-child {
    margin-bottom: 5px;
  }
  .selectReplaceOptions {
    padding-left: $skui-spacing-m;
  }
}

.modal {
  z-index: 200;
  .modalContent {
    padding: 0;
    > div {
      padding-bottom: $skui-spacing-s !important;
    }
  }
}

.modalContainer {
  > div {
    z-index: 50;

    > div {
      width: 40em;
    }
  }
}

.dragableOptions {
  .hiddenContainer {
    overflow: hidden;
    height: 100%;
    padding: 1px;
    transition: height 0.5s linear;
    &.hide {
      height: 0;
    }
  }
}

.DeleteOptionModal {
  label:last-child {
    margin-bottom: 5px;
  }
  .selectReplaceOptions {
    padding-left: $skui-spacing-m;
  }
}
.optionsReadOnly {
  margin-top: $skui-spacing-s;
  margin-bottom: $skui-spacing-s;
}
