@import "../../assets/colors";

button.Link {
  border: none;
  outline: none;
  user-select: none;
  background: none;
  font-family: "DMSans", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: underline;
  color: $primary-blue;

  &:hover {
    text-decoration: none;
  }

  &.small {
    font-size: 0.75rem;
    line-height: normal;
  }
}

.LinkWithIcon {
  align-items: center;

  > svg {
    margin-right: 4px;
  }
}
