.Checkboxes {
  .item {
    margin-bottom: 0.5rem;
    margin-right: 1rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
