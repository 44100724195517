@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.CreateNewCampaign {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  background-color: $surface-light-darker;

  main {
    display: flex;
    flex-direction: column;
    position: relative;
    top: -16px;
    width: 100%;

    &.modelAndName {
      max-width: 446px;
    }

    &.automation {
      max-width: 574px;
    }

    &.dates {
      max-width: 486px;
    }

    &.attendees {
      max-width: 756px;
    }

    &.notifications {
      max-width: 652px;
    }

    &.workflowAndConfidentiality {
      max-width: 475px;
    }

    &.summary {
      max-width: 548px;
    }
  }
}
