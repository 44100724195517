@import "../../assets/designSystem";

.TableOfContent {
  width: 100%;
  overflow: hidden;
  padding: $skui-spacing-s;
  position: sticky;
  top: 3rem;
  flex-grow: 0;
  height: fit-content;

  &.espace-rh {
    @include espace-rh-body2-regular;
  }

  &.espace-collab {
    @include espace-collab-body2-regular;
  }
}
