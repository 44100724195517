@import "@skillup/ui";

.labelWrapper {
  display: flex;
  flex-direction: row;

  .required {
    color: $secondary-red;
    margin-left: 0.125rem;
  }
}
