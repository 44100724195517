.FiltersSummary {
  border-bottom: 1px solid #dceeff;
  padding: 7px 15px 5px;
  z-index: 21;

  .subContainer {
    display: flex;

    & > span:first-child {
      color: #666;
      font-size: 13px;
      font-weight: 800;
      padding: 6px 10px 6px 0;
    }
  }
}

.Sorter {
  background: #eee;
  border-radius: 5px;
  display: flex;
  margin: 3px 10px 3px 0px;
  padding: 0px 0px 0px 8px;
  font-size: 12px;
  align-items: center;

  & > span:nth-child(2) {
    display: flex;
    margin-left: 5px;
  }

  .removeButton {
    align-items: center;
    background: #2782ff;
    border: none;
    border-radius: 0 5px 5px 0;
    color: #fff;
    display: flex;
    justify-content: center;
    padding: 3px;
    width: 20px;
    height: 100%;
    margin-left: 8px;
    cursor: pointer;
  }
}
