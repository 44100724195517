@import "uiAssets/ColorsVars.scss";

.PortalAdmin {
  color: #666;
  display: flex;
  justify-content: space-around;
  padding: 10px;

  .toggle {
    align-items: center;
    display: flex;

    > div {
      width: 300px;
    }
  }

  .portal-type {
    margin-top: 15px;

    > div:last-of-type {
      margin-left: 50px;
    }
  }

  > div {
    border-radius: 7px;
    box-shadow: $bigBoxShadow;
    overflow: hidden;
    width: calc(50% - 20px);

    &:before {
      background: $gradientBlue;
      border-radius: 7px 7px 0 0;
      content: "";
      display: block;
      height: 10px;
      width: 100%;
    }

    > header {
      box-shadow: $bigBoxShadow;
      color: $blue;
      font-size: 24px;
      font-weight: 100;
      padding: 10px 15px;
    }

    &:first-of-type > div {
      margin: 10px;
      padding: 10px;
    }

    .share {
      padding: 20px 0;
    }
  }
}
