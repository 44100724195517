@import "uiAssets/ColorsVars.scss";

.confirmationModaleContent {
  display: flex;
  flex-direction: column;

  label.modaleMessage {
    flex-grow: 1;
    font-size: 14px;
    color: #000000;
    margin: 20px 35px;
  }

  .warning {
    flex-grow: 1;
    display: flex;
    padding: 10px;
    background-color: #fdf3d1;
    box-sizing: border-box;
    border-left: 2px solid #816521;
    margin: 0 35px;

    label.warningMessage {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.43;
      letter-spacing: -0.11px;
      color: #816521;
    }
  }

  .buttonsWrapper {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;

    .modaleButton {
      margin: 20px;
    }
  }
}

.FinancialDetails {
  padding-top: 20px;
  position: relative;

  > div.block {
    > h3 {
      color: #2881ff;
      font-weight: 600;
      padding-bottom: 8px;
      padding-left: 23px;
    }

    > ul {
      padding: 0 20px 10px;
    }
  }
}
