.Sorter {
  font-weight: 400;
  border-top: solid 1px #ddd;

  width: 100% !important;

  & > p:first-child {
    font-weight: 500;
  }

  & > p {
    padding: 10px 8px;
  }

  & > p:not(:first-child):hover {
    background-color: #f6f6f6;
  }
}
