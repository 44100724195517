@import "@skillup/ui";

.content {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid $pale-lilac !important;
  > .filterColumn {
    border-right: 1px solid $pale-lilac;
    padding-right: 20px;
    margin-left: 25px;
    height: 409px;
    overflow-y: auto;
    max-width: 266px;
    > .filterSection {
      border-bottom: 1px solid $pale-lilac;
      flex: none;
      order: 3;
      flex-grow: 0;
      padding: 20px 0px;

      > h2 {
        font-weight: 500;
        font-size: 0.8rem;
        margin: 0 0 15px 0;
      }

      > .waitingList {
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        gap: 5px;
        padding: 10px;
        border-radius: 4px;
        border: solid 1px $pale-lilac;
        > .emptyWaitingList {
          font-size: 0.8rem;
          color: #9aa7b9;
          font-weight: 300;
        }
        & li {
          display: flex;
          flex-grow: 0;
          font-size: 0.8rem;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          text-align: left;
          color: $b-w-black;
          list-style: none;
          justify-content: space-between;
          padding: 2px 0px;
          > p {
            display: flex;
            align-self: center;
            margin-right: 15px;
          }

          > .chevronList {
            display: block;
            color: #2782ff;
            border-radius: 1px;
            background-color: #f2f2f2;
            cursor: pointer;
            height: 1.125rem;
            &:hover {
              transform: scale(1.1);
            }
          }
        }
      }
    }
    > .importSection {
      display: flex;
      align-items: stretch;
      color: #2782ff;
      border-style: dashed;
      border-width: 1px;
      border-color: #2782ff;
      border-radius: 5px;
      font-weight: 400;
      font-size: 0.8rem;
      margin-top: 15px;
      margin-bottom: 15px;
      & p {
        padding-right: 10px;
        display: flex;
        align-self: center;
        margin-left: 10px;
      }
    }
  }
  > .selectedColumn {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    > .selectedNumberBand {
      height: 40px;
      width: 100%;
      flex-grow: 0;
      display: flex;
      gap: 10px;
      padding: 10px 20px;
      background-color: #fafafa;
      border-bottom: 1px solid $pale-lilac !important;
      > p {
        font-size: 0.9rem;
        font-weight: 500;
        color: #2782ff;
      }
    }
    > .selectedUsersList {
      display: flex;
      flex-direction: column;
      list-style: none;
      height: 369px;
      overflow-y: auto;
      > li {
        display: flex;
        width: 100%;
        border-bottom: 1px solid $pale-lilac !important;
        padding: 10px 20px;
        justify-content: space-between;
        align-items: center;
        height: 55px;
        &.errorOnEntry {
          background-color: rgba($secondary-red, 0.2);
          border: 1px solid $secondary-red !important;
        }
        > .userInitial {
          display: flex;
          width: 34px;
          height: 34px;
          background: #4998c7;
          border-radius: 50%;
          justify-content: center;
          align-items: center;
          margin-right: 15px;
          > p {
            color: white;
            font-size: 0.8rem;
          }
        }
        > .managerInitial {
          @extend .userInitial;
          background: #c74967 !important;
        }
        > .userInfos {
          flex-grow: 1;
          line-height: 1rem;
          & p {
            font-size: 0.9rem;
          }
          & span {
            font-size: 0.75rem;
            font-weight: 300;
            color: #9aa7b9;
          }
        }
        > .managerInfos {
          @extend .userInfos;
          flex-grow: 0;
          width: 175px;
        }
        > .selectedUserCross {
          cursor: pointer;
          & :hover {
            transform: scale(1.1);
          }
        }

        > .selectedUsersState {
          display: flex;
          justify-content: flex-end;
          text-align: flex-end;

          p {
            color: #2782ff;
            font-size: 0.8rem;
            font-weight: 400;
          }
        }
      }
    }
  }
}

.AddTraineesContent {
  &.content {
    overflow-x: hidden;
    overflow-y: auto;
    > .filterColumn {
      height: auto;
    }
  }
}

.alert {
  width: 100%;
  padding: 1.5rem;
}
