@import "../../../../assets/designSystem";

.container {
  display: flex;
  align-items: center;

  .icon {
    margin-right: $skui-spacing-xxs;
  }

  .skillContainer {
    display: grid;
    width: 100%;
    grid-template-areas: "skillname level";
    gap: 1rem;
    grid-template-columns: 2fr 1fr;
    background: $surface-light;
    border: 1px solid $border-onLight;
    border-radius: 0.25rem;
    margin-bottom: $skui-spacing-xxs;
    padding: $skui-spacing-xs;

    .skillName {
      @include espace-rh-caption-regular;
      overflow: hidden;

      .tooltip {
        display: block;

        .skillNameOverflow {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }

    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $plainText-onLight-default;

      .svgContainer {
        display: flex;
        color: $plainText-onLight-lighter;

        > div {
          display: flex;
          align-items: center;

          svg {
            margin-left: $skui-spacing-xs;
            font-size: 1rem;
            cursor: pointer;
          }
        }
      }
    }
  }

  .actionBorder {
    border: 2px solid $action-onDark-darker;
  }

  .isSelected {
    border: 2px solid $action-onLight-default;
  }
}
