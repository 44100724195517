@import "@skillup/ui";

.editionView {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.25rem;
  height: 100vh;
  width: 100vw;

  .doubleInput {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 36rem;
    margin: auto;
    margin-bottom: 1rem;
    flex-wrap: wrap;
  }

  .reference {
    display: flex;
    flex-direction: column;
    max-width: 36rem;
    margin: auto;
    margin-bottom: 1rem;
    span {
      margin-top: -0.35rem;
      @include espace-rh-components-input-help-text;
    }
  }
}

.DeleteOptionModal {
  max-width: 33rem;
  .content {
    padding-bottom: $skui-spacing-s;
  }
}
