@import "@skillup/ui";

.container {
  padding: $skui-spacing-s;

  .descriptionHelp {
    margin: $skui-spacing-s 0;
  }

  .labelAndButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
  }

  .choiceRow {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: $skui-spacing-xxs 0;

    .choiceInput {
      flex: 1;
    }
  }

  .delete {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0.75rem;
  }

  .buttonUnderline {
    text-decoration: underline;
    color: $action-onLight-darkest;
    button {
      font-weight: normal !important;
    }
  }
}

.permissions__action {
  padding: $skui-spacing-s $skui-spacing-s 0 $skui-spacing-s;

  .permissions__actionChoice {
    flex: 1;
  }
}
