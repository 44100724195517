@import "../../assets/colors";

.RadioBox {
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  .line {
    align-items: center;
  }

  &.checked {
    .circle {
      background-color: rgba(95, 195, 215, 0.2);
      border-color: $secondary-blue;

      .dot {
        visibility: visible;
      }
    }

    .label {
      font-weight: 500;
    }
  }

  &.readOnly {
    .label {
      color: $bluey-grey;
    }

    &:hover {
      cursor: not-allowed;

      .circle {
        cursor: not-allowed;
      }
    }
  }

  &.checked.readOnly {
    .circle {
      border-color: $bluey-grey;
      background-color: rgba(154, 167, 185, 0.2);

      .dot {
        visibility: visible;
        background-color: $bluey-grey;
      }
    }

    .label {
      color: $b-w-black;
    }
  }

  &.horizontal {
    border-radius: 100px;
    border: 1px solid $bluey-grey;
    padding: 0.531rem 0.75rem;

    .label {
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 1.25;
      letter-spacing: 0.15px;
      color: $slate;
    }

    &.checked {
      border-color: $secondary-blue;
      background-color: rgba(95, 195, 215, 0.2);

      .label {
        color: $secondary-blue;
      }
    }

    &.readOnly {
      .label {
        color: $bluey-grey;
      }
    }

    &.checked.readOnly {
      border-color: $bluey-grey;
      background-color: rgba(154, 167, 185, 0.1);

      .label {
        color: $bluey-grey;
      }
    }
  }

  &.errored {
    .circle {
      border-color: $secondary-red;
    }
  }

  &.checked.errored {
    .circle {
      border-color: $secondary-red;
      background-color: rgba($secondary-red, 0.2);

      .dot {
        visibility: visible;
        background-color: $secondary-red;
      }
    }

    .label {
      color: $b-w-black;
    }
  }

  .circle {
    width: 16px;
    height: 16px;
    margin-right: 0.5rem;
    background-color: white;
    border: 1px solid $bluey-grey;
    border-radius: 50%;
    cursor: pointer;
    flex-shrink: 0;

    .dot {
      visibility: hidden;
      background-color: $secondary-blue;
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
  }

  .label {
    font-size: 0.875rem;
    line-height: 1.21;
    letter-spacing: 0.15px;
    text-align: left;
    color: $b-w-black;
    user-select: none;
  }

  .helpText {
    padding-left: 1.5rem;
    padding-top: 0.25rem;

    span {
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1.125;
      color: #69798c;
    }
  }

  .help {
    align-items: center;
    margin-left: 0.5rem;
    position: relative;

    .questionIcon {
      width: 1rem;
      height: 1rem;
      background-color: white;
      border: 1px solid $secondary-blue;
      border-radius: 50%;
      font-size: 0.75rem;
      color: $secondary-blue;

      &:hover {
        color: white;
        cursor: pointer;
        background-color: $secondary-blue;
      }
    }

    .tooltip {
      margin-left: 0.75rem;
      width: 18rem;
      background-color: $secondary-blue;
      border-radius: 7px;
      padding: 0.8125rem;
      position: absolute;
      left: 1.5rem;
      align-items: center;
      z-index: 1;

      &:before {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 10.4px 6px 0;
        border-color: transparent $secondary-blue transparent transparent;
        position: absolute;
        left: -0.625rem;
      }

      > p {
        font-size: 0.75rem;
        color: white;
        user-select: none;
      }
    }
  }
}
