.deleteListModal {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 30px;

  > p {
    padding: 20px;
    width: 100%;
  }
}
