@import "../../../../assets/designSystem";

.SkillLevel {
  width: 100%;
  display: flex;
  gap: 0.25rem;
  padding-top: 0.15rem;

    .level {
      background: $greyscale-light;
      border-radius: 0.125rem;
      height: 0.5rem;
      flex-grow: 1;
  
      &.reached {
        background: $action-onLight-default;
      }
    }
}
