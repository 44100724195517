.Filter {
  padding: 8px;

  p {
    font-weight: 500;
    margin-bottom: 8px;
  }

  .filterContainer {
    font-size: 14px;
    font-weight: 400;
    padding: 8px 8px 8px 4px;

    .option {
      cursor: pointer;
      padding: 4px;
    }

    input {
      margin-right: 8px !important;
      cursor: pointer;
    }

    label {
      cursor: pointer;
    }
  }
}
