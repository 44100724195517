@import "@skillup/ui";

.PortalContent {
  padding: $skui-spacing-s;
  display: flex;
  .title {
    @include espace-rh-h3;
    color: $plainText-onLight-lighter;
  }
  .tableOfContent {
    min-width: 12rem;
  }
  .contentToggles {
    padding-left: $skui-spacing-s;
  }
  .portalContentCategories {
    margin-top: $skui-spacing-l;
    padding-left: $skui-spacing-s;
  }
  .switchList {
    @include espace-collab-h6;
    color: $plainText-onLight-lighter;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: $skui-spacing-xs;
    margin-bottom: $skui-spacing-l;
  }
  .lmsBanners {
    padding-left: $skui-spacing-s;
    .content {
      margin-bottom: $skui-spacing-l;
      .titleForm {
        padding-top: $skui-spacing-xs;
        padding-bottom: $skui-spacing-s;
      }
    }
  }
}
