@import "../../assets/colors";

input.NumberInput {
  border-radius: 4px;
  border: 1px solid $pale-lilac;
  color: $b-w-black;
  outline: none;
  padding: 0.688rem 0.625rem;
  font-size: 0.875rem;
  line-height: 1.21;
  letter-spacing: 0.15px;
  width: 100%;

  &::placeholder {
    color: $bluey-grey;
  }

  &:focus {
    border-color: $primary-blue;
  }

  &.readOnly {
    &:focus {
      border-color: $pale-lilac;
    }
  }

  &.errored {
    border-color: $secondary-red !important;
  }
}
