@import "@skillup/ui";

.CompanyCard {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: white;
  border: solid 1px rgba(105, 121, 140, 0.2);
  border-radius: 4px;
  box-shadow: $elevation-actionableCard;
  width: 630px;
  padding: $skui-spacing-s;
  cursor: pointer;

  &.child {
    width: 550px;
  }

  &.vertical {
    .content {
      .infos {
        flex-direction: column;
      }
    }
  }

  &:focus {
    box-shadow: none;
    outline: 2px solid $action-onLight-darker;
    outline-offset: 1px;
  }

  > div {
    margin-bottom: $skui-spacing-s;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .name {
      @include espace-rh-h4;
      color: $plainText-onLight-lighter;
    }
  }

  .content {
    display: flex;
    flex-direction: row;

    .logo {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-right: $skui-spacing-l;

      > img {
        height: 80px;
        width: 80px;
      }
    }

    .infos {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      flex: 1;

      @media (max-width: 600px) {
        flex-direction: column;
      }

      .block {
        margin-right: $skui-spacing-l;
        word-break: break-word;

        .cardLine {
          display: flex;
          flex-direction: column;
          justify-content: stretch;
          margin-bottom: $skui-spacing-xs;
          text-align: left;

          .header {
            @include espace-rh-bold-caption;
            color: $plainText-onLight-lighter;
          }

          .content {
            @include espace-rh-body2-regular;
            color: $plainText-onLight-default;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
