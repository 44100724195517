@import "../../assets/designSystem";

.Switch {
  cursor: pointer;
  .label {
    margin-left: $skui-spacing-xs;
    color: $plainText-onLight-default;
  }
  &.darkMode {
    .label {
      color: $plainText-onDark-default;
    }
  }
  &.disabled {
    cursor: default;
    .label {
      opacity: 0.3;
    }
  }
  &:not(.disabled):focus-visible .toggle {
    outline: solid 1px $action-onLight-default;
    outline-offset: 1px;
  }

  &.espace-rh {
    .label {
      @include espace-rh-label;
    }
  }

  &.espace-collab {
    .label {
      @include espace-collab-label;
    }
  }
}
