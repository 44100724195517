@import "@skillup/ui";

.contentContainer {
  padding: $skui-spacing-s;

  .ImagesSteps {
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    padding: 0 4.325rem;

    @include isIE11 {
      width: inherit;

      .step.first {
        margin-right: 6rem;
      }

      .step.middle {
        margin: 0 6rem;
      }

      .step.last {
        margin-left: 6rem;
      }
    }

    .step {
      max-width: 6rem;
      text-align: center;

      img.illustration {
        width: 4.125rem;
        height: 4.125rem;
        border: 3px solid $pale-lilac;
        border-radius: 50%;
        margin-bottom: 1.438rem;
        z-index: 1;
      }

      span.stepLabel {
        font-size: 1rem;
        font-weight: bold;
        line-height: 1.19;
        letter-spacing: 0.15px;
        color: $b-w-black;
      }
    }

    .bar {
      position: absolute;
      width: calc(100% - 4.325rem * 3);
      height: 0.188rem;
      background-color: $pale-lilac;
      top: calc(4.125rem / 2);
      left: calc(4.325rem + 4.325rem / 2);
      z-index: 0;

      .progress {
        width: 0%;
        height: 100%;
        background-color: $secondary-green;
      }
    }
  }
}
