@import "../../assets/designSystem";
@import "../../assets/colors";

.containerCheckbox {
  align-items: center;
  display: flex;
  font-size: 14px;
  color: $text-on-light-default;
  @include espace-rh-body1-regular;

  &:last-of-type {
    margin-bottom: 0;
  }

  > div {
    cursor: pointer;
  }

  > div:last-child {
    display: flex;

    > span {
      margin-left: 4px;
      font-weight: bold;
    }
  }

  > .checkbox {
    align-items: center;
    background: transparent;
    background-color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    justify-content: left;
    left: 0;
    padding-top: 0;
    position: sticky;
    user-select: none;
    width: 20px;
    z-index: 9;

    > div {
      align-items: center;
      border: 2px solid #ccc;
      border-radius: 2px;
      display: flex;
      height: 16px;
      justify-content: center;
      width: 16px;

      > svg {
        opacity: 0;
        pointer-events: none;
        stroke: #2782ff;
        stroke-width: 10px;
        stroke-linecap: round;
        stroke-linejoin: round;
        fill: none;
        overflow: visible;
      }
    }

    &.selected {
      > div {
        background-color: $decoration-blue-base;
        border-color: $decoration-blue-base;

        > svg {
          opacity: 1;
          stroke: #fff;
        }
      }

      &.disabled {
        > div {
          background-color: #cbcbcb;
          border-color: #cbcbcb;
        }
      }
    }
  }
}
