@import "uiAssets/ColorsVars.scss";

/*** Main modal ***/
.manualImportSessionModal {
  padding: 0px 20px;

  .preview {
    flex-grow: 0;
    grid-gap: 10px;
    gap: 10px;
    padding: 0px 25px 10px 25px;
    border-bottom: 1px solid #eaeaf1;

    .recall {
      margin-bottom: 5px;
      flex-grow: 0;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.1px;
      color: #888888;
    }

    p {
      flex-grow: 0;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.1px;
      color: #192f4b;
    }

    p.trainingName {
      height: 16px;
      flex-grow: 0;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.1px;
      color: #192f4b;
    }
  }

  > p {
    margin-top: 10px;
    height: 17px;
    flex-grow: 0;
    font-size: 14px;
    line-height: 1.21;
    letter-spacing: 0.15px;
    text-align: left;
    font-weight: 500;
    color: #000000;
  }

  .pickState {
    display: flex;
    margin-top: 10px;
    margin-left: 20px;

    > div {
      margin-right: 15px;
    }
  }

  p.datesExplanation {
    height: 35px;
    margin-left: 20px;
  }

  .pickUsers {
    width: 410px;
    margin-top: 10px;
    margin-bottom: 10px;

    > div.userList {
      align-content: flex-start;
      border: 2px dotted $blue;
      border-radius: 0 0 15px 15px;
      border-top: 0;
      display: flex;
      flex-wrap: wrap;
      margin: auto;
      min-height: 150px;
      padding: 5px 10px;

      > .noUser {
        margin: 30px auto;
        text-align: center;

        svg {
          margin: 0 auto;
        }

        p {
          color: #777;
          font-size: 12px;
          margin: 10px;
        }
      }

      > div.user {
        color: #333;
        display: flex;
        margin: 4px 5px;

        > div {
          align-items: center;
          background: $error;
          border-radius: 5px;
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
          cursor: pointer;
          display: flex;
          height: 30px;
          justify-content: center;
        }

        > span:first-of-type {
          align-items: center;
          background: #eee;
          border-radius: 5px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          display: flex;
          flex-grow: 1;
          font-size: 13px;
          font-weight: 500;
          height: 30px;
          justify-content: center;
          max-width: 400px;
          padding: 2px 10px;
          text-align: center;
          width: auto;
        }
      }

      &.usersToPickFrom  {
        > div {
          > div {
            background: $blue;
          }
        }
      }
    }
  }

  .nextStep {
    width: 100%;
    display: flex;
    padding: 10px 0px;
    justify-content: flex-end;
    border-top: 1px solid #eaeaf1;

    > button {
      margin: inherit;
    }
  }
}

/*** RadioButton sub-component ***/
.radioButton {
  display: flex;
  flex-direction: row;
  align-items: center;

  > p {
    margin-left: 10px;
  }

  > .option {
    width: 18px;
    height: 18px;
    padding: 4px;
    border: solid 1px #286af6;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: rgba(95, 195, 215, 0.2);
    }

    &.selected {
      background-color: rgba(95, 195, 215, 0.2);
    }

    .middleCircle {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #286af6;
    }
  }
}

/*** Dates pickers sub-component ***/
.datesPicker {
  margin-top: 10px;
  width: 40%;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  .dates {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }

  label {
    height: 17px;
    flex-grow: 0;
    font-size: 14px;
    line-height: 1.21;
    letter-spacing: 0.15px;
    text-align: left;
    font-weight: 500;
    color: #000000;
  }
}
