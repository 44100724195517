@import "@skillup/ui";

.permissions {
  padding: $skui-spacing-s;
}

.permissions__action {
  margin: 0 0 $skui-spacing-s 0;
  // padding: 0 1rem;
}

.permissions__actionChoice {
  padding: $skui-spacing-xxs 0;
}

.permissions__reviewType {
  padding: $skui-spacing-s $skui-spacing-s 0 $skui-spacing-s;
}

.permissions__actionLabel {
  color: $plainText-onLight-lighter;
  @include espace-rh-components-input-label;
}

.permissions__errorContainer {
  border-left: 2px solid $status-error-default;
  padding-left: $skui-spacing-xs;
}
