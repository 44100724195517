@import "../../assets/colors";

.Container {
  position: relative;
  border-radius: 6px;

  &.round {
    border-radius: 50%;
  }

  .notifications {
    position: absolute;
    flex-shrink: 0;
    top: -0.5rem;
    right: -0.5rem;
    width: 1.125rem;
    height: 1.125rem;
    background-color: $secondary-red;
    border-radius: 50%;
    z-index: 1;

    .count {
      font-size: 0.625rem;
      font-weight: bold;
      color: white;
      user-select: none;
      text-align: center;
    }
  }
}
