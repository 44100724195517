@import "../../../assets/designSystem";

.BaseInput {
  display: flex;
  align-items: center;
  padding: $skui-spacing-xs $skui-spacing-s;
  border: 1px solid $border-onLight;
  border-radius: $skui-spacing-xxs;
  background-color: $surface-light;
  &.withIcon {
    padding: $skui-spacing-xs $skui-spacing-s $skui-spacing-xs $skui-spacing-xs;
    svg {
      fill: $plainText-onLight-lighter;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  &:hover {
    border-color: $greyscale-medium;
  }
  &.empty {
    input::placeholder {
      color: $plainText-onLight-placeholder;
    }
  }
  &.disabled {
    background-color: $surface-light-darker;
    border-color: $border-onLight;
    opacity: 0.3;
  }
  &.readOnly {
    border: none !important;
    padding: 0;
    background-color: transparent;
    &.darkMode {
      input {
        color: $plainText-onDark-default;
      }
    }
  }
  &:focus-within {
    border-color: $action-onLight-default;
    outline: 2px solid $action-onLight-default;
    outline-offset: 1px;
  }
  &.error {
    border-color: $status-error-default;
    &:hover {
      border-color: $status-error-darker;
    }
  }

  input {
    border: none;
    background-color: transparent;
    color: $plainText-onLight-default;
    width: 100%;
    &.withIcon {
      margin-left: $skui-spacing-s;
    }
    &.error {
      color: $status-error-darker;
    }
  }

  &.espace-collab {
    input {
      @include espace-collab-components-input-value;
      &.empty {
        @include espace-collab-components-input-placeholder;
      }
    }
  }

  &.espace-rh {
    input {
      @include espace-rh-components-input-value;
      &.empty {
        @include espace-rh-components-input-placeholder;
      }
    }
  }
}
