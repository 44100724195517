@import "@skillup/ui";

.Modal {
  width: 40rem;

  .SubHeader {
    @include espace-rh-caption-regular;
    color: $plainText-onLight-lighter;
  }

  .ContentModal {
    .searchInput {
      width: 100%;
      margin-bottom: $skui-spacing-s;
      margin-top: $skui-spacing-xxs;
    }

    .column {
      width: 50%;
      &:first-child {
        margin-right: $skui-spacing-xs;
      }
      &:last-child {
        margin-left: $skui-spacing-s;
      }

      .columnTitle {
        padding-left: $skui-spacing-xs;
        @include espace-rh-caption-bold;
        color: $plainText-onLight-lighter;
        display: flex;
        gap: $skui-spacing-s;
        &.disabled {
          opacity: 0.4;
        }
      }

      .columnItem {
        padding-left: $skui-spacing-xs;
        padding-top: $skui-spacing-xs;
        padding-bottom: $skui-spacing-xs;
        margin-bottom: $skui-spacing-xxs;
        cursor: pointer;

        &.checkedItem {
          background-color: $action-onLight-lightest;
          border-radius: $skui-spacing-xs;
        }

        &.disabledItem {
          opacity: 0.3;
          cursor: default;
        }
      }
    }
  }
}
