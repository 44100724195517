@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.PositionnedTrainees {
  width: inherit;

  .high {
    display: flex;
    justify-content: space-between;

    .badge span {
      color: $status-warning-darker;
    }
  }

  .default {
    font-size: 11px;
    color: #828282;
  }

  .min {
    color: $warning;
  }

  .max {
    color: $error;
  }
}
