@import "../../assets/designSystem";

$activeStatusBoxShadow: inset 100px 100px 0px rgba(12, 92, 136, 0.1),
  inset -1px -1px 8px rgba(12, 92, 136, 0.1), inset 1px 1px 8px rgba(12, 92, 136, 0.1);

.ButtonWrapper {
  display: inline-flex;
}

.Button {
  position: relative;
  border: none;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  a {
    color: inherit;
    text-decoration: none;
}
  

  .contentWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    white-space: nowrap;

    svg {
      &:not(.actionIcon) {
        margin-right: $skui-spacing-xs;
      }
      &.actionIcon {
        margin-left: $skui-spacing-xs;
      }
    }
  }

  &.high {
    box-shadow: inset 0px -2px 0px $surface-dark;
    color: $plainText-onDark-default;
    background: $action-onLight-darker;

    &:not(.disabled) {
      &:hover {
        box-shadow: none;
        background: $surface-dark-darker;
      }

      &:focus-visible,
      &:active {
        box-shadow: none;
        background: $surface-dark-darker;
        outline: 2px solid $action-onLight-darker;
        outline-offset: 1px;
      }

      &:active {
        box-shadow: $activeStatusBoxShadow;
      }
    }

    .loaderWrapper {
      position: absolute;

      path {
        fill: $plainText-onDark-default;
      }
    }

    &.darkMode {
      color: $plainText-onLight-default;
      background: $action-onDark-default;
      box-shadow: inset 0px -2px 0px #cc9900;

      &:not(.disabled) {
        &:hover {
          background: $action-onDark-lighter;
          box-shadow: none;
        }

        &:focus-visible,
        &:active {
          background: $action-onDark-lighter;
          outline: 2px solid white;
          box-shadow: none;
        }

        &:active {
          box-shadow: $activeStatusBoxShadow;
        }
      }

      .loaderWrapper {
        position: absolute;

        path {
          fill: $plainText-onLight-default;
        }
      }
    }
  }

  &.mid {
    color: $action-onLight-darkest;
    box-sizing: border-box;
    border: 1px solid $action-onLight-default;
    background: none;

    .loaderWrapper {
      position: absolute;

      path {
        fill: $action-onLight-darkest;
      }
    }

    &:not(.disabled) {
      &:hover {
        background: $effects-hover-onLight;
      }

      &:focus-visible,
      &:active {
        background: $effects-hover-onLight;
        outline: 2px solid $action-onLight-default;
        outline-offset: 1px;
      }

      &:active {
        box-shadow: $activeStatusBoxShadow;
      }
    }

    &.darkMode {
      color: $plainText-onDark-default;
      border: 1px solid $plainText-onDark-default;

      &:not(.disabled) {
        &:hover {
          background: $effects-hover-onDark;
        }

        &:focus-visible,
        &:active {
          background: $effects-hover-onDark;
          outline: 2px solid white;
        }

        &:active {
          box-shadow: $activeStatusBoxShadow;
        }
      }

      .loaderWrapper {
        position: absolute;

        path {
          fill: $plainText-onDark-default;
        }
      }
    }
  }

  &.low {
    color: $action-onLight-darkest;
    background: none;

    &:not(.disabled) {
      &:hover {
        background: $effects-hover-onLight;
      }

      &:focus-visible,
      &:active {
        background: $effects-hover-onLight;
        outline: 2px solid $action-onLight-default;
        outline-offset: 1px;
      }

      &:active {
        box-shadow: $activeStatusBoxShadow;
      }
    }

    .loaderWrapper {
      position: absolute;

      path {
        fill: $action-onLight-darkest;
      }
    }

    &.darkMode {
      color: $plainText-onDark-default;

      &:not(.disabled) {
        &:hover {
          background: $effects-hover-onDark;
        }

        &:focus-visible,
        &:active {
          background: $effects-hover-onDark;
          outline: 2px solid white;
        }

        &:active {
          box-shadow: $activeStatusBoxShadow;
        }
      }

      .loaderWrapper {
        position: absolute;

        path {
          fill: $plainText-onDark-default;
        }
      }
    }
  }

  &.disabled {
    opacity: 0.3;
    cursor: default;
  }

  &.M {
    padding: $skui-spacing-xxs $skui-spacing-s;
    &.withActionIcon {
      padding-right: $skui-spacing-xs;
    }
    &.hasIcon {
      padding-left: $skui-spacing-xs;
    }

    svg {
      height: 1.5rem;
      width: 1.5rem;
    }

    &.espace-rh {
      @include espace-rh-button;
    }

    &.espace-collab {
      @include espace-collab-button;
    }
  }

  &.S {
    padding: $skui-spacing-xxs $skui-spacing-xs;

    svg {
      height: 1.3125rem;
      width: 1.3125rem;
    }

    &.espace-rh {
      @include espace-rh-button-small;
    }

    &.espace-collab {
      @include espace-collab-button-small;
    }
  }

  &.espace-rh {
    border-radius: 0.25rem;
    &.light {
      font-weight: 100;
    }

    &.normal {
      font-weight: 400;
    }

    &.bold {
      font-weight: 700;
    }
  }

  &.espace-collab {
    border-radius: 1rem;
    &.light {
      font-weight: 100;
    }

    &.normal {
      font-weight: 400;
    }

    &.bold {
      font-weight: 700;
    }
  }

  .loaderWrapper {
    position: absolute;
    display: none;
  }

  &.loading {
    .contentWrapper {
      opacity: 0;
    }

    .iconWrapper {
      opacity: 0;
    }

    .loaderWrapper {
      position: absolute;
      display: block;
    }
  }

  &.iconOnly {
    background: none;
    box-shadow: none;

    .iconWrapper {
      display: flex;
      height: 1.5rem;
      width: 1.5rem;
      position: relative;

      svg {
        fill: $plainText-onLight-lighter;
        z-index: 5;
        height: inherit;
        width: inherit;
      }

      &.navButton {
        &::before {
          background: white;
          border: 1px solid $border-onLight;
          box-shadow: 0px 4px 16px $elevation-modal;
        }
      }

      &::before {
        content: "";
        position: absolute;
        height: $skui-spacing-xl;
        width: $skui-spacing-xl;
        transform: translate(-0.5rem, -0.5rem);
        border-radius: 50%;
      }

      &.S {
        height: 1rem;
        width: 1rem;
        svg {
          height: inherit;
          width: inherit;
        }
        &::before {
          height: 1.666rem;
          width: 1.666rem;
          transform: translate(-0.333rem, -0.333rem);
        }
      }

      &.XS {
        height: 0.75rem;
        width: 0.75rem;
        svg {
          height: inherit;
          width: inherit;
        }
        &::before {
          height: 1rem;
          width: 1rem;
          transform: translate(-0.125rem, -0.125rem);
        }
      }
    }

    &:not(.disabled) {
      &:focus-visible,
      &:active {
        .iconWrapper {
          &::before {
            background: $plainText-onDark-default;
            border: 2px solid $action-onLight-darker;
          }
          box-sizing: border-box;

          svg {
            fill: $plainText-onLight-default;
          }
        }
      }

      &:hover,
      &:active {
        .iconWrapper {
          &::before {
            background: $effects-hover-onLight;
          }

          svg {
            fill: $plainText-onLight-default;
          }
        }
      }
    }

    &.darkMode {
      svg {
        fill: $plainText-onDark-darker;
      }

      &:not(.disabled) {
        &:focus-visible,
        &:active {
          .iconWrapper {
            &::before {
              border: 2px solid $plainText-onDark-default;
              background: none;
            }

            svg {
              fill: $plainText-onDark-default;
            }
          }
        }

        &:hover,
        &:active {
          .iconWrapper {
            &::before {
              background: $effects-hover-onDark;
            }

            svg {
              fill: $plainText-onDark-default;
            }
          }
        }
      }
    }
  }
}
