.dataTable {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.dataTableModule {
  overflow-y: hidden;
  overflow-x: scroll;
}

.Header {
  /**
    * It's obviously the scrollbar Watson
    */
  width: calc(100% - 16px);

  .subHeader {
    position: relative;
    border-bottom: solid 3px #2782ff;
  }

  .column {
    padding: 8px;
    font-weight: bold;
    font-size: 13px;
    width: calc(100% / 6);
    display: flex !important;
    flex-flow: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.Rows {
  font-size: 13px;
}
