.UserImportError {
  box-shadow: rgba(0, 0, 0, 0.15) 0 0 5px;
  display: flex;
  margin: 16px 0;

  &.hidden {
    display: none;
  }

  .content {
    align-items: center;
    background-color: #f9a1a1;
    display: flex;
    width: 100%;
    border-radius: 5px 0 0 5px;
    overflow: hidden;

    .iconContainer {
      padding: 0 25px;
    }

    .info {
      background-color: #de4343;
      flex-grow: 1;
      padding: 16px 20px;
      color: #f5f8fc;

      p.line {
        color: #fff;
        font-size: 13px;
        margin-bottom: 4px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  button.hideBtn {
    background-color: #de4343;
    border: none;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    outline: none;
    padding: 0 20px;
  }
}
