@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.detailedIndicator {
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: $skui-spacing-s;
  padding: $skui-spacing-s;
  border-radius: $skui-spacing-s;

  background-color: $surface-light;
  border: 1px solid $decoration-blue-lighter;

  .header {
    justify-content: space-between;

    .title {
      @include espace-rh-h6;

      color: $plainText-onLight-lighter;
    }
  }
  .cardContainer {
    height: 25rem;
    div {
      width: 100%;
    }
  }
}
