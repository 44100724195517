@import "../../../../assets/designSystem";

.skillPopover {
  width: auto;
}

.skillContainer {
  display: grid;
  grid-template-areas: "skillname level";
  gap: $skui-spacing-xs;
  grid-template-columns: 5fr 3fr;
  padding: $skui-spacing-xxs $skui-spacing-xs;
  cursor: pointer;

  &:hover {
    background-color: $effects-hover-onLight;
    border-radius: $skui-spacing-xxs;
  }

  .skillName {
    font-weight: 400;
    line-height: 1.125rem;
    font-size: 0.75rem;
    overflow: hidden;
    color: $plainText-onLight-default;
    margin-left: $skui-spacing-xs;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .skillNameOverflow {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 300px;

      &:first-letter {
        text-transform: uppercase;
      }
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .singleItemTooltip {
    border: lightcoral 1px dashed;
    position: absolute;
  }
}
