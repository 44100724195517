@import "../../../assets/colors";
@import "../../../assets/mixins";

.SearchResultsTrainingCard {
  border-radius: 10px;
  background-color: white;
  border: 1px solid $pale-lilac;
  padding: 1rem 1.25rem;

  &:hover {
    cursor: pointer;
  }

  .top {
    .main {
      align-items: flex-start;

      img.orgProfileLogo {
        width: 2.875rem;
        max-height: 2.875rem;
        flex-shrink: 0;
      }

      .sectionContainer {
        padding: 0 1.25rem;

        h3.name {
          font-size: 1rem;
          font-weight: 500;
          line-height: 1.06;
          letter-spacing: 0.15px;
          color: $b-w-black;
          margin-bottom: 0.325rem;

          &:hover {
            text-decoration: underline;
          }
        }

        p.subtitle {
          margin-top: 0.625rem;
          font-size: 0.75rem;
          line-height: 1rem;
          max-height: calc(1rem * 2); // line-height * max-lines;
          overflow: hidden;
          color: $bluey-grey;
        }
      }
    }
  }

  .bottom {
    margin-top: 0.5rem;
    align-items: center;

    .meta {
      align-items: center;
      margin-right: 0.875rem;

      span.value {
        font-size: 0.75rem;
        color: $b-w-black;
        margin-left: 0.425rem;
      }
    }
  }

  @include isMobile {
    .top {
      flex-direction: column;

      .main {
        .orgProfileLogo {
          display: none;
        }

        .sectionContainer {
          padding: 0;

          .ratingContainer {
            margin-bottom: 0.325rem;
          }

          p.subtitle {
            display: none;
          }
        }
      }
    }

    .bottom {
      margin-top: 0;

      .meta {
        margin-right: 0.875rem;
      }
    }
  }
}
