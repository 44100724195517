@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.Loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  font-family: "Noto Sans", sans-serif;
  font-size: 0.75em;
  color: #69798c;
}

.Svg {
  background: linear-gradient(-45deg, #f6f7f8, #e5e8eb, #c9ced4, #909aa7);
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
}
