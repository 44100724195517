@import "../../../../assets/designSystem";

.errorOverlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $skui-spacing-l;
  padding: $skui-spacing-l;
}

.text {
  color: $plainText-onLight-placeholder;
  text-align: center;
}