@import "../../assets/colors";

.item {
  padding: 0.5rem;
  border-radius: 5px;
  font-size: 0.75rem;
  margin-top: 0.5rem;
  list-style-type: none;

  span.itemLabel {
    color: $b-w-black;
    font-size: 0.875rem;
    font-weight: 500;
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
  div.itemIcon {
    cursor: pointer;
  }
  div.itemDescription {
    margin-top: 0.5rem;
    margin-bottom: 0;
    font-size: 0.75rem;
    color: $slate;
    white-space: pre-line;
    .errorItem {
      padding-bottom: 1rem;
      p {
        font-weight: 600;
      }
    }
  }
}

.itemLabels {
  margin-top: 1.25rem;

  .choiceLevel:not(:last-of-type) {
    margin-right: 0.25rem;
  }
}
