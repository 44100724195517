@import "@skillup/ui";

.link {
  text-decoration: none;
}

.card {
  border-radius: 4px;
  box-shadow: $elevation-actionableCard;
  background: $surface-light;
  border: 1px solid $greyscale-light;
  cursor: pointer;

  &:hover {
    box-shadow: $elevation-actionableCard-hover;
    .title {
      color: $plainText-onLight-default;
    }
  }
  
  &:focus {
    box-shadow: none;
    outline: 2px solid $action-onLight-darker;
    outline-offset: 2px;
  }
}
