@import "../../assets/designSystem";

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.DataTable {
  color: $plainText-onLight-default;

  .TableHeader {
    position: relative;
    min-height: $skui-spacing-l;
    color: $plainText-onLight-default;
    margin-bottom: $skui-spacing-s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0.5rem;

    .TableHeaderActions {
      display: flex;
      gap: $skui-spacing-xs;
    }
  }

  transition: opacity 400ms ease-in-out;
  &.loading {
    .TableWrapper,
    .thead,
    .headerShadow {
      opacity: 0.3;
      pointer-events: none;
    }
  }

  .Table {
    &.compact {
      padding: 0;
    }

    background-color: $surface-light;
    border: 1px solid rgba(105, 121, 140, 0.2);
    box-shadow: 0 0 2px rgba(12, 92, 136, 0.15);
    border-radius: 8px;
    margin-bottom: $skui-spacing-xs;
    padding: 0 $skui-spacing-xxs $skui-spacing-xs $skui-spacing-xxs;
    max-width: 100%;
    position: relative;

    .LoaderWrapper {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      z-index: 30;
      opacity: 0;

      &.display {
        opacity: 1;
      }

      &.fade-out {
        animation-duration: 400ms;
        animation-name: fade-out;
        animation-timing-function: ease-in-out;
      }
    }

    /******************************
    /*  HEADER
    ******************************/
    .thead {
      position: sticky;
      z-index: 20;
      display: flex;
      overflow: hidden;
      top: -$skui-spacing-m;
      background-color: $surface-light;
      border-radius: 0.5rem 0.5rem 0 0;

      &.selectionMode {
        .th {
          &.action {
            position: static;
            > * {
              visibility: hidden;
            }
          }
        }
      }

      &.compact {
        top: -1px; // for sticky header shadow
        padding: 1px 0; // compensate top position
        height: 2rem;

        .th {
          background-color: $surface-light-darker !important;
          padding: $skui-spacing-xs !important;
        }
      }

      .tableCheckbox {
        padding: 0.25rem;
        padding-left: 0;
        > div:first-of-type {
          margin-right: 0;
        }
      }

      .th {
        padding: $skui-spacing-s;

        &.compact {
          padding: $skui-spacing-xs;
        }

        font-size: 0.75rem;
        text-align: start;
        color: $plainText-onLight-lighter;
        display: inline-flex;

        &.action,
        &.selection {
          width: 3rem;
          min-width: 3rem;
          max-width: 3rem;
          height: 5rem;
          z-index: 1;
          vertical-align: center;

          &.compact {
            width: 2rem;
            min-width: 2rem;
            max-width: 2rem;
            height: 2rem;

            &:last-of-type {
              width: 100%;
              max-width: unset;
            }
          }
        }

        &.selection {
          padding: $skui-spacing-s;
          cursor: pointer;
          position: sticky;
          left: 0;
          background-color: $surface-light;

          &:hover > div > div {
            box-shadow: 0 0 3px $effects-hover-onDark;
          }

          &.scroll {
            &::before {
              content: "";
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              width: 4px;
              box-shadow: $panel-shadow-right;
            }
          }
        }

        &.action {
          position: sticky;
          right: -$skui-spacing-xxs;
          background-color: $surface-light;

          &.scroll {
            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
              width: 4px;
              box-shadow: $panel-shadow-left;
            }
          }
        }
      }
    }

    .headerShadow {
      display: none;

      &.pinned {
        display: block;
        position: sticky;
        z-index: 21;
        height: 4px;
        top: 3.25rem;
        box-shadow: $panel-shadow-below;

        &.compact {
          top: 1.5rem;
        }
      }
    }

    .simpleScroll {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 4px;
      display: none;

      &.scrollLeft {
        display: block;
        left: -4px;
        box-shadow: $panel-shadow-right;
      }

      &.scrollRight {
        display: block;
        right: -4px;
        box-shadow: $panel-shadow-left;
      }
    }

    /******************************
    /*  BODY
    ******************************/
    .TableWrapper {
      overflow-x: auto;
      overflow-y: hidden;

      &.noScroll {
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
          height: 0;
        }
      }

      > table {
        display: table;
        border-collapse: collapse;
        width: 100%;
        table-layout: fixed;

        tr,
        .tr {
          .tableCheckbox {
            padding: 0.25rem;
            padding-left: 0;
            > div:first-of-type {
              margin-right: 0;
            }
          }

          &.clickable {
            cursor: pointer;
          }

          td {
            &.selection {
              width: 3rem;
              max-width: 3rem;
              padding: $skui-spacing-s;

              &.compact {
                width: 2rem;
                max-width: 2rem;
                padding: $skui-spacing-xs;
                border-top: 1px solid $border-onLight;
                border-bottom: 1px solid $border-onLight;
                border-radius: 0;
              }

              &.scroll {
                &::before {
                  content: "";
                  position: absolute;
                  right: 0;
                  top: 0;
                  bottom: 0;
                  width: 4px;
                  box-shadow: $panel-shadow-right;
                }
              }

              cursor: pointer;
              position: sticky;
              left: 0;
              background-color: $surface-light;

              &:hover > div > div {
                box-shadow: 0 0 3px $effects-hover-onDark;
              }
            }

            &.action {
              position: sticky;
              right: -$skui-spacing-xxs;
              background-color: $surface-light;

              &.scroll {
                &::before {
                  content: "";
                  position: absolute;
                  left: 0;
                  top: 0;
                  bottom: 0;
                  width: 4px;
                  box-shadow: $panel-shadow-left;
                }
              }
            }
          }
        }

        &.selectionMode {
          td {
            &.action {
              position: static;
              > * {
                visibility: hidden;
              }
            }
          }
        }

        tbody {
          tr {
            > td {
              &:first-child {
                border-top-left-radius: 0.375rem;
                border-bottom-left-radius: 0.375rem;
              }

              &:last-child {
                border-top-right-radius: 0.375rem;
                border-bottom-right-radius: 0.375rem;
              }

              &.action {
                width: 3rem;
                min-width: 3rem;
                overflow: visible;
                white-space: normal;
                cursor: default;
                vertical-align: middle;
                text-align: center;

                &.compact {
                  width: 2rem;
                  min-width: 2rem;
                  padding: $skui-spacing-xs;
                  border-top: 1px solid $border-onLight;
                  border-bottom: 1px solid $border-onLight;
                }
              }

              &.invisible {
                > * {
                  visibility: hidden;
                }
              }

              &.action.toggleOpened {
                z-index: 1;
              }
            }

            &:focus-within {
              .pencil {
                visibility: visible !important;
              }
            }
            &:hover > td,
            &:focus-within > td {
              background-color: $greyscale-lighter;
            }
            &.selected > td {
              background-color: $decoration-blue-lighter;
            }
          }
        }
      }

      .emptyResult {
        height: 120px;

        .message {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: $skui-spacing-l $skui-spacing-xs;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);

          p {
            margin-bottom: $skui-spacing-xs;
          }
        }
      }
    }
  }

  &.espace-collab {
    @include espace-collab-font;

    .TableHeader {
      @include espace-collab-body2-regular;
    }

    .Table {
      @include espace-collab-body1-regular;

      &.compact {
        @include espace-collab-body2-regular;
      }

      .thead {
        @include espace-collab-caption-bold;
      }

      .emptyResult {
        @include espace-collab-body2-regular;
      }
    }
  }

  &.espace-rh {
    @include espace-rh-font;

    .TableHeader {
      @include espace-rh-body2-regular;
    }

    .Table {
      @include espace-rh-body1-regular;

      &.compact {
        @include espace-rh-body2-regular;
      }

      .thead {
        @include espace-rh-caption-bold;
      }

      .emptyResult {
        @include espace-rh-body2-regular;
      }
    }
  }
}
