@import "@skillup/ui";

.createOrManageDocument {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  padding: $skui-spacing-m;

  .manageDocument {
    margin-bottom: 2rem;

    > div:first-of-type {
      margin-top: 0;
    }
  }

  .addButtonWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .createDocumentWrapper {
    background-color: $greyscale-lighter;
    border: solid 1px $border-onLight;
    border-radius: 8px;
    padding: 1rem 1.5rem;
    .createDocument {
      .title {
        @include espace-rh-h5;
        color: $plainText-onLight-default;
        margin-bottom: $skui-spacing-s;
      }

      .inputLine {
        margin-bottom: $skui-spacing-xs;

        .label {
          @include espace-rh-components-input-label;
          color: $plainText-onLight-lighter;
        }

        .input {
          @include espace-rh-components-input-value;
          color: $plainText-onLight-lighter;
          width: 100%;
        }
        .radioBoxes {
          display: flex;
          padding-top: 0.5rem;
          .radioBoxElement {
            margin-right: 1.5rem;
          }
        }
        .warningText {
          margin-top: 0.5rem;
          color: $plainText-onLight-default;
          font-family: "Noto Sans Display";
        }
      }
    }

    .buttonsWrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .button {
        margin-right: $skui-spacing-xs;
        box-sizing: border-box;

        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }

  .trackingUser {
    @include espace-rh-h5;
    margin-bottom: $skui-spacing-s;
  }
}
