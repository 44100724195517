@import "../../assets/designSystem";

.NavigationItem {
  .wrapper {
    padding: $skui-spacing-xxs 0 $skui-spacing-xxs $skui-spacing-xs;
    .button,
    .navItem,
    .dropDownButton {
      cursor: pointer;
      width: 100%;
      text-decoration: none;
      padding: 0.75rem $skui-spacing-xs 0.75rem $skui-spacing-xs;
      display: flex;
      align-items: center;
      border-radius: 8px 0px 0px 8px;
      border: 2px solid transparent;
      border-right: none;

      @media (max-width: 768px) {
        padding: 0.75rem $skui-spacing-s 0.75rem $skui-spacing-s;
      }

      label {
        cursor: pointer;
        margin-left: $skui-spacing-s;
        text-align: left;
        flex-grow: 1;
      }
      svg {
        cursor: pointer;
        width: 1.5rem;
        height: 1.5rem;
      }
      .moreIcon {
        display: flex;
        vertical-align: center;
        margin-left: $skui-spacing-s;
      }
    }
    .navItem {
      cursor: default;
      label {
        cursor: default;
      }
      svg {
        cursor: default;
      }
    }
  }

  .children {
    &.hide {
      display: none;
    }
  }

  &.darkMode {
    .wrapper {
      .button,
      .navItem {
        label,
        svg {
          color: $plainText-onDark-darker;
          fill: $plainText-onDark-darker;
        }
        &:hover {
          label,
          svg {
            color: $plainText-onDark-default;
            fill: $plainText-onDark-default;
          }
        }
        &:active,
        &.active {
          background-color: $surface-light;
          label,
          svg {
            color: $plainText-onLight-default;
            fill: $plainText-onLight-default;
          }
        }
        &:focus {
          border-color: $action-onDark-default;
        }
      }
      .navItem {
        &:active {
          background: none;
          label,
          svg {
            color: $plainText-onDark-darker;
            fill: $plainText-onDark-darker;
          }
        }
        &:hover {
          label,
          svg {
            color: $plainText-onDark-darker;
            fill: $plainText-onDark-darker;
          }
        }
        &:focus {
          border-color: transparent;
        }
      }
    }
  }

  &:not(.darkMode) {
    .wrapper {
      .button {
        label,
        svg {
          color: $plainText-onLight-lighter;
          fill: $plainText-onLight-lighter;
        }
        &:hover {
          label,
          svg {
            color: $plainText-onLight-default;
            fill: $plainText-onLight-default;
          }
        }
        &:active,
        &.active {
          background-color: $decoration-blue-lighter;
          label,
          svg {
            color: $plainText-onLight-default;
            fill: $plainText-onLight-default;
          }
        }
        &:focus {
          border-color: $action-onLight-default;
        }
      }
    }
  }
}
