.preview {
  display: flex;
  flex-direction: column;
  padding: 20px;

  > .links {
    display: flex;
    flex-direction: row;
    padding: 15px;

    > button {
      margin: auto;
    }
  }
}
