@import "../../assets/colors";

@mixin chunk($color) {
  border: 1px solid $color;
  background-color: rgba($color, 0.2);

  span.label {
    color: $color;
  }
}

.TargetEvaluation {
  display: inline-flex;
  min-width: 5.813rem;
  border-radius: 100px;
  border: 1px solid $bluey-grey;
  height: 2.5rem;
  padding: 0 0.781rem;
  user-select: none;

  span.label {
    letter-spacing: 0.15px;
    color: $slate;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.21;

    &.withEmoji {
      margin-right: 0.375rem;
    }
  }

  span.emoji {
    font-size: 1.5rem;
    line-height: 0.71;
    letter-spacing: 0.15px;
  }

  &.small {
    height: 2rem;
    min-width: 4.125rem;
    padding: 0 0.75rem;

    span.label {
      font-size: 0.75rem;
      line-height: 1.25;
    }

    span.emoji {
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 0.83;
      letter-spacing: 0.15px;
    }
  }

  &:hover,
  &.active {
    cursor: pointer;

    &.numeric.chunk-0,
    &.ordinal.chunk-0 {
      @include chunk($secondary-red);
    }

    &.numeric.chunk-1,
    &.ordinal.chunk-1 {
      @include chunk($secondary-orange);
    }

    &.numeric.chunk-2 {
      @include chunk($secondary-yellow);
    }

    &.numeric.chunk-3,
    &.ordinal.chunk-2 {
      @include chunk($secondary-green);
    }

    &.numeric.chunk-4,
    &.ordinal.chunk-3 {
      @include chunk($secondary-blue);
    }
  }
}
