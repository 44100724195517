@import "uiAssets/ColorsVars.scss";

.ActionsBox {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: stretch;
  margin-bottom: 30px;
  position: relative;
  width: 100%;
  z-index: 1;

  @media (min-width: 900px) {
    justify-content: flex-end;
    margin-bottom: 0;
    width: 250px;
  }

  .actionsWrapper {
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: stretch;

    .unmanagedWarning {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      text-align: center;
      margin-bottom: 20px;
    }

    .action {
      margin-bottom: 20px;
    }

    .action:last-child {
      margin-bottom: 0px;
    }
  }

  > .content {
    background: #fff;
    border-radius: 7px;
    box-shadow: $bigBoxShadow;
    width: 100%;

    > ul {
      border-radius: 0 0 7px 7px;
      box-shadow: rgba(40, 139, 255, 0.2) 0 3px 8px;
      list-style: none;
      margin: 0;
      padding: 0;

      > li {
        align-items: center;
        border-bottom: 1px solid #eee;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0;
        padding: 10px 20px;

        > span {
          color: #333;
          display: block;
          font-size: 13px;

          &:last-of-type {
            color: #666;
            display: block;
            margin-left: 15px;
            text-align: right;
          }
        }

        > small {
          color: #666;
          display: block;
          flex-basis: 100%;
          font-size: 13px;
          margin-top: 3px;
          text-align: right;
        }
      }
    }

    > .separatorEestimateRequest {
      text-align: center;
    }

    > .onDemandSessions,
    > .noSession {
      padding: 15px;
      text-align: center;
    }
  }

  > .actions {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-top: 20px;

    > button {
      margin-left: 0 !important;

      &:first-of-type {
        margin-right: 20px !important;
      }
    }

    > .success {
      background-color: #92c123;
      border-radius: 7px;
      box-shadow: rgba(0, 0, 0, 0.1) 0 4px 10px;
      color: #fff;
      flex-basis: 100%;
      font-size: 14px;
      margin-top: 15px;
      padding: 7px 12px;
      text-align: center;
    }

    > .bookingButtonSuccess {
      display: none;
    }
  }

  > .skillupActions {
    border-radius: 7px;
    box-shadow: $bigBoxShadow;
    margin: 20px 0;
    padding: 10px;

    > h4 {
      color: $blue;
      text-align: center;
    }

    > input {
      display: block;
      margin-top: 10px;
      padding: 5px;
      width: 100%;
    }

    a,
    button {
      background: #edf3fe;
      border: none;
      border-radius: 4px;
      color: #276ef1;
      display: block;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      margin: 10px 0;
      padding: 8px;
      text-align: center;
      text-decoration: none;
      width: 100%;

      &:hover {
        background: #d2e0fc;
      }
    }
  }
}
