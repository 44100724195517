@import "uiAssets/ColorsVars.scss";

.CampaignUserUpdateModal {
  padding: 20px;

  form.searchForm {
    display: flex;
    flex-direction: column;

    .searchInput {
      display: flex;
      flex-direction: column;

      div[class^="results"] {
        height: auto;
        position: initial;
        width: 100%;
      }
    }

    .usersList {
      display: flex;
      margin-top: 8px;

      .user {
        align-items: center;
        background-color: #f6f6f6;
        border: 1px solid #efefef;
        border-radius: 4px;
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        margin-bottom: 8px;
        width: 100%;

        .meta {
          display: flex;
          flex-direction: column;
          padding: 5px 10px;

          span.fullName {
            color: #333;
            font-size: 13px;
          }

          small.email {
            color: #ccc;
            font-size: 10px;
          }
        }

        .removeUserBtn {
          background: none;
          border: none;
          border-radius: 0 4px 4px 0;
          display: flex;
          height: 100%;
          justify-content: flex-start;
          margin-left: 2px;
          width: 20px;

          .removeUserIcon {
            stroke: #ccc !important;
          }

          &:hover .removeUserIcon {
            stroke: #000 !important;
          }
        }
      }
    }

    .modalFooter {
      display: flex;
      flex-direction: column;
      margin-top: 14px;
    }
  }
}
