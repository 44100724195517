@import "@skillup/ui";
@import "uiAssets/ColorsVars.scss";

.Employees {
  .searchInput {
    margin-left: 10px;
    width: 18.75rem;
    margin-bottom: 1.188rem;
  }

  .tableContainer {
    will-change: top;
    max-height: 100%;
    overflow: auto;
  }

  thead {
    z-index: 1;
  }

  .moreBtn {
    cursor: pointer;
  }

  div.previewLink {
    cursor: pointer;
    &:hover {
      color: $bluey-grey;
    }
  }
}
