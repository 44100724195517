.StartDate {
  .details {
    font-size: 11px;
    color: #828282;
  }
  max-height: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
