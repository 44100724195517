.calendars {
  position: relative;
  z-index: 10;
}

.calendar {
  padding: 16px 0;

  > span {
    font-size: 12px;
    display: block;
  }

  > div {
    width: 100%;
  }
}

.linear {
  background: linear-gradient(45deg, rgb(0, 102, 255), rgb(0, 180, 255));
  height: 3px;
}
