@import "../../../assets/colors";

.DateRangePickerCalendar {
  border-radius: 10px;
  box-shadow: 0 5px 24px 0 rgba(229, 229, 229, 0.12), 0 13px 19px 0 rgba(229, 229, 229, 0.14),
    0 7px 8px 0 rgba(229, 229, 229, 0.15);
  border: 1px solid $pale-lilac;
  background-color: white;
  padding: 0.375rem;

  .header {
    height: 2.125rem;
    align-items: center;
    justify-content: space-between;

    .displayActions {
      .subBtn:hover,
      .addBtn:hover {
        cursor: pointer;
      }
    }

    span.activeMonth {
      font-size: 1rem;
      line-height: 1.25;
      letter-spacing: -0.11px;
      text-align: center;
      color: $b-w-black;
      text-transform: capitalize;
      user-select: none;
    }
  }

  .line {
    margin-top: 0.1875rem;
    margin-bottom: 0.1875rem;

    .inlineItem {
      width: calc(100% / 7);
      height: 2.125rem;

      &.withinInterval {
        background-color: rgba($secondary-blue, 0.1);

        &.activeStartDate {
          background: linear-gradient(
            90deg,
            transparent 50%,
            rgba($secondary-blue, 0.1) 50%,
            rgba($secondary-blue, 0.1) 100%
          );
        }

        &.activeEndDate {
          background: linear-gradient(
            270deg,
            transparent 50%,
            rgba($secondary-blue, 0.1) 50%,
            rgba($secondary-blue, 0.1) 100%
          );
        }
      }

      &.activeStartDate,
      &.activeEndDate {
        .dayContainer {
          cursor: pointer;
          box-shadow: 2px 2px 12px 0 rgba(47, 56, 74, 0.12);
          background-color: $secondary-blue;

          span.dayNumber,
          span.dayNumber.isWeekend {
            font-weight: bold;
            color: white;
          }
        }
      }

      .dayContainer {
        border-radius: 50%;
        width: 2.125rem;
        height: 2.125rem;

        &:not(.disabled):hover {
          cursor: pointer;
          box-shadow: 2px 2px 12px 0 rgba(47, 56, 74, 0.12);
          background-color: $secondary-blue;

          span.dayNumber,
          span.dayNumber.isWeekend {
            font-weight: bold;
            color: white;
          }
        }

        &.disabled {
          opacity: 0.3;
        }

        span.dayNumber {
          font-size: 0.875rem;
          text-align: left;
          color: $slate;
          user-select: none;

          &.isWeekend {
            color: $bluey-grey;
          }
        }
      }

      span.dayLabel {
        font-size: 0.688rem;
        font-weight: bold;
        text-align: center;
        color: $bluey-grey;
        user-select: none;
      }
    }
  }
}
