@import "uiAssets/ColorsVars.scss";

.RowContainer {
  cursor: pointer;

  .row {
    padding: 0 8px;
    display: flex;
    border-bottom: solid 1px #e0e0e0;
    align-items: center;
  }

  &:hover {
    background: #f6f6f6;
  }

  &:hover:not(.active) {
    div[data-as="checkbox"] {
      background: $dataTableActiveLine;

      div {
        > svg {
          opacity: 0.3;
        }
      }
    }
  }

  &.active {
    background: #f6f6f6;
  }
}
