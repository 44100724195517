@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.skillsDetailsModal,
.skillsDeletionModal {
  .headerBar {
    height: 10px;
    flex-grow: 0;
    background-image: $gradientBlue;
  }

  .buttonsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    gap: 10px;
  }

  .content {
    padding: 32px;

    > h2 {
      color: $blue;
      font-size: 24px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      margin-bottom: 32px;
    }

    > div.detail {
      margin-bottom: 20px;

      > label {
        color: $bluey-grey;
        height: 19px;
        font-size: 14px;
        flex-grow: 0;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.15px;
        text-align: left;
        margin-bottom: 8px;
      }

      > p {
        color: $b-w-black;
        min-height: 17px;
        align-self: stretch;
        flex-grow: 0;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.21;
        letter-spacing: 0.15px;
        text-align: left;
      }
    }

    > div.separator {
      height: 1px;
      align-self: stretch;
      flex-grow: 0;
      background-color: $pale-lilac;
      margin: 20px 0;
    }

    > div.evaluations {
      > div.evaluation {
        margin-bottom: 20px;
        > label {
          height: 16px;
          align-self: stretch;
          flex-grow: 0;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.1px;
          text-align: left;
          color: #2e76f6;
        }

        > p {
          align-self: stretch;
          flex-grow: 0;
          font-family: Segoe UI;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.21;
          letter-spacing: 0.15px;
          text-align: left;
          color: #192f4b;
        }
      }
    }
  }
}
