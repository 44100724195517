.Popover {
  position: relative;
  display: inline-block;
  height: fit-content;
  width: fit-content;
}
.PopoverBackdrop {
  overflow: hidden;
  z-index: 999;
  &.hover {
    pointer-events: none;
  }
  .menu {
    pointer-events: auto;
    overflow: auto;
  }
}
