@import "@skillup/ui";

.badge {
  display: inline-flex;
  padding: 0.125rem $skui-spacing-xs 0.125rem 0.125rem;
  align-items: center;
  border-radius: 1rem;
  background: $status-error-default;

  .icon {
    cursor: inherit;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    background: $status-error-default;
    border: 1px solid $status-error-default;

    svg {
      fill: $plainText-onDark-default;
    }
  }

  .value {
    cursor: inherit;
    padding-left: $skui-spacing-xs;
    color: $plainText-onDark-default;
    text-align: center;
    font-family: Noto Sans Display;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.125rem;
  }
}

.badge:hover {
  outline: 2px solid $plainText-onDark-default;
  cursor: pointer;
}
