@import "uiAssets/ColorsVars.scss";

.Faq {
  padding: 0 20px 10px;

  > h2 {
    background: $mainColorL;
    color: $blue;
    font-size: 17px;
    font-weight: 300;
    margin: 20px 0 10px;
    padding: 5px 8px;
  }

  > p {
    margin-bottom: 10px;
    padding-left: 20px;
  }
}
