@import "@skillup/ui";

[aria-label="stepper"] label {
  text-align: center;
}

.columnsText {
  margin-left: $skui-spacing-m;

  li::marker {
    color: $plainText-onLight-default
  }
}

.getTemplateLink {
  @include espace-rh-caption-link;
  color: $action-onLight-darkest;
}

.invalidCollabManagerPair {
  text-decoration: line-through !important;
  color: $plainText-onLight-lighter;
}

.goToPreviousStepLink {
  cursor: pointer;
  color: inherit;
}
.warningCollabOwnManagerDownloadLink {
  cursor: pointer;
  color: $status-warning-darker;
}

[data-field="observator"], 
[data-field="coordinator"], 
[data-field="noRole"] {
    padding: unset;
}

.attendees {
  .fileDrop {
    @include espace-rh-body1-regular;
  }

  .removeFileButton {
    padding: $skui-spacing-m $skui-spacing-xl;
    border-radius: 4px;
    border: 2px dashed $border-onLight;
  }
}

.managers {
  .dateInput div[aria-label="inputtext-date"] {
    width: 100%;
  }
}
