.Flex {
  display: flex;
  flex-direction: row;

  &.debug {
    border: 1px solid red;
  }

  &.flexRow {
    flex-direction: row;
  }

  &.flexColumn {
    flex-direction: column;
  }

  &.flexCenter {
    align-items: center;
    justify-content: center;
  }

  &.flexGrow {
    flex-grow: 1;
  }

  &.flexWrap {
    flex-wrap: wrap;
  }
}
