@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.SkillSearch {
  h4.label {
    display: inline-block;
    color: $blue;
    border-bottom: 3px solid $blue;
    font-size: 1.5rem;
    font-weight: 300;
    margin-bottom: 1rem;
    padding-bottom: 5px;
  }

  .searchInput {
    width: 500px;
  }

  .skill {
    margin-bottom: 20px;
    > .skillLabel {
      font-size: 20px;
      font-weight: 600;
      color: $b-w-black;
    }

    > .skillDescription {
      font-size: 16px;
      color: $slate;
      margin-bottom: 5px;
    }

    > table {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      max-height: 100%;
      margin-top: 0.75rem;
      > thead {
        > tr {
          > th {
            padding: 10px;
            border-top: 1px solid $pale-lilac;
            border-right: 1px solid $pale-lilac;
            border-left: 1px solid $pale-lilac;
          }
        }
      }

      > tbody {
        > tr {
          border-top: 1px solid $pale-lilac;

          > td {
            height: 25px;
            white-space: nowrap;
            border-bottom: 1px solid $pale-lilac;
            padding: 16px;
            font-size: 0.75rem;
            line-height: 1.33;
            color: $b-w-black;
            border-right: 1px solid $pale-lilac;
            border-left: 1px solid $pale-lilac;

            .expectationButton {
              height: 25px;
              margin: 0 auto;
            }

            &:first-of-type {
              width: 150px;
            }

            &:nth-of-type(2) {
              width: 250px;
            }

            &:nth-of-type(3) {
              width: 500px;
            }

            &:nth-of-type(4) {
              width: 100px;
            }
          }
        }
      }
    }
  }
}
