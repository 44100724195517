@import "../../../assets/designSystem";

.Filter {
  .GroupLabel {
    align-items: center;
    justify-content: space-between;
  }
}

.Input, .Select > div {
  height: 2.5rem;
  width: 100%;
}
