@import "uiAssets/ColorsVars.scss";

.UserSearchInput {
  position: relative;

  > div.results {
    position: absolute;
    top: 38px;
    left: -1px;
    width: 300px;

    border: 1px solid #ccc;
    background: #fff;
    height: 277px;
    overflow-y: auto;

    z-index: 999;

    > div {
      align-items: top;
      cursor: pointer;
      display: flex;
      height: 57px;
      padding: 10px;

      > svg {
        margin-top: 4px;
      }

      > .info {
        display: flex;
        flex-direction: column;
        padding-left: 15px;
        > span:first-of-type {
          font-size: 14px;
          color: black;
        }

        > span:last-of-type {
          font-size: 13px;
          color: #ccc;
        }
      }

      &:hover {
        background: #f6f6f6;
        /* > .info {
          > span:first-of-type {
            color: white;
          }
        }

        > svg {
          stroke: white !important;
        } */
      }
    }
  }
}
