@import "@skillup/ui";
@import "uiAssets/ColorsVars.scss";

.TrainingInscription {
  // background: #f7f7f7;
  display: flex;
  flex-direction: column;
  height: auto;
  margin: 0 auto;
  min-height: 100vh;
  width: auto;

  > div > header {
    &:before {
      background: $gradientMain;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      content: "";
      display: block;
      height: 10px;
      left: 0;
      top: 0;
      width: 100%;
    }
  }

  > .companyLogo {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    filter: grayscale(100%);
    height: 100px;
    margin: 30px auto 0;
    opacity: 0.5;
    width: 100px;
  }

  > .card {
    background: #fff;
    border-radius: 7px;
    box-shadow: rgba(40, 139, 255, 0.4) 0 4px 15px;
    margin: 9% auto auto;
    padding-bottom: 50px;

    .iconMore {
      background-color: #f5f5f5;
      border: 1px solid #ddd;
      color: #666;
      cursor: pointer;
      font-size: 12px;
      font-weight: 500;
      padding: 3px 10px;
      text-align: center;
      user-select: none;
      z-index: 10;
    }

    .iconMore:hover {
      color: #333;
    }

    > .success {
      background-color: #92c123;
      border-radius: 7px;
      box-shadow: rgba(0, 0, 0, 0.1) 0 4px 10px;
      color: #fff;
      flex-basis: 100%;
      font-size: 14px;
      margin: auto;
      margin-top: 15px;
      padding: 7px 12px;
      text-align: center;
      width: 250px;
    }

    > header {
      background: #f6f6f6;
      border-bottom: 1px solid #e6e6e6;
      padding-bottom: 20px;
      text-align: center;

      > h1 {
        color: $blue;
        font-size: 30px;
        font-weight: 400;
        padding: 14px 0 5px;
      }

      > p {
        color: #666;
        font-size: 14px;
        font-style: italic;
      }
    }

    > .training {
      align-items: top;
      display: flex;
      margin-bottom: 30px;
      padding: 16px 50px;
      width: 100%;

      > .summary {
        padding: 10px;
        width: 100%;
      }

      > .summary > .top {
        border: 1px solid #ddd;
        border-bottom: none;
        border-radius: 4px;
        display: flex;
        flex-flow: row wrap;
        position: relative;

        &.open {
          border-radius: 4px 4px 0 0;
        }

        .containerTrainingInfos {
          background-color: $borderColorLight;
          border-bottom: 1px solid #ddd;
          border-top: 1px solid #ddd;
          display: flex;
          flex-grow: 1;

          div {
            border-right: 1px solid #ddd;
            font-size: 12px;
            padding: 8px 20px;
          }
        }

        .iconMore {
          border-radius: 0 0 10px 10px;
          border-top: none;
          bottom: -22px;
          left: calc(50% - 75px);
          position: absolute;
          width: 170px;
        }

        > div:first-child {
          padding: 10px 10px 10px 20px;

          .orgLogo {
            background-color: #fff !important;
            background-position: 50% 50% !important;
            background-repeat: no-repeat !important;
            background-size: 60px !important;
            border-radius: 7px;
            box-shadow: rgba(0, 0, 0, 0.15) 0 3px 10px;
            height: 65px;
            width: 65px;
          }
        }

        > .title {
          padding: 8px 0 0 16px;
          width: calc(100% - 95px);

          > h2 {
            color: $blue;
            font-size: 18px;
            font-weight: 400;
            line-height: 36px;
          }

          .organization {
            font-size: 12px;
            font-style: italic;
            margin: 5px 0 10px;
          }
        }
      }

      > .summary > .description {
        font-size: 13px;
        font-weight: 300;
        padding-right: 20px;
        padding-top: 20px;
        text-align: left;
      }

      .containerContent {
        border: solid 1px #ddd;
        border-top: none;
        overflow: hidden;
        padding: 32px;
        position: relative;

        .iconMore {
          border-bottom: none;
          border-radius: 10px 10px 0 0;
          bottom: 0;
          left: 50%;
          position: absolute;
          transform: translateX(-50%);
        }

        > h3 {
          border-bottom: 1px solid #26e;
          color: #26e;
          font-size: 24px;
          font-weight: 100;
          margin-bottom: 20px;
          padding: 0 0 10px;
        }

        blockquote {
          color: #000;

          * {
            margin-bottom: 35px;
            text-align: left;
          }

          ul {
            padding-left: 20px;
          }

          ul ul {
            margin-bottom: 5px;
          }

          h2,
          h3,
          h4,
          h5 {
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 10px;
          }

          li {
            margin-bottom: 5px;
          }
        }
      }

      > .details {
        flex-basis: 35%;
        padding: 20px 10px 10px;
      }

      > .details > ul {
        border: 1px solid #ddd;
        border-radius: 7px;
        list-style: none;
        margin: 0;
        padding: 0;
      }

      > .details > ul > li {
        align-items: center;
        border-bottom: 1px solid #ddd;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0;
        padding: 10px 20px;
      }

      > .details > ul > li:last-of-type {
        border-bottom: none;
      }

      > .details > ul > li > span {
        color: #333;
        display: block;
        font-size: 12px;
      }

      > .details > ul > li > span:last-of-type {
        color: #666;
        display: block;
        margin-left: 15px;
        text-align: right;
      }

      > .details > ul > li > small {
        color: #666;
        display: block;
        flex-basis: 100%;
        font-size: 12px;
        margin-top: 3px;
        text-align: right;
      }
    }

    > .containerMoreQuestions {
      align-items: center;
      display: flex;

      p {
        color: rgb(170, 170, 170);
        margin: auto;
        margin-top: 40px;
        position: relative;
        text-align: center;
        width: 63%;
      }

      > p:before {
        background: $borderColor;
        border-radius: 50%;
        color: #fff;
        content: "?";
        font-size: 25px;
        font-weight: 700;
        height: 36px;
        left: -50px;
        padding-left: 3px;
        position: absolute;
        width: 36px;
      }

      a {
        color: $altScoreColor;
        padding-left: 8px;
      }
    }

    > form {
      display: flex;
      flex-direction: column;
      align-items: center;

      > .error {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }

      > .success {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
      }

      > .forms {
        align-items: top;
        display: flex;
        flex-flow: row wrap;
        margin-bottom: 24px;
        width: 100%;

        > .trainee {
          align-items: center;
          display: flex;
          flex-basis: 49%;
          flex-direction: column;
        }

        > .divider {
          background: $blue;
          height: 150px;
          margin: 0 auto;
          margin-top: 24px;
          width: 1px;
        }

        > .session {
          align-items: center;
          display: flex;
          flex-basis: 49%;
          flex-direction: column;
          justify-content: flex-start;

          > form.traineeForm {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: auto;
            padding: 50px;
            width: 400px;
          }

          > div.sessionDatesPanel {
            display: flex;
            font-size: 13px;
            width: 275px;
            margin-top: 15px;
            background: $borderColorAlt;
            // padding: 10px;
            border-radius: 5px;

            > div:first-of-type {
              margin-right: 10px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              margin-top: 0px;
              background: $borderColor;
              width: 45px;
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
              > svg {
                margin-top: -5px;
                fill: $altScoreColor !important;
              }
            }

            > div.content {
              padding: 10px 0 10px 5px;
              > span {
                font-weight: 600;
                color: $infoD;
              }
              > ul {
                list-style: none;
                padding-left: 10px;
                color: $headerAltColor;
              }
            }
          }
        }
      }
    }
  }

  .dropdown {
      margin-bottom: $skui-spacing-m;
    .Label {
      font-weight: normal;
      color: black;
    }
    .DSSelect {
      width: 17.25rem; 
      &::placeholder {
        color: black;
      }
    }
  }

  @media (min-width: 1px) and (max-width: 900px) {
    margin: 0 auto 50px;
  }

  @media (min-width: 1px) and (min-width: 992px) {
    .home.card {
      width: 992px;
    }
  }

  @media (min-height: 681px) and (max-height: 810px) {
    .home.card {
      margin: 2% auto auto !important;
    }
  }

  @media (min-height: 1px) and (max-height: 680px) {
    .home.card {
      margin: 0.5% auto auto !important;
    }
  }

  @media (min-width: 1px) and (max-width: 992px) {
    .home.card {
      width: auto;
    }
  }

  @media (min-width: 1px) and (max-width: 768px) {
    background: #fff;

    > div > header {
      &:before {
        background: none;
        content: "";
      }
    }

    > .card {
      box-shadow: none;
      padding-bottom: 35px;

      > header {
        background: none;
        border-bottom: none;
      }
    }

    .training {
      padding: 0 !important;
    }

    .forms > div {
      flex-basis: 100% !important;
    }

    .divider {
      display: none;
    }
  }
}
