@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.Summary {
  padding-top: $skui-spacing-xxs;
  padding-bottom: $skui-spacing-xxs;
}

// Get all the rows (except the last one)
.Row:not(:nth-last-child(1 of .Row)) {
  .StatusCell {
    color: $plainText-onLight-lighter !important;
  }
}

// Get the last row
.Row:nth-last-child(1 of .Row) {
  @include espace-rh-body2-bold;

  background-color: $greyscale-light;
  color: $plainText-onLight-default;
}


.TooltipCell {
  overflow: hidden;
  text-overflow: ellipsis;
  @include espace-rh-body2-regular;
}
