@import "@skillup/ui";

.TrainingCard {
  min-width: 405px;
  label {
    overflow: hidden;
    height: 3.375rem;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .content {
    display: flex;
    justify-content: space-between;
    @include espace-rh-caption-regular;
    color: #9aa7b9;
    margin-bottom: $skui-spacing-s;
  }

  .footer {
    display: flex;
    @include espace-rh-body2-regular;
    color: $plainText-onLight-default;
    .section,
    .sessionDatesWrapper {
      display: flex;
      gap: $skui-spacing-xxs;
    }
  }
}
