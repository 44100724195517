@import "@skillup/ui";

.modalContent {
  min-width: 500px;
  padding-bottom: $skui-spacing-s;
}
.companyConnectionLink {
  margin-bottom: $skui-spacing-l;
  h2 {
    font-size: 0.75rem;
    color: $plainText-onLight-lighter;
    font-weight: 600;
  }
}
