@import "../../assets/designSystem";
@import "../../assets/colors";
@import "../../assets/mixins";

#modal {
  position: fixed;
  z-index: 600;
  left: 0;
  top: 0;
}

.modalPortal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  .background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(black, 0.45);
  }

  .scrollContainer {
    margin: auto;
    width: 100%;
    overflow: auto;
    max-height: 100%;
    padding: 2.375rem;

    &.fit {
      height: 100%;
    }

    .modalContainer {
      height: 100%;

      .mobileCloseBtn {
        display: none;
      }

      .Modal {
        position: relative;
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);

        &.fit {
          height: 100%;
        }

        &.fullWidth {
          width: 100%;
        }

        &.espace-rh {
          width: 600px;
          border-radius: $skui-spacing-xs;
          border: 1px solid rgba(105, 121, 140, 0.2);
          display: flex;
          flex-direction: column;
          justify-content: stretch;

          &.small {
            width: 440px;
          }

          .header {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            padding: $skui-spacing-s $skui-spacing-m;

            &.withoutTitle {
              justify-content: space-between;
            }

            .title {
              @include espace-rh-components-modal-title;
              color: $plainText-onLight-default;
            }
          }
        }

        &.espace-collab {
          border-radius: 10px;

          .content {
            min-height: 6.25rem;
            border-radius: 10px;
            padding: 3.5rem 3.75rem;

            h2.title {
              margin-top: 0;
              margin-bottom: 3.188rem;
              font-size: 1.375rem;
              font-weight: bold;
              line-height: 1.27;
              letter-spacing: 0.15px;
              color: $b-w-black;
              user-select: none;
            }
          }

          .closeBtn {
            position: absolute;
            right: 1.562rem;
            top: 1.562rem;
            padding: 0.688rem;
            z-index: 1;
          }
        }
      }
    }

    @include isMobile {
      padding: 0;

      .modalContainer {
        .Modal {
          max-width: inherit;
          width: 100%;
          box-shadow: none;
          border-radius: 0;

          .closeBtn {
            right: 1.25rem;
          }

          .content {
            min-height: inherit;
            border-radius: 0;
            padding: 3.5rem 1.25rem;
          }
        }
      }
    }
  }
}
