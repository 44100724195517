@import "@skillup/ui";

.templateCard {
  font-size: 14px;
  border-radius: 5px;
  justify-content: space-between;
  background-color: $surface-light;
  border: 1px solid $border-on-light;

  .actions {
    margin-left: auto;
  }
}

