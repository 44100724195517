@import "uiAssets/ColorsVars.scss";

.relatedFormationsLinks {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 7px;
  margin: 30px;
  max-width: 750px;
  padding: 10px 15px;

  > h2 {
    color: #333;
    font-weight: 700;
    margin-bottom: 10px;
  }

  > div {
    column-count: 2;
    > a,
    > button {
      color: $blue;
      display: block;
      font-size: 13px;
      margin: 0 12px 2px 0;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      &:before {
        color: #333;
        content: "> ";
      }

      &.selected {
        color: #666 !important;
        text-decoration: none;

        > div > span {
          color: #fff !important;
        }
      }

      &.seeMoreLess {
        color: #000;
        text-decoration: none;
      }

      &.hidden {
        display: none !important;
      }

      > span {
        color: #666;
        font-size: 11px;
      }
    }
  }
}
