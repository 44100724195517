@import "@skillup/ui";
@import "uiAssets/ColorsVars.scss";

.SkillsList {
  .searchInput {
    margin-left: 10px;
    width: 18.75rem;
    margin-bottom: 1.188rem;
  }

  .tableContainer {
    will-change: top;
    max-height: 100%;
    overflow: auto;

    .loading {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      p {
        color: $plainText-onLight-lighter;
        animation: loadingText 0.6s infinite;
        font-size: 0.875rem;
      }
    }
  }

  thead {
    z-index: 1;
  }

  tr.previewLink {
    cursor: pointer;
    &:hover > td {
      color: $bluey-grey;
    }
  }
}

@keyframes loadingText {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.6;
  }
}
