@import "@skillup/ui";

.modifyCompany {
  padding: $skui-spacing-s;
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  .logoLineWrapper {
    display: flex;
    flex-direction: column;
    justify-content: stretch;

    .label {
      @include espace-rh-components-input-label;
      color: $plainText-onLight-lighter;
    }

    .logoWrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      .logo {
        height: 80px;
        width: 80px;

        > img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }

  .buttonsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .button {
      margin-right: $skui-spacing-xs;
      box-sizing: border-box;

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .deleteButtonWrapper {
    margin-top: $skui-spacing-xl;
    margin-bottom: $skui-spacing-xl;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
