@import "../../../assets/colors";
@import "../../../assets/mixins";

.CatalogTrainingCard {
  min-width: 18.875rem;
  min-height: 9.875rem;
  border-radius: 12px;
  background-color: white;
  padding: 1.375rem;
  justify-content: space-between;
  border: 1px solid $pale-lilac;
  box-shadow: 0 0 6px 0 rgba(229, 229, 229, 0.4);

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 6px 0 rgba(229, 229, 229, 0.95);
  }

  @include isIE11 {
    /* IE10+ specific styles go here */
    margin-right: 1.125rem;
    margin-bottom: 1.125rem;
    flex-grow: 1;
  }

  .top {
    width: 100%;

    h5.trainingName {
      display: block;
      display: -webkit-box;
      text-decoration: none;
      font-size: 0.875rem;
      letter-spacing: 0.15px;
      font-weight: bold;
      line-height: 1.15;
      color: $b-w-black;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-bottom: 0.3125rem;
    }

    span.organizationName {
      width: 100%;
      font-size: 0.75rem;
      color: $slate;
    }
  }

  .footer {
    align-items: center;
    justify-content: space-between;

    .duration {
      align-items: center;

      span.value {
        color: $slate;
        margin-top: 1px;
        margin-left: 0.1875rem;
        font-size: 0.75rem;
        letter-spacing: 0.15px;
      }
    }
  }

  @include isMobile {
    min-width: auto;
  }
}
