@import "@skillup/ui";

.placeholder {
  @include espace-rh-body1-italic;
  color: $placeholder-onLight;
}

.mailHistory {
  @include espace-rh-caption-regular;
  margin-top: $skui-spacing-m;
  gap: $skui-spacing-xxs;

  h2 {
    @include espace-rh-caption-bold;
    color: $plainText-onLight-lighter;
  }
}