@import "../../assets/designSystem";

.wrapper {
  margin: 0 auto;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  box-sizing: border-box;
  touch-action: manipulation;
  transform-origin: 0 0;
  transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0) scaleX(var(--scale-x, 1))
    scaleY(var(--scale-y, 1));

  &.center {
    justify-content: center;
  }
}

.item {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 40px;
  height: auto;
  border-radius: $skui-spacing-xxs;
  background: $surface-light;
  outline: 1px solid $border-onLight;
  box-shadow: $elevation-actionableCard;
  opacity: 0.9;
  padding: $skui-spacing-xs;

  &:hover:not(.disabled) {
    box-shadow: $elevation-actionableCard-hover;
    opacity: 1;

    .remove {
      visibility: visible;
      cursor: pointer;
    }
  }

  &.dragOverlay {
    cursor: inherit;
    opacity: 1;
    background: $surface-light;
    outline: 0.125rem solid $action-onLight-default;
    outline-offset: -0.125rem;
    box-shadow: $elevation-actionableCard-hover;
    z-index: 1;
  }

  &.dragging:not(.dragOverlay) {
    outline: 2px dashed rgba(105, 121, 140, 0.2);
    outline-offset: -2px;
    border-radius: $skui-spacing-xxs;
    background: transparent;
    box-shadow: none;

    & * {
      visibility: hidden;
    }
  }

  &.disabled {
    color: #999;
    background-color: #f1f1f1;
    cursor: not-allowed;
  }

  &:not(.withHandle) {
    touch-action: manipulation;
  }

  &.color:before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 100%;
    width: 3px;
    display: block;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    background-color: var(--color);
  }

  .head {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: $skui-spacing-s;

    .title {
      @include espace-rh-body1-regular;
      color: $plainText-onLight-default;
      flex-grow: 1;
    }

    .handle,
    .remove {
      display: flex;
      width: $skui-spacing-m;
      height: $skui-spacing-m;
      align-items: center;
      justify-content: center;
      flex: 0 0 auto;
      touch-action: none;
      border-radius: 5px;
      border: none;
      outline: none;
      appearance: none;
      background-color: transparent;
      -webkit-tap-highlight-color: transparent;

      svg {
        flex: 0 0 auto;
        margin: auto;
        height: 100%;
        overflow: visible;
      }

      &.removeActionDisabled {
        cursor: not-allowed;
        svg {
          opacity: 0.3;
        }
      }
    }
  }

  .content {
    margin: 8px 40px 0 40px;
  }
}

.handle {
  &.disabled {
    cursor: not-allowed;
  }
  &:not(.disabled) {
    cursor: grab;
  }
  svg {
    fill: $greyscale-medium;
  }
}

.remove {
  cursor: pointer;
  svg {
    fill: $plainText-onLight-lighter;
  }
}

.remove:not(.alwaysVisible) {
  visibility: hidden;
}

.list {
  display: grid;
  grid-auto-rows: max-content;
  box-sizing: border-box;
  width: 100%;
  grid-gap: $skui-spacing-xs;
  transition: background-color 350ms ease;
  grid-template-columns: repeat(var(--columns, 1), 1fr);

  &.horizontal {
    width: 100%;
    grid-auto-flow: column;
  }
}
