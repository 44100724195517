@import "@skillup/ui";

.HeaderComment {
  .userPreview {
    margin: $skui-spacing-s 0 $skui-spacing-xxs 0;
    > div {
      padding-top: $skui-spacing-xxs;
      span {
        color: white !important;
      }
    }
  }
  
  .comment {
    color: $text-on-light-default;
    border-radius: $skui-spacing-xxs;
    padding: $skui-spacing-xs $skui-spacing-s;
    width: 90%;
    margin-left: auto;
    background-color: #fff;
    transition: height 0.3s ease;
  }

  .expandButton {
    margin-top: $skui-spacing-xs;
    margin-left: auto;
  }
}
