@import "@skillup/ui";

.fullPage {
  width: 100%;
  height: auto;
}

.divided {
  width: 100%;
  height: auto;
  min-height: 1px;
  display: flex;
  flex-wrap: wrap; // should we scroll internally instead ?
  background: $surface-light;
}

.divided__left {
  z-index: 2;
  width: 50%;
  height: auto;
  min-height: 1px;
  display: flex;
  flex-direction: column;
  background: $surface-light;
  box-shadow: $panel-shadow-right;
}

.divided__right {
  position: relative;
  width: 50%;
  background-color: $surface-light-darker;
  height: auto;
  min-height: 1px;
  display: flex;
  flex-direction: column;
}
