@import "@skillup/ui";

.TrainingName {
  max-height: 100%;
  overflow: hidden;

  & > span {
    color: $plainText-onLight-lighter;
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.9375rem;
  }
}
