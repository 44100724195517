@import "../../assets/designSystem";

.tooltipTrigger {
  display: inline-block;
}
.tooltipTriggerFlex {
  display: flex;
  flex-grow: 1;
}

.tooltip {
  padding: 0.1875rem 0.4375rem;
  border-radius: 4px;
  width: max-content;
  max-width: 15rem;
  background-color: $surface-dark-darker;
  color: $plainText-onDark-default;
  z-index: 21;

  &.darkMode {
    background-color: $surface-light;
    color: $plainText-onLight-default;

    .label::before {
      background-color: $surface-light;
    }
  }

  .tooltipArrow {
    height: 1rem;
    position: absolute;
    width: 1rem;
    pointer-events: none;

    &:after {
      border-style: solid;
      content: "";
      display: block;
      height: 0.4rem;
      margin: auto;
      position: absolute;
      width: 0;
    }
  }

  .label {
    overflow: hidden;
    text-align: center;
  }

  .isLongLabel {
    text-align: left !important;
  }

  &.espace-collab {
    @include espace-collab-caption-regular;
  }

  &.espace-rh {
    @include espace-rh-caption-regular;
  }
}

.tooltip[data-popper-placement*="bottom"] .tooltipArrow {
  left: 0;
  margin-top: -0.4rem;
  top: 0;
}

.tooltip[data-popper-placement*="bottom"] {
  .tooltipArrow::after {
    border-color: transparent transparent $surface-dark-darker transparent;
    border-width: 0 0.5rem 0.4rem 0.5rem;
  }

  &.darkMode {
    .tooltipArrow::after {
      border-color: transparent transparent $surface-light transparent;
    }
  }
}

.tooltip[data-popper-placement*="top"] .tooltipArrow {
  bottom: 0;
  left: 0;
  margin-bottom: -1rem;
}

.tooltip[data-popper-placement*="top"] {
  .tooltipArrow::after {
    border-color: $surface-dark-darker transparent transparent transparent;
    border-width: 0.4rem 0.5rem 0 0.5rem;
  }

  &.darkMode {
    .tooltipArrow::after {
      border-color: $surface-light transparent transparent transparent;
    }
  }
}

.tooltip[data-popper-placement*="right"] .tooltipArrow {
  left: 0;
  margin-left: -0.7rem;
}

.tooltip[data-popper-placement*="right"] {
  .tooltipArrow::after {
    border-color: transparent $surface-dark-darker transparent transparent;
    border-width: 0.5rem 0.4rem 0.5rem 0;
    left: 6px;
    top: 0;
  }

  &.darkMode {
    .tooltipArrow::after {
      border-color: transparent $surface-light transparent transparent;
    }
  }
}

.tooltip[data-popper-placement*="left"] .tooltipArrow {
  margin-right: -0.7rem;
  right: 0;
}

.tooltip[data-popper-placement*="left"] {
  .tooltipArrow::after {
    border-color: transparent transparent transparent $surface-dark-darker;
    border-width: 0.5rem 0 0.5rem 0.4em;
    left: 3px;
    top: 0;
  }

  &.darkMode {
    .tooltipArrow::after {
      border-color: transparent transparent transparent $surface-light;
    }
  }
}
