.Popover {
  position: relative;
  display: flex;

  > .Toggler {
    cursor: pointer;
  }

  > .Mask {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: default;

    > .Content {
      position: absolute;
      z-index: 1;
    }
  }
}
