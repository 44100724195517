@import "@skillup/ui";
@import "uiAssets/ColorsVars.scss";

.list {
  border-spacing: 0;
  max-height: 100%;
  width: 100%;
  margin-top: 0;
  position: relative;
  border-collapse: separate;
  table-layout: fixed;

  > thead {
    position: sticky;
    top: 0;
    left: 0;

    .toggleColumnOptions {
      position: absolute;
      top: 15px;
      right: 0;
      cursor: pointer;
    }

    > .sort {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }

    > tr {
      > th {
        position: sticky;
        top: 0;
        white-space: nowrap;
      }
    }
  }

  > tbody {
    overflow: scroll;

    > tr {
      > td {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .moreBtn {
          transform: rotate(90deg);
          cursor: pointer;
        }

        &.showOverflow {
          overflow: visible;
        }
      }
    }
  }

  &.classic {
    > thead {
      > tr {
        > th {
          font-size: 0.75rem;
          font-weight: 500;
          line-height: 1.33;
          text-align: left;
          color: $b-w-black;
          user-select: none;
          background-color: #fff;
          padding: 1rem;
          border-bottom: 1px solid $blue;

          .toggleColumnOptions {
            position: absolute;
            top: 15px;
            right: 0;
            cursor: pointer;
          }

          > .sort {
            position: absolute;
            top: 15px;
            right: 15px;
            cursor: pointer;
          }
        }
      }
    }

    > tbody {
      > tr {
        > td {
          border-bottom: 1px solid $pale-lilac;
          padding: 16px;
          font-size: 0.75rem;
          line-height: 1.33;
          color: $b-w-black;
        }
      }
    }
  }

  &.new {
    > thead {
      background-color: white;

      > tr {
        > th {
          color: $slate;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          text-align: left;
          padding: 17px;
          padding-left: 7px;
          border-bottom: 1px solid $pale-lilac;

          &:last-of-type {
            border-bottom: none;
          }
        }
      }
    }

    > tbody {
      > tr {
        > td {
          border-bottom: 1px solid $pale-lilac;
          font-size: 13px;
          line-height: 15px;
          padding: 20px 5px 20px 7px;
          color: $b-w-black;

          &:last-of-type {
            border-bottom: none;
          }
        }
      }
    }
  }
}

.columnOptions {
  width: 300px;
  padding: 15px;
  background: #fff;
  z-index: 99999;
  border: 1px solid #ccc;
  border-radius: 5px;

  > div.filter {
    > p {
      margin-bottom: 5px;
    }
  }

  > .sort {
    margin-top: 15px;
    > p {
      margin-bottom: 5px;
    }

    > .order {
      display: flex;
      flex-wrap: nowrap;

      > button {
        height: 30px;

        &:nth-of-type(2) {
          margin-left: 15px;
        }
      }
    }
  }
}
