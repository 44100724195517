@import "uiAssets/ColorsVars.scss";

.RadioButtons {
  // display: flex;
  // flex-direction: column;

  .option {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    cursor: pointer;

    &:last-of-type {
      margin-bottom: 0;
    }

    > label {
      font-size: 14px;
      cursor: pointer;
    }

    .circle {
      flex-shrink: 0;
      border: 2px solid $blue;
      border-radius: 50%;
      content: "";
      height: 10px;
      width: 10px;
      margin-right: 5px;
      transition: border-width 200ms ease-out;
    }

    &.checked {
      .circle {
        border-width: 5px;
      }
    }

    &.disabled {
      cursor: not-allowed;

      .circle {
        border-color: $borderColor;
      }

      > label {
        color: $borderColor;
        cursor: not-allowed;
      }
    }
  }
}
