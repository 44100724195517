@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.InterviewsHeader {
  align-items: center;
  display: flex;
  gap: 0 $skui-spacing-xs;
  justify-content: space-between;
  h2 {
    font-size: 12px;
    font-weight: 400;
  }
}
