@import "uiAssets/ColorsVars.scss";

.Tooltip {
  > button {
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 4px;
    min-width: 24px;
    min-height: 24px;
    border-radius: 50%;
  }

  .dataTip {
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 6px;
    padding: 10px;
    max-width: 200px;
  }
}
