@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

section.CampaignCard {
  background-color: #ffffff;
  border: 1px solid rgba(25, 47, 75, 0.1);
  border-radius: 5px;
  color: #666;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  justify-content: space-between;
  margin-bottom: 23px;
  padding: 15px;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.08) 0 6px 16px;
  }

  .tags {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .tagsLeft {
      display: flex;
      gap: 10px;
      flex-direction: row;
      justify-content: center;
    }

    .tagsRight {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .moreVertIcon {
      height: 100%;
    }
  }

  .infos {
    margin-top: 20px;

    .name {
      font-weight: 500;
      font-size: 18px;
      color: #192f4b;
    }

    .props {
      margin-top: 5px;

      .meta {
        list-style-type: none;
        color: #535d6d;
        font-size: 12px;
      }
    }
  }

  .progress {
    display: flex;
    flex-direction: column;
    justify-content: right;

    .progressBarInfos {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 5px;

      svg {
        width: 10px;
      }

      .textWrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        .percentage {
          font-weight: 500;
          font-size: 14px;
          color: #535d6d;
          margin: 0 5px;
        }

        .details {
          font-weight: 400;
          font-size: 12px;
          color: #a1a1a1;
        }
      }
    }
  }
}

.dropdowContainer {
  display: flex;
  margin-left: 0.75rem;
}
