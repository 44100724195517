@import "@skillup/ui";

.CreateJob {
  height: 100%;
  width: 100%;

  .content {
    padding: 3.125rem 2.5rem;
    overflow: auto;
    height: inherit;

    section {
      margin-bottom: 2.438rem;

      &.skills {
        padding: 15px 0;
      }
    }
  }
}
