@import "uiAssets/ColorsVars.scss";

.results {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  & .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    > .name {
      font-size: 0.85rem;
      font-weight: 400;
      display: flex;
      align-items: center;
      color: #192f4b;
    }
    > .email {
      font-size: 0.75rem;
      font-weight: 300;
      color: #9aa7b9;
    }
  }
  > .result {
    width: 100%;
  }
  > .result:hover {
    background-color: #f5f6f8;
    cursor: pointer;
  }
  > .noResult {
    display: flex;
    align-items: center;
    font-size: 0.85rem;
    font-weight: 400;
    color: #192f4b;
  }
}
