.FilterWrapper {
  .openIcon {
    background: none;
    border: none;
    cursor: pointer;
  }
}

.filters {
  background: #fff;
  border-radius: 5px;
  box-shadow: rgba(40, 139, 255, 0.3) 0 0 20px;
  display: flex;
  flex-flow: column;
  width: 270px;
}
