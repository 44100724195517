@import "../../../../assets/designSystem";

.SkillLevel {
  width: 100%;
  display: flex;
  gap: $skui-spacing-xxs;

  .level {
    position: relative;
    background: $greyscale-light;
    border-radius: 0.125rem;
    height: $skui-spacing-xs;
    flex-grow: 1;

    &.reached {
      background: $greyscale-dark;
    }
  }
}

.badgeContainer {
  margin-left: $skui-spacing-xs;
  
  .tooltip {
    z-index: 10;
  }
  .reached {
    color: $status-success-default;
  }
  .notReached {
    color: $status-warning-default;
  }
  .notEvaluated {
    color: $greyscale-dark;
  }
}
