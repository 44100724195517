@import "uiAssets/ColorsVars.scss";

.Paging {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px auto;

  > a {
    align-items: center;
    display: flex;
    font-size: 16px;
    height: 44px;
    justify-content: center;
    margin-bottom: 10px;
    text-decoration: none;
    transition: color 0.75s, background-color 0.75s, border-color 0.75s;
    user-select: none;
    width: 44px;

    &.light {
      border: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;
      color: #696969;
      margin-right: -1px;

      &:hover {
        background-color: $mainColor;
        border-color: $mainColor;
        color: #fff;
      }
    }

    &.dark {
      background-color: $mainColor;
    }

    &.dark:hover {
      background-color: $mainColorD;
    }

    &.endingsLinks {
      background-color: $mainColor;
      color: #fff;
    }

    &.endingsLinks:hover {
      background-color: $mainColor;
      color: #fff;
    }

    &.currentLink {
      color: $mainColor;
      font-weight: 700;
    }

    &:first-of-type {
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
    }

    &:last-of-type {
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
}
