@import "../../../assets/colors";

.TrainingSummaryCard {
  border-radius: 12px;
  background-color: white;
  padding: 1.375rem;
  justify-content: space-between;
  border: 1px solid $pale-lilac;

  &:hover {
    cursor: pointer;
  }

  .left {
    h5.trainingName {
      display: block;
      display: -webkit-box;
      text-decoration: none;
      font-size: 0.875rem;
      letter-spacing: 0.15px;
      font-weight: bold;
      line-height: 1.15;
      color: $b-w-black;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-bottom: 0.1875rem;
    }

    span.organizationName {
      width: 100%;
      font-size: 0.875rem;
      color: $slate;
    }
  }

  .right {
    margin-left: 1.375rem;

    .price {
      flex-shrink: 0;
      padding: 0.1875rem 0.5rem;
      border-radius: 4px;
      background-color: rgba($bluey-grey, 0.1);

      span.value {
        white-space: nowrap;
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.21;
        letter-spacing: 0.15px;
        text-align: center;
        color: $b-w-black;
      }
    }
  }
}
