.FooterNav {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .prev {
    display: flex;
    justify-content: flex-start;
  }

  .next {
    display: flex;
    justify-content: flex-end;
  }
}
