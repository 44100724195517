@import "../../../assets/designSystem";

.SimpleTextArea {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: inherit;
  padding: $skui-spacing-xs $skui-spacing-s;
  border: 1px solid $border-onLight;
  border-radius: $skui-spacing-xxs;
  background-color: $surface-light;
  color: $plainText-onLight-default;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  resize: vertical;

  &:hover {
    border-color: $greyscale-medium;
  }
  &.empty {
    textarea::placeholder {
      color: $plainText-onLight-placeholder;
    }
  }
  &:focus-within {
    border-color: $action-onLight-default;
    outline: 2px solid $action-onLight-default;
    outline-offset: 1px;
  }
  &.error {
    color: $status-error-darker;
    border-color: $status-error-default;
    &:hover {
      border-color: $status-error-darker;
    }
  }

  &.espace-collab {
      @include espace-collab-components-input-value;
      &.empty {
        @include espace-collab-components-input-placeholder;
      }
  }

  &.espace-rh {
      @include espace-rh-components-input-value;
      &.empty {
        @include espace-rh-components-input-placeholder;
      }
  }
}
