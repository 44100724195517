.child {
  width: 100%;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  margin-bottom: 15px;
  padding: 10px;
  font-size: 0.7rem;
  position: relative;
  box-sizing: border-box;

  &:hover {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }

  > .footer {
    border-top: 1px solid #ccc;
    margin-top: 10px;
    padding-top: 10px;
  }

  > .delete {
    position: absolute;
    top: 18px;
    right: 10px;
    cursor: pointer;
  }

  > .duplicate {
    position: absolute;
    top: 18px;
    right: 35px;
    cursor: pointer;
  }

  > .transfer {
    position: absolute;
    top: 10px;
    right: 60px;
    cursor: pointer;
  }

  > span.kind {
    background: #069;
    color: #fff;
    font-weight: 500;
    padding: 2px 5px;
    border-radius: 3px;
  }

  > form {
    margin-top: 10px;

    input {
      font-size: 0.7rem;
      height: 30px;
      box-sizing: border-box;
    }

    textarea {
      font-size: 0.7rem;
    }

    > .select {
      max-width: 500px;
    }

    label {
      display: inline-block;
      margin-top: 5px;
      margin-bottom: 2px;
      font-weight: 700;
    }

    .columns {
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      .columnBlockWrapper {
        border: 1px solid #ccc;
        padding: 15px 20px;
        width: 350px;
        margin-bottom: 15px;
        margin-right: 15px;
        position: relative;

        .restriction {
          display: flex;
        }

        .moveColumnUp {
          position: absolute;
          top: 10px;
          right: 55px;
          cursor: pointer;
        }

        .moveColumnDown {
          position: absolute;
          top: 10px;
          right: 35px;
          cursor: pointer;
        }

        .delete {
          position: absolute;
          top: 10px;
          right: 10px;
          cursor: pointer;
        }
      }
    }
  }
}

.disallow {
  width: 100%;
  height: auto;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 12px;
  margin-top: 10px;

  > h3 {
    font-weight: 700;
    margin-bottom: 5px;
  }

  > .disallowAuth {
    display: flex;
    align-items: center;

    // styling the toggle
    > div:first-of-type {
      height: 0.9rem;
      width: 1.85rem;
      > div:first-of-type {
        height: 0.75rem;
        width: 0.75rem;
      }
    }

    > p {
      padding-left: 10px;
      margin-top: 2px;
      margin-bottom: 5px;
    }
  }
}
