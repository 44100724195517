@import "@skillup/ui";
@import "uiAssets/ColorsVars.scss";

.JobDetailsModal {
  > .headerBar {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 10px;
    flex-grow: 0;
    background-image: $gradientBlue;
  }

  > .content {
    height: 726px;
    overflow-y: auto;
    padding: 32px;

    > h2 {
      height: 32px;
      margin-bottom: 32px;
      align-self: stretch;
      flex-grow: 0;
      font-family: Segoe UI;
      font-size: 24px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: $blue;
    }

    > div.detail {
      margin-bottom: 20px;

      > label {
        display: block;
        color: $bluey-grey;
        height: 19px;
        font-size: 14px;
        flex-grow: 0;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.15px;
        text-align: left;
        margin-bottom: 8px;
      }

      ul {
        padding-left: 20px;
      }

      > p {
        color: $b-w-black;
        align-self: stretch;
        flex-grow: 0;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.21;
        letter-spacing: 0.15px;
        text-align: left;

        > div.preview {
          ul {
            padding-left: 20px;

            > li {
              padding-top: 5px;
            }
          }
        }
      }
    }

    > div.skills {
      max-height: 400px;

      > div.actions {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }

      > div.list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 100%;
      }
    }
  }
}

.skill {
  padding: 16px;
  width: 495px;
  min-height: 94px;
  border-radius: 10px;
  border: solid 1px #eaeaf1;
  background-color: #ffffff;
  margin-right: 14px;
  position: relative;
  margin-bottom: 10px;

  > h3 {
    align-self: stretch;
    flex-grow: 0;
    font-family: Segoe UI;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: 0.15px;
    text-align: left;
    color: $b-w-black;
  }

  > div.categories {
    display: flex;
    margin-top: 7px;

    > div.categoryValue {
      display: flex;
      align-items: center;

      > span {
        font-family: Segoe UI;
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.15px;
        text-align: left;
        color: #666666;
        margin-right: 10px;
        padding-left: 3px;
      }

      > .ellipse {
        width: 5px;
        height: 5px;
        flex-grow: 0;
        background-color: #c4c4c4;
        border-radius: 2px;
        margin-top: 3px;
      }
    }
  }

  > div.expectedLevel {
    margin-top: 6px;
    font-family: Segoe UI;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #535d6d;

    > span {
      color: $blue;
      font-size: 12px;
    }
  }

  > div.more {
    position: absolute;
    top: 42%;
    right: 15px;
    cursor: pointer;
  }

  > div.options {
    width: 202px;
    height: 80px;
    display: flex;
    position: absolute;
    top: 60%;
    right: 15px;
    z-index: 5;

    flex-direction: column;

    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 5px 15px 0 rgba(68, 68, 79, 0.1);
    border: solid 1px #eaeaf1;
    background-color: #ffffff;

    > div {
      display: flex;
      align-items: center;
      height: 16px;
      flex-grow: 0;
      font-family: Segoe UI;
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.1px;
      text-align: left;
      color: #535d6d;

      > svg {
        margin-right: 14px;
      }

      &:first-of-type {
        margin-bottom: 15px;
      }
    }
  }
}
