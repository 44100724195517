@import "uiAssets/ColorsVars.scss";

button.addElementButton {
  align-items: center;
  background: $mainColorL;
  border: none;
  border-radius: 7px;
  box-shadow: $buttonBoxShadow;
  color: $blue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  margin: 5px;
  padding: 15px 20px;
  transition: transform 0.2s ease-out;
  width: 340px;
  height: 120px;

  > div {
    align-items: center;
    background: #fff;
    border: 2px dashed #ccc;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    padding: 10px;
    margin-bottom: 10px;

    > svg {
      display: block;
      transition: transform 0.2s ease-out !important;
    }
  }

  &:hover {
    box-shadow: $buttonHoverBoxShadow;
    transform: translateY(-1px);

    > div > svg {
      transform: rotate(180deg);
    }
  }
}
