@import "@skillup/ui";

.placeholder {
  @include espace-rh-caption-bold;

  color: $placeholder-onLight;

  .placeholderText {
    width: 25%;
    text-align: center;
  }
}
