@import "@skillup/ui";

.doubleInput {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 36rem;
  margin: auto;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}

.reference {
  display: flex;
  flex-direction: column;
  max-width: 36rem;
  margin: auto;
  margin-bottom: 1rem;
  color: $text-on-light-default;
  span {
    @include espace-rh-components-input-help-text;
  }
  p {
    margin-bottom: 0.4rem;
  }
}

.assistiveArea {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: $text-on-light-lighter;
}

.referenceDisplay {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: $text-on-light-default;
  margin-bottom: 0.3rem;

  > div:first-child {
    margin-right: 1rem;
  }

  > span:nth-child(2) {
    visibility: hidden;
  }

  &:hover {
    > span:nth-child(2) {
      visibility: visible;
    }
  }
}

.referenceEdit {
  > div:first-child {
    width: 19.375rem; // 15 big uppercase characters (which is the reference limit)
  }
}
