@import "@skillup/ui";

.title {
  @include espace-rh-h6;
  color: $plainText-onLight-default;
  &.divider {
    padding-top: 1rem;
    border-top: 1px solid $border-onLight;
  }
}

.inputLine {
  display: flex;
  flex-direction: column;
  margin-bottom: $skui-spacing-xs;

  .label {
    @include espace-rh-components-input-label;
    color: $plainText-onLight-lighter;
  }

  .input {
    @include espace-rh-components-input-value;
    color: $plainText-onLight-default;
  }

  .inputDate {
    margin-right: 0;
  }
  .inputDateContainter {
    > div {
      width: 100%;
    }
  }

  .radioBox {
    .radioBoxElement {
      margin-top: $skui-spacing-xs;
    }
  }
}
