@import "@skillup/ui";

.editJob {
  height: 100vh;
  width: 100%;
  overflow: hidden;

  .content {
    height: calc(100vh - 51px);
    padding: 3.125rem 2.5rem;
    overflow: auto;

    .section {
      margin-top: 2.438rem;
    }
  }
}
