@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.content {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  padding: 0 $skui-spacing-m;
  padding-bottom: $skui-spacing-s;

  .inputLine {
    > div,
    .datePicker div {
      width: 100%;
    }

    .label {
      @include espace-rh-components-input-label;
      color: $plainText-onLight-lighter;
    }

    .input {
      @include espace-rh-components-input-value;
      color: $plainText-onLight-default;
    }
    span {
      color: $plainText-onLight-lighter;
      font-size: 0.8rem;
    }
  }

  .dateBlock {
    margin-top: $skui-spacing-xl;
  }

  .confidentialLine {
    margin-top: 2.5rem;
    text-align: left;
    font-weight: 400;

    .label {
      @include espace-rh-components-input-label;
      color: $plainText-onLight-lighter;
    }

    .switch {
      display: flex;
      justify-content: flex-start;
      margin-top: 1rem;
    }

    .labelSwitch {
      min-width: 399px;
      flex: 0;
    }

    .description {
      @include espace-rh-components-input-help-text;
      padding-left: 2.75rem;
      color: $plainText-onLight-lighter;
    }
  }

  .OptionsStepWarning {
    margin-top: 1rem;
  }
}

.footer {
  margin-top: $skui-spacing-s;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .button {
    margin-right: $skui-spacing-xs;
    box-sizing: border-box;

    &:last-child {
      margin-right: 0px;
    }
  }
}
