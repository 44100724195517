@import "@skillup/ui";

.decorativeTag {
  display: flex;
  flex-wrap: nowrap;
  padding: $skui-spacing-xxs $skui-spacing-xs;
  border-radius: 0.25rem;
  justify-content: center;
  align-items: center;
  gap: $skui-spacing-xs;
  cursor: pointer !important;
  margin-right: $skui-spacing-xs;
  font-weight: 600;
}

.greyTag {
  background-color: $greyscale-lighter;
  .label { color: $greyscale-darker; }
  .icon { color: $greyscale-dark; }
}

.blueTag {
  background-color: $decoration-blue-lightest;
  .label { color: $decoration-blue-darker; }
  .icon { color: $decoration-blue-base; }
}

.yellowTag {
  background-color: $decoration-yellow-lightest;
  .label { color: $decoration-yellow-darker; }
  .icon { color: $decoration-yellow-base; }
}

.redTag {
  background-color: $decoration-red-lightest;
  .label { color: $decoration-red-darker; }
  .icon { color: $decoration-red-base; }
}

.pinkTag {
  background-color: $decoration-pink-lightest;
  .label { color: $decoration-pink-darker; }
  .icon { color: $decoration-pink-base; }
}

.greenTag {
  background-color: $decoration-green-lightest;
  .label { color: $decoration-green-darker; }
  .icon { color: $decoration-green-base; }
}

.orangeTag {
  background-color: $decoration-orange-lightest;
  .label { color: $decoration-orange-darker; }
  .icon { color: $decoration-orange-base; }
}

.tooltip {
  white-space: normal;
  max-height: auto;
  padding-top: 0.3rem;
}