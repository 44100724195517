@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.keyIndicator {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  gap: $skui-spacing-s;
  padding: $skui-spacing-s;
  border-radius: 1rem;

  background-color: $surface-light;
  border: 2px solid $decoration-blue-lighter;

  min-height: 7.5rem;
  min-width: 12.5rem;

  > p {
    @include espace-rh-caption-bold;
    font-size: 12px;
    color: $plainText-onLight-lighter;
  }

  > .header {
    gap: $skui-spacing-s;
    justify-content: start;
    color: $decoration-blue-darker;
    align-items: center;

    > .icon {
      width: 3rem;
      height: 3rem;
      opacity: 0.5;
    }

    > .value, button {
      @include espace-rh-h6;
      height: 3rem;
      font-size: 48px;
      line-height: 40px;
      font-weight: 400;
      
      color: $decoration-blue-darker;
    }
  }
}
