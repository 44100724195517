@import "uiAssets/ColorsVars.scss";

.noSessionModal {
  height: auto;
  padding: 15px;

  > p {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    button {
      margin: 0 !important;
    }
  }
}
