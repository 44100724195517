@import "uiAssets/ColorsVars.scss";

.CatalogMenu {
  position: relative;
  z-index: 9;

  @media (min-width: 1px) and (max-width: 650px) {
    display: none;
  }

  > button {
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    height: 50px;
    margin-right: 20px;
    padding-left: 5px;

    > span {
      display: block;
    }

    > svg {
      display: block;
      margin-left: 8px;
    }

    &.menuVisible > svg {
      transform: rotate(90deg);
    }
  }

  &:hover > div > svg {
    transform: rotate(90deg);
  }

  > menu {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 7px;
    box-shadow: $bigBoxShadow;
    left: 0;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    top: 40px;
    transform: translateY(15px);
    transition: transform 0.2s ease-out, opacity 0.2s ease-out, width 0.2s ease-out;
    width: 200px;
    will-change: width;

    &.menuVisible {
      opacity: 1;
      pointer-events: auto;
      transform: translateY(0px);
    }

    @media (min-width: 1px) and (max-width: 550px) {
      width: 150px;
    }

    &:hover {
      width: 500px;

      @media (min-width: 1px) and (max-width: 550px) {
        width: 300px;
      }

      &.allCatalogHover {
        width: 200px;
      }
    }

    > a {
      color: #000;
      display: block;
      font-size: 13px;
      font-weight: 700;
      padding: 6px 10px;
      text-decoration: none;
      width: 200px;

      * {
        pointer-events: none;
      }

      &:hover {
        background: $blue;
        color: #fff;
      }
    }

    > div {
      > a {
        align-items: center;
        background: none;
        border: none;
        color: #000;
        display: flex;
        font-size: 13px;
        justify-content: space-between;
        padding: 6px 10px;
        text-align: left;
        text-decoration: none;
        transition: background 0.2s ease-out, color 0.2s ease-out;
        width: 200px;

        * {
          pointer-events: none;
        }

        @media (min-width: 1px) and (max-width: 550px) {
          width: 150px;
        }

        > span {
          display: block;
          flex-grow: 1;
        }

        > svg {
          display: block;
          flex-grow: 0;
          flex-shrink: 0;
          margin-left: 10px;
        }
      }

      &:first-of-type > button {
        border-radius: 7px 0 0 0;
      }

      &:last-of-type > button {
        border-radius: 0 0 0 7px;
      }

      > div {
        background: #f6f6f6;
        box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px;
        display: none;
        height: 100%;
        left: 200px;
        position: absolute;
        top: 0;
        width: 300px;

        @media (min-width: 1px) and (max-width: 550px) {
          left: 150px;
          width: 150px;
        }

        > a {
          color: #000;
          display: block;
          font-size: 13px;
          overflow: hidden;
          padding: 4px 10px;
          text-decoration: none;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;

          &:first-of-type {
            font-weight: 700;
          }

          &:hover {
            background: $blue;
            color: #fff;
          }
        }
      }

      &.subMenuVisible {
        > div {
          display: block;
        }

        > a {
          background: $blue;
          color: #fff;

          > svg {
            stroke: #fff !important;
            transform: translateX(5px);
          }
        }
      }
    }
  }
}
