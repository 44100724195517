.rectangleTag {
  padding: 10px;
  display: flex;
  flex-direction: row;
  border-radius: 4px;

  .tagLabel {
    font-size: 12px;
    margin-left: 5px;
  }

  *:first-child {
    margin-left: 0px;
  }
}
