@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.Interviews {
  display: flex;
  flex: 1;
  flex-direction: column;
  .label {
    @include espace-rh-caption-bold;
    color: $plainText-onLight-lighter;
    margin-top: $skui-spacing-s;
  }

  .row {
    display: flex;
  }

  .search {
    margin-bottom: $skui-spacing-s;

    input {
      width: 12rem;
    }
  }
}
