.ExpandableModal,
.ExpandableModal.ready {
  display: flex;
  padding: 20px 0;
  position: relative;
  width: 250px;

  > div.background {
    background: rgba(255, 255, 255, 0.8);
    display: none;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
  }

  > div.innerContainer {
    border-radius: 7px;
    width: 100%;
  }
}
