.OffBoundsPositioningForm {
  padding: 16px;

  .errors {
    font-size: 13px;
  }

  .button {
    margin: 24px auto 8px !important;
    height: 40px;
  }

  .durationContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    > * {
      width: 45%;
    }
  }
}
