@use "sass:list";
@import "../../assets/designSystem";
@import "../Avatar/variables";

.AvatarGroup {
  display: flex;
  font-size: 1rem;
  margin-left: 0.2em;
  &.espace-rh {
    @include espace-rh-font;
  }
  &.espace-collab {
    @include espace-collab-font;
  }

  @each $value in $sizes {
    &.#{list.nth($value, 1)} {
      font-size: list.nth($value, 2);
    }
  }

  .singleAvatar {
    display: flex;
    margin-left: -0.2em;
  }

  .avatarWrapper {
    display: flex;
  }
  .flexDirection {
    flex-direction: row-reverse;
  }

  .moreBubbleContainer {
    margin-left: 0.35em;
    display: flex;

    .moreBubble {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      background-color: $action-onLight-darkest;

      > span {
        font-weight: 600;
        font-size: 0.375em;
        color: $surface-light;
      }
    }

    .greyMode {
      background-color: "darkgray" !important;
    }
  }
}
