.pageSettings {
  display: flex;
  flex-grow: 1;

  .pool {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    width: 200px;
    height: 100%;
    background: #f5f5f5;
    padding: 20px;
    flex-shrink: 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
    overflow-y: auto;
    background-color: #fff;
    padding: 15px 30px;
    padding-top: 50px;

    > .breadcrumb {
      border-bottom: 1px solid #ccc;
      padding: 5px 15px;
      color: #fff;
      font-weight: 700;
      font-size: 0.9rem;
      background: #0088c6;
    }

    > .drop {
      min-width: 200px;
      padding: 15px;
      flex-grow: 1;
      background: #0088c6;

      > div {
        height: 100%;
      }
    }
    .children {
      width: 100%;
      flex-grow: 1;
      overflow-y: auto;
      padding: 0;
      background: #fff;
      border: 1px solid #ccc;
      display: flex;
      flex-direction: column;
    }
  }
}
