@import "@skillup/ui";

#sidePanel {
}

.sidePanelContent {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100vh;
  width: 400px;
  min-width: 400px;
  z-index: 21;
  overflow: scroll;
  background: white;
  box-shadow: -5px 0px 12px 0px rgba(0, 0, 0, 0.1);

  .header {
    background-color: $surface-dark;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    padding: $skui-spacing-s;
    padding-left: $skui-spacing-l;

    .titleWrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .title {
        @include espace-rh-h2;
        color: white;
      }

      .subTitle {
        @include espace-rh-h6;
        color: $plainText-onDark-darker;
      }

      .headerDetails {
        padding-top: 1.5rem;
        @include espace-rh-body1-regular;
        color: $plainText-onDark-default;
      }
    }

    .closeButtonWrapper {
      position: absolute;
      right: $skui-spacing-s;
      top: $skui-spacing-s;
    }
  }
}
