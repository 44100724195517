.Footer {
  background-color: #f5f8fc;
  padding: 50px 20px;

  .logo {
    display: block;
    margin-bottom: 35px;
  }

  .site .map {
    a,
    button {
      background: none;
      border: 0;
      color: #1f6389;
      display: block;
      font-size: 15px;
      font-weight: 500;
      line-height: 1.4;
      margin-bottom: 20px;
      outline: 0;
      padding: 0;
      text-decoration: none;
    }
    a:hover,
    button:hover {
      text-decoration: underline;
    }

    .smiley {
      font-size: 20px;
    }
  }

  .columnUser {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;

    .mapUser {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 90%;

      a,
      button {
        text-align: center;
      }
    }
  }

  .social {
    align-items: center;
    display: flex;
    margin: 30px 0 10px;

    a {
      color: #7d96a8;
      display: block;
      margin-right: 15px;
      transition: color ease-in-out 0.1s;

      &:hover {
        color: #ffd042;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .tags {
    a {
      color: #8c8c8c;
      display: block;
      font-size: 13px;
      line-height: 2.46;
      text-align: left;
      text-decoration: none;
    }

    h4 a {
      color: #1f6389;
      font-size: 20px;
      font-weight: 500;
      text-align: left;
    }
  }

  @media (min-width: 768px) {
    display: flex;
    justify-content: center;

    .column {
      flex-basis: 25%;
      flex-grow: 1;
      margin-right: 30px;
      max-width: 350px;

      &.large {
        flex-basis: 50%;
        max-width: 700px;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .twoColumns {
      columns: 2;
    }
  }
}
