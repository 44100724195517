@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.Modal {
  max-width: 35rem;
  width: 35rem;
}

.modalForm {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  gap: $skui-spacing-s;
  color: $plainText-onLight-lighter;

  .textarea input {
    height: 66px;
    word-break: break-word;
  }

  ul.assistive {
    margin-left: 1rem;
  }

  .radioForm {
    flex-direction: row;
    gap: $skui-spacing-s;
  }

  .logoPreview {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $skui-spacing-xs;
    img  {
      object-fit: contain;
      height: 50;
    }
  }
}
