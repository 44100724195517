@import "@skillup/ui";

.commentsBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1rem;
}

.comment_wrapper {
  align-items: flex-start;
}

.comment {
  color: $plainText-onLight-default;
  @include espace-rh-body2-regular;
  padding: 0.25rem 0.5rem;
  margin-left: 0.5rem;
  border-radius: 0.25rem;
  max-width: 18.5rem;
  margin-bottom: 0.875rem;
  white-space: pre-line;
  word-break: break-word;
  background-color: $greyscale-lighter;
}

.comment_date {
  align-items: center;

  > p {
    color: #535d6d;
    font-size: 0.75rem;
    margin: 0.5rem;
  }

  > hr {
    flex: 1 1;
    border: solid 1px #eaeaf1;
  }
}
