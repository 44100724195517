@import "../../../../assets/designSystem";

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $skui-spacing-xxs;

  .linesPerPage {
    justify-self: center;
    align-items: center;
    gap: $skui-spacing-xxs;

    .select {
      height: 32px;
    }
  }

  .pagination {
    justify-content: center;
    align-items: center;
    gap: $skui-spacing-l;

    .buttonGroup {
      gap: $skui-spacing-xs;
    }
  }

  .linesPerPage,
  .lineCounter,
  .pagination {
    .text {
      color: $plainText-onLight-default;
    }
  }

  .footerLabel {
    > * + * {
      margin-left: $skui-spacing-xs;
    }

    .pageInput {
      height: 32px;
      input {
        width: 48px;
      }
    }
  }
}
