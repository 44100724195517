@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.newSkill {
  width: 100%;
  overflow: hidden;

  .content {
    padding: 40px;
    height: calc(100vh - 50px);
    overflow: auto;

    .inputs {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 0.875rem;
    }

    .section {
      margin-top: 2.438rem;
    }

    .title {
      width: fit-content;
      h2 {
        width: fit-content;
        font-size: 24px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.67;
        letter-spacing: normal;
        text-align: left;
        color: $blueDarker;
      }

      > div.underline {
        margin-top: 13px;
        height: 2px;
        align-self: stretch;
        background-color: $blue;
        width: 100%;
      }
    }

    .evaluations {
      margin-top: 10px;
      padding-top: 10px;

      .evaluation {
        display: flex;
        font-size: 0.75rem;
        padding-left: 0;
        align-items: center;

        > label:first-of-type {
          margin-right: 14px;
          width: 280px;
        }

        > label:last-of-type {
          flex-grow: 1;
        }

        > svg {
          margin-left: 29px;
          margin-top: 15px;
          cursor: pointer;
        }
      }
    }

    .categories {
      margin-top: 20px;
      padding-top: 10px;

      > form {
        display: flex;
        margin-top: 15px;
        div {
          flex-direction: row;
        }

        > div {
          .label {
            font-size: 0.75rem;
          }
          > div {
            flex-wrap: wrap;
            margin: 0 20px 15px 0;

            border-bottom: 3px solid;
            border-image: linear-gradient(45deg, #0066ff, #00b4ff) 50;

            > div {
              border: none;
              overflow: hidden;
              box-shadow: rgb(246, 246, 246) 0px 0px 0px 1000px inset;
            }
          }
        }
      }
    }
  }
}
