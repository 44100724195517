@import "uiAssets/ColorsVars.scss";

.positioningModal {
  > p {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .buttons {
    display: flex;
    margin-top: 20px;
  }

  .warning {
    background: #fff3cd;
    border-left: 3px solid #856404;
    color: #856404;
    margin: 20px 0;
    padding: 10px;
  }

  .loader {
    display: block;
    height: 30px;
    margin-right: 30px;
    width: 30px;
  }

  .options {
    &.small button {
      padding: 15px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    svg {
      margin-right: 30px;
    }

    div.label {
      flex-grow: 1;
      text-align: left;
    }

    h2 {
      color: #2782ff;
      font-weight: 700;
      margin-bottom: 10px;
    }

    p {
      color: #555;
      font-size: 12px;
    }
  }
}
