.createNewTemplate {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow-x: auto;

  .save {
    position: absolute;
    top: 10px;
    right: 15px;
    height: 30px;
  }

  .preview {
    position: absolute;
    top: 10px;
    right: 175px;
    height: 30px;
  }

  .showJson {
    position: absolute;
    top: 10px;
    right: 335px;
    height: 30px;
  }

  .pool {
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 100%;
    background: #f5f5f5;
    padding: 20px;
    flex-shrink: 0;

    .item {
      // background: #f0f0f0;
      background: #fff;
      border-radius: 2px;
      padding: 10px;
      margin-bottom: 15px;
      font-size: 0.7rem;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

      &:hover {
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
      }
    }
  }

  .structure {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    padding: 8px;
    padding-bottom: 55px;
    width: 550px;
    flex-shrink: 0;
    background: #fff;

    .targetCategories {
      font-size: 0.7rem;
    }

    .sectionsList {
      width: 100%;
      background: #0088c6;
      padding: 5px 10px;
      padding-top: 0;
      margin-top: 15px;
      border-radius: 5px;
      flex-grow: 1;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

      > h2 {
        font-weight: 500;
        color: #fff;
        font-size: 1rem;
        padding: 5px;
      }
    }

    .addSection {
      position: fixed;
      bottom: 5px;
      width: 277px;
      margin-left: 10px;
      border: 1px solid #ccc;

      > span {
        font-weight: 700;
      }
    }

    > h2 {
      font-weight: 500;
      font-size: 0.9rem;
      margin: 15px 0;
    }

    > input {
      margin-bottom: 10px;
      font-size: 0.7rem;
    }
  }

  .settings {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
    overflow-y: auto;
    background-color: #fff;
    padding: 15px 30px;
    padding-top: 50px;

    .children {
      width: 100%;
      flex-grow: 1;
      overflow-y: auto;
      padding: 0;
      background: #fff;
      border: 1px solid #ccc;
      display: flex;
      flex-direction: column;

      > .breadcrumb {
        border-bottom: 1px solid #ccc;
        padding: 5px 15px;
        color: #fff;
        font-weight: 700;
        font-size: 0.9rem;
        background: #0088c6;
      }

      > .drop {
        padding: 15px;
        flex-grow: 1;
        background: #0088c6;

        > div {
          height: 100%;
        }
      }
    }
  }
}
