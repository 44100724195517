@import "uiAssets/ColorsVars.scss";

.portalForm {
  width: 100%;
  display: flex;

  > div.settings {
    width: 50%;

    > h3 {
      font-size: 16px;
      font-weight: 500;
      text-align: center;
    }

    > div.editionContent {
      > div.labels {
        padding: 20px;
      }

      > div.fields {
        padding: 20px;
        list-style: none;

        div.sortableField {
          margin-bottom: 8px;
          width: 100%;
          border: 1px solid #ccc;
          display: flex;
          align-items: center;
          padding: 10px;

          .requiredField {
            text-decoration: underline;
            background: rgb(204, 204, 204);
            padding: 0 4px;
            border-radius: 4px;
            cursor: pointer;
          }

          .dndContainer {
            width: 30px;
            height: 250px;

            & > div {
              width: 100%;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              svg {
                cursor: pointer;
              }
            }

            & > div:last-child {
              position: relative;
              height: calc(100% - 30px);
              p {
                transform: rotate(90deg);
                width: 192px;
                display: block;
                position: absolute;
                text-align: center;
                margin-left: 10px;
                cursor: pointer;
              }
            }
          }

          > div:first-of-type {
            display: flex;
            flex-flow: column;
            flex-grow: 1;
            > span {
              display: block;
            }
          }
        }
      }
    }
  }

  > div.preview {
    width: 50%;
    padding-top: 15px;
    // position: fixed;
    right: 0;
    > h3 {
      font-size: 16px;
      font-weight: 500;
      text-align: center;
    }

    > div.previewContent {
      padding: 15px;

      > div.modal {
        position: relative;
        padding: 30px;
        border-radius: 7px;
        box-shadow: rgba(0, 0, 0, 0.15) 0 0 20px;
        margin: 30px auto;
        max-width: 500px;

        &:before {
          background: $gradientBlue;
          content: "";
          display: block;
          height: 10px;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
          border-top-left-radius: 7px;
          border-top-right-radius: 7px;
        }

        &:after {
          background: $gradientBlue;
          content: "";
          display: block;
          height: 10px;
          left: 0;
          position: absolute;
          bottom: 0;
          width: 100%;
          border-bottom-left-radius: 7px;
          border-bottom-right-radius: 7px;
        }

        > h3 {
          font-size: 26px;
          padding: 20px 0;
          text-align: center;
          color: $blue;
        }
      }
    }
  }

  .addFieldContainer {
    padding: 20px 20px 10px;

    > p {
      padding-bottom: 8px;
    }
  }
}
