@import "uiAssets/ColorsVars.scss";

.RequalificationModal {
  &:before {
    background: linear-gradient(45deg, #06f, #00b4ff);
    content: "";
    display: block;
    height: 10px;
  }

  .contentHeader {
    align-items: center;
    background: #f6f6f6;
    box-shadow: 0 0 20px rgba(40, 139, 255, 0.3);
    display: flex;
    height: 50px;
    justify-content: space-between;
    padding: 0 10px;
    position: relative;

    h2 {
      color: #2883ff;
      font-size: 18px;
      margin: 0 30px 0 10px;
    }

    .searchGroup {
      display: flex;
      width: 960px;

      > div.localSearch {
        align-items: center;
        border: 0;
        border-radius: 0;
        display: flex;
        flex-grow: 1;
        position: relative;
        transition: background 0.2s ease-out;

        > button {
          align-items: center;
          background: #2782ff;
          border: 0;
          border-radius: 0 7px 7px 0;
          display: flex;
          flex-grow: 0;
          flex-shrink: 0;
          justify-content: center;
          padding: 10px;
          transition: background 0.2s ease-out;

          &:hover svg {
            transform: scale(1.2);
          }
        }

        > input {
          background: #eaeaea;
          border: none;
          display: block;
          padding: 7px 0 6px 15px;
          width: 100%;

          &:hover {
            background: #dedede;
          }
        }
      }
    }

    select {
      background: #fff;
      border: 0;
      border-radius: 7px 0 0 7px;
      color: #000;
      display: block;
      padding: 8px;
      width: 240px;
      min-width: 240px;

      option {
        color: #000;
      }
    }

    > button {
      align-items: center;
      background: none;
      border: none;
      border-radius: 50px;
      display: flex;
      flex-grow: 0;
      flex-shrink: 0;
      justify-content: center;
      padding: 10px 15px;
      transition: transform 0.2s ease-out;
    }

    > button:hover {
      transform: rotate(180deg);
    }
  }

  .content {
    display: flex;
    height: calc(100vh - 180px);
    max-height: 495px;
  }

  .right {
    flex-grow: 1;
    overflow-y: scroll;
    position: relative;

    > .emptySign {
      align-items: center;
      display: flex;
      margin-top: 150px;

      > svg {
        display: block;
        margin: 20px auto;
        opacity: 0.5;
        width: 150px;
      }
    }
  }

  .actions {
    background: #eee;
    border-top: 1px solid #ccc;
    display: flex;
    /* justify-content: space-between; */
    padding: 10px;

    .spacer {
      flex-grow: 1;
    }

    > .warning {
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      padding: 0 10px;
      margin-right: 15px;
      flex-grow: 99;
      border-radius: 5px;
      border: 2px solid $warning;
    }
  }
}
