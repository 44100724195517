@import "uiAssets/ColorsVars.scss";

.addTrainingScheduleModal {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 15px 25px;
  padding-bottom: 55px;
  width: 100%;
  flex-shrink: 0;
  background: #fff;

  h2 {
    font-weight: 500;
    font-size: 0.9rem;
    margin: 15px 0;
    color: $blue;
  }
  .addTrainingScheduleSelect {
    width: 100%;
    font-size: 0.7rem;
  }
  .addTrainingScheduleButton {
    margin-top: 30px;
  }
  .addTrainingScheduleTitle {
    width: 100%;
    font-size: 0.7rem;
  }
}
