@import "uiAssets/ColorsVars.scss";

$blockPadding: 0 20px;
$blockHeaderPadding: 5px 8px;
$blockHeaderBackground: #f6f6f6;

.SidePanel {
  box-shadow: 0 0 20px rgba(40, 139, 255, 0.3);
  flex-grow: 0 !important;
  flex-shrink: 0;
  font-size: 13px;
  height: 100vh;
  overflow: auto;
  width: 475px;
  position: absolute;
  top: 0;
  right: 0;
  background: #ffff;
  z-index: 5;

  > .activity {
    background: #fff;
    position: relative;
  }

  p.warning {
    color: #333;
    font-style: italic;
    margin: 30px 20px;
    text-align: center;
  }

  > nav {
    border-bottom: 1px solid #e3e3e3;
    display: flex;
    flex-shrink: 0;
    padding: 10px 15px 0;

    > button {
      cursor: pointer;
    }

    > button,
    > span {
      background: #f6f6f6;
      border: none;
      border-bottom: 1px solid #e3e3e3;
      border-radius: 5px 5px 0 0;
      box-shadow: inset 0 -7px 15px -10px #ccc;
      color: #666;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 1px;
      margin: 0 5px -1px;
      padding: 8px 15px;
      transition: box-shadow 150ms ease-out, color 200ms ease-out, background-color 150ms ease-out;
    }

    > button:not(.active):hover {
      background-color: #f0f0f0;
      box-shadow: inset 0 -7px 15px -10px #bbb;
      color: #111;
    }

    > button.active {
      background-color: transparent;
      border: 1px solid #e3e3e3;
      border-bottom: 1px solid #fff;
      box-shadow: none;
      color: #000;
      padding: 8px 13px;
    }
  }
  > div.gradientBackground {
    background: linear-gradient(180deg, #f3f3f3, #fff);

    > header {
      align-items: center;
      display: flex;
      height: 50px;
      justify-content: flex-end;

      > button {
        align-items: center;
        background: none;
        border: none;
        display: flex;
        justify-content: center;
        margin-right: 15px;
        padding: 0 0 0 10px;
        cursor: pointer;

        > svg {
          display: block;
        }
      }
    }

    > div.actionAndSummary {
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 5px 10px 0 rgba(40, 139, 255, 0.19);
      margin: 0 15px 20px;
    }
  }

  > .details {
    margin-bottom: 20px;

    > h2 {
      background: $blockHeaderBackground;
      color: $blue;
      font-size: 15px;
      padding: $blockHeaderPadding;
    }

    > .detail {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      margin-top: 5px;
      padding: 0 20px;

      > .label {
        font-size: 12px;
        font-weight: 500;
        width: 120px;
      }

      > p {
        flex-grow: 1;
        max-width: 230px;
        padding-left: 20px;
        right: 0;
        text-align: right;

        > a {
          color: $blue;
          text-decoration: none;
        }
      }
    }
  }
}
