@import "@skillup/ui";

.wrapper {
  width: 650px;
}
.modal {
  .content {
    @include espace-rh-font;
    color: $plainText-onLight-default;
    padding: 0 $skui-spacing-m;
    padding-bottom: $skui-spacing-s;
    .toggles {
      display: flex;
      justify-content: stretch;
      section:first-child {
        margin-right: $skui-spacing-s;
      }
      .notificationsWrapper {
        display: flex;
        flex-direction: column;
        label {
          padding: 0.25rem 0 0;
        }
        .notificationsToggleWrapper {
          display: flex;
          flex-direction: row;
          padding: 0.25rem 0;
        }
        .toggleButton {
          margin-top: $skui-spacing-xs;
          margin-left: -$skui-spacing-xs;
          span {
            font-weight: 600;
          }
        }
      }
    }
  }

  .alert {
    margin: $skui-spacing-s 0 $skui-spacing-m 0;
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: $skui-spacing-s;

    .button {
      margin-right: $skui-spacing-xs;
      box-sizing: border-box;

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}
