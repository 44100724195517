@import "@skillup/ui";

.versionLink {
  text-decoration-color: $action-onLight-darkest;
}

.historyModal {
  max-width: 50rem;
  width: 100%;

  .modalContent {
    & > div {
      padding: 0 !important; 
      border-radius: 0% !important;
    }

    .sidebar {
      box-shadow: $panel-shadow-right;
      z-index: 1;
  
      .listItemLink {
        text-decoration: none;
      }
  
      .listItem {
        width: 14rem;
      }
  
      .selectedListItem {
        background: linear-gradient(to right, $action-onLight-default 4px, $greyscale-lighter 4px 100%);
        box-sizing: border-box;
      }
    }
  }

  footer {
    display: none;
  }
}
