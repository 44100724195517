.addFieldModal {
  width: 500px;
  height: 100%;
  border: 1px solid #ccc;
  overflow: auto;

  > h3 {
    font-size: 16px;
    font-weight: 500;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    position: sticky;
    top: 0;
    z-index: 2;
    background: #eee;
    color: #333;
  }

  .hidden {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  > form.settings {
    padding: 0 20px 10px 20px;
    > div {
      margin-top: 15px;

      > label {
        font-size: 12px;
        font-weight: 500;
      }

      > p {
        padding-left: 15px;
        padding-top: 5px;
      }

      > ul.options {
        padding-left: 15px;
        padding-top: 10px;
        list-style: none;
        > li {
          margin-bottom: 10px;

          &.option {
            display: flex;

            > div.toggle {
              display: flex;
              justify-content: center;
              cursor: pointer;
              user-select: none;

              margin: auto 0;
              width: 50px;
            }
          }
        }
      }
    }

    div.addOption {
      > div:first-of-type {
        display: flex;
        flex-direction: row;
        align-items: center;
        > input {
          flex-grow: 1;
          margin-right: 10px;
        }
      }
    }

    > div.toggleWrapper {
      display: flex;
      align-items: center;
    }
  }

  > form.choice {
    padding: 10px 20px 10px 20px;
  }
}
