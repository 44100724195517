@import "uiAssets/ColorsVars.scss";

.Header {
  align-items: center;
  background: #ffffff;
  border-bottom: 1px solid #dedede;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  height: 50px;
  padding: 0 20px;
  position: relative;
  width: 100%;
  z-index: 5;
  justify-content: space-between;

  > h1 {
    align-items: center;
    color: #666;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    font-size: 16px;
    letter-spacing: 0.5px;
    padding-right: 20px;

    > svg {
      margin-right: 10px;
    }
  }

  .children {
    display: flex;
    button:not(:last-child) {
      margin-right: 10px;
    }
  }
}
