.manageAdminsView {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;

  .manageAdminsContent {
    display: flex;
    flex: 1;
    padding: 20px 40px 40px;
    flex-direction: column;
    overflow: auto;

    .tableHeaderWrapper {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid #eaeaf1;

      .tableTitle {
        color: #2782ff;
        border-bottom: 1px solid #2782ff;
        padding: 10px 0;
      }
    }

    .table {
      display: flex;
      flex-direction: column;
      padding-top: 40px;

      .tableRow {
        padding: 10px 0;
        border-bottom: 1px solid #eaeaf1;
        display: flex;
        flex-direction: row;

        &.tableHeader {
          padding-bottom: 20px;
        }

        .tableCell {
          width: 25%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-right: 1rem;

          &:first-child {
            flex: 1;
          }
        }
      }

      .tableHeader {
        .tableHeaderCell {
          font-size: 14px;
          color: #72767c;
          font-weight: normal;
        }
      }

      .fullName {
        font-size: 14px;
        color: #192f4b;
      }

      .email {
        font-size: 14px;
        color: #9aa7b9;
      }

      .role {
        font-size: 14px;
        color: #535d6d;
      }

      .dropdown {
        width: 100%;
        margin-right: 2rem;
        span {
          font-size: 14px;
          color: #535d6d;
        }
        h2 {
          width: 100%;
        }
      }
    }
  }
}
