.AppMessages {
  bottom: 0;
  font-size: 14px;
  position: fixed;
  right: 0;
  z-index: 999999;

  > div {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.15) 0 0 5px;
    display: flex;
    flex-direction: row;
    margin: 15px 15px 5px 0;
    min-height: 60px;
    position: relative;
    width: 275px;

    > button {
      background: #fff;
      border: none;
      border-radius: 100px;
      box-shadow: rgba(0, 0, 0, 0.15) 0 0 5px;
      height: 24px;
      position: absolute;
      right: -10px;
      top: -10px;
      width: 24px;
    }

    > .iconContainer {
      align-items: center;
      border-radius: 5px 0 0 5px;
      display: flex;
      flex-grow: 0;
      flex-shrink: 0;
      justify-content: center;
      width: 60px;
    }

    > .messageContent {
      border-radius: 0 5px 5px 0;
      color: #fff;
      flex-grow: 1;
      padding: 10px 15px;

      > header {
        font-size: 12px;
        letter-spacing: 1px;
        margin-bottom: 5px;
        text-transform: uppercase;
      }
    }
  }
}
