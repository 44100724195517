@import "@skillup/ui";

.layout {
  height: 100%;
}
.container {
  width: 100%;
  background: $surface-light-darker;
  padding: $skui-spacing-m;
  height: 100%;

  .cell {
    overflow: hidden;
    gap: $skui-spacing-xxs;
    padding: $skui-spacing-xs 0.75rem;

    .tooltip {
      white-space: normal;
    }

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .subtitle {
    @include espace-rh-caption-regular;
    color: $plainText-onLight-lighter;
  }

  .dataGrid {
    padding-bottom: $skui-spacing-s;
  }
}
