@import "@skillup/ui";

.TargetSettingsModal {

  max-width: 600px;
  .label {
    margin: $skui-spacing-xxs 0;
  }

  .noItem {
    @include espace-rh-components-input-placeholder;
    color: $placeholder-onLight;
  }

  .addButton {
    color: $action-onLight-darkest;
    button {
      font-weight: normal !important;
    }
  }
}
