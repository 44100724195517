@import "@skillup/ui";

.sidePanel {
  display: flex;
  flex-flow: column;
  > div {
    flex: 1 1 auto;
  }

  .container {
    display: flex;
    height: 100%;
    flex-direction: column;
    padding: $skui-spacing-s $skui-spacing-s $skui-spacing-s $skui-spacing-l;

    .campaignListContainer {
      margin-top: $skui-spacing-s;

      .campaignContainer {
        display: flex;
        flex: 1;
        margin-bottom: $skui-spacing-xs;

        .link {
          vertical-align: text-bottom;
          color: $action-onLight-darkest;
          font-size: 1rem;
          font-weight: 400;
          margin-right: $skui-spacing-xs;
          margin-bottom: $skui-spacing-xs;

          text-decoration: underline;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;

          &:hover {
            color: $action-onLight-darker;
            cursor: pointer;
          }
        }

        .icon {
          color: $plainText-onLight-lighter;
          font-size: 1rem;
          margin-bottom: 0.2rem;
          align-self: center;
        }
      }
    }

    .footerContainer {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: $skui-spacing-s;
      @include espace-rh-caption-regular;
      color: $plainText-onLight-lighter;

      .lastContributorAvatar {
        margin-right: $skui-spacing-s;
      }
    }
  }
}
