.List {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 2px;
  margin-top: 5px;

  .value {
    flex-grow: 1;
    > button {
      width: 100%;
      cursor: pointer;
      border: none;
      background-color: white;
      height: 1.8rem;
      font-size: 0.8rem;
      text-align: start;
      padding-left: 5px;
    }
  }

  .chevrons {
    align-self: center;
    > button {
      cursor: pointer;
      background-color: white;
      border: 0;
      align-items: stretch;
      padding-right: 5px;
    }
  }
}
