@import "uiAssets/ColorsVars.scss";

.PlanDashboardView {
  overflow: auto;
  flex-grow: 1;

  .timeGraphContainer {
    background-color: #fff;
    border: 2px solid #eee;
    border-radius: 5px;
    margin: 20px;
  }

  .header {
    display: flex;
    align-items: center;
    margin: 15px 15px 0;

    h2 {
      font-size: 22px;
      font-weight: 700;
      margin-right: 15px;
    }

    .yearButton {
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 8px 15px;
      background: none;
      margin-right: 15px;
      font-weight: 600;

      &.active,
      &:hover {
        background: #e7f3ff;
      }
    }
  }
}
