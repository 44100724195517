@import "uiAssets/ColorsVars.scss";

.PlaceFilter {
  width: 100%;

  h3 {
    color: #333;
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .inputContainer {
    position: relative;
  }

  .cancelButton {
    background: #fff;
    cursor: pointer;
    display: none;
    position: absolute;
    right: 10px;
    top: 10px;
  }

  &:hover {
    .cancelButton {
      display: block;
    }
  }

  .predictions {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 100%;
    width: 100%;
    z-index: 2;

    button.prediction {
      user-select: none;
      align-items: center;
      background: #fff;
      border: none;
      border-bottom: 1px solid #ccc;
      color: $mainColor;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px 15px;
      text-align: left;
      text-decoration: none;
      transition: color 0.5s, background 0.5s;
      width: 100%;

      > svg {
        flex-shrink: 0;
        margin-left: 10px;
        transition: fill 0.5s;
      }

      &:last-of-type {
        border-bottom: none;
      }

      &:hover {
        cursor: pointer;
        background: $mainColor;
        color: #fff;

        > svg {
          fill: #fff;
        }
      }
    }
  }
}
