@import "uiAssets/ColorsVars.scss";

.Breadcrumb {
  align-items: center;
  border-bottom: 1px solid #ddd;
  display: flex;
  flex-flow: row wrap;
  max-width: 780px;
  padding: 0 30px 10px;

  &.training {
    border-bottom: 0;
    font-size: 13px;
    margin-bottom: 20px;
    max-width: 100%;
    padding: 0;

    .currentLink {
      text-overflow: ellipsis;
    }
  }

  .home {
    position: relative;
    top: 2px;
  }

  > .linkContainer {
    color: #999;
    margin-right: 10px;
    padding: 8px 0;
    position: relative;
    text-decoration: none;
    transition: color 0.5s;

    &:hover {
      color: $blue;
    }

    &.currentLink {
      color: $blue;
      margin-right: 0;

      > svg.chevron {
        fill: $blue;
      }
    }

    > svg.chevron {
      fill: #999;
      margin-left: 10px;
    }

    > div {
      background: #fff;
      border-radius: 7px;
      box-shadow: $bigBoxShadow;
      margin-top: 5px;
      min-width: 300px;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      transform: translateY(10px);
      transition: opacity 0.2s ease-out, transform 0.2s ease-out;
      z-index: 999;

      > a {
        align-items: center;
        color: $blue;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 15px;
        text-decoration: none;
        transition: color 0.2s ease-out, background 0.2s ease-out;

        &:hover {
          background: $blue;
          color: #fff;

          > svg {
            fill: #fff !important;
          }
        }

        > svg {
          flex-shrink: 0;
          margin-left: 10px;
          transition: fill 0.5s;
        }

        &:first-of-type {
          border-radius: 7px 7px 0 0;
        }

        &:last-of-type {
          border-radius: 0 0 7px 7px;
        }
      }
    }

    &:hover > div {
      opacity: 1;
      pointer-events: all;
      transform: translateY(0);
    }
  }

  > a:hover {
    text-decoration: underline;
  }
}
