@import "../../assets/designSystem";

article.Card {
  display: flex;
  flex-direction: column;
  width: 405px;
  max-width: 100%;
  border-radius: 4px;
  box-shadow: $elevation-actionableCard;
  background: $surface-light;
  border: 1px solid $greyscale-light;

  padding-top: $skui-spacing-s;
  padding-bottom: $skui-spacing-s;
  color: $plainText-onLight-lighter;

  &.withCover {
    padding-top: 0;
  }
  &.withFooter {
    padding-bottom: 0;
  }

  &.actionnable {
    cursor: pointer;
    &:hover {
      box-shadow: $elevation-actionableCard-hover;
      .title {
        color: $plainText-onLight-default;
      }
    }
    &:focus {
      box-shadow: none;
      outline: 2px solid $action-onLight-darker;
      outline-offset: 2px;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding: 0 $skui-spacing-s;
    margin-bottom: $skui-spacing-xs;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    &.withActions {
      padding: 0 $skui-spacing-s;
    }
    &.cover {
      height: 150px;
      padding-top: $skui-spacing-s;
      background-size: cover !important;
      background-position: center !important;
    }
    .labels {
      margin-bottom: $skui-spacing-xs;
      & > div {
        margin-right: $skui-spacing-xs;
      }
    }
  }

  .titleWrapper {
    display: inline-flex;
    align-items: center;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 $skui-spacing-s;
    margin-bottom: $skui-spacing-s;
    &.withActions {
      padding: 0 $skui-spacing-s;
    }
    &.withHeader {
      margin-bottom: $skui-spacing-xs;
    }
  }

  .toggler {
    margin: $skui-spacing-xs;
  }

  .tooltip {
    margin: 0 $skui-spacing-xxs;
    display: inline-flex;
    align-items: center;
    svg {
      color: $decoration-blue-base;
    }
  }

  .content {
    padding: 0 $skui-spacing-s;
    color: $plainText-onLight-default;
    max-height: 27rem;
    &.withActions {
      padding: 0 $skui-spacing-s;
    }
  }

  .footer {
    padding: $skui-spacing-xs $skui-spacing-s 0 $skui-spacing-s;
    margin-bottom: $skui-spacing-xs;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .quickActions {
      display: flex;
      flex-wrap: nowrap;
      & > button {
        margin-right: $skui-spacing-xs;
      }
    }
  }

  &.espace-collab {
    .title .label {
      @include espace-collab-components-card-title;
    }
    .label,
    .content {
      @include espace-collab-font;
    }
  }

  &.espace-rh {
    .title .label {
      @include espace-rh-components-card-title;
    }
    .label,
    .content {
      @include espace-rh-font;
    }
  }
}
