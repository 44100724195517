@import "@skillup/ui";

.UserInfo {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1.5rem 1.5rem 2.5rem;
  gap: 1rem;

  @media (max-width: 640px) {
    padding: 0;
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0px;
    gap: 10px;
  }
  .infos {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0px;
    gap: 1rem;

    @media (max-width: 640px) {
      flex-direction: column;
      flex-grow: 1;
      gap: 0;
    }

    .infoCard {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 1rem;
      gap: 1.5rem;
      background: $surface-light;
      border: 1px solid $border-onLight;
      border-radius: 7px;
      color: $plainText-onLight-lighter;
      width: 13.5rem;
      overflow: hidden;
      @media (max-width: 640px) {
        gap: $skui-spacing-s;
        border-radius: 0;
        width: 100%;
        border-top: none;
      }
      h2 {
        @include espace-rh-h5;
      }
      p {
        @include espace-rh-value;
        color: $plainText-onLight-default;
        margin-top: $skui-spacing-xs;
        min-height: 1.5rem;
        white-space: pre-wrap;
        word-break: break-all;
      }
    }
  }
}
