@import "@skillup/ui";

.activities_btn {
  margin-top: 0.5rem;
}

.log_wrapper {
  align-items: flex-start;
}

.log {
  color: $plainText-onLight-lighter;
  @include espace-rh-body2-regular;
  padding: 0.25rem 0.5rem;
  margin-left: 0.5rem;
  border-radius: 0.25rem;
  max-width: 18.5rem;
  margin-bottom: 0.875rem;
  white-space: pre-line;
  word-break: break-word;
  .description {
    @include espace-rh-caption-regular;
  }
}

.comments {
  margin-top: 0;
}

.log_divider {
  align-items: center;

  > p {
    color: $plainText-onLight-lighter;
    @include espace-rh-caption-regular;
    margin: 0.5rem;
  }

  > hr {
    flex: 1 1;
    border: solid 1px #eaeaf1;
  }
}
