@import "uiAssets/ColorsVars.scss";

.TrainingHeader {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 5px;
  padding: 0 10px;

  > div {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1000px;
    min-height: 50px;

    @media (max-width: 749px) {
      justify-content: center;
    }

    > img {
      height: 28px;
      margin-right: 10px;
    }

    .home {
      color: $blue;
      margin-left: 10px;
      text-decoration: none;

      svg {
        margin-right: 10px;
        position: relative;
        top: 2px;
      }
    }

    .locale {
      margin: 0 15px;

      > button {
        background: none;
        border: none;
        color: #666;
        margin: 0 5px;
        position: relative;
        text-decoration: none;
        z-index: 0;

        &:disabled {
          color: $blue;
          cursor: initial;
        }
      }

      > span {
        color: #666;
      }
    }
  }
}
