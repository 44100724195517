.DatePicker {
  > label:empty {
    display: none;
  }
  .container {
    input {
      font-size: 1rem;
      padding-left: 0;
      padding-right: 2rem;
    }
    .calendarIcon {
      bottom: 1.125rem;
    }
    .clearCalendarIcon {
      display: flex;
      bottom: 1.25rem;
    }
    &:hover .clearCalendarIcon {
      display: flex;
    }
  }
}
.calendar {
  width: 100%;
  max-width: 300px;
  margin-top: 0.375rem;
  position: absolute;
  z-index: 8;
  top: 100%;
  right: 0;
}
