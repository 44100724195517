@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.chart {
  width: 100%;
  position: relative;

  .alert {
    line-height: 1.5rem;
  }
}

.customLegend {
  display: flex;
  flex-direction: column;
  gap: $skui-spacing-xxs;
  top: 0;
  left: 4rem;
  position: absolute;
  height: 78px;
  min-width: 172px;
  background-color: $surface-light;
  border: solid 1px $border-onLight;
  border-radius: 5px;
  padding: $skui-spacing-xs;
  list-style-type: none;
  color: $plainText-onLight-lighter;

  .legendText {
    @include espace-rh-caption-regular;
    padding-left: $skui-spacing-s;
    p {
      display: inline-flex;
      color: $plainText-onLight-default;
      margin-left: $skui-spacing-xs;
    }
  }
  .title {
    @include espace-rh-caption-bold;
    .bullet {
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      margin-right: 0.5rem;
      background-color: $decoration-yellow-base;
      display: inline-flex;
    }
  }
}

.crosshair {
  color: "white";
  box-shadow: "0 2px 4px red";
}

.tooltip {
  @include espace-rh-caption-regular;
  padding: $skui-spacing-s;
  border-radius: $skui-spacing-s;
  background-color: $surface-light;
  opacity: 0.9;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: $skui-spacing-xs;
  color: $plainText-onLight-lighter;

  .tooltip_divider {
    height: 1px;
    width: 100%;
    background-color: $border-on-light;
  }

  .tooltip_line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
  }

  .tooltip_line_label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .tooltip_line_value {
    @include espace-rh-caption-bold;
    color: $plainText-onLight-default;
  }
}
