@import "uiAssets/ColorsVars.scss";

.Header {
  display: flex;
  justify-content: center;
  padding-bottom: 35px;
  padding-top: 18px;
  width: 100%;
  .container {
    display: flex;
    max-width: 800px;
    width: 100%;
  }
}
