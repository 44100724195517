@import "@skillup/ui";

.body {
  border: solid 1px $border-onLight;
  border-radius: $skui-spacing-xxs;
}

.activatedKeyToWarn {
  list-style-position: inside;
  font-weight: 600;
}
