@import "../../../assets/designSystem";

.Toggle {
  position: relative;
  width: 2.25rem;
  height: 1.25rem;
  border-radius: 2rem;
  border: 0.125rem solid $greyscale-light;
  background-color: $greyscale-light;
  transition: background-color 0.2s, border 0.2s;
  align-items: center;
  cursor: pointer;

  .slider {
    position: absolute;
    top: 0;
    left: 0;
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    user-select: none;
    outline: none;
    background-color: $surface-light;
    transition: 0.2s;
  }
  &.darkMode {
    border-color: $greyscale-dark;
    background-color: $greyscale-dark;
    .slider {
      background-color: $greyscale-medium;
    }
  }

  &.active {
    border-color: $decoration-blue-base;
    background-color: $decoration-blue-base;

    .slider {
      transform: translate(1rem);
      background-color: $decoration-blue-darker;
    }

    &.darkMode {
      border-color: $decoration-yellow-base;
      background-color: $decoration-yellow-base;
      .slider {
        background-color: $decoration-yellow-darker;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }

  &:not(.disabled) {
    &:not(.active) {
      .slider {
        opacity: 0.5;
        box-shadow: 1px 1px 2px rgba(102, 102, 102, 0.25);
      }
      &:hover {
        .slider {
          opacity: 1;
          box-shadow: 2px 2px 2px rgba(102, 102, 102, 0.25);
        }
      }
    }
    &.active {
      &:hover {
        .slider {
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
            $decoration-blue-darker;
        }
        &.darkMode {
          .slider {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
              $decoration-yellow-darker;
          }
        }
      }
    }
  }
}
