@import "@skillup/ui";

.container {
  width: 100%;
  background: $surface-light-darker;
  
  .content {
    flex: 1;
    padding: $skui-spacing-m;
  
    .cell {
      overflow: hidden;
      .textCell {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }  
}