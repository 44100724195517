@import "@skillup/ui";
.globalTargetsCompletionChild {
  @include espace-rh-body1-regular;
  padding: $skui-spacing-s;
  color: $plainText-onLight-lighter;

  > p:first-of-type {
    margin: 0 0 $skui-spacing-s 0;
  }

  ul {
    padding-left: $skui-spacing-m;
  }
}

.settings {
  padding: $skui-spacing-s $skui-spacing-s 0 $skui-spacing-s;

  > p {
    @include espace-rh-caption-regular;
    color: $plainText-onLight-lighter;
    margin: $skui-spacing-s 0 0 0;
  }
}

.settings__actionChoice {
  &:first-of-type {
    margin-bottom: $skui-spacing-xs;
  }
}
