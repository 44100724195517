@import "../../assets/designSystem";

$activeStatusBoxShadow: inset 100px 100px 0px rgba(12, 92, 136, 0.1),
  inset -1px -1px 8px rgba(12, 92, 136, 0.1), inset 1px 1px 8px rgba(12, 92, 136, 0.1);

.ExpansionPanel {
  width: 100%;
  border-radius: 0.125rem;
  border-width: 1px;
  border-style: solid;
  border-color: $border-onLight;

  > button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 3rem;
    padding: 1em;
    color: $plainText-onLight-default;
    width: 100%;
    cursor: pointer;
    border: none;
    border-radius: 2px;
    background-color: transparent;

    > div.label {
      text-align: left;
      flex-grow: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding-right: $skui-spacing-s;
    }

    > div.iconWrapper {
      width: 40px;
      min-width: 40px; // Avoids being squashed by the label
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      background-color: $effects-hover-onLight;
      outline: none;
    }

    &:focus {
      outline: 1px solid $action-onLight-default;
      outline-offset: 2px;
    }

    &:hover,
    &:focus {
      background-color: $effects-hover-onLight;
      > div.iconWrapper {
        background-color: $effects-hover-onLight;
      }
    }
  }

  > .content {
    max-height: 0;
    padding: 0 $skui-spacing-s;
    height: auto;
    transition: all 0.05s ease-in-out;
    animation: fadeOut;
    overflow: hidden;
    box-sizing: content-box;

    &.opened {
      padding: 1em;
      visibility: visible;
      max-height: fit-content;
    }
  }

  &.fullBleed {
    border: none;
    border-radius: 0;
  }

  &.darkMode {
    border-color: $border-onDark;

    > button {
      color: $plainText-onDark-default;

      &:focus {
        outline: 1px solid $action-onDark-default;
        outline-offset: 2px;
      }

      &:hover {
        outline: none;
      }

      &:hover,
      &:focus {
        background-color: $effects-hover-onDark;
        > div.iconWrapper {
          background-color: $effects-hover-onDark;
        }
      }
    }
  }

  &.espace-rh {
    @include espace-rh-body1-regular;
  }

  &.espace-collab {
    @include espace-collab-body1-regular;
  }
}

@keyframes fadeOut {
  99% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
  }
}
