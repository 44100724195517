@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.layoutHeader {
  position: relative;
  background-color: $surface-dark;
  padding: 0 $skui-spacing-l $skui-spacing-xs $skui-spacing-xl;

  .filters {
    display: none;
  }
  > p {
    @include espace-rh-caption-bold;
    color: $plainText-onDark-darker;
    padding-bottom: $skui-spacing-xs;
  }

  &.isExpanded {
    .filters {
      display: block;
    }
    > p {
      display: none;
    }
  }

  .expandButton {
    position: absolute;
    right: $skui-spacing-s;
    bottom: $skui-spacing-xs;
  }
}
