@import "@skillup/ui";

.action-card {
  display: flex;
  align-items: center;
  background-color: $surface-light;
  border-radius: 5px;
  border: 1px solid $border-on-light;
  font-size: 14px;
}

.status-indicator {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  margin-right: 11px;
}

.action-name {
  flex-grow: 1;
  font-weight: bold;
}

.info-text {
  color: #777;
}

.actions {
  margin-left: auto;
}