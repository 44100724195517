@import "@skillup/ui";

.ArchiveTemplateModal {
  width: 30rem;
  .content {
    @include espace-rh-body1-regular;
    color: $plainText-onLight-default;
    gap: $skui-spacing-s;
    padding: $skui-spacing-s 0;
  }
}
