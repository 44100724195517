@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.Projects {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $skui-spacing-s;

    > div > :nth-child(2) {
      margin: 0;
    }

    > div > :nth-child(6) {
      margin: 0;
    }

    > div > button {
      margin: 0 $skui-spacing-xs;
    }
  }

  .dataTableWrapper {
    display: flex;
    flex: 1;
    overflow: hidden;
  }
}
