@import "@skillup/ui";

.autocompleteInput {
  display: block;

  > div:nth-child(2) > div > div {
    padding: $skui-spacing-xxs $skui-spacing-xs $skui-spacing-xxs $skui-spacing-s;
    input {
      @include espace-rh-body1-regular;
      &::placeholder {
        opacity: 1;
        color: $placeholder-onLight;
      }
    }
  }

  li.option {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    font-size: 1rem;
    width: 100%;
    padding: 0.75rem 1rem;
    
    &:hover {
      background-color: $effects-hover-onLight;
    }
    
    > div.orgAndLabel {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
  
      > span:first-of-type {
        width: auto;
        text-overflow: ellipsis;
        color: $plainText-onLight-default;
        font-size: 1rem;
        line-height: 1.25rem;
      }
  
      > span:last-of-type {
        width: auto;
        text-overflow: ellipsis;
        color: $plainText-onLight-lighter;
      }
    }
  
    > div.reference {
      color: $plainText-onLight-lighter;
      font-size: 1rem;
      line-height: 1.25rem;
    }
  }
}