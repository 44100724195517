@import "@skillup/ui";

.content {
  width: 35rem;
  max-width: 35rem;
  padding-bottom: $skui-spacing-l;   
  
  .newSection {
    padding-top: $skui-spacing-m;
  }

  .goal {
    padding-top: $skui-spacing-xs;
  }

  .customHelpText {
    padding-left: $skui-spacing-m;
    @include espace-rh-components-input-help-text;
    color: $plainText-onLight-lighter;
    line-height: $skui-spacing-s;
  }
}
