@import "../../assets/colors";

.Skill {
  padding: 0.188rem 0.625rem;
  background-color: rgba($secondary-green, 0.5);
  border-radius: 2px;
  user-select: none;

  span.label {
    margin-top: 1px;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: 0.15px;
    color: white;
    white-space: nowrap;
  }
}
