@import "../../../assets/mixins";
@import "../../../assets/designSystem";

.TrainingProgramListItem {
  display: flex;
  background: $surface-light;

  border-radius: $skui-spacing-xs;
  padding: $skui-spacing-xs;
  gap: $skui-spacing-s;

  &.actionnable {
    &.selected {
      background-color: $action-onLight-lightest;
    }
  }

  .selection {
    display: flex;
    justify-content: center;
    align-items: center;
    input {
      appearance: none;
      width: 2rem;
      height: 2rem;
      background: $greyscale-lighter
        url('data:image/svg+xml,<svg width="18" height="14" viewBox="0 0 18 14" xmlns="http://www.w3.org/2000/svg"><path d="M5.9999 10.7001L1.7999 6.5001L0.399902 7.9001L5.9999 13.5001L17.9999 1.5001L16.5999 0.100098L5.9999 10.7001Z" fill="white"/></svg>')
        no-repeat center center;
      border: 1px solid $border-on-light;
      border-radius: 1rem;
    }
    input:checked {
      background-color: $action-onLight-default;
    }
  }
  .body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    h1 {
      color: $plainText-onLight-default;
    }

    .organisation {
      color: $plainText-onLight-lighter;
    }
  }

  .action {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.espace-rh {
    h1 {
      @include espace-rh-body2-bold;
    }
    .organisation {
      @include espace-rh-caption-regular;
    }
  }
  &.espace-collab {
    h1 {
      @include espace-collab-body2-bold;
    }
    .organisation {
      @include espace-collab-caption-regular;
    }
  }
}
