@import "@skillup/ui";

.configuration {
  width: 100%;
  min-height: 100vh;

  flex-direction: column;
  .fixedHeaderWrapper {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .configFormWrapper {
    gap: $skui-spacing-l;
  }

  .dropdown {
    margin: $skui-spacing-l !important;
    > span {
      margin: 0 !important;
    }
  }

  .panelTypeWording {
    gap: 1rem;
    line-height: 8px;
    font-weight: 400px;
    font-size: 12px;
  }
  .resetButton {
    @include espace-rh-caption-link;
    color: $action-onLight-darkest;
    cursor: pointer;
  }

  * {
    opacity: 1;
  }
  background-color: $surface-dark-darker;

  .mobileView {
    background-color: #0a4d71;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: fixed;
  }
}

.textErrorAssistiveArea {
  flex-direction: column;

  > div:has(> svg) {
    gap: $skui-spacing-xs !important;
  }
  > div > div:has(> span) {
    gap: $skui-spacing-xxs !important;
  }
}

.panelContent {
  margin: 0;
  // width: 100%;
  background-color: $surface-dark-darker;
  padding: $skui-spacing-s;
  border: dashed 3px white;
  border-top-style: solid;
  justify-content: center !important;
}

.draggablePanels {
  margin-left: $skui-spacing-l;
  margin-right: $skui-spacing-l;
  :nth-child(1) {
    gap: 0;
    margin: 0;
  }

  :nth-child(2) {
    margin: 0;
  }

  > .dragContainer {
    > div[style] > li > div {
      border-radius: 0;
      margin: $skui-spacing-s;
      padding: 0;
      outline: unset;
      box-shadow: unset;

      > div {
        align-items: normal;
        margin: $skui-spacing-xs;
        > div {
          > svg {
            width: 100%;
            fill: $plainText-onLight-lighter;
          }
        }
      }

      :nth-child(2) {
        margin: 0;
      }
    }
  }

  > div > ul > li > div {
    border-radius: 0;
    margin: $skui-spacing-s;
    padding: 0;
    outline: unset;
    box-shadow: unset;
    > div {
      align-items: normal !important;
      > div:has(> svg) {
        margin: $skui-spacing-xs !important;
      }
      > div {
        > svg {
          width: 100%;
          fill: $plainText-onLight-lighter;
        }
      }
    }
  }
}

.draggableHeader {
  justify-content: space-between;
  align-items: flex-start;
  padding: $skui-spacing-xs;
  .headerType {
    @include espace-rh-body1-regular;
    color: $plainText-onLight-lighter;
  }
}

.draggableCards {
  .CardKeyIndicatorTitleInput {
    width: 100%;
  }

  .sortableCardsTitle {
    justify-content: space-between;
  }

  .CardKeyIndicator {
    margin: 0;
    padding: 10px;
  }

  .addCardButton {
    svg > :first-child {
      opacity: 1;
      > path {
        fill: $plainText-onDark-darker;
      }
    }
    margin: $skui-spacing-s;
  }

  > div > ul {
    row-gap: $skui-spacing-s !important;
    .addGraph {
      width: 528px;
      justify-content: center;
    }
    > li {
      margin: 0 !important;
      > div > div {
        align-items: normal !important;
        > :first-child {
          width: 2rem;
          > svg {
            width: 100%;
            fill: $plainText-onLight-lighter;
          }
        }
        > :nth-child(2) {
          > div {
            > div:first-of-type {
              padding: 0;
            }
            > div {
              margin-left: $skui-spacing-s;
              margin-right: $skui-spacing-s;
            }
          }
        }
        > div > div > span {
          height: fit-content;
        }
      }
    }
  }
}

.dagglableCardsDetaileds > div > ul {
  display: grid;
  grid-template-columns: 50px 2fr 50px 2fr 50px;
  @media (max-width: 1260px) {
    grid-template-columns: 50px 2fr;
    .addCardButtonRepeated {
      display: none !important;
    }
  }
  > li {
    width: 488px;
    > div > :nth-child(2) {
      border-radius: $skui-spacing-xs;
      background: $greyscale-light;
      padding: $skui-spacing-xs;
      padding-left: $skui-spacing-xl;
      margin-top: $skui-spacing-xs;

      height: 100%;
      > div {
        gap: $skui-spacing-xs;
        width: 100%;
        > div > div {
          width: 100%;
        }
      }
    }
  }
}

.dagglableCardsKeys > div > ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  > li {
    width: 236px;
    height: 96px;
  }
}
