@import "uiAssets/ColorsVars.scss";

.CellDropDown {
  margin: 15px 0;
  position: relative;
  width: 100%;

  > input {
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
  }

  > span {
    display: block;
    font-size: 13px;
  }

  > span.label {
    height: 17px;
  }

  > button {
    align-items: center;
    background: transparent;
    border: none;
    display: flex;
    height: 30px;
    justify-content: space-between;
    letter-spacing: 0.5px;
    padding: 0 10px;
    position: relative;
    width: 100%;
  }

  > button > span {
    display: block;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: pre;
    width: calc(100% - 20px);
  }

  > button > div.iconContainer {
    display: flex;
    height: 100%;
    left: 14px;
    position: absolute;
    top: 0;
    width: 20px;
  }

  > button > div.iconContainer > svg.icon {
    display: block;
    height: 100%;
    margin: auto;
    margin-right: 10px;
    width: 100%;
  }

  > .optionsContainer {
    background: #fff;
    border: solid 1px rgba(0, 0, 0, 0.105);
    border-top: none;
    box-shadow: rgba(0, 0, 0, 0.15) 5px 6px 10px 1px;
    display: none;
    margin-left: -10px;
    max-height: 300px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 30px;
    width: calc(100% + 20px);
  }

  > .optionsContainer > .optionsInnerContainer {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  > .optionsContainer > .optionsInnerContainer > .option {
    align-items: center;
    background: none;
    border: none;
    display: flex;
    justify-content: space-between;
    opacity: 0;
    padding: 10px;
    position: relative;
    transform: translateX(150px);
    transition: 0.2s color ease-out;
    width: 100%;
  }

  > .optionsContainer > .optionsInnerContainer > .option:hover {
    background: $gradientBlue;
    color: #fff;
  }

  > .optionsContainer > .optionsInnerContainer > .option:hover > svg {
    stroke: #fff !important;
  }

  > .optionsContainer > .optionsInnerContainer > .option > span {
    display: block;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: pre;
    width: calc(100% - 20px);
  }

  > .optionsContainer > .optionsInnerContainer > .border {
    background: #eee;
    height: 1px;
    width: 100%;
  }
}
