.header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 34px;

  > h2 {
    color: #333333;
    font-size: 18px;
    font-weight: 600;
  }

  > button {
    color: #2782ff;
    background: none;
    border: none;
    text-decoration: underline;
    cursor: pointer;
  }

  > .disabled {
    color: #ddd;
    cursor: auto;
  }
}

.categoryWrapper {
  padding-bottom: 12px;

  > h3 {
    color: #333333;
    font-weight: 600;

    > svg {
      margin-right: 8px;
    }
  }

  > div {
    color: #999999;
    font-size: 13px;
  }
}

.categoryWrapper:not(:last-child) {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 12px;
}
