.confirmEvaluationDeletionModal {
  padding: 28px 17px;
  padding-bottom: 18px;

  > p {
    width: 232px;
    height: 38px;
    margin: auto;
    flex-grow: 0;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #192f4b;
    font-weight: 400;
  }

  > .action {
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;
  }
}
