.Disclaimer {
  padding: 16px;

  > p {
    padding: 8px 8px 16px;
  }

  > ul {
    padding: 8px 24px;

    > li {
      padding-bottom: 8px;
      font-size: 14px;
    }
  }

  .checkbox {
    padding: 8px;

    > span {
      color: #000000;
    }
  }

  .toggle {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    div {
      flex-shrink: 0;
    }

    label {
      margin-left: 10px;
      font-size: 12px;
    }
  }

  .buttonContainer {
    display: flex;
    padding: 24px 0 8px;
    justify-content: center;

    button {
      height: 40px;
    }
  }
}
