@import "uiAssets/ColorsVars.scss";

.InteractiveButton {
  background: $gradientMain;
  border: none;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 10px;
  color: white;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  letter-spacing: 0.3px;
  line-height: 1;
  max-width: 100%;
  overflow: hidden;
  padding: 0 15px;
  position: relative;
  text-decoration: none;
  width: auto;
  display: block;
  // animation-name: on-mount;
  // animation-duration: 300ms;
  // animation-fill-mode: forwards;

  &:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.2) 0 6px 15px;
  }

  &.loading {
    cursor: default !important;
    border-radius: 1000px;
    width: 40px;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0;

    &.xsmall {
      width: 25px;
    }

    &.small {
      width: 30px;
    }

    &.big {
      width: 50px;
    }

    .innerContent {
      display: none;
    }
  }

  &.xsmall {
    font-size: 12px;
    height: 25px;
    padding: 0 8px;

    .interactiveButtonLoader {
      width: 20px;
    }
  }

  &.small {
    font-size: 12px;
    height: 30px;
    padding: 0 12px;

    .interactiveButtonLoader {
      width: 20px;
    }
  }

  &.big {
    font-size: 20px;
    height: 50px;
    padding: 0 20px;

    .interactiveButtonLoader {
      width: 40px;
    }
  }

  .interactiveButtonLoader {
    width: 30px;
  }

  .innerContent {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: center;
  }
}

// @keyframes on-mount {
//   from {
//     transform: scale(0);
//     opacity: 0;
//   }
//   to {
//     transform: scale(1);
//     opacity: 1;
//   }
// }
