@import "@skillup/ui";

.trackingSidePanel {
  @include espace-rh-font;
  color: $plainText-onLight-default;
  display: flex;
  flex-flow: column;
  header {
    flex: 0 1 auto;
  }
  > div {
    flex: 1 1 auto;
  }
  .headerStatus {
    margin-bottom: $skui-spacing-xs;
    > div {
      margin-right: 0.5rem;
    }
  }
  .headerDropDown {
    margin-top: $skui-spacing-l;
    display: flex;
    justify-content: flex-end;
  }

  .trackingContainer {
    padding: $skui-spacing-s;
    padding-left: $skui-spacing-l;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    height: 100%;

    .trackingRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .trackingRowSection {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: stretch;
        align-items: flex-start;
        &:first-child {
          margin-right: $skui-spacing-xs;
        }
      }
    }

    .trackingSection {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      margin-bottom: $skui-spacing-m;
    }

    .noTrackingNote {
      margin-top: $skui-spacing-xs;
    }

    .trackingNote {
      display: flex;
      margin-top: $skui-spacing-xs;
      > :first-child {
        margin-right: $skui-spacing-s;
      }
    }

    .sectionValue {
      margin-top: $skui-spacing-xs;
      border: none;
      border-radius: 0;
      padding: 0;
    }

    .fileList {
      margin-top: $skui-spacing-xs;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .fileListBtnSection {
        display: flex;
        align-items: center;
        .fileBtn:first-child {
          margin-right: $skui-spacing-s;
        }
      }
    }
  }
  .trackingSidePaneldivider {
    padding: 0 $skui-spacing-s;
  }
}
