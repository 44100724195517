@import "@skillup/ui";

.UserAreaSelect {
  .areaFilter {
    width: 260px;
    > div:first-of-type {
      > div:first-of-type {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    .allSelected {
      font-size: 1rem;
    }
  }
}
