.tag {
  > .tagText {
    padding: 4px 8px;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    width: fit-content;
    border-radius: 4px;
  }
}
