@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.StepContainer {
  display: flex;
  flex-direction: column;

  header.containerHeader {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    h3.stepTitle {
      color: $plainText-onLight-lighter;
      font-size: 22px;
      font-weight: 500;
    }

    span.info {
      color: $smallTextColor;
      font-size: 14px;
    }
  }
}

.CardWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  .QuestionsStep {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    div[class^="content"] {
      max-height: none;
    }

    .Input {
      display: flex;
      flex-direction: column;
      label {
        @include espace-rh-components-input-label;
        color: $plainText-onLight-lighter;
        &.requiredField:after {
          content: " *";
          color: $secondary-red;
        }
      }
      p {
        @include espace-rh-components-input-help-text;
        color: $plainText-onLight-lighter;
        margin-top: 0.25rem;
        margin-bottom: 1rem;
      }
      .Dropdown {
        display: flex;
        justify-content: flex-end;
        div:first-child {
          width: 100%;
          margin-right: 1.0625rem;
        }
        a {
          display: flex;
        }

        button > div:first-child {
          width: 22px;
          margin-left: auto;
          margin-right: 0;
        }
      }
      .alert {
        margin-bottom: $skui-spacing-s;
      }
      .Checkbox {
        margin: 1.25rem 0rem 0.5rem;

        div:first-child {
          margin-bottom: 0.2rem;
        }
      }
    }

    .showQuestions {
      color: $mainColor;
      cursor: pointer;
      font-size: 14px;
      text-decoration: underline;
    }

    span.info {
      color: $smallTextColor;
      font-size: 13px;
      margin-top: 6px;
      margin-bottom: 18px;
    }

    span.description {
      font-size: 13px;
      position: relative;
      top: -5px;
      text-align: justify;
      color: $paragraphColor;
      opacity: 0.8;
    }

    .managerOnlyTooltip {
      max-width: 400px;
    }
  }

  .DatesStep {
    flex: 1;
    width: 100%;

    section.option {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;

      label {
        @include espace-rh-components-input-label;
        color: $plainText-onLight-lighter;
        &.requiredField:after {
          content: " *";
          color: $secondary-red;
        }
      }

      span.info {
        @include espace-rh-components-input-help-text;
        color: $plainText-onLight-lighter;
        margin-top: 0.25rem;
      }
    }

    section.campaignType {
      display: flex;
      margin-bottom: 24px;
      font-size: 15px;
      > .radio {
        padding-top: 2px;
        display: flex;
        flex-direction: row;
        align-items: center;
        > div {
          padding-left: 17px;
          margin-bottom: 0;
        }
      }
    }
  }

  .UsersStep {
    flex: 1;
    min-width: 48rem;
    div[class^="content"] {
      max-height: none;
    }
    width: 100%;

    .searchForm {
      display: flex;
      flex-direction: column;

      .searchInput {
        display: flex;
        flex-direction: column;

        div[class^="results"] {
          height: auto;
          position: initial;
          width: 100%;
        }
      }
    }
  }

  .MailingSettingsCard {
    display: flex;
    flex: 1;
    width: 100%;

    .MailingSettingsWrapper {
      display: flex;
      flex-direction: row;

      @media (max-width: 574px) {
        display: flex;
        flex-direction: column;
      }

      .notificationsWrapper {
        display: flex;
        flex-direction: column;
        width: 400px;

        label {
          padding: 0 0 0.25rem;
        }

        .notificationsToggleWrapper {
          display: flex;
          flex-direction: row;
          align-items: "center";
          padding: 0.25rem 0;

          .notificationsToggle {
            margin: 0;
          }
          label {
            padding: 0;
          }
        }
      }
      .remindersWrapper {
        display: flex;
        flex-direction: column;
        width: 400px;
        label {
          padding: 0 0 0.25rem;
        }

        .remindersToggleWrapper {
          display: flex;
          flex-direction: row;
          align-items: "center";
          padding: 0.25rem 0;

          .remindersToggle {
            margin: 0;
          }
          label {
            padding: 0;
          }
        }
      }
    }

    .warningContainer {
      display: flex;
      margin-bottom: 16px;

      .warningText {
        color: #b74d01;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        margin-left: 8px;
      }
    }
  }

  .FinalStepCard {
    display: flex;
    flex: 1;
    width: 100%;
    max-height: 45rem;

    > div {
      max-height: 45rem;

      .table {
        display: flex;
        flex-direction: column;
        > :not(:first-child) {
          padding: 1.75rem 0 0;
        }
        .row {
          display: flex;
          flex-direction: row;

          .element {
            display: flex;
            flex-direction: column;
            flex: 1;
          }

          &:last-of-type {
            border-bottom: none;
          }

          .title {
            @include espace-rh-components-input-label;
            color: $plainText-onLight-lighter;
          }

          .value {
            @include espace-rh-components-input-value;
            color: $plainText-onLight-default;
            padding-top: 0.5rem;
          }
        }
      }
    }
  }

  .FinalStepAlert {
    margin-top: 1rem;

    .alert {
      margin: 1rem 0;
      &:first-of-type {
        margin-top: 0;
      }
    }

    a.warningDownload {
      color: $status-warning-darker !important;
    }

    .alertButton {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 0.25rem;
      a {
        @include espace-rh-body1-regular;
        text-decoration: none;
        border-radius: 0.25rem;
        padding: 0 $skui-spacing-xs;
        border: 1px solid $action-onLight-darkest;
        color: $action-onLight-darkest;
      }
    }
  }

  .OptionsStep {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    max-height: 45rem;

    > div {
      max-height: 45rem;

      // > :last-child > :not(:last-child) {
      //   margin-bottom: 2rem;
      // }
      .optionsGroup {
        margin-bottom: 2rem;
        .groupTitle {
          @include espace-rh-caption-regular;
          margin-bottom: 1rem;
        }
        .option {
          margin-bottom: 2rem;
          .switch {
            display: flex;
          }
          .labelSwitch {
            min-width: 399px;
            flex: 0;
          }

          .description {
            @include espace-rh-components-input-help-text;
            padding-left: 2.75rem;
            color: $plainText-onLight-lighter;
          }
        }
      }
      .confidentialGroup {
        .groupTitle {
          @include espace-rh-caption-regular;
          margin-bottom: 1rem;
        }
        .option {
          .switch {
            display: flex;
          }
          .labelSwitch {
            min-width: 399px;
            flex: 0;
          }

          .description {
            @include espace-rh-components-input-help-text;
            padding-left: 2.75rem;
            color: $plainText-onLight-lighter;
          }
        }
      }
      .OptionsStepWarning {
        margin-top: 0.5rem;
      }
    }
  }

  .AutomationStep {
    flex: 1;
    min-width: 38rem;
    align-self: center;
    div[class^="content"] {
      max-height: none;
    }
    margin-bottom: $skui-spacing-s;

    .title {
      @include espace-rh-h5;
    }

    .loading {
      height: 6.5rem;
    }

    .filters {
      padding-top: $skui-spacing-s;
      align-items: flex-start;
      width: 100%;
    }
    
    .description {
      @include espace-rh-body1-regular;
    }

    .popnow {
      display: flex;
      flex-direction: row;

      p {
        align-self: center;
        @include espace-rh-caption-regular;
        color: $plainText-onLight-lighter;
      }

      
    }
  }

  .Trigger {
    flex: 1;
    min-width: 38rem;
    align-self: center;
    margin-bottom: $skui-spacing-s;

    .title {
      @include espace-rh-h5;
    }

    .block1 {
      padding-top: $skui-spacing-s;
      justify-content: space-between;
      gap: $skui-spacing-m;
      .triggerEvent {
        padding-top: $skui-spacing-xs;
      }

      .number {
        max-width: 5rem;
        min-width: 5rem;
        margin-right: $skui-spacing-xxs;
      }

      .select {
        max-width: 12rem;
        min-width: 8rem;
        margin-right: $skui-spacing-xxs;
        div {
          max-width: 12rem;
        }
      }

      .selectDirection {
        max-width: 12rem;
        min-width: 8rem;
        font-weight: bold;
        div {
          max-width: 12rem;
        }
      }
    }

    .block2 {
      padding-top: $skui-spacing-s;
      .number {
        max-width: 5rem;
      }

      p {
        padding-left: $skui-spacing-xs;
        padding-top: $skui-spacing-xs;
      }
    }

    .caption {
      align-self: center;
      @include espace-rh-caption-regular;
      color: $plainText-onLight-lighter;
    }
  }

  .Info {
    min-width: 38rem;
    align-self: center;
    @include espace-rh-components-input-help-text;
    color: $plainText-onLight-lighter;
  }
}

.popover {
  padding: $skui-spacing-xs;
  gap: $skui-spacing-xs;

  background: $surface-light;
  border: 1px solid rgba(193, 207, 220, 0.3);
  border-radius: $skui-spacing-xxs;

  .popoverContent {
    display: flex;
    flex-direction: row;
    padding: $skui-spacing-xxs;
    gap: $skui-spacing-xs;
    width: 100%;

    p {
      padding-top: $skui-spacing-xxs;
    }
  }
}
