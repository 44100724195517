@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.pieLabel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > .name {
    @include espace-rh-caption-bold;
    color: $plainText-onLight-lighter;
  }
  > .value {
    @include espace-rh-body1-bold;
    color: $plainText-onLight-default;
  }
}

.defaultTooltip {
  padding: $skui-spacing-s;
  border-radius: $skui-spacing-s;
  background-color: $surface-light;

  opacity: 0.9;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  border-radius: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: $skui-spacing-xs;

  > .name {
    @include espace-rh-caption-bold;
    color: $plainText-onLight-lighter;
  }
  > .value {
    @include espace-rh-h3;
    color: $plainText-onLight-default;
  }
}
