@import "../../assets/designSystem";

.TimelineRoot {
  letter-spacing: initial !important;
  &.espace-collab {
    @include espace-collab-font;
  }
  &.espace-rh {
    @include espace-rh-font;
  }
}

.Timeline {
  color: $plainText-onLight-lighter;
  &.darkMode {
    color: $plainText-onDark-darker;
    p.S {
      color: $plainText-onDark-default;
    }
  }

  p.S {
    color: $plainText-onLight-default;
  }

  h6.noTitle {
    line-height: 2.25rem !important;
  }

  &.espace-collab {
    h3,
    .timePeriod {
      @include espace-collab-h3;
      &.S {
        @include espace-collab-caption-regular;
      }
    }
    h6 {
      @include espace-collab-h6;
    }
    p {
      @include espace-collab-caption-regular;
    }
  }

  &.espace-rh {
    h3,
    .timePeriod {
      @include espace-rh-h3;
      &.S {
        @include espace-rh-caption-regular;
      }
    }
    h6 {
      @include espace-rh-h6;
    }
    p {
      @include espace-rh-caption-regular;
    }
  }
}
