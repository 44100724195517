@import "../../assets/colors";

.TextArea {
  position: relative;
  align-items: center;
  width: 100%;

  > textarea {
    box-sizing: border-box;
    border-radius: 15px;
    border: 1px solid $pale-lilac;
    background-color: $light-grey;
    padding: 0.875rem;
    resize: none;
    outline: none;
    width: 100%;
    overflow: auto;
    font-size: 0.875rem;
    line-height: 1.21;
    letter-spacing: 0.15px;
    color: $b-w-black;

    &::placeholder {
      color: $bluey-grey;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &:focus {
      border-color: $primary-blue;
      background-color: white;
    }

    &.hasError {
      border-color: $secondary-red;
    }

    &.readOnly {
      cursor: not-allowed;
      &:focus {
        border-color: $pale-lilac;
        background-color: $light-grey;
      }
    }
  }

  span.errorMessage {
    position: absolute;
    bottom: 0.875rem;
    right: 0.875rem;
    font-size: 0.75rem;
    color: $secondary-red;
  }
}
