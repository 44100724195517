@import "../../assets/colors";

.DropDownMenu {
  padding-top: 2px;
  .Menu {
    border: 1px solid $pale-lilac;
    background: #fff;
    z-index: 1;
    border-radius: 5px;
    box-shadow: 0 0 15px -10px rgba(0, 0, 0, 0.9);
    user-select: none;

    > div {
      padding: 8px 45px 8px 15px;
      border-bottom: 1px solid $pale-lilac;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;

      &:hover {
        background: $pale-lilac;
        cursor: pointer;
      }

      &:last-of-type {
        border-bottom: none;
      }

      > svg {
        margin-right: 10px;
      }
    }

    &.topTooltip {
      top: -40px;
    }
  }
}
