.badge {
  font-size: 10px;
  font-weight: 700;
  text-align: center;
  padding: 5px;
  border-radius: 10px;
}

.yellow {
  background: #fef5ed;
  color: #f2994a;
}

.green {
  background: #e9f7ef;
  color: #0daf85;
}

.gray {
  background: #f1f1f1;
  color: #7c7c7c;
}
