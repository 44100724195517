@import "uiAssets/ColorsVars.scss";

.ColumnMover {
  display: flex;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 999;

  > .columnOverlay {
    flex-grow: 0;
    flex-shrink: 0;
    left: 40px;
    position: relative;

    > div {
      height: 100%;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 50%;

      &:hover {
        opacity: 1;
      }
    }

    &.nonMovable > div:hover {
      opacity: 1;
    }

    > div:first-of-type {
      box-shadow: inset 2px 0 0 $blue;
      left: -1px;
    }

    > div:last-of-type {
      box-shadow: inset -2px 0 0 $blue;
      right: -1px;
    }
  }

  > .fakeColumn {
    margin-left: 15px;
    opacity: 0.3;
    pointer-events: none;
    position: absolute;
    z-index: 999;

    > h3 {
      align-items: center;
      background: $gradientBlue;
      border: none;
      border-bottom: 1px solid #ddd;
      color: #fff;
      display: flex;
      height: 43px;
      opacity: 1;
      overflow: hidden;
      padding: 5px 8px;

      > span {
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      > svg {
        margin-right: 8px;
      }
    }

    > div {
      background: rgba(150, 150, 150, 0.3);
      height: 100%;
      position: relative;
      width: 100%;
    }
  }
}
