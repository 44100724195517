.Warnings {
  padding: 16px;

  p {
    padding: 8px;
  }

  ul {
    padding: 8px 24px;

    > li {
      padding-bottom: 8px;
      font-size: 14px;
    }
  }

  .checkbox {
    padding-top: 8px;

    > span {
      font-size: 13px;
      color: #000000;
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 8px;

    > button {
      height: 40px;
    }
  }
}
