@import "@skillup/ui";

.container {
  display: grid;
  grid-template-columns: 3.75rem 1fr; 
  grid-template-rows: 1fr 3.75rem; 
  gap: 1rem;
  grid-template-areas:
    "yAxis  matrix"
    "axisSwap xAxis";
  width: 100%;
}

.gridViewContainer {
  height: 100%;
}

.axis {
  display: flex;
  align-items: center;
  justify-content: center;

  .arrows {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;

    .labels {
      cursor: pointer;
      position: absolute;
      top: 50%;
      left: 50%;
      width: max-content;
      transform-origin: 0 0;
    }
  }
}

.yAxis {
  grid-area: yAxis;
  .arrowLeft {
    flex-direction: column-reverse;
    .label {
      transform: rotate(-90deg) translate(-50%, -50%);
    }
    .arrowBody {
      width: 2rem;
      background: linear-gradient(0deg, #DFF3FC 0%, #BFDDEB 100%);
      border-radius: 0 0 4px 4px;
    }
    .arrowEnd {
      width: 0;
      height: 0;
      border-left: 28px solid transparent;
      border-right: 28px solid transparent;
      border-bottom: 25px solid #BFDDEB;
    }
  }
}

.xAxis {
  grid-area: xAxis;
  .arrowBottom {
    .label {
      transform: translate(-50%, -50%);
    }
    .arrowBody {
      height: 2rem;
      background: linear-gradient(90deg, #DFF3FC 0%, #BFDDEB 100%);
      border-radius: 4px 0 0 4px;
    }
    .arrowEnd {
      width: 0;
      height: 0;
      border-top: 28px solid transparent;
      border-bottom: 28px solid transparent;
      border-left: 25px solid #BFDDEB;
    }
  }
}

.yScale {
  grid-area: yScale;
  display: flex;
  flex-direction: column;
}

.yScaleLabelContainer {
  flex: 1;
  display: flex;
  align-items: center;

  .yScaleLabel {
    @include espace-rh-caption-bold;
    color: $plainText-onLight-lighter;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3; 
    -webkit-box-orient: vertical;
  }
}

.xScale {
  grid-area: xScale;
  display: flex;
  flex-direction: row;
}

.xScaleLabelContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  .xScaleLabel {
    @include espace-rh-caption-bold;
    color: $plainText-onLight-lighter;
    
    text-align: center;
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3; 
    max-height: calc(3 * 1.2em); /* Assuming line height is 1.2 */
    line-height: 1.2em; /* Adjust line height as needed */
    text-overflow: ellipsis;
  }
}

.axisSwap {
  grid-area: axisSwap;

  span {
    color: $plainText-onLight-lighter;
    width: 18px;
    height: 14px;
  }
}

.loader {
  grid-area: matrix;
  gap: $skui-spacing-xs;
}

.matrix {
  grid-area: matrix;
  gap: $skui-spacing-xs;
}

.matrixCell {
  border-radius: $skui-spacing-xs;
  border: solid 2px transparent;
  gap: $skui-spacing-xxs;
  padding: $skui-spacing-s;

  &:hover {
    cursor: pointer;
    border: solid 2px $action-onDark-default;
  }
}

.detailedViewMatrixCell {
  height: 100%;
  min-height: 6rem;
}

.paragraph {
    width: 50%;
}

@media screen and (max-width: 768px) {
  .paragraph {
    width: 100%;
  }
}

.underMedian {
  background-color: #C0E2F1;
}

.overMedian {
  background-color: #0088C7;
}

.p10 {
  background-color: #FFFFFF;
  border: solid 2px $border-onLight;
  border-radius: $skui-spacing-xs;
}

.p90 {
  background-color: #0A4D71;
}

.selected {
  border: solid 2px $action-onDark-default;
}

.noSelected {
  opacity: 30%;
}

.talentMatrixDrawer {
  width: 320px;
  background-color: $surface-dark-darker;

  .talentMatrixDrawerContainer {
    background-color: $surface-light-darker;
    height: 100%;
    border-radius: $skui-spacing-xs;
    position: relative;

    .talentMatrixDrawerCloseButton {
      position: absolute;
      right: $skui-spacing-s;
    }
  }
}
