@import "@skillup/ui";

.modal {
  width: 100%;
  max-width: 43.75rem;

  .inputSection {
    margin-top: $skui-spacing-s;

    .DSComponent {
      margin: $skui-spacing-xs 0;
    }
  }
}
