$mainColor: #1f6389;
$mainColorD: #0e2d3e;
$mainColorL: #f5f8fc;
$contrastColor: #ffd042;

$info: #378ad4;
$infoD: #134979;
$infoL: #96bde0;
$success: #92c123;
$successD: #4d6908;
$successL: #c9ec74;
$warning: #f39400;
$warningD: #8c590b;
$warningL: #ffd088;
$error: #de4343;
$errorD: #942020;
$errorL: #f9a1a1;

$headerAltColor: #4c4c4c;
$highlightColor: #ffd042;
$primaryColor: #2099cc;
$secondaryColor: #40c4f4;
$smallTextColor: #aaa;
$textColor: #333;
$paragraphColor: #2e5062;

$borderColor: #cbcbcb;
$borderColorAlt: #e5e5e5;
$borderColorLight: #f5f5f5;
$borderColorLightAlt: #eaeaea;

$scoreColor: #78bb5a;
$altScoreColor: #8c8c8c;

$blue: #2782ff;
$blueDarker: #2b71f6;
$blueAlt: #00b4ff;
$yellow: #ffd042;

$bigBoxShadow: rgba(40, 139, 255, 0.3) 0 0 20px;
$baseBoxShadow: rgba(40, 139, 255, 0.3) 0 0 10px;
$buttonBoxShadow: rgba(40, 139, 255, 0.3) 0 2px 5px;
$buttonHoverBoxShadow: rgba(40, 139, 255, 0.3) 0 3px 7px;

$dataTableActiveLine: #f6f6f6;
$dataTableRegularBorder: #e9e9e9;
$cardBackground: #fafafa;
$containerShadow: rgba(0, 0, 0, 0.1) 0 0 6px;

$gradientBlue: linear-gradient(45deg, #06f, #00b4ff);
$gradientMain: linear-gradient(45deg, #06f, #00b4ff);
$gradientPink: linear-gradient(45deg, #9600c7, #fb11d3);
$gradientRed: linear-gradient(45deg, #9e0000, #ff3636);
$gradientGreen: linear-gradient(45deg, #88a000, #a0e400);
$gradientOrange: linear-gradient(45deg, #c75d00, #ff9b00);
$gradientYellow: linear-gradient(45deg, rgb(249, 172, 0), rgb(255, 214, 90));
