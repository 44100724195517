@import "uiAssets/ColorsVars.scss";

.Summary {
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 5px 10px 0 rgba(40, 139, 255, 0.19);
  margin: 0 15px 20px;

  > div:first-of-type {
    position: relative;
    padding: 15px 15px 10px 15px;
    > h3 {
      color: $blue;
      font-size: 16px;
      font-weight: 600;
      max-width: 205px;
    }

    > span {
      font-size: 14px;
      font-style: italic;
      padding-left: 10px;
    }

    > div {
      position: absolute;
      display: flex;
      // top: 0;
      // right: 0;
      width: 165px;
      height: 30px;
      top: 15px;
      right: 15px;
      background: #eaeaea;
      color: $infoD;
      font-weight: 600;
      border-radius: 8px;
      padding: 5px 10px;
      font-size: 13px;
      justify-content: center;
      align-items: center;

      &.warning {
        color: #4f3b20;
        background-color: #ffefcc;

        > span {
          margin-left: 7px;
        }
      }

      &.success {
        background-color: #bde48f;
        color: #345a18;
      }
    }
  }

  > div:nth-of-type(2) {
    padding: 0 15px 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      display: flex;
      > span.tag {
        border-radius: 3px;
        font-weight: 600;
        padding: 5px 10px;
        font-size: 13px;
        height: 28px;
        margin-right: 10px;
      }
      > span.tag.type {
        background-color: #bde48f;
        color: #345a18;
      }

      > span.tag.mode {
        background-color: #eaeaea;
        color: #777;
        border-radius: 15px;
      }

      > span.duration {
        font-size: 14px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        color: #777;
        font-weight: 500;
      }
    }
    > span:last-of-type {
      font-size: 20px;
      color: #888;
    }
  }

  > div:nth-of-type(3) {
    padding: 0 15px 10px 18px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    > span:first-of-type {
      margin-right: 4px;
    }
  }

  > div.city,
  > div.room,
  > a.link {
    font-size: 14px;
    padding: 0 15px 5px 20px;
  }

  > a.link {
    color: inherit;
    line-height: 2;
    &:hover {
      text-decoration: none;
    }
  }

  > div.areas {
    padding: 0 15px 5px 20px;
    font-size: 14px;
    word-wrap: break-word;
    > :global(.display-text-group) {
      display: inline;
      :global(.read-more-button) {
        margin-left: 4px;
        display: inline-block;
        color: $info;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .buttonsContainer {
    padding: 10px 15px 15px 15px;
    display: flex;
    justify-content: space-between;
    position: relative;
    > .showMore {
      align-items: center;
      border-radius: 4px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
      cursor: pointer;
      display: flex;
      justify-content: center;
      margin-right: 5px;
      padding: 6px 10px;
      transition: background-color 300ms ease-in-out;
      z-index: 6;

      span {
        color: #444;
        display: block;
        font-size: 12px;
        margin-right: 10px;
      }

      &:hover,
      &.clicked {
        background-color: $blue;

        span {
          color: #fff;
        }

        > svg {
          stroke: #fff !important;
        }
      }

      &.clicked {
        > svg {
          transform: rotate(180deg);
        }
      }
    }

    > .background {
      background: transparent;
      height: 100vh;
      left: 0;
      position: fixed;
      top: 0;
      width: 100vw;
      z-index: 3;
    }
  }
}
