@import "@skillup/ui";

.trackingSection {
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  .content {
    color: $plainText-onLight-default;

    .documentName {
      margin-top: $skui-spacing-xs;
      border: none;
      border-radius: 0;
      padding: 0;
    }
  }
}
