@import "../../assets/designSystem";

.ProgressBar {
  width: 100%;
  .section {
    display: flex;
    align-items: center;
    position: relative;

    .value,
    .percentage {
      overflow: hidden;
      min-width: 2.5rem;
      font-size: 0.8rem;
      color: $plainText-onLight-lighter;
      margin-left: $skui-spacing-xs;
    }

    &.list {
      margin-bottom: $skui-spacing-xs;
      .col {
        display: flex;
        flex-direction: column;
        &.content {
          flex-grow: 1;
        }
      }
      .row {
        height: 1.5rem;
        display: flex;
        align-items: center;
//        justify-content: center;
        margin-bottom: $skui-spacing-xs;
      }

      label {
        color: $plainText-onLight-default;
        white-space: nowrap;
      }
      .value,
      .percentage {
        text-align: center;
      }
    }

    &.block {
      display: block;
      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: $skui-spacing-xxs;
        margin-top: $skui-spacing-xxs;
        .label {
          margin-right: auto;
        }
      }
      .bar {
        margin-bottom: $skui-spacing-xs;
      }
      .value,
      .percentage {
        text-align: right;
      }
    }

    &.segmented {
      flex-direction: column;
      .bar {
        height: 1rem;
        border-radius: 0.5rem;
        .tooltipWrapper {
          position: absolute;
          width: 100%;
          display: flex;
          .tooltip {
            height: 1rem;
            margin: 0;
          }
        }
        &.thin {
          height: 0.5rem;
        }
      }
      .legends {
        width: 100%;
        margin-top: $skui-spacing-xs;
        &.grid {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          @media (min-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
          }
          @media (min-width: 1024px) {
            grid-template-columns: repeat(3, 1fr);
          }
        }
        &.column {
          display: flex;
          flex-direction: column;
        }
        .legend {
          display: flex;
          align-items: center;
          margin-bottom: $skui-spacing-xxs;
          margin-right: $skui-spacing-s;
          .bullet {
            width: 0.67rem;
            min-width: 0.67rem;
            height: 0.67rem;
            min-height: 0.67rem;
            border-radius: 50%;
            margin-right: 0.4rem;
            &.blue {
              background-color: #60c3d7;
            }
            &.yellow {
              background-color: #ffbb00;
            }
            &.red {
              background-color: #f4645d;
            }
            &.green {
              background-color: #99cd32;
            }
            &.pink {
              background-color: #fe9fd8;
            }
            &.orange {
              background-color: #ff6600;
            }
            &.grey {
              background-color: $greyscale-light;
            }
          }
        }
      }
    }

    .bar {
      height: 0.5rem;
      border-radius: 0.25rem;
      flex-grow: 1;
      width: 100%;
      display: flex;
      overflow: hidden;
      background-color: $greyscale-lighter;
      &.darkMode {
        background-color: $greyscale-darker;
      }
      &.border {
        border: 1px solid $greyscale-light;
      }
      .subBar {
        &.gradiantBlue {
          background: linear-gradient(94.05deg, #5fc3d7 0%, #0088c6 100%);
        }
        &.blue {
          background-color: #60c3d7;
        }
        &.yellow {
          background-color: #ffbb00;
        }
        &.red {
          background-color: #f4645d;
        }
        &.green {
          background-color: #99cd32;
        }
        &.pink {
          background-color: #fe9fd8;
        }
        &.orange {
          background-color: #ff6600;
        }
        &.grey {
          background-color: $greyscale-light;
        }
        &.success {
          background-color: $status-success-default;
        }
        &.successLight {
          background-color: $status-success-lighter;
        }
        &.neutral {
          background-color: $greyscale-medium;
        }
      }
    }
  }

  &.espace-rh {
    @include espace-rh-body1-regular;
    .section {
      &.list {
        .value,
        .percentage {
          @include espace-rh-body2-regular;
        }
      }
      &.segmented .legends .legend {
        @include espace-rh-caption-regular;
      }
    }
  }
  &.espace-collab {
    @include espace-collab-body1-regular;
    .section {
      &.list {
        .value,
        .percentage {
          @include espace-collab-body2-regular;
        }
      }
      &.segmented .legends .legend {
        @include espace-collab-caption-regular;
      }
    }
  }
}
