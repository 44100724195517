@import "@skillup/ui";
@import "uiAssets/ColorsVars.scss";

.uploadUserDatabases {
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1;

  .content {
    padding: 29px 92px 58px 92px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: visible;

    > h2 {
      font-size: 16px;
      line-height: 19px;
      color: $b-w-black;
      margin: 40px 0 15px 0;
    }

    .list {
      overflow-y: auto;
      overflow-x: visible;
    }
  }
}

.confirmCancelation {
  padding: 20px;

  > .actions {
    width: 100%;
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;
  }
}

.cancelCell {
  color: $blue;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
    text-underline-offset: 2px;
  }
}
