@import "../../../assets/colors";
@import "../../../assets/mixins";

.Reviews {
  align-items: center;

  .stars {
    position: relative;
    left: -0.25rem;
  }

  &.small {
    span.rating {
      color: $slate;
      font-size: 0.75rem;
      font-weight: 500;
      margin-top: 1px;
      margin-left: 0.1875rem;
    }

    span.count {
      margin-left: 0.1875rem;
      color: $slate;
    }
  }

  span.count {
    font-size: 0.75rem;
    color: $b-w-black;
    margin-top: 1px;
  }
}
