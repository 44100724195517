@import "../../../assets/mixins";
@import "../../../assets/designSystem";

.TrainingProgramCard {
  display: flex;
  background: $surface-light;

  border: 1px solid $border-onLight;
  border-radius: 4px;
  box-shadow: $elevation-nonActionableCard;
  &.actionnable {
    box-shadow: $elevation-actionableCard;
    &.selected {
      box-shadow: none;
      outline: 2px solid $action-onLight-default;
      outline-offset: 2px;
    }
  }

  .selection {
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    input {
      appearance: none;
      height: $skui-spacing-xs;
      width: $skui-spacing-xs;
      cursor: pointer;
      background: $greyscale-lighter
        url('data:image/svg+xml,<svg width="9" height="9" viewBox="0 0 9 9" xmlns="http://www.w3.org/2000/svg" fill="none"><circle cx="4.49989" cy="4.50001" r="3.55556"/></svg>')
        no-repeat center center;
      outline: 2px solid $border-on-light;
      outline-offset: 2px;
      border-radius: 1rem;
    }
    input:checked {
      background-color: $decoration-blue-base;
      outline: 2px solid $decoration-blue-base;
    }
    @include isMobile {
      display: none;
    }
  }
  .body {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: $skui-spacing-s;
    gap: $skui-spacing-xs;

    .header {
      display: flex;
      justify-content: space-between;
      h1 {
        color: $plainText-onLight-default;
        margin-right: $skui-spacing-xs;
        max-height: 3.375rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .rating {
      margin-bottom: $skui-spacing-s;
    }

    .footer {
      display: flex;
      justify-content: space-between;
      @include isMobile {
        flex-direction: column;
      }
      .chips {
        display: flex;
        flex-wrap: wrap;
        gap: $skui-spacing-xs;
        @include isMobile {
          margin-bottom: $skui-spacing-s;
        }
      }
    }
  }

  &.espace-rh {
    h1 {
      @include espace-rh-h5;
    }
  }
  &.espace-collab {
    h1 {
      @include espace-collab-h5;
    }
  }
}
