@import "uiAssets/ColorsVars.scss";

section.list {
  border-radius: 7px;
  box-shadow: 0 0 0 0.0625rem rgba(0, 0, 0, 0.02), 0 0.0625rem 0.25rem 0.0625rem rgba(0, 0, 0, 0.06);
  margin: 20px auto;
  width: calc(100% - 40px);
  user-select: none;

  > header {
    align-items: center;
    background: #f5f5f5;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    font-size: 15px;
    font-weight: 600;
    user-select: none;

    > h2 {
      align-items: center;
      color: #4c4c4c;
      display: flex;

      > button {
        padding-left: 5px;
        margin-right: 10px;
      }
    }

    button {
      align-items: center;
      background: none;
      border: none;
      display: flex;
      justify-content: center;
      > svg {
        display: block;
      }
    }

    > form {
      align-items: center;
      display: flex;
      flex-grow: 1;
      padding: 0 10px 0 18px;

      > input {
        background: none;
        border: none;
        border-bottom: 1px dashed $blue;
        color: $blue;
        flex-grow: 1;
        font-size: 20px;
        font-weight: 300;
        margin-right: 10px;
      }

      > button {
        padding: 15px 10px;
      }
    }

    > div {
      display: flex;

      > button {
        padding: 0 10px;
      }
    }
  }

  > main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
    background: #fff;
    border: 1px solid #fff;
    border-top: none;

    > div {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
    }

    > footer {
      align-items: center;
      display: flex;
      padding: 10px;
      position: relative;
      width: 100%;

      > input {
        border: none;
        border-bottom: 1px solid $blue;
        color: $blue;
        flex-grow: 1;
        font-size: 16px;
      }

      > div {
        background: #fff;
        border-radius: 7px;
        box-shadow: $bigBoxShadow;
        overflow: hidden;
        position: absolute;
        top: 40px;
        width: calc(100% - 20px);
        z-index: 999;

        > button {
          background: none;
          border: none;
          border-bottom: 1px solid #ddd;
          display: block;
          overflow: hidden;
          padding: 5px 8px;
          text-align: left;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;

          &:last-of-type {
            border-bottom: none;
          }
        }
      }
    }
  }
}
