@import "uiAssets/ColorsVars.scss";

.BookingModal {
  button.closeModalButton {
    align-items: center;
    background: none;
    border: none;
    display: flex;
    justify-content: center;
    margin: 0 !important;
    padding: 15px 20px;
    position: absolute;
    right: 0;
    top: 10;
    transition-duration: 0.5s;
  }
  button.closeModalButton:hover {
    transform: scale(1.3) rotate(-180deg);
  }
  h2 {
    color: #2782ff;
    display: block;
    font-size: 24px;
    padding: 30px 0 20px;
    text-align: center;
  }
  form.addTrainee {
    padding: 15px 40px;
  }
  button.submitButton {
    margin: 30px auto !important;
  }
  .topBorder {
    background: linear-gradient(45deg, #06f, #00b4ff);
    height: 10px;
  }
}
