@import "@skillup/ui";

.infos {
  text-align: center;
  border-radius: 4px;
  height: 4.125rem;
  width: 100%;
  background-color: $greyscale-lighter;
  margin-top: $skui-spacing-xs;
  padding: 0.75rem 0;
  p {
    color: $text-on-light-lighter;
    font-size: 12px;
  }
  .users {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.45rem;
    p {
      margin-left: 0.5rem;
    }
  }
}
