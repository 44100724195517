@import "../../assets/colors";
@import "../../assets/mixins";

.Badge {
  border-radius: 50%;

  span.value {
    font-size: 0.767rem;
    font-weight: bold;
    letter-spacing: 0.06px;
    color: white;
  }
}
