@import "@skillup/ui";

.ApplicationError {
  padding: $skui-spacing-m;
  gap: $skui-spacing-l;
  width: 100%;

  p {
    @include espace-rh-body1-regular;
    color: $plainText-onLight-default;
    text-align: center;
    width: 475px;
  }
}
