@import "@skillup/ui";

.container {
  padding: $skui-spacing-s;
  width: 100%;
  min-height: 100%;

  .blockTable {
    margin-bottom: $skui-spacing-xxl;
  }

  h4 {
    @include espace-rh-h4;
    color: $plainText-onLight-lighter;
    margin-bottom: $skui-spacing-xs;
  }
}

.selectFilter {
  margin-left: $skui-spacing-xxs;
}
