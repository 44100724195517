@import "uiAssets/ColorsVars.scss";

.CatalogRoot {
  overflow: hidden !important;
  // specific case that we don't need overflow from the childrenLayout of DSLayout, to fix double scroll and permeit onClick scrolling

  .catalogLayout {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    flex: 1;

    .scrollableWrapper {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: auto;

      > .loaderContainer {
        display: flex;
        min-height: 500px;
      }

      > div {
        background: #f0f0f0;
        border-radius: 7px;
        box-shadow: $baseBoxShadow;
        display: flex;
        flex-wrap: wrap;
        margin: 20px;

        @supports (display: grid) {
          display: grid;
          grid-gap: 1px;
          grid-template-columns: 1fr 1fr;

          @media (min-width: 850px) {
            grid-template-columns: 1fr 1fr 1fr;
          }

          @media (min-width: 1250px) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
          }

          @media (min-width: 1650px) {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          }

          @media (min-width: 1950px) {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
          }

          @media (min-width: 2250px) {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
          }

          @media (min-width: 2550px) {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
          }

          @media (min-width: 2850px) {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
          }
        }

        > button {
          align-items: center;
          background: #fff;
          color: #000;
          display: flex;
          border: 0;
          flex-basis: 22%;
          flex-direction: column;
          flex-grow: 1;
          justify-content: center;
          padding: 20px 13px 7px;
          text-align: center;
          text-decoration: none;
          transition: background-color 0.2s ease-out;
          cursor: pointer;

          > span {
            display: block;
          }

          > span:first-of-type {
            font-size: 16px;
            line-height: 1.1;
            margin-bottom: 5px;
          }

          > .trainingCount {
            color: #999;
            font-size: 13px;
          }

          > span:last-of-type {
            color: $blue;
            font-size: 12px;
            opacity: 0;
            overflow: hidden;
            padding-top: 3px;
            transform: translateX(-20%);
            transition: all 0.15s ease-in-out;

            > svg {
              margin-left: 5px;
            }
          }

          &:hover {
            background: #f0f0f0;

            > span:last-of-type {
              opacity: 1;
              transform: translateX(0);
            }
          }
        }
      }

      > section {
        background: #fff;
        border-radius: 7px;
        box-shadow: $bigBoxShadow;
        display: flex;
        flex-direction: column;
        margin: 20px;
        padding: 15px 19px;

        @media (min-width: 600px) {
          flex-direction: row;
        }

        > div:first-of-type {
          flex-grow: 0;
          flex-shrink: 0;
          margin-bottom: 30px;
          text-align: center;
          width: 100%;

          @media (min-width: 600px) {
            margin-bottom: 0;
            margin-right: 15px;
            text-align: left;
            width: 200px;
          }

          > h2 {
            color: $blue;
            font-size: 16px;
            text-transform: uppercase;
          }

          > p {
            color: #666;
            font-size: 13px;
            margin-bottom: 20px;
          }
        }

        > div:last-of-type {
          column-count: 1;
          flex-grow: 1;

          @media (min-width: 800px) {
            column-count: 2;
          }

          @media (min-width: 1300px) {
            column-count: 3;
          }

          @media (min-width: 1700px) {
            column-count: 4;
          }

          @media (min-width: 2400px) {
            column-count: 5;
          }

          > div {
            display: inline-block;
            width: 100%;

            > a {
              color: $blue;
              display: block;
              padding: 3px 5px;
              text-decoration: none;
              transition: color 0.2s ease-out, background 0.2s ease-out;

              &:hover {
                background: $blue;
                color: #fff;
              }
            }

            > div {
              padding: 0 0 20px 10px;

              > a {
                color: #666;
                display: block;
                font-size: 12px;
                padding: 3px 5px;
                text-decoration: none;
                transition: color 0.2s ease-out, background 0.2s ease-out;

                &:hover {
                  background: #999;
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
}
