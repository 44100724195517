@import "@skillup/ui";
@import "uiAssets/ColorsVars.scss";

.creationView {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.25rem;
  height: 100vh;
  width: 100vw;
}
.actions {
  background: #eee;
  border-top: 1px solid #ccc;
  display: flex;
  padding: 10px;
  align-items: center;

  .spacer {
    flex-grow: 1;
  }

  .info {
    background: #fff;
    border-radius: 4px;
    margin-right: 10px;
    padding: 5px;
    width: 320px;
    font-size: 12px;
  }
}
