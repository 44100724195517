@import "@skillup/ui";

.CompanySettings {
  display: flex;
  flex-direction: column;
  background: $surface-light-darker;
  flex: 1;
  overflow: scroll;

  .scrollWrapper {
    display: flex;
    flex-direction: row;
    flex: 1;

    .mainCompanyWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: $skui-spacing-l;
      background-color: $surface-light;

      &:not(.withChildren) {
        flex: 1;
      }

      &.withChildren {
        box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.1);
        width: 390px;
        align-items: flex-start;
      }

      .header {
        margin-bottom: $skui-spacing-l;
        @include espace-rh-h3;
        color: $plainText-onLight-lighter;
      }

      .addLinkedButton {
        margin-top: $skui-spacing-m;
      }
    }

    .subCompaniesWrapper {
      display: flex;
      flex-direction: column;
      padding: $skui-spacing-l;
      flex: 1;

      .companyList {
        display: flex;
        flex-direction: column;
        padding-left: $skui-spacing-l;
        padding-right: $skui-spacing-l;

        .headerWrapper {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-bottom: $skui-spacing-l;
          width: 550px;

          .header {
            @include espace-rh-h3;
            color: $plainText-onLight-lighter;
          }
        }

        .subCompaniesList {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          .childCard {
            margin-bottom: $skui-spacing-s;
          }
        }
      }
    }
  }
}
