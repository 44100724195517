.careerCard {
  background-color: #ffffff;
  border: 1px solid rgba(25, 47, 75, 0.1);
  border-radius: 5px;
  color: #666;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  justify-content: space-between;
  margin-bottom: 23px;
  padding: 15px;
  text-decoration: none;
  position: relative;

  &:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.08) 0 6px 16px;
  }
}
