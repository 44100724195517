@import "uiAssets/ColorsVars.scss";

.TrainingPage {
  flex-grow: 1;
  height: calc(100vh - 45px);
  overflow: auto;

  @media (min-width: 768px) {
    height: 100vh;
  }

  > div {
    margin: 0 auto;
    padding: 0 30px;

    > header {
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      padding-top: 50px;

      > a {
        background-color: #fff;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: 60px;
        border-radius: 7px;
        box-shadow: rgba(0, 0, 0, 0.15) 0 3px 10px;
        display: block;
        flex-grow: 0;
        flex-shrink: 0;
        height: 75px;
        margin-right: 20px;
        width: 75px;
      }

      > div {
        > h1 {
          color: $blue;
          font-size: 32px;
          font-weight: 100;
          line-height: 36px;
          margin-bottom: 5px;
        }

        > p {
          color: #333;
          font-size: 13px;
          font-style: italic;
          margin-bottom: 5px;
        }

        > h2 {
          color: #666;
        }
      }

      .tags {
        > span {
          background: #eee;
          border-radius: 3px;
          display: inline-block;
          font-size: 11px;
          margin: 0 3px 3px 0;
          padding: 2px 5px;
          text-transform: uppercase;

          &.cpf {
            background: $contrastColor;
            color: #fff;
          }

          &.certification {
            background: $blue;
            color: #fff;
          }
        }
      }
    }

    > div {
      align-items: flex-start;
      display: flex;
      flex-direction: row-reverse;

      @media (min-width: 1px) and (max-width: 900px) {
        flex-direction: column;
      }

      > div {
        &:last-of-type {
          flex-grow: 1;
          margin-right: 60px;
          width: 100%;

          > blockquote {
            background: #eee;
            border-radius: 0 7px 7px 0;
            font-style: italic;
            padding: 14px 17px 14px 20px;
            position: relative;
            white-space: pre-wrap;

            &:before {
              background: $gradientBlue;
              content: "";
              display: block;
              height: 100%;
              left: 0;
              position: absolute;
              top: 0;
              width: 5px;
            }

            > span {
              color: #666;
              display: block;
              font-size: 11px;
              font-style: normal;
              font-weight: 400;
              margin-top: 5px;
              text-align: right;
            }
          }

          > .deleted {
            background-color: #fff3cd;
            border: 1px solid transparent;
            border-color: #ffeeba;
            border-radius: 0.25rem;
            color: #856404;
            margin-bottom: 1rem;
            padding: 0.75rem 1.25rem;
          }

          > .description {
            margin: 30px 0;
            padding-bottom: 0;
          }

          > nav {
            align-items: center;
            display: flex;
            margin-bottom: 30px;
            padding: 15px 0;
            position: relative;

            @supports (position: sticky) {
              position: sticky;
              top: 0;
            }

            &:before {
              background: #fff;
              content: "";
              display: block;
              height: 100%;
              left: -10px;
              position: absolute;
              top: 0;
              width: calc(100% + 20px);
            }
          }
          > .description > h3,
          > section > h3 {
            border-bottom: 1px solid $blue;
            color: $blue;
            font-size: 24px;
            font-weight: 100;
            margin-bottom: 20px;
            padding: 0 0 10px;
          }

          > section {
            padding-bottom: 40px;
            text-align: justify;

            > blockquote {
              color: #000;
              letter-spacing: 0.3px;

              * {
                margin-bottom: 35px;
                text-align: justify;
              }

              ul {
                padding-left: 20px;
              }

              ul ul {
                margin-bottom: 5px;
              }
              h2,
              h3,
              h4,
              h5 {
                font-size: 16px;
                font-weight: 400;
                margin-bottom: 10px;
              }

              li {
                margin-bottom: 5px;
              }

              // cf CKEEditor css file
              &.intraBlock {
                font-size: 14px;

                iframe {
                  margin-bottom: 0;
                }

                p,
                h2,
                h3,
                ul,
                li {
                  margin-bottom: 0;
                }

                p {
                  margin-bottom: 0;
                }

                h2 {
                  font-size: 20px;
                  font-weight: 500;
                }

                h3 {
                  font-size: 18px;
                  font-weight: 500;
                }

                strong {
                  font-weight: 600;
                }

                ul,
                ol {
                  padding-left: 20px;
                }

                ul ol {
                  margin-bottom: 0;
                }

                ol ul {
                  margin-bottom: 0;
                }
              }
            }

            > .sessionsContainer {
              display: flex;
              flex-direction: row;

              > div {
                display: flex;
                flex-direction: column;

                &:first-of-type {
                  flex-grow: 0;
                  margin-right: 15px;
                }

                &:last-of-type {
                  flex-grow: 1;

                  > div {
                    align-items: center;
                    background: none;
                    border: none;
                    border-bottom: 1px solid #ddd;
                    color: #666;
                    display: flex;
                    font-size: 14px;
                    justify-content: flex-start;
                    letter-spacing: 0.3px;
                    min-width: 250px;
                    padding: 10px 12px 10px 0;
                    text-align: left;
                    transition: color 0.2s ease-out, background 0.2s ease-out, padding 0.2s ease-out;
                    width: 100%;
                  }
                }
                > button,
                > div > div {
                  align-items: center;
                  background: none;
                  border: none;
                  border-bottom: 1px solid #ddd;
                  color: #666;
                  display: flex;
                  font-size: 14px;
                  justify-content: flex-start;
                  letter-spacing: 0.3px;
                  min-width: 250px;
                  padding: 10px 12px 10px 0;
                  text-align: left;
                  transition: color 0.2s ease-out, background 0.2s ease-out, padding 0.2s ease-out;
                  width: 100%;

                  &.selected {
                    background: $gradientBlue;
                    color: #fff;
                    padding-left: 10px;

                    > svg {
                      stroke: #fff !important;
                    }
                  }

                  &:hover {
                    background: $gradientBlue;
                    color: #fff;
                    padding-left: 10px;
                    cursor: pointer;

                    > svg {
                      stroke: #fff !important;
                    }
                  }
                }
              }

              @media (min-width: 1px) and (max-width: 600px) {
                flex-direction: column;

                > div:first-of-type {
                  margin-right: 0;
                }
              }
            }

            > .aggregatedScore {
              align-items: center;
              display: flex;
              flex-flow: row wrap;
              justify-content: space-between;
              margin-bottom: 25px;

              > div {
                align-items: center;
                background: #f6f6f6;
                border-radius: 7px;
                box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
                color: #666;
                display: flex;
                flex-direction: column;
                font-size: 16px;
                height: 125px;
                justify-content: center;
                text-align: center;
                text-transform: capitalize;
                width: calc(25% - 20px);

                > div {
                  align-items: center;
                  color: $blue;
                  display: flex;
                  font-size: 24px;
                  margin-top: 10px;
                }
              }

              > span {
                color: #666;
                font-size: 13px;
                margin-left: 3px;
              }

              @media (min-width: 1px) and (max-width: 650px) {
                > div {
                  margin-bottom: 20px;
                  width: calc(50% - 10px);
                }
              }
            }

            > .review {
              display: flex;
              flex-direction: row;
              letter-spacing: 0.3px;
              padding: 25px 0;

              > div {
                border-top: 1px solid #ddd;
                display: flex;
                flex-direction: column;
                flex-shrink: 0;
                margin-right: 20px;
                padding-top: 10px;
                width: 150px;

                &:first-of-type > span {
                  align-items: center;
                  display: flex;
                  margin-bottom: 5px;
                  text-transform: capitalize;

                  > svg {
                    display: block;
                    margin-right: 10px;
                  }
                }

                &:last-of-type {
                  flex-grow: 1;
                  margin-right: 0;

                  > p {
                    white-space: pre-wrap;
                  }

                  > span {
                    color: #666;
                    display: block;
                    font-size: 13px;
                    margin-top: 5px;
                  }
                }
              }

              > p {
                border-top: 1px solid #ddd;
                flex-grow: 1;
                letter-spacing: 0.3px;
                padding-top: 10px;

                > span {
                  color: #999;
                  display: block;
                  font-size: 13px;
                  font-style: italic;
                  margin-top: 5px;
                  text-align: right;
                }
              }

              @media (min-width: 1px) and (max-width: 625px) {
                flex-direction: column;

                > div {
                  width: 100%;
                }

                > p {
                  border-top: none;
                }
              }
            }
          }

          @media (min-width: 1px) and (max-width: 900px) {
            margin-right: 0;
          }
        }
      }
    }
  }
}
