@import "../../assets/colors";
@import "../../assets/mixins";

.SearchInput {
  padding: 0.375rem 0.625rem;
  border-radius: 8px;
  background-color: rgba($bluey-grey, 0.1);
  align-items: center;

  .searchValue {
    border: none;
    background: none;
    outline: none;
    padding-left: 0.625rem;
    width: 100%;
    font-size: 0.875rem;
    line-height: 1.21;
    letter-spacing: 0.15px;
    color: $b-w-black;

    @include placeholder {
      font-weight: 300;
      color: $bluey-grey;
    }
  }
}
