@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.modal {
  width: 100%;
  max-width: 75rem;

  .modalContent {
    .leftPanel {
      flex: 3;
      max-width: 230px;
      padding-top: $skui-spacing-s;

      .label {
        @include espace-rh-h6;
        color: $plainText-onLight-default;
      }

      > div {
        padding-bottom: $skui-spacing-s;
      }

      .summonEventsList {
        max-height: 60vh;
        overflow-y: auto;

        background-image: linear-gradient(to top, white, white),
          linear-gradient(to top, white, white),
          linear-gradient(to top, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0)),
          linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0));
        background-position: bottom center, top center, bottom center, top center;
        background-color: white;
        background-repeat: no-repeat;
        background-size: 100% 1rem, 100% 1rem, 100% 0.5rem, 100% 0.5rem;
        background-attachment: local, local, scroll, scroll;

        > .filterCard {
          cursor: pointer;
          padding: $skui-spacing-xs $skui-spacing-s;

          > div {
            align-items: center;

            .icon {
              padding-right: $skui-spacing-s;
            }

            .mildLabel {
              @include espace-rh-body1-regular;
              color: $plainText-onLight-default;
            }

            .smallLabel {
              @include espace-rh-body2-regular;
              color: $plainText-onLight-default;
            }

            .endOfLine {
              @include espace-rh-body2-regular;
              color: $plainText-onLight-default;
            }
          }

          &.filterCardAtive {
            background: rgba(193, 207, 220, 0.3);
            color: $textColor;

            .mildLabel {
              @include espace-rh-body1-bold;
            }
          }
          &:not(.filterCardAtive):hover {
            background-color: $cardBackground;
          }
        }
      }
    }

    .rightPanel {
      flex: 10;
      padding: $skui-spacing-s;
      background-color: $surface-light-darker;

      .label {
        @include espace-rh-h6;
        color: $plainText-onLight-default;
      }

      .metadata {
        padding-bottom: $skui-spacing-s;
        margin-left: $skui-spacing-xs;

        span.startOfLine {
          @include espace-rh-body2-bold;
        }

        span.endOfLine {
          @include espace-rh-body2-regular;
          color: $plainText-onLight-default;
        }

        .users {
          padding: $skui-spacing-xxs 0;

          .customTextarea {
            resize: none;

            &.fullUsersList {
              height: 3rem;
              overflow: auto;

              background-image: linear-gradient(
                  to top,
                  $surface-light-darker,
                  $surface-light-darker
                ),
                linear-gradient(to top, $surface-light-darker, $surface-light-darker),
                linear-gradient(to top, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0)),
                linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0));
              background-position: bottom center, top center, bottom center, top center;
              background-color: $surface-light-darker;
              background-repeat: no-repeat;
              background-size: 100% 1rem, 100% 1rem, 100% 0.5rem, 100% 0.5rem;
              background-attachment: local, local, scroll, scroll;
            }
            .moreUsersLink {
              color: $primary-blue;
              &:hover {
                cursor: pointer;
              }
            }

            span.precision {
              @include espace-rh-caption-regular;
              color: $plainText-onLight-lighter;
            }
          }
        }

        .alert {
          margin-top: $skui-spacing-xs;
        }
        .comment {
          @include espace-rh-caption-regular;
          color: $plainText-onLight-default;
        }
      }

      .email {
        border: 1px solid rgba(105, 121, 140, 0.2);
        border-radius: 8px;
        padding: 0.5rem 1rem;
        white-space: pre-line;
        overflow-y: auto;
        margin-left: $skui-spacing-xs;

        background-image: linear-gradient(to top, white, white),
          linear-gradient(to top, white, white),
          linear-gradient(to top, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0)),
          linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0));
        background-position: bottom center, top center, bottom center, top center;
        background-color: white;
        background-repeat: no-repeat;
        background-size: 100% 1rem, 100% 1rem, 100% 0.5rem, 100% 0.5rem;
        background-attachment: local, local, scroll, scroll;

        .body {
          @include espace-rh-body1-regular;
          color: $plainText-onLight-default;
          padding-top: $skui-spacing-xs;
        }
      }

      .emailAttachments {
        flex-wrap: wrap;
        overflow-y: auto;
        align-content: flex-start;
        margin: $skui-spacing-xs 0;

        background-image: linear-gradient(to top, $surface-light-darker, $surface-light-darker),
          linear-gradient(to top, $surface-light-darker, $surface-light-darker),
          linear-gradient(to top, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0)),
          linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0));
        background-position: bottom center, top center, bottom center, top center;
        background-color: $surface-light-darker;
        background-repeat: no-repeat;
        background-size: 100% 1rem, 100% 1rem, 100% 0.5rem, 100% 0.5rem;
        background-attachment: local, local, scroll, scroll;

        .calendarEvents {
          width: 18.25rem;
          height: 3.75rem;
          border: 1px solid rgba(105, 121, 140, 0.2);
          border-radius: 4px;
          padding: $skui-spacing-xs;
          background-color: white;
          margin: $skui-spacing-xs;

          justify-content: space-between;

          .upperPart {
            justify-content: space-between;
            .title {
              padding-left: $skui-spacing-xs;
              @include espace-rh-body1-bold;
              color: $plainText-onLight-default;
            }
          }

          .lowerPart {
            padding-left: $skui-spacing-l;
            .label {
              @include espace-rh-caption-regular;
              color: $plainText-onLight-default;
            }
          }
        }

        .calendarEventsExpanded {
          width: 18.25rem;
          max-height: 8.125rem;
          border: 1px solid rgba(105, 121, 140, 0.2);
          border-radius: 4px;
          padding: $skui-spacing-xs;
          background-color: white;
          margin: $skui-spacing-xs;

          overflow-y: auto;

          background-image: linear-gradient(to top, white, white),
            linear-gradient(to top, white, white),
            linear-gradient(to top, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0)),
            linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0));
          background-position: bottom center, top center, bottom center, top center;
          background-color: white;
          background-repeat: no-repeat;
          background-size: 100% 1rem, 100% 1rem, 100% 0.5rem, 100% 0.5rem;
          background-attachment: local, local, scroll, scroll;

          .upperPart {
            justify-content: space-between;
            margin-bottom: $skui-spacing-xxs;

            .title {
              padding-left: $skui-spacing-xs;
              @include espace-rh-body1-bold;
              color: $plainText-onLight-default;
            }
          }

          .lowerPart {
            padding-left: $skui-spacing-l;
            .startOfLine,
            span.startOfLine {
              color: $plainText-onLight-default;
              white-space: nowrap;
            }

            .endOfLine,
            span.endOfLine,
            li {
              @include espace-rh-caption-regular;
              color: $plainText-onLight-default;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            li {
              list-style: none;
            }
          }
        }

        .files {
          width: 18.25rem;
          height: 3.75rem;
          border: 1px solid rgba(105, 121, 140, 0.2);
          border-radius: $skui-spacing-xxs;
          padding: $skui-spacing-xs;
          background-color: white;
          margin: $skui-spacing-xs;

          justify-content: space-between;
          align-items: center;

          .title {
            width: 13.75rem;
            height: 1.5rem;
            padding-left: $skui-spacing-xs;
            @include espace-rh-body1-regular;
            color: $plainText-onLight-default;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .dropdown {
          height: 1.5rem;
        }
      }
    }
  }
}
