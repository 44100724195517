@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.CampaignDetails {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 10px;
  min-width: 15rem;
  font-family: "Noto Sans", sans-serif;

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > h1 {
      color: $plainText-onLight-lighter;
      font-size: 18px;
      margin: 5px 0;
    }
  }
  .meta {
    > h2 {
      @include espace-rh-body1-regular;
      font-size: 16px;
      color: $plainText-onLight-default;
    }
    .dateBlock {
      display: flex;
      flex-direction: column;
      margin-bottom: $skui-spacing-s;
      label {
        @include espace-rh-label;
        font-size: 12px;
        font-weight: 600;
        color: $plainText-onLight-lighter;
      }
      span {
        color: $plainText-onLight-lighter;
        font-weight: 400;
        font-size: 0.8rem;
      }
    }

    .automated {
      .dateBlock {
        margin-bottom: 0.5rem;
      }
    }
  }

  .dropDownMenu {
    .icon {
      height: 30px;
      width: 30px;
      border-radius: 5px;
      border: 1px solid #eee;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    button.dropDownItem {
      background: none;
      border: none;
      border-bottom: 1px solid #eee;
      display: block;
      font-size: 13px;
      padding: 15px 20px;
      text-align: left;
      width: 100%;
      cursor: pointer;

      &:hover {
        background: $gradientBlue;
        color: #fff;
      }

      &.disabled {
        background: #eee;
        color: #999;
        cursor: not-allowed;
      }

      &:first-of-type {
        border-radius: 7px 7px 0 0;
      }

      &:last-of-type {
        border-bottom: none;
        border-radius: 0 0 7px 7px;
      }
    }
  }
}
