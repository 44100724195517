@import "uiAssets/ColorsVars.scss";

.UserImportError {
  box-shadow: rgba(0, 0, 0, 0.15) 0 0 5px;
  display: flex;
  margin: 16px 0;

  &.hidden {
    display: none;
  }

  .content {
    align-items: center;
    background-color: $errorL;
    display: flex;
    width: 100%;
    overflow: hidden;

    .iconContainer {
      padding: 0 25px;
    }

    .info {
      background-color: $error;
      flex-grow: 1;
      padding: 16px 20px;
      color: $mainColorL;

      p.line {
        color: #fff;
        font-size: 13px;
        margin-bottom: 4px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  button.hideBtn {
    background-color: $error;
    border: none;

    cursor: pointer;
    outline: none;
    padding: 0 20px;
  }

  *:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  *:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
