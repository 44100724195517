@import "../../assets/designSystem";

.Loader {
  align-items: center;
  display: flex;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}

.LoaderBar {
  width: 100%;
  background-color: $greyscale-light;
  height: 0.25rem;
  position: relative;
  overflow: hidden;

  > div {
    background: $action-onLight-default;
    height: 0.25rem;
    position: absolute;
    bottom: 0;
    top: 0;
    width: 20%;

    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    animation-name: indeterminate-progress-bar;
    animation-timing-function: ease-in-out;
  }
}

@keyframes indeterminate-progress-bar {
  0% {
    right: 100%;
  }

  100% {
    right: -50%;
  }
}
