@import "@skillup/ui";

.UserAreaSelect {
  .select {
    > div:first-of-type {
      border-radius: 0.125rem;
      cursor: pointer;
      > div:first-of-type {
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }
}
