.section {
  background: #fff;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }

  > input {
    height: 30px;
    margin-bottom: 15px;
    font-size: 0.7rem;
  }

  > .pages {
    min-height: 40px;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    background: #fdfdfd;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    input {
      height: 30px;
      margin-right: 12px;
      font-size: 0.7rem;
    }

    > p {
      width: 100%;
      height: 100%;
      text-align: center;
    }
  }

  > .action {
    display: flex;
    justify-content: space-between;
    > button {
      height: 30px;
      margin-bottom: 5px;
    }
  }
}
