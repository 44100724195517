@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.SearchInSchedule {
  position: relative;
  z-index: 5;
  width: 18rem;

  b {
    color: $blue;
    font-weight: 600;
  }

  > div:first-of-type {
    position: relative;

    > svg {
      position: absolute;
      right: 10px;
      top: 10px;
      height: 1.2rem;
      width: 1.2rem;
      color: $plainText-onLight-lighter;
    }

    > input {
      @include espace-rh-body2-regular;
      background: #fff;
      border: 1px solid #69798c33;
      border-radius: 6px;
      display: block;
      height: 40px;
      padding: 0 30px 0 12px;
      transition: border 175ms ease-in-out;
      width: 100%;
      text-overflow: ellipsis;

      &:not(:focus) {
        font-style: italic;
      }

      &:focus {
        border: 1px solid $blue;

        &::placeholder {
          color: #999;
        }
      }
    }
  }

  > div.background {
    background: transparent;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }

  > div.results {
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0 6px 28px rgba(20, 55, 95, 0.5);
    max-height: 450px;
    overflow: auto;
    position: absolute;
    top: 45px;
    width: 100%;

    > a {
      align-items: center;
      color: #666;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px 15px;
      text-decoration: none;
      transition: padding 200ms ease-in-out;

      &.more {
        background-color: #eee;
        color: #666;
        justify-content: center;
        padding: 15px 0;
      }

      > div.details {
        max-width: 65%;

        > h2 {
          color: #333;
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 3px;
        }

        > h3 {
          color: #666;
          font-size: 13px;
          font-weight: 500;
          margin-bottom: 3px;
        }

        > p {
          color: #444;
          font-size: 12px;
          font-style: italic;
        }
      }
      > div.arrow {
        align-items: center;
        display: flex;
        opacity: 0;
        position: absolute;
        right: 30px;
        text-align: right;
        transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;

        > p {
          color: $blue;
          font-size: 13px;
          margin-right: 5px;
        }
      }

      > div.state {
        align-items: center;
        display: flex;
        flex-shrink: 0;
        font-size: 13px;
        justify-content: flex-end;
        text-align: left;
        transition: opacity 200ms ease-in-out;

        > svg {
          margin: 0 10px;
        }
      }

      &:not(:first-child) {
        border-top: 1px solid #eee;
      }

      &:hover {
        background-color: #f6f6f6;
        padding-left: 25px;
        padding-right: 5px;

        > .arrow {
          opacity: 1;
          transform: translateX(15px);
        }

        > div.state {
          opacity: 0;
        }
      }
    }

    > p {
      font-size: 13px;
      font-style: italic;
      margin: 30px 0;
      text-align: center;
    }
  }
}
