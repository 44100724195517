@import "uiAssets/ColorsVars.scss";

.FilterSummary {
  border-bottom: 1px solid #dceeff;
  padding: 7px 15px 5px;
  z-index: 21;

  > div {
    display: flex;
    flex-wrap: wrap;

    > h3 {
      color: #666;
      font-size: 13px;
      font-weight: 800;
      padding: 6px 10px 6px 0;
    }

    > div {
      align-items: stretch;
      background: #eee;
      border-radius: 5px;
      display: flex;
      margin: 3px 10px 3px 0px;

      &.and {
        align-items: center;
        background: none;
        color: #666;
        font-size: 12px;
        font-weight: 500;
        justify-content: center;
      }

      &:not(.and) {
        cursor: pointer;
      }

      > div {
        max-width: 500px;
        overflow: hidden;
        padding: 3px 10px;
        text-overflow: ellipsis;
        white-space: nowrap;

        > span {
          color: #666;
          font-weight: 700;
        }
      }

      > button {
        align-items: center;
        background: $blue;
        border: none;
        border-radius: 0 5px 5px 0;
        color: #fff;
        display: flex;
        justify-content: center;
        padding: 3px;
        width: 20px;

        > svg {
          display: block;
          margin: 0 auto;
        }
      }
    }
  }
}
