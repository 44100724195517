@import "uiAssets/ColorsVars.scss";

.SearchInput {
  align-items: center;
  background: #fff;
  background-color: #eaeaea;
  border-radius: 7px;
  display: flex;
  flex-grow: 1;
  position: relative;
  transition: background-color 0.2s ease-out;

  @media (min-width: 450px) {
    min-width: 400px;
  }

  > input {
    background: transparent;
    border: none;
    display: block;
    flex-grow: 1;
    padding: 5px 0 5px 15px;
  }

  &:hover {
    background-color: #e0e0e0;
  }

  > a {
    align-items: center;
    background: $blue;
    border-radius: 0 7px 7px 0;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
    padding: 10px;
    transition: background-color 0.2s ease-out;

    > svg {
      stroke: #fff !important;
    }

    &:hover > svg {
      transform: scale(1.2);
    }
  }

  > div {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 5px;
    box-shadow: $bigBoxShadow;
    left: 0;
    position: absolute;
    top: 40px;
    width: 400px;
    z-index: 10;

    @media (min-width: 1px) and (max-width: 550px) {
      display: none;
    }

    @media (min-width: 551px) and (max-width: 1024px) {
      width: 300px;
    }

    > div {
      align-items: center;
      background: #eee;
      display: flex;
      font-size: 13px;
      padding: 6px 10px;

      > svg {
        display: block;
        flex-shrink: 0;
        margin-right: 8px;
      }
    }

    > a {
      color: #333;
      display: block;
      font-size: 13px;
      overflow: hidden;
      padding: 6px 10px 6px 35px;
      text-decoration: none;
      text-overflow: ellipsis;
      transition: background-color 0.2s ease-out, color 0.2s ease-out;
      white-space: nowrap;

      > svg {
        display: inline-block;
        flex-shrink: 0;
        margin-right: 10px;
      }

      > span > b {
        color: $blue;
      }

      &:first-of-type {
        border-radius: 5px 5px 0 0;
        padding: 8px 10px 6px;
      }

      &:last-of-type {
        border-radius: 0 0 5px 5px;
        padding-bottom: 8px;
      }

      &:hover {
        background: $blue;
        color: #fff;

        > svg {
          stroke: #fff !important;
        }

        > span > b {
          color: #fff;
        }
      }
    }
  }

  > .searchBackground {
    background: rgba(255, 255, 255, 0.95);
    display: none;
    height: 100vh;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 3;
  }

  > .selfCloser {
    background: none;
    border: none;
    display: none;
    padding: 20px;
    position: fixed;
    right: 0;
    top: 0;
    transition: transform 0.2s ease-out;
    z-index: 4;

    &:hover {
      transform: rotate(180deg) scale(1.1);
    }
  }

  > .inputContainer {
    color: #999;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin: 0 15px;
    padding: 0 0 0 5px;
    position: relative;
    z-index: 4;

    > .selfOpener {
      background: none;
      border: none;
      display: block;
      height: calc(100% + 20px);
      left: 0;
      opacity: 0;
      position: absolute;
      top: -10px;
      width: 100%;

      @media (min-width: 1px) and (max-width: 550px) {
        display: none;
      }
    }

    > span {
      display: block;
      flex-grow: 0;
      flex-shrink: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre;

      @media (min-width: 1px) and (max-width: 550px) {
        display: none;
        width: 75px;
      }
    }

    > svg {
      @media (min-width: 1px) and (max-width: 550px) {
        margin: 00 0 0 !important;
      }
    }

    > input {
      background: none;
      border: none;
      display: none;
      left: 2px;
      position: absolute;
      top: -4px;
      width: calc(100% - 25px);

      @media (min-width: 1px) and (max-width: 550px) {
        display: block;
        position: initial;
        text-overflow: ellipsis;
      }
    }

    &:after {
      background: $gradientBlue;
      bottom: -7px;
      content: "";
      display: block;
      height: 2px;
      left: 0;
      position: absolute;
      width: 100%;
    }

    > .resultsContainer {
      background: #fff;
      border-radius: 7px;
      box-shadow: rgba(40, 139, 255, 0.25) 0 5px 20px;
      display: none;
      flex-direction: row-reverse;
      left: 10px;
      max-height: calc(100vh - 200px);
      max-width: 1200px;
      min-height: 450px;
      opacity: 0;
      overflow: auto;
      position: fixed;
      top: 125px;
      width: calc(100% - 20px);

      @media (min-width: 1px) and (max-width: 950px) {
        flex-direction: column-reverse;
      }

      > div {
        background: #fff;
        box-shadow: rgba(40, 139, 255, 0.25) 0 0 20px;
        flex-grow: 1;
        flex-shrink: 1;
        min-width: 275px;
        position: relative;

        @media (min-width: 1px) and (max-width: 950px) {
          width: 100%;
        }

        > h3 {
          align-items: center;
          border-bottom: 1px solid $blue;
          color: $blue;
          display: flex;
          height: 40px;
          padding: 0 10px;
        }

        > .categoriesContainer,
        > .organizationsContainer {
          max-height: 418px;
          overflow: auto;
        }

        > a,
        > div > a {
          align-items: center;
          border-bottom: 1px solid #eee;
          color: #333;
          display: flex;
          height: 38px;
          justify-content: space-between;
          padding: 0 10px;
          text-decoration: none;
          transition: background-color 0.2s ease-out, color 0.2s ease-out;
          width: 100%;

          > span {
            display: block;
            max-width: calc(100% - 20px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre;
          }

          &:last-of-type {
            border: none;
          }

          &.total {
            background: #eee;
            color: #666;
            padding: 12px 10px;
            position: relative;
          }

          &:hover {
            background: $gradientBlue;
            color: #fff;

            > svg {
              stroke: #fff !important;
            }
          }
        }
      }
    }
  }
}
