@import "@skillup/ui";
@import "uiAssets/ColorsVars.scss";

.ConnectionModes {
  background-color: #fafafa;
  @include espace-rh-body1-regular;
  padding: $skui-spacing-l;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;

  .companySettings {
    max-width: 1500px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: $skui-spacing-l;
    .companyConnectionModesWrapper {
      display: flex;
      flex-direction: column;
      @include isDesktop {
        max-width: 600px;
      }
      .companyConnectionModes {
        display: flex;
        @include isMobile {
          flex-wrap: wrap;
        }
        justify-content: flex-start;
        row-gap: $skui-spacing-l;
        column-gap: 6rem;
        .companyConnectionMode {
          > .connectionModeName {
            display: flex;
            align-items: center;
            margin-bottom: 0.125rem;
            > .label {
              margin-right: $skui-spacing-xxs;
              font-weight: 600;
            }
          }
        }
      }
      .connectionModesDisclaimer {
        @include espace-rh-components-input-help-text;
        color: $plainText-onLight-lighter;
        margin-top: $skui-spacing-xxs;
      }
    }

    .companyConnectionLink {
      margin-bottom: $skui-spacing-xxl;
      h2 {
        font-size: 0.75rem;
        color: $plainText-onLight-lighter;
        font-weight: 600;
      }
      .description {
        margin-top: $skui-spacing-xxs;
        font-size: 0.75rem;
        line-height: 1.125rem;
        color: $text-on-light-lighter;
      }
      .toggle {
        display: flex;
        align-items: flex-start;
        margin-top: 0.75rem;

        label {
          flex: 1;
          margin-left: 0.625rem;
          .content {
            color: $plainText-onLight-default;
            opacity: 0.4;
          }
          .description {
            margin-top: 0.125rem;
          }
        }
      }
    }
  }

  .lastConnection {
    .warning {
      color: $status-warning-darker;
      font-weight: bold;
    }
    .error {
      color: $status-error-darker;
      font-weight: bold;
    }
  }

  .Status {
    &.done {
      background-color: $greyscale-light;
      color: $greyscale-darker;
    }
    &.success {
      background-color: $status-success-lightest;
      color: $status-success-darker;
    }
    &.progress {
      background-color: $action-onLight-lightest;
      color: $action-onLight-darkest;
    }
    &.warning {
      background-color: $status-warning-lightest;
      color: $status-warning-darker;
      border: 1px solid $status-warning-default;
    }
    &.error {
      background-color: $status-error-lightest;
      color: $status-error-darker;
      border: 1px solid $status-error-default;
    }
    border-radius: 0.125rem;
    display: inline-flex;
    align-items: center;
    padding: 0.125rem;
    svg {
      margin-left: 0.25rem;
      margin-left: 0.25rem;
    }
    .label {
      margin-left: 0.375rem;
      margin-right: 0.375rem;
      font-size: 0.75rem;
      white-space: nowrap;
    }
  }

  .authStrategy {
    > h2 {
      @include espace-rh-h5;
      margin-bottom: $skui-spacing-xs;
      color: $plainText-onLight-lighter;
    }

    .buttonsWrapper {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: $skui-spacing-s;
    }

    .AuthStrategiesTable > div {
      margin-top: $skui-spacing-s;
      max-width: 100%;
    }
  }

  .userSettings {
    margin-top: $skui-spacing-s;
    > h2 {
      @include espace-rh-h5;
      margin-bottom: $skui-spacing-xs;
      color: $plainText-onLight-lighter;
    }
    .userFilters {
      display: flex;
      flex-wrap: wrap;
      row-gap: 1rem;
      margin-bottom: 1.25rem;
      .userFilter {
        display: inline-block;
        margin-right: $skui-spacing-s;
        > .label {
          @include espace-rh-components-input-label;
          color: $plainText-onLight-lighter;
        }
        .quickFilterToggle {
          width: 250px;
          font-size: 0.75rem;
        }
        > .input {
          min-width: 125px;
          height: 2.5rem;
          > input,
          .userAreaFilter {
            height: 100%;
          }
          .userAreaFilter > div:first-child {
            height: 100%;
            background-color: white;
            border-radius: 4px;
            border: 1px solid $pale-lilac;
            color: $b-w-black;
            &:focus {
              border-color: $primary-blue;
            }
          }
        }
      }
    }
  }

  .TableWrapper {
    max-width: 1200px;
    overflow: hidden;
    .Table {
      @media (max-width: 1000px) {
        overflow-x: auto;
      }
      td:first-child div {
        margin: auto;
      }
    }
  }
}

.EditUserModal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  animation: fadein 0.5s ease-in normal 1;
  z-index: 5;
}

.UserActionModal {
  margin: 1rem;
  margin-top: 0;
  .modalContent {
    ul {
      padding-inline-start: 25px;
    }
  }
  .modalButtons {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    flex-wrap: wrap;
  }
}

.ClipboardText {
  display: flex;
  align-items: center;
  margin-top: $skui-spacing-xxs;
  margin-bottom: $skui-spacing-xs;
  .clipboardInput {
    flex: 1;
    cursor: default;
    border: 0;
    font-size: 1rem;
    text-overflow: ellipsis;
    padding: 0;
    background-color: transparent;
  }
}

.NewPasswordModal {
  .modalContent {
    > h2 {
      @include espace-rh-body1-bold;
    }
    .clipboardText {
      width: 250px;
      margin: 0.25rem auto;
      background-color: white;
      border-radius: 4px;
      border: 1px solid $pale-lilac;
      padding: 4px 8px 6px 8px;
      @include espace-rh-h3;
      > input {
        font-size: 1.5rem;
      }
    }
    .explainText {
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
    }
    .warning {
      display: flex;
      color: $status-warning-darker;
      > div {
        margin-top: 0.1rem;
        margin-left: $skui-spacing-xs;
      }
      margin-top: 1.25rem;
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
