@use "sass:list";
@import "../../assets/designSystem";

.chipNumber {
  padding: 0.125rem $skui-spacing-xxs;
  border-radius: $skui-spacing-xxs;
  display: inline;
  align-items: center;
  justify-content: center;
  font-size: 0.625rem;
  font-weight: 600;

  @include decoration-colors;

  &.espace-rh {
    @include espace-rh-font;
  }
  &.espace-collab {
    @include espace-collab-font;
  }
}
