@import "@skillup/ui";

.DSModal {
  width: 100%;
  max-width: 56rem;

  .modal {
    .alert {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1000;
      margin-left: -1.5rem;
      margin-right: -1.5rem;
    }

    .infoSection {
      margin-top: $skui-spacing-m;
      .dateForm {
        width: 100%;
        margin: $skui-spacing-s 0 $skui-spacing-xs 0;

        .dateFormGroup > div:nth-child(3) {
          display: block;
        }

        > div > div {
          gap: $skui-spacing-xxs $skui-spacing-s;
        }

        .dateRangePicker > div > div {
          width: 10rem;
        }

        .timeRangeContainer {
          > div > div {
            width: 7.85rem;
          }

          .timeError fieldset {
            border: 1px solid #d32f2f;
          }
        }

        .toggle {
          span {
            white-space: nowrap;
          }
        }
      }

      .priceForm {
        margin-top: $skui-spacing-xs;
        .sessionPrices {
          margin: $skui-spacing-xxs 0 $skui-spacing-xxs $skui-spacing-m;
          gap: $skui-spacing-s;
          .priceInput {
            width: 17.5rem;
          }
        }
      }

      .participantsNumber {
        @include espace-rh-body1-regular;
        padding: $skui-spacing-xs $skui-spacing-s;
      }

      .attendeeForm {
        padding-top: 0.5rem;
        gap: $skui-spacing-s;
        .input {
          width: 19rem;
        }
      }

      label {
        outline: none;
      }

      .assistiveErrorText {
        color: $status-error-darker;
        svg {
          margin-top: 2px;
          margin-right: $skui-spacing-xs;
        }
      }
    }

    .perimeterSection {
      margin-top: $skui-spacing-m;

      .perimeterForm,
      .trainerForm {
        width: 19rem;
        margin: $skui-spacing-s 0;
      }

      .reviewsForm {
        margin-top: $skui-spacing-s;
        .toggle {
          margin-top: $skui-spacing-xxs;
        }
      }

      .reviewsAlert {
        margin-top: $skui-spacing-xs;
        margin-bottom: $skui-spacing-s;
      }
    }

    .shortInput {
      width: 19rem;
      margin-right: $skui-spacing-s;
    }

    input::placeholder {
      opacity: 1;
      font-style: italic;
    }
  }
}
