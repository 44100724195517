@import "uiAssets/ColorsVars.scss";

.UserChoiceModal {
  position: relative;
  transition: height 0.2s ease-in;
  padding: 0px 20px;

  .preview {
    flex-grow: 0;
    grid-gap: 10px;
    gap: 10px;
    padding: 10px 25px;
    border-bottom: 1px solid #eaeaf1;

    .recall {
      margin-bottom: 5px;
      flex-grow: 0;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.1px;
      color: #888888;
    }

    p {
      flex-grow: 0;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.1px;
      color: #192f4b;
    }
  }

  .nextStep {
    width: 100%;
    display: flex;
    padding: 10px 0px;
    justify-content: flex-end;
    border-top: 1px solid #eaeaf1;

    > button {
      margin: inherit;
    }
  }
}
