@import "@skillup/ui";

.ActionDenied__Container {
  border: $skui-spacing-xxs;
  border-color: $border-on-light;
  background: $greyscale-lighter;
  gap: $skui-spacing-xs;
  border-radius: $skui-spacing-xxs;
  margin-bottom: $skui-spacing-xs;
}

.ActionDenied__Label {
  text-decoration: line-through;
}