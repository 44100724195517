@import "uiAssets/ColorsVars.scss";

.PortalCategories {
  margin: 20px auto;

  > h2 {
    border-bottom: 1px solid $blue;
    color: $blue;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
  }

  > div {
    align-items: center;
    border-radius: 7px;
    box-shadow: $buttonBoxShadow;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 7px 11px;

    > span {
      display: block;
      flex-grow: 1;
    }

    > div {
      align-items: center;
      display: flex;
      flex-grow: 0;
      flex-shrink: 0;

      > button {
        align-items: center;
        background: none;
        border: none;
        display: flex;
        font-size: 11px;
        margin-left: 15px;
        text-transform: uppercase;

        > span {
          padding-top: 2px;
        }

        > svg {
          display: block;
          margin-left: 5px;
        }
      }
    }
  }

  > section {
    margin-top: 30px;

    > .openAddCategoryButton {
      align-items: center;
      background: none;
      border: none;
      border-radius: 7px;
      color: $blue;
      display: flex;
      font-size: 14px;
      font-weight: 700;
      justify-content: center;
      width: 100%;

      > span {
        display: block;
      }

      > div {
        background: #fff;
        border: 2px dashed #ccc;
        border-radius: 20px;
        margin-right: 10px;
        padding: 8px;

        > svg {
          display: block;
          transition: transform 0.2s ease-out !important;
        }
      }

      &:hover > div > svg {
        transform: rotate(180deg);
      }
    }

    > .lazyRenderedList {
      background: #fff;
      border-radius: 7px;
      box-shadow: $bigBoxShadow;
      height: 300px;
      margin-bottom: 10px;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 10px;

      > .scrollContainer {
        position: relative;

        > div {
          align-items: center;
          background: $mainColorL;
          border-radius: 5px;
          cursor: pointer;
          display: flex;
          font-size: 12px;
          font-weight: 100;
          height: 26px;
          justify-content: space-between;
          left: 4px;
          padding: 0 0 0 5px;
          position: absolute;
          top: 2px;
          width: calc(100% - 8px);

          > div {
            align-items: center;
            background: #999;
            border-radius: 0 5px 5px 0;
            color: #fff;
            display: flex;
            height: 26px;
            padding: 0 5px;
            pointer-events: none;
          }
        }
      }
    }

    > .addCategoryButton {
      align-items: center;
      background: #fff;
      border: none;
      border-radius: 7px;
      display: flex;
      margin-bottom: 10px;
      padding: 5px 8px;
      width: 100%;

      > svg {
        display: block;
        margin-right: 10px;
      }
    }
  }
}
