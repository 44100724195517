.trackingUuidNotFound {
  .warningText {
    width: 100%;
    padding: 1.5rem 1rem;

    > p:first-child {
      margin-bottom: 1.5rem;
    }
  }

  .actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 1.5rem 1rem;

    > div {
      margin-left: 1.5rem;
    }
  }
}
