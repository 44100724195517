.assignEmployeeToJobModal {
  padding: 20px;

  p {
    margin-bottom: 10px;
  }

  button {
    margin-top: 20px;
  }
}
