@import "uiAssets/ColorsVars.scss";

.SetInterviewModal {
  padding: 20px;
  display: flex;
  flex-direction: column;

  .errorMessage {
    > div {
      border-radius: 5px;
      > div:first-child {
        padding: 0 16px;
      }
      > div:last-child {
        padding: 8px 16px;
        font-size: 12px;
      }
    }
  }

  .user {
    margin-bottom: 12px;
  }

  section.date {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;

    > label {
      font-size: 12px;
      color: $textColor;
      margin-bottom: 3px;
    }

    &.disabled {
      > div {
        > div {
          > div {
            background-color: rgba(196, 196, 196, 0.6) !important;
          }
        }
      }
    }
  }

  footer.actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;

    .backBtn {
      margin-right: 8px;
      background: white;
      border: 1px solid $borderColor;
      color: $smallTextColor;
      box-shadow: none;
    }
  }
}
