@import "@skillup/ui";

.PortalSchedules {
  padding: $skui-spacing-s $skui-spacing-l;
  height: 100%;
  background-color: $surface-light-darker;
  gap: 2rem;
  .Lists {
    gap: $skui-spacing-l;
    .item {
      justify-content: space-between;
      .infos {
        @include espace-rh-caption-regular;
        color: $plainText-onLight-lighter;
        .title {
          @include espace-rh-body1-bold;
          color: $plainText-onLight-default;
        }
        gap: $skui-spacing-m;
      }
      .actions {
        gap: $skui-spacing-xs;
      }
    }
  }
  .importedButton {
    margin-top: auto;
  }
}

.modalContent {
  @include espace-rh-body1-regular;
  color: $plainText-onLight-default;
  padding: $skui-spacing-s 0;
  max-width: 560px;
  white-space: pre-wrap;

  .alert {
    @include espace-rh-body1-bold;
    color: $status-error-darker;
    margin-top: $skui-spacing-s;
  }

  p.label {
    font-weight: 600;
    margin: $skui-spacing-xs 0;
  }

  label {
    margin: $skui-spacing-xs 0;
  }
}
