@import "@skillup/ui";

.ColdReviewSidePanel {
  header {
    padding-bottom: 0;
    
    .subtitleWrapper {
      margin-bottom: $skui-spacing-s;
      h6 {
        -webkit-line-clamp: 2 !important;
      }
    }
    p {
      @include espace-rh-body2-regular;
    }
    .actionMenu {
      justify-content: flex-end;
      margin-bottom: $skui-spacing-s;
    }
    > div {
      max-height: none !important;
      overflow: unset !important;
    }
  }

  .content {
    .pieTitle {
      @include espace-rh-h6;
      color: $plainText-onLight-default;
    }
    .info {
      @include espace-rh-caption-regular;
      color: $plainText-onLight-lighter;
      margin-top: $skui-spacing-m;
      font-family: "Noto Sans Display";
      font-size: 12px;
      line-height: 18px;
    }
    margin-bottom: 1rem;
  }

  .chartComponent {
    width: 100%;
    height: auto;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    left: 0;
    margin: $skui-spacing-m 0 $skui-spacing-m 0 ;
  }

  .legend {
    height: 150px;

    .legendItem {
      @include espace-rh-caption-regular;
    }

    svg {
      font-size: 24px;
    }
  }

  .QuestionsDetails {
    margin-top: $skui-spacing-m;
    margin-bottom: $skui-spacing-m;
    padding:0;
    border: 0;
    border-left: 8px;
    border-color: $greyscale-light;
    border-style: solid;
    color: $plainText-onLight-default;
    > div > button > div:first-child {
      text-overflow: unset;
      white-space: unset;
      font-size: 14px;
      font-weight: 600;
    }
    &.positive.selected {
      border-color: $status-success-default;
    }
    &.negative.selected {
      border-color: $decoration-red-base;
    }
    ul {
      font-size: 14px;
      list-style: none;
      li:before {
        content: "•";
        padding-right: 4px;
        margin-left: -9px;
      }
    }
    .titleQuestionLabel {
      button {
        height: auto;
        padding-top: 0;
        padding-bottom: 0;
      }
        :focus {
          outline: none;
          background-color: white;
        }
    }
  }

  .sidePanelFooter {
    font-weight: 400;
    font-size: 16px;
    color: $plainText-onLight-lighter;
    gap: $skui-spacing-m;
    word-wrap: 'break-word'
  }

  .commentQuestion {
    padding: 4px 8px 4px 8px;
    margin-left: 21px;
    background: $greyscale-lighter;
    border-radius: 4px;
    border: 1px solid $greyscale-light;
    list-style: none;
    :hover {
      background-color: $greyscale-lighter;
    }
  }

    footer {
    display: none;
  }
}
