.RemoveTrainingFromList {
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;

  > p {
    padding: 20px;
    width: 100%;
  }

  > div {
    display: flex;
    justify-content: space-between;
  }
}
