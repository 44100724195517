@import "../../assets/designSystem";

.UserPopoverContent {
  width: 15rem;
  background-color: $surface-light;
  border: 1px solid rgba(105, 121, 140, 0.2);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 4px;

  .body {
    padding: $skui-spacing-xs;
    display: flex;
    gap: $skui-spacing-xs;
    .props {
      flex: 1;
      h2 {
        color: $plainText-onLight-default;
      }
      .subProps {
        .prop {
          color: $plainText-onLight-lighter;
        }
      }
      &.espace-rh {
        h2 {
          @include espace-rh-body1-regular;
        }
        .subProps {
          .prop {
            @include espace-rh-caption-regular;
          }
        }
      }
      &.espace-collab {
        h2 {
          @include espace-collab-body1-regular;
        }
        .subProps {
          .prop {
            @include espace-collab-caption-regular;
          }
        }
      }
    }
  }
  .footer {
    text-align: center;
    margin-bottom: $skui-spacing-xs;
  }
}
