@import "../../assets/colors";

.FloatingTabs {
  display: flex;
  align-items: center;

  .tab {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.531rem 0.875rem;
    border-radius: 8px;
    background-color: transparent;
    margin-right: 0.325rem;
    min-width: 7.9rem;
    text-decoration: none;

    span.label {
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.21;
      letter-spacing: 0.15px;
      text-align: center;
      color: $bluey-grey;
      user-select: none;
    }

    &.active,
    &:hover {
      cursor: pointer;
      background-color: rgba($bluey-grey, 0.1);

      span.label {
        font-weight: bold;
        color: $b-w-black;
      }
    }
  }
}
