@import "uiAssets/ColorsVars.scss";

.TrainingsResults {
  flex-grow: 1;
  height: calc(100vh - 45px);
  overflow: auto;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    height: 100vh;
  }

  > div {
    display: flex;

    > main {
      display: block;
      flex-grow: 2;
      overflow: hidden;

      &.center {
        margin: 0 auto;
        max-width: 780px;
        width: 100%;
      }

      > h1 {
        color: $blue;
        font-size: 32px;
        font-weight: 400;
        margin-bottom: 5px;
        max-width: 780px;
        padding: 10px 30px 0;
      }

      > .noTraining {
        color: $warning;
        font-size: 13px;
        font-weight: 700;
        margin-bottom: 20px;
        padding: 0 30px;
      }

      > .export {
        display: flex;
        margin: 0 30px;
        max-width: 750px;

        > div {
          flex-grow: 1;
        }

        button {
          width: auto !important;
        }
      }

      > .pagination {
        margin: 20px;
        max-width: 780px;
      }
    }
  }
}
