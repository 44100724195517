@import "../../assets/designSystem";

.Carousel {
  width: 100%;
  position: relative;

  .previous {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    padding-left: $skui-spacing-xs;
    width: 5rem;
    height: 100%;
    justify-content: left;
    background: linear-gradient(90deg, rgba(var(--bg-color), 1) 25%, rgba(var(--bg-color), 0) 100%);
    &.hide {
      visibility: hidden;
    }
  }
  .next {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    padding-right: $skui-spacing-xs;
    width: 5rem;
    height: 100%;
    justify-content: right;
    background: linear-gradient(90deg, rgba(var(--bg-color), 0) 0%, rgba(var(--bg-color), 1) 75%);
    &.hide {
      visibility: hidden;
    }
  }
}
