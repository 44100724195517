@import "../../assets/designSystem";

.SkillsList {
  .categoriesContainer {
    display: flex;
    flex-direction: column;
    background: $surface-light;
    border: 1px solid $border-onLight;
    border-radius: $skui-spacing-xxs;
    margin-bottom: $skui-spacing-xs;
    padding: $skui-spacing-s;

    .categoryName {
      font-weight: 600;
      line-height: 1.5rem;
      font-size: 1rem;
      margin-bottom: $skui-spacing-s;
      color: $plainText-onLight-lighter;
      word-wrap: break-word;

      &:first-letter {
        text-transform: uppercase;
      }
    }
  }

  .categoryContainer {
    display: flex;
    flex-direction: column;
    background: $surface-light;
  }
}
