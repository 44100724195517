@import "@skillup/ui";

.OpenEndedQuestion {
  .answersContainer {
    position: relative;
    margin: auto;
    width: 95%;
  }
  .answerContainer {
    width: 100%;
  }
  .answer {
    margin-bottom: 1.5rem;
    &.last {
      margin-bottom: 0.125rem;
    }
    font-size: 0.875rem;

    .header {
      display: flex;
      align-items: center;
    }

    .label {
      margin-left: $skui-spacing-xs;
      .role {
        font-size: 12px;
        color: $plainText-onLight-lighter;
      }
    }
    .content {
      color: $plainText-onLight-default;
      background-color: $decoration-blue-lightest;
      border-radius: 4px;
      padding: 4px 8px;
      white-space: pre-line;
      margin-top: 0.5rem;
      margin-left: 2.5rem;
      border: 1px solid $decoration-blue-lighter;
    }
  }
  .emptyAnswers {
    text-align: center;
    .crossImg {
      width: 4.5rem;
      margin: 0 auto;
    }
    .pendingImg {
      height: 6.5rem;
      margin: 0 auto;
    }
    .message {
      @include espace-rh-body1-bold;
      color: $plainText-onLight-default;
      margin-top: 2rem;
      margin-bottom: 1.5rem;
    }
  }
  article > div:nth-child(3) {
    padding: 0 !important;
  }
}
