@import "@skillup/ui";

.UserTraining {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1.5rem 0;
  gap: 1.5rem;

  .filter {
    display: flex;
    width: 100%;
    padding: 0 1.5rem;
    justify-content: space-between;
    .dropdownCheckbox {
      min-width: 15.5rem;
    }
    .toggle {
      display: flex;
      margin-left: auto;
      margin-bottom: 0.1rem;
      width: 5.5rem;
      justify-self: flex-end;

      span {
        width: 50%;
        height: 33px;
      }

      button {
        width: 100%;
        padding: 0rem;

        span > svg {
          width: 1.25rem;
          height: 1.25rem;
        }
      }
    }
  }

  p {
    @include espace-rh-body1-regular;
    color: $plainText-onLight-lighter;
  }

  > p,
  img {
    margin: auto;
    margin-top: $skui-spacing-l;
  }

  .table,
  .timeline {
    width: 100%;
    padding: 0 1.5rem;

    .cell {
      .title {
        .tooltip {
          white-space: normal;
          max-height: auto;
        }

        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100%;
        }
      }
      .subtitle {
        @include espace-rh-caption-regular;
        color: $plainText-onLight-lighter;
      }
    }
  }

  .carousel {
    margin-top: $skui-spacing-xs;
  }

  .loading {
    height: 250px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .loadingLogo {
      transform: scale(1.25);
      margin-bottom: 1rem;
      svg path {
        fill: $surface-light-darker;
      }
    }
    p {
      color: $plainText-onLight-lighter;
      animation: loadingText 0.6s infinite;
      font-size: 0.875rem;
    }
  }
}

@keyframes loadingText {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.6;
  }
}
