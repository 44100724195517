@import "@skillup/ui";

.EvaluationBlock {

  &:first-of-type {
    margin-bottom: 0.6875rem;
  }

  .noEvaluation {
    @include espace-rh-body1-regular;
    color: $placeholder-onLight;
    width: 15rem;
  }

  span.evaluationValue {
    font-weight: bold;
    margin-right: 0.25rem;
  }

  .evaluationChoice {
    @include espace-rh-body1-bold;
    flex: 1;
    width: 15rem;
    background-color: $greyscale-lighter;
    height: 1.875rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .evaluationGauge {
    width: 15rem;

    div {
      margin: 0 !important;
    }

    section {
      margin: 0 !important;
    }
  }
}