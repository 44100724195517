@import "../../assets/colors";
@import "../../assets/mixins";
@import "../../assets/designSystem";

.UserPreview {
  .meta {
    &.L {
      margin-left: $skui-spacing-s;
    }
    &.M,
    &.S {
      margin-left: $skui-spacing-xs;
    }

    span.fullName {
      color: $plainText-onLight-default;
    }

    span.propertyLabel {
      color: $plainText-onLight-lighter;
    }
  }

  &.espace-rh {
    span.propertyLabel {
      @include espace-rh-caption-regular;
    }

    &.L {
      span.fullName {
        @include espace-rh-body1-regular;
      }
    }
    &.M,
    &.S {
      span.fullName {
        @include espace-rh-body2-regular;
      }
    }
  }

  &.espace-collab {
    span.propertyLabel {
      @include espace-collab-caption-regular;
    }

    &.L {
      span.fullName {
        @include espace-collab-body1-regular;
      }
    }
    &.M,
    &.S {
      span.fullName {
        @include espace-collab-body2-regular;
      }
    }
  }
}
