@import "@skillup/ui";

.campaignCard {
  color: $plainText-onLight-lighter;
  background: $surface-light;
  border: 1px solid $border-onLight;
  border-radius: $skui-spacing-xxs;
  box-shadow: $elevation-actionableCard;

  &:hover {
    cursor: pointer;
    box-shadow: $elevation-actionableCard-hover;
  }
}

.state {
  width: fit-content;
}