@import "@skillup/ui";

.deleteCompany {
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  .modalText {
    @include espace-rh-body1-regular;
    padding: $skui-spacing-m;
  }

  .buttonsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: $skui-spacing-s $skui-spacing-m;

    .button {
      margin-right: $skui-spacing-xs;
      box-sizing: border-box;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
