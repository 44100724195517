@import "../../assets/colors";

.ProgressBar {
  align-items: center;

  .valueContainer {
    color: $b-w-black;
    margin-right: 0.562rem;
    margin-left: 1rem;
    align-items: baseline;

    span.value {
      font-size: 1rem;
      font-weight: bold;
      line-height: 1.19;
      letter-spacing: 0.15px;
    }

    span.unit {
      font-size: 0.75rem;
      letter-spacing: 0.06px;
    }
  }

  .barContainer {
    width: 100%;
    height: 0.375rem;
    border-radius: 6.25rem;
    background-color: rgba(216, 216, 216, 0.3);

    .progress {
      background-color: $primary-yellow;
      border-top-left-radius: 6.25rem;
      border-bottom-left-radius: 6.25rem;
      &.full {
        border-radius: 100px;
      }
    }
  }
}
