@import "uiAssets/ColorsVars.scss";

.SidePanelWrapper {
  background: #fff;
  box-shadow: $baseBoxShadow;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 410px;
  z-index: 100;

  > .loader {
    height: 100%;
  }

  > .header {
    height: 0;
    position: relative;
    width: 50px;

    > div {
      background: #f6f6f6;
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
      cursor: pointer;
      height: 33px;
      left: -25px;
      padding: 10px 0 0 10px;
      position: absolute;
      top: 1px;
      transition: box-shadow 0.1s ease-out;
      width: 25px;
      z-index: 9;

      &:hover {
        box-shadow: rgba(40, 40, 40, 0.3) 0 0 20px;
      }
    }
  }

  > .content {
    overflow-y: auto;
  }
}
