@import "@skillup/ui";

.content__item__actions {
  background-color: $surface-dark-darker;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: $skui-spacing-xs 0;
  > span {
    display: flex;
    align-items: center;
    padding: 0 $skui-spacing-xs;
  }
}

.content__item__no__actions {
  background-color: $surface-dark-darker;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: $skui-spacing-s 0;
}
