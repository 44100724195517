.Sidebar {
  background: #f5f8fc;
  border-right: 1px solid #ccc;
  overflow-y: auto;
  width: 250px;

  > .types {
    display: flex;
    padding: 15px;
    padding-bottom: 0;
  }

  > div.filters {
    overflow-y: auto;
    padding: 15px;

    h3 {
      color: #333;
      font-size: 13px;
      font-weight: 700;
      margin-bottom: 10px;
      margin-top: 20px;
    }

    > h3:first-of-type {
      margin-top: 0;
    }

    .sessionToggle {
      align-items: center;
      border-bottom: 1px solid #ddd;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      padding-bottom: 10px;

      h3 {
        color: #2782ff;
        margin-top: 10px;
      }

      button {
        background: #eaeaea;
        border: 1px solid #777;
        border-radius: 9px;
        height: 18px;
        position: relative;
        transition: background 0.1s ease-in;
        width: 40px;

        &:before {
          background: #777;
          border-radius: 6px;
          content: "";
          display: block;
          height: 12px;
          left: 2px;
          position: absolute;
          top: 2px;
          transition: left 0.1s ease-in;
          width: 12px;
        }

        &.active {
          background: #d8e8fe;
          border: 1px solid #2782ff;
        }

        &.active:before {
          background: #2782ff;
          left: 24px;
        }
      }
    }

    button.deploy {
      background: none;
      border: none;
      color: #2782ff;
      font-size: 13px;
      margin-top: 5px;
      text-align: left;
    }

    > ul {
      list-style: none;

      > li {
        cursor: pointer;
        display: flex;
        font-size: 13px;
        justify-content: space-between;

        > .checkbox {
          align-items: center;
          color: #333;
          display: flex;

          > div:first-of-type {
            align-items: center;
            background: #fff;
            border: 1px solid #ccc;
            border-radius: 3px;
            display: flex;
            flex-shrink: 0;
            height: 14px;
            justify-content: center;
            margin-right: 10px;
            width: 14px;
          }
        }

        > span {
          color: #999;
        }
      }
    }
  }
}
