@import "@skillup/ui";
@import "uiAssets/ColorsVars.scss";

.CompletionRates {
  margin-top: 20px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: right;

  > h2 {
    border-bottom: 1px solid $blue;
    color: $blue;
    font-size: 16px;
    margin-bottom: 15px;
    padding-bottom: 10px;
  }

  .Card {
    flex: 1;
  }

  .result {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;

    .value {
      font-size: 13px;
    }

    .rates {
      display: flex;

      span {
        font-size: 13px;

        &.percentage {
          min-width: 80px;
          text-align: right;
        }
      }
    }
  }
  .content {
    display: flex;
    margin-top: -0.5rem;
    margin-right: 1rem;
    .column {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-right: $skui-spacing-s;
      font-family: "Noto Sans Display";
      font-size: 0.8rem;
      color: $plainText-onLight-lighter;
      p {
        font-weight: 600;
      }
      label {
        align-items: flex-end;
      }
      .title {
        align-items: flex-end;
      }
      & > * {
        display: flex;
        align-items: flex-end;
        min-height: 1.7rem;
      }
      &:first-child {
        align-items: flex-start;
      }
    }
  }
  .Card {
    max-width: 18rem;
    height: 100px;
    .header {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .percentage {
        font-size: 2rem;
        font-weight: 400;
        color: $decoration-blue-darker;
        margin-right: 1rem;
      }
      p {
        font-size: 0.8rem;
        max-width: 15rem;
      }
    }
  }
}
