@import "@skillup/ui";

.StructureBuilder {
  width: 100%;
  height: calc(100% - 5rem);;
  .structure {
    width: 19rem;
    padding: 0;
    background-color: $surface-light;
    height: 100%;
    overflow: auto;
    scroll-behavior: smooth;
  }
  .content {
    width: calc(100% - 19rem);
    height: 100%;
    overflow: auto;
    background-color: $decoration-blue-darker;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $skui-spacing-xxl;
  }
}

.structure__builder__layout {
  overflow: hidden !important;
}

.header__buttons__undoRedo {
  display: flex;
  flex-direction: row;
  gap: $skui-spacing-s;
}

.toaster__button {
  padding-left: $skui-spacing-l;
  > span {
    &:first-of-type {
      padding-right: $skui-spacing-xs;
    }
  }
}

.toaster__subtext {
  @include espace-rh-caption-bold;
}

.loader {
  svg {
    fill: $surface-light;
    width: 1.5rem;
    height: 1.5rem;
  }
}
