@import "uiAssets/ColorsVars.scss";

.ScheduleImportModal {
  padding: 10px;

  > .dropZone {
    align-items: center;
    border: 3px dashed #999 !important;
    border-radius: 7px;
    color: #666;
    font-size: 13px;
    font-weight: 700;
    margin: 0 auto 10px;
    max-width: 100%;
    text-align: center;
    user-select: none;

    > div {
      align-items: center;
      cursor: pointer;
      display: flex;
      height: 100px !important;
      justify-content: center;
      width: 100%;
    }
  }

  h2 {
    border-bottom: 1px solid $blue;
    color: $blue;
    font-size: 16px;
  }
}
