@import "../../../assets/designSystem";

.TableHeaderCell {
  display: inline-block;
  &.iconOnly {
    width: 5rem;
  }
  @media (min-width: 1000px) {
    &.sticky {
      position: sticky;
      left: var(--parent-offset);
      background-color: $surface-light;
      z-index: 10;

      &.scroll {
        &::before {
          content: "";
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          width: 4px;
          box-shadow: $panel-shadow-right;
        }
      }
    }
  }
  &.col-type-date {
    width: 7rem;
  }

  > div.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: $plainText-onLight-lighter;
    position: relative;

    .headerTitle {
      display: flex;
      align-items: center;

      &.hasIcon {
        min-width: 15px;
      }

      &:not(.hasIcon),
      &:not(.hasIcon) > span {
        display: flex;
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .headerIcon {
      cursor: pointer;
      z-index: 1;
      visibility: hidden;
      position: relative;
      display: flex;
      align-items: center;

      &.compact {
        button {
          margin: 0 !important;
          padding: 0 !important;
        }
      }

      button {
        margin: 0.5rem 0.25rem;
        padding: 0.5rem 0;
      }

      &.selectedIcon {
        opacity: 1;
        &:not(.active) .badge {
          display: block;
        }
      }

      .badge {
        display: none;
        width: 0.25rem;
        height: 0.25rem;
        border-radius: 0.125rem;
        background-color: $action-notification;
        position: absolute;
        right: 0.2rem;
        top: 0.875rem;
        &.compact {
          right: 0;
          top: 0;
        }
      }
    }

    .iconTip {
      display: inline-flex;
      svg {
        width: 1rem;
        height: 1rem;
      }
    }

    &:hover {
      color: $greyscale-darker;
    }

    .actions {
      flex-grow: 1;
      display: flex;
    }

    .actions.left {
      margin-right: 0.5rem;
      justify-content: flex-end;
    }

    .actions.right {
      margin-left: 0.5rem;
      justify-content: flex-start;
    }

    &.alignRight {
      .actions.left {
        display: flex;
      }
      .actions.right {
        display: none;
      }
      &:hover .actions.left .headerIcon {
        visibility: visible;
      }
    }

    &.alignCenter {
      .actions.left .headerIcon {
        visibility: hidden;
      }
      .actions.right {
        display: flex;
      }
      &:hover .actions.right .headerIcon {
        visibility: visible;
      }
    }

    &.alignLeft {
      .actions.left {
        display: none;
      }
      .actions.right {
        display: flex;
      }
      &:hover .actions.right .headerIcon {
        visibility: visible;
      }
    }

    &.showFilters {
      .headerIcon.selectedIcon {
        visibility: visible;
      }
    }
  }
}

.filterForm {
  width: 250px;
  &.largeFilterForm {
    width: 350px;
  }

  padding: 1rem 0;
  background-color: $surface-light;
  border: 1px solid rgba(105, 121, 140, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 4px 4px;
  font-weight: normal;

  .filterHeader {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    margin-bottom: 1rem;

    .filterTitle {
      flex: 1;
      color: $plainText-onLight-lighter;
      font-weight: 600;
      font-size: 0.875rem;
    }

    .filterClear {
      button {
        padding: 0rem 0.5rem;
        color: $greyscale-dark;
      }
    }
  }

  .filterContent {
    padding: 0 1rem;
  }
}
