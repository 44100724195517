@import "../../../../assets/designSystem";


.toolbar {
  justify-content: space-between;
  align-items: center;
  height: 2rem;
  margin-bottom: $skui-spacing-xxs;

  .left {
    gap: $skui-spacing-xs;
    .text {
      color: $plainText-onLight-lighter;
    }
  }
  .right {
    gap: $skui-spacing-xs;
  }
}