@import "@skillup/ui";

.dragzone {
  display: flex;

  > div.availableFields {
    width: auto;
    height: 600px;
    overflow: hidden;
    flex-shrink: 0;
    margin-right: 15px;
  }

  > div.tabs {
    flex-grow: 1;
    height: 600px;
    overflow: auto;
  }
}

div.section {
  overflow: hidden;
  min-width: 250px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  padding: 0 10px;
  background-color: #ebecf0;
  height: 600px;

  h3 {
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    padding: 10px 0;
  }

  > button {
    margin: 0 auto;
  }

  .list {
    overflow: auto;
    height: calc(100% - 41px);
    padding: 0 10px;
    margin-top: 10px;

    > .field {
      display: flex;
      flex-direction: column;
      background: #fff;
      border-radius: 10px;
      padding: 10px;
      margin-bottom: 7px;
      box-shadow: 0 1px 0 rgba(9, 30, 66, 0.25);
      align-items: flex-start;

      > div.fieldType {
        background: $action-onLight-darkest;
        color: #fff;
        width: auto;
        height: 17px;
        line-height: 16px;
        padding: 0 8px;
        border-radius: 4px;
        font-size: 11px;
        font-weight: 500;
        margin-bottom: 0.5rem;
      }

      > div.fieldBinding {
        background: $action-onLight-default;
        color: #fff;
        width: auto;
        height: 17px;
        line-height: 16px;
        padding: 0 8px;
        border-radius: 4px;
        font-size: 11px;
        font-weight: 500;
        margin-bottom: 0.5rem;
      }

      > div.fieldLabel {
        font-size: 0.85rem;
        font-weight: 600;
      }
    }
  }
}

div.horizontal {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 150px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  padding: 10px;
  background-color: #ebecf0;

  h3 {
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    padding-left: 10px;
  }

  > .list {
    display: flex;
    flex-direction: row;
    padding: 10px;
    overflow: auto;
    flex-shrink: 0;

    > .field {
      margin-right: 10px;
      min-width: 190px;
      display: flex;
      flex-direction: column;
      background: #fff;
      border-radius: 10px;
      padding: 10px;
      box-shadow: 0 1px 0 rgba(9, 30, 66, 0.25);
      align-items: flex-start;

      > div.fieldType {
        background: $action-onLight-darkest;
        color: #fff;
        width: auto;
        height: 17px;
        line-height: 16px;
        padding: 0 8px;
        border-radius: 4px;
        font-size: 11px;
        font-weight: 500;
        margin-bottom: 0.5rem;
      }

      > div.fieldBinding {
        background: $action-onLight-default;
        color: #fff;
        width: auto;
        height: 17px;
        line-height: 16px;
        padding: 0 8px;
        border-radius: 4px;
        font-size: 11px;
        font-weight: 500;
        margin-bottom: 0.5rem;
      }

      > div.fieldLabel {
        font-size: 0.85rem;
        font-weight: 600;
      }
    }
  }
}
