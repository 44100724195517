@import "../../assets/colors";

.Avatar {
  border-radius: 50%;
  flex-shrink: 0;
  width: 3.625rem;
  height: 3.625rem;

  &.small {
    width: 2rem;
    height: 2rem;

    span.label {
      font-size: 0.875rem;
      font-weight: 500;
    }
  }

  &.loading {
    background-color: $status-violet;

    span.label {
      display: none;
    }
  }

  span.label {
    font-size: 1rem;
    line-height: 1.21;
    letter-spacing: 0.15px;
    text-align: center;
    font-weight: bold;
    user-select: none;
  }
}
