@import "uiAssets/ColorsVars.scss";

.appModalContainer .appModalDialog {
  height: auto;
}

.UserSelectionModal {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  flex-shrink: 0;
  background: #fff;

  > .info {
    padding: 15px 25px;
    width: 100%;
    font-size: 0.9rem;
    border-bottom: 1px solid #eaeaf1;

    > span {
      font-weight: bold;
    }
  }

  > .footer {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 20px 40px;
    width: 100%;
    border-top: 1px solid #eaeaf1;
  }

  > div.tab {
    height: 30px;
    margin: auto;
    margin-bottom: 20px;
    padding: 0 40px;
    position: relative;

    > div.backgroundBar {
      background: $borderColorAlt;
      height: 5px;
      left: 9%;
      position: absolute;
      width: 80%;
    }

    > div.progressBar {
      background: $gradientBlue;
      height: 5px;
      left: 9%;
      position: absolute;
      transition: width 0.1s ease-in;
      width: 40%;
    }

    > .step {
      align-items: center;
      background: #fff;
      display: flex;
      font-size: 12px;
      height: 50px;
      justify-content: center;
      position: absolute;
      text-align: center;
      top: -23px;
      width: 100px;

      &.form {
        border: 3px solid $borderColorAlt;
        left: 22%;
      }

      &.recap {
        border: 3px solid $borderColorAlt;
        margin-top: 40px;
        right: 22%;
      }

      &.active {
        border: 3px solid $blue;
      }
    }

    &.recap  {
      margin-top: 40px;
      > div.progressBar {
        width: 80%;
      }
    }
  }
}
