@import "@skillup/ui";

.searchInput {
  width: 100%;

  input {
    width: 100%;
    border-color: $border-onLight;
    font-size: $skui-spacing-s;
    &::placeholder {
      font-style: italic;
    }
  }
  svg {
    fill: $plainText-onLight-lighter;
    height: $skui-spacing-m;
    width: $skui-spacing-m;
    margin-left: -$skui-spacing-l;
  }
}

.description {
  overflow-wrap: break-word;

  h2 {
    @include espace-rh-h5;
  }

  h3 {
    font-weight: 600;
  }

  li {
    margin-left: $skui-spacing-m;
  }
}
