@import "../../../../assets/designSystem";

.DataTableCell {
  border-top: solid 1px $surface-light;
  border-bottom: solid 1px $surface-light;
  color: $plainText-onLight-default;
  padding: $skui-spacing-s;
  &.compact {
    padding: $skui-spacing-xs;
    border-top: 1px solid $border-onLight;
    border-bottom: 1px solid $border-onLight;
  }

  .tooltip {
    width: 100%;
  }

  &.rtl {
    text-align: right;
  }

  &.center {
    text-align: center;
  }

  @media (min-width: 1000px) {
    &.sticky {
      position: sticky;
      left: var(--parent-offset);
      background-color: $surface-light;
      z-index: 10;

      &.scroll {
        &::before {
          content: "";
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          width: 4px;
          box-shadow: $panel-shadow-right;
        }
      }
    }
  }

  .cell {
    .content {
      display: flex;

      &.customWidth {
        margin: -1rem;
        padding: 1rem;
        &.compact {
          margin: -0.5rem;
          padding: 0.5rem;
        }
      }

      .textWrapper {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        box-sizing: content-box;
        margin-right: -1px;
      }
    }
  }

  &.editable {
    padding: 0.25rem;
    .cell {
      padding: 0.75rem;
      input {
        border: none;
      }

      .addIcon {
        display: inline-block;
        opacity: 0;
      }

      &:hover {
        background-color: white !important;
        cursor: url("../../assets/edit.png"), crosshair;
        .pencil {
          visibility: visible;
        }
        .addIcon {
          opacity: 1;
        }
      }
    }
  }
}
