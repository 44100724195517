@import "../../assets/colors";

.Checkbox {
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  .square {
    align-items: center;
    width: 1rem;
    height: 1rem;
    border: 1px solid $bluey-grey;
    background-color: white;
    box-sizing: border-box;
    border-radius: 1px;
    margin-right: 0.5rem;
    flex-shrink: 0;
    position: relative;

    .inputHidden {
      display: none;
      cursor: inherit;
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      margin: 0;
      padding: 0;
      z-index: 1;
    }
  }

  span.label {
    font-size: 0.875rem;
    line-height: 1.21;
    letter-spacing: 0.15px;
    text-align: left;
    color: $b-w-black;
    user-select: none;
  }

  &.checked {
    .square {
      border-color: $secondary-blue;
      background-color: $secondary-blue;
    }

    span.label {
      font-weight: 500;
    }
  }

  &.readOnly {
    span.label {
      color: $bluey-grey;
    }

    &:hover {
      cursor: not-allowed;
    }
  }

  &.readOnly.checked {
    .square {
      border-color: $bluey-grey;
      background-color: $bluey-grey;
    }

    span.label {
      color: $b-w-black;
    }
  }

  &.errored {
    .square {
      border-color: $secondary-red;
    }
  }

  &.checked.errored {
    .square {
      border-color: $secondary-red;
      background-color: $secondary-red;
    }
  }
}
