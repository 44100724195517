@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.RequiredSkillModal {
  border-radius: 8px;

  > .blueTop {
    position: static;
    width: 450px;
    height: 10px;
    left: 0px;
    top: 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: $gradientBlue;
  }

  > .header {
    padding: 20px;

    > h2 {
      color: $blue;
      font-weight: 600;
      margin-bottom: 4px;
    }
  }

  > .content {
    padding: 0 20px;

    textarea {
      height: 80px;
      font-size: 13px;
      font-weight: 400;
      line-height: 15.83px;
      color: $b-w-black;
    }
  }

  > .levels {
    h3 {
      font-weight: 500;
      font-style: normal;
      font-size: 12px;
      line-height: 14, 06px;
      letter-spacing: 0.8px;
      margin-bottom: 16px;
    }
    padding: 0 20px;
  }

  > .actions {
    padding: 0 20px 20px 20px;
    margin-top: 35px;
    display: flex;
    justify-content: flex-end;
  }
}
