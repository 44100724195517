@import "@skillup/ui";

.ClosedEndedQuestion {
  .answers {
    margin-top: 1rem;
    .answerContainer {
      cursor: pointer;
      .answerHeader > label {
        cursor: pointer;
      }
      .avatar > button {
        cursor: pointer;
        label {
          cursor: pointer;
        }
      }
    }
  }
  article {
    padding-bottom: $skui-spacing-xxs !important;
  }
}
