@import "../../../assets/mixins";
@import "../../../assets/colors";

.DateRangeInput {
  position: relative;
  min-width: 16rem;
  margin-right: 0.75rem;

  .label {
    padding-left: 0.625rem;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: 0.15px;
    color: $b-w-black;
    user-select: none;

    &.requiredField:after {
      content: " *";
      color: $secondary-red;
    }
  }

  .periodInputContainer {
    position: relative;

    .input {
      cursor: default;
      padding-left: 2rem;
      margin-top: 0.375rem;
      margin-bottom: 0.375rem;

      &::placeholder {
        font-style: italic;
      }
      &.focus {
        border-color: $primary-blue;
      }

      &.invalid {
        border-color: $secondary-red;
      }
    }

    .clearPeriodBtn {
      background-color: $light-grey;
      position: absolute;
      right: 0.625rem;
      bottom: 1rem;
      display: none;

      > svg[role="icon"] > path {
        fill: $primary-blue;
      }

      &:hover {
        cursor: pointer;
        background-color: $pale-lilac;
      }
    }

    &:hover .clearPeriodBtn {
      display: block;
    }

    .calendarIcon {
      position: absolute;
      left: 0.625rem;
      bottom: 1rem;
    }
  }

  @include isMobile {
    width: 100%;
    margin-right: 0;
  }
}
