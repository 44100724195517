@import "uiAssets/ColorsVars.scss";

.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 12px;
  border: none;
  border-radius: 7px;
  font-size: 12px;
  height: 30px;
  font-weight: 500;
  user-select: none;
  background: $gradientBlue;
  box-shadow: $containerShadow;
  color: $mainColorL;
  cursor: pointer;

  &:disabled {
    background: $borderColor;
    color: $borderColorAlt;
    cursor: not-allowed;
  }
}
