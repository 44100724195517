.TargetsHistoryImport {
  .dropZone {
    align-items: center;
    border: 3px dashed #999 !important;
    border-radius: 7px;
    color: #666;
    font-size: 13px;
    font-weight: 700;
    margin: 0 auto 10px;
    max-width: 100%;
    text-align: center;
    user-select: none;

    > div {
      align-items: center;
      cursor: pointer;
      display: flex;
      height: 100px !important;
      justify-content: center;
      width: 100%;
    }
  }

  .baseContainer {
    border-radius: 8px;
    margin: 8px 0;
    color: white;
    padding: 8px;
  }

  .errorsContainer {
    @extend .baseContainer;
    background: #ff6760;
  }

  .warningsContainer {
    @extend .baseContainer;
    background: #f4cc0c;
  }

  .successContainer {
    @extend .baseContainer;
    background: #0eaf85;
  }

  .buttonContainer {
    display: flex;

    > button {
      margin-right: 16px;
    }
  }
}
