@import "@skillup/ui";

.EvaluatedUserTargetsDataGrid {

  .campaignSelect {
    width:30rem;
  }
  // This is a hack to center the DSProgressbar, I have no idea how to do it without changing the DSProgressbar component itself
  .datagrid__evaluation--progressbar {
    div {
      margin:0!important;
    }
    section {
      margin:0!important;
    }
  }

  .container__footer {
    margin-top: $skui-spacing-s;
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
}
