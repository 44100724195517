.SummonForIntra {
  padding: 24px;

  & > button {
    margin: auto;
    padding: 16px;
    height: 48px;
    margin-top: 24px;
  }
}
