@import "@skillup/ui";

.ArchivedTemplatesModal {
  min-width: 70%;
  .search {
    position: sticky;
    z-index: 9;
    top: 0;
  }
  .list {
    width: 100%;
    display: flex;
    padding-top: $skui-spacing-xs;
    gap: $skui-spacing-xs;
  }

  .list__item {
    align-items: center;
    justify-content: space-between;
  }
  .list__item__content {
    flex-direction: column;
    gap: 0;
  }
  .list__item__content__title {
    @include espace-rh-body1-regular;
    color: $plainText-onLight-default;
  }
  .list__item__content__subtitle {
    @include espace-rh-caption-regular;
    color: $plainText-onLight-lighter;
  }
  .list__item--empty {
    justify-content: center;
    padding: 0.9375rem 1.875rem;
  }
}
