@import "@skillup/ui";

.header {
  background: $surface-dark;
}

.toggle {
  // TODO: For some reason the toggle component doesn't seem to be suited for icons,
  // therefore this css fixes that but in multiple places in the app there are other fixes,
  // we should refacto the toggleButtongroup to receive icons properly
  display: flex;
  justify-self: flex-end;

  button {
    padding: 0.25rem 0.5rem;
  }
}

.peopleReviewPage {
  background: $surface-light-darker;
  overflow: auto;
}

.drawer {
  display: flex;
}
