@import "uiAssets/ColorsVars.scss";

.Actions {
  background: #fff;
  border-radius: 7px;
  box-shadow: 0 6px 28px rgba(20, 55, 95, 0.5);
  color: #333;
  cursor: pointer;
  font-weight: 500;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 55px;
  transform: translateY(50px);
  transition: transform 75ms ease-in-out, opacity 75ms ease-in-out;
  width: 300px;
  z-index: 5;

  &:before {
    background-color: #fff;
    box-shadow: rgba(40, 139, 255, 0.2) 0 0 20px;
    content: "";
    height: 20px;
    position: absolute;
    right: 23px;
    top: -5px;
    transform: rotate(-45deg);
    width: 20px;
    z-index: -1;
  }

  > div {
    background-color: #fff;
    font-size: 13px;
    padding: 15px 20px;
    user-select: none;
    z-index: 5;

    &:first-of-type {
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
    }

    &:last-of-type {
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
    }

    &:not(:first-of-type) {
      border-top: 1px solid #eee;
    }

    &:hover {
      background: $gradientBlue;
      color: #fff;
    }
  }
}
