@import "uiAssets/ColorsVars.scss";

.checkbox {
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  justify-content: left;
  left: 0;
  padding-left: 15px;
  padding-top: 0;
  position: sticky;
  user-select: none;
  width: 40px;
  z-index: 9;

  > div {
    align-items: center;
    border: 2px solid #ccc;
    border-radius: 2px;
    display: flex;
    height: 16px;
    justify-content: center;
    transition: background 0.2s ease-out;
    width: 16px;

    svg {
      opacity: 0;
      pointer-events: none;
      stroke: $blue !important;
      stroke-width: 10px !important;
    }
  }
}

.checkbox.isSelected {
  fill: none;
  overflow: visible;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 6;
  transition: stroke 0.2s ease-o;

  & > div {
    background-color: $blue;
    border-color: #2782ff;

    & > svg {
      opacity: 1;
      stroke: rgb(255, 255, 255) !important;
    }
  }
}
