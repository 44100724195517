@import "uiAssets/ColorsVars.scss";

.DataTable {
  display: flex;
  font-size: 13px;

  > .wrapper {
    height: 100%;
    overflow: hidden;
    width: 100%;
    z-index: 0;

    > header {
      align-items: center;
      background: #f6f6f6;
      display: flex;
      height: 50px;
      justify-content: space-between;

      > h1 {
        align-items: center;
        color: #666;
        display: flex;
        font-size: 16px;
        padding: 0 15px;

        > span {
          background-color: $blue;
          border-radius: 17px;
          color: #fff;
          font-size: 13px;
          font-weight: 700;
          margin: 0 7px;
          padding: 0 5px;
        }

        > svg {
          display: none;
          margin-right: 10px;
        }
      }

      > div {
        align-items: center;
        display: flex;
        padding: 0 15px;
      }
    }

    > .tableWrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 0;
      position: relative;

      .checkbox {
        align-items: center;
        background: transparent;
        background-color: #fff;
        border: none;
        cursor: pointer;
        display: flex;
        flex-shrink: 0;
        justify-content: left;
        left: 0;
        padding-left: 15px;
        padding-top: 0;
        position: sticky;
        user-select: none;
        width: 40px;
        z-index: 9;

        > div {
          align-items: center;
          border: 2px solid #ccc;
          border-radius: 2px;
          display: flex;
          height: 16px;
          justify-content: center;
          transition: background 0.2s ease-out;
          width: 16px;

          > svg {
            opacity: 0;
            pointer-events: none;
            stroke: $blue !important;
            stroke-width: 10px !important;
          }
        }
        &.addAll {
          border-bottom: 2px solid $blue;
          left: 0;
          position: absolute;
          top: 0;
          z-index: 10;

          > div {
            border-color: #666;
          }

          &:hover > div {
            border-color: $blue;
          }
        }
        &.selected {
          > div {
            background-color: $blue;
            border-color: $blue;

            > svg {
              opacity: 1;
              stroke: #fff !important;
            }
          }
        }
      }

      > .rows {
        flex-grow: 1;
        overflow: scroll;
        position: relative;
        will-change: top;

        > .emptySign {
          align-items: center;
          display: flex;
          margin-top: 150px;

          > svg {
            display: block;
            margin: 20px auto;
            opacity: 0.5;
            width: 150px;
          }
        }

        > .headerRow {
          border-bottom: 2px solid $blue;
          display: flex;
          flex-direction: row;
          flex-shrink: 0;
          height: 100%;
          margin-left: 40px;
          overflow: hidden;
          position: sticky;
          top: 0;
          will-change: left;
          z-index: 9;

          > .headerCell {
            flex-grow: 0;
            flex-shrink: 0;
            height: 100%;
            position: relative;

            > .content {
              align-items: center;
              background: #fff;
              border-left: 2px solid transparent;
              border-right: 2px solid transparent;
              display: flex;
              flex-direction: row;
              height: 100%;
              justify-content: space-between;
              padding: 0 8px;
              position: relative;
              transition: background 150ms ease-in-out;

              > div {
                display: flex;
                justify-content: space-between;
                width: 100%;

                > h3 {
                  align-items: center;
                  background: transparent;
                  border: none;
                  display: flex;
                  flex-grow: 1;
                  overflow: hidden;
                  padding: 5px 0;
                  user-select: none;

                  > span {
                    font-weight: 700;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }

                > button {
                  background-color: #eee;
                  border: none;
                  border-radius: 1px;
                  margin: 5px 0;
                  opacity: 1;
                  padding: 1px 4px;
                  transition: opacity 0.2s ease-out;
                  cursor: pointer;

                  &.active {
                    > svg {
                      fill: $blue !important;
                    }
                  }
                }
              }
            }

            > .content.isActive,
            > .content.isSelected {
              background: $gradientBlue;
              border: none;

              > div {
                > h3 {
                  background: transparent;
                  color: #fff;
                }
                > button {
                  background: transparent;
                  background-color: rgba(255, 255, 255, 0.2);
                  opacity: 1;
                  > svg {
                    stroke: #fff !important;
                  }
                  &.active {
                    > svg {
                      fill: #fff !important;
                    }
                  }
                }
              }
            }
            > .content.isSelected {
              cursor: move;
            }
            > .content:hover {
              background-color: #fafafa;
            }
            > .resizer {
              background: transparent;
              cursor: col-resize;
              height: 100%;
              position: absolute;
              right: 0;
              top: 0;
              width: 5px;
            }
            > .resizer:hover {
              background: $blue;
              opacity: 0.8;
            }
          }
        }

        > .bodyRow {
          border-bottom: 1px solid $dataTableRegularBorder;
          border-top: 1px solid transparent;
          display: flex;
          flex-direction: row;
          left: 0;
          position: absolute;
          top: 0;
          will-change: top;

          &.loading {
            opacity: 0.5;

            > .cell {
              cursor: default;
              .loading {
                width: 70%;
                height: 8px;
                margin-top: 6px;
                background-color: black;
                animation: loadingText 0.6s infinite;
              }
            }
          }

          > .cell {
            cursor: pointer;
            height: 44px;
            padding: 13px 10px;

            &:not(.displayFlex) {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            &.displayFlex {
              align-items: center;
              display: flex;
              flex-grow: 0;
              flex-shrink: 0;
              justify-content: flex-start;
            }

            > * {
              cursor: default;
            }

            > input {
              align-items: center;
              background: transparent;
              border: none;
              display: flex;
              flex-grow: 1;
              height: 28px;
              text-overflow: ellipsis;
              width: 100%;
            }

            > a {
              background: none;
              border: none;
              color: $blue;
              cursor: pointer !important;
              flex-grow: 1;
              height: 28px;
              overflow: hidden;
              text-decoration: none;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 100%;
            }

            &.editable {
              background: #f6fbff;

              > * {
                cursor: inherit;
              }

              &:hover,
              &:focus {
                > input {
                  background: #fff !important;
                }
                background: #fff !important;
                border-left: 2px solid #ddd;
                border-right: 2px solid #ddd;
                padding-left: 8px;
                padding-right: 8px;
              }
            }

            &.colHovered {
              background-color: $dataTableActiveLine;
              border-left: 1px solid $dataTableRegularBorder;
              border-right: 1px solid $dataTableRegularBorder;
              padding-left: 9px;
              padding-right: 9px;
            }
          }

          &.selected {
            font-weight: 500;
          }

          &.selected,
          &:hover:not(.selected) {
            > .checkbox,
            > .cell,
            > .cell > input,
            > .cell > a {
              background: $dataTableActiveLine;
            }

            > .cell.colHovered {
              background: #eaeaea;
            }
            z-index: 3;
          }
          &:hover:not(.selected) > .checkbox > div {
            border-color: $blue !important;
            > svg {
              opacity: 0.3;
            }
          }
        }
      }
    }
  }

  > .actions {
    display: flex;
    flex-wrap: wrap;
    padding-left: 55px;

    > button {
      margin: 0 10px 10px 0 !important;
    }
  }
}

@keyframes loadingText {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.5;
  }
}
