@import "@skillup/ui";

.gridCell {
  border-bottom: 1px solid $border-onLight;
  padding: $skui-spacing-xs 0;
  padding-right: $skui-spacing-s;

  &:nth-last-child(-n+3) {
    border-bottom: none;
  }
}


.gridCellTitle {
  grid-column: 1/4;
  border-bottom: 1px solid $border-onLight;
  padding-bottom: $skui-spacing-xs;
  padding-top: $skui-spacing-l;
  
  &:first-of-type {
    padding-top: 0;
  }
}
