@import "@skillup/ui";

.customField {
  margin-bottom: $skui-spacing-s;
  
  input {
    font-style: normal !important; 
    &::placeholder{
      font-style: italic !important; 
    }
  }

  .datePicker {
    background-color: white;
  }
}
