@import "../../../assets/designSystem";

div.HorizontalDivider {
  display: block;
  width: 100%;
  height: 1px;
  border-top: 1px solid;
  border-color: $border-onLight;

  &.onDark {
    border-color: $border-onDark;
  }

  @each $key, $value in $spacing-map {
    &.mt-#{$key} {
      margin-top: $value;
    }

    &.mb-#{$key} {
      margin-bottom: $value;
    }
  }
}
