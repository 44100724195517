@import "@skillup/ui";

.content {
  width: calc(100% - 19rem);
  height: 100%;
  overflow: auto;
  scroll-behavior: smooth;
  padding: 0 $skui-spacing-l;
  background-color: $decoration-blue-darker;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $skui-spacing-xxl;
  padding-bottom: 5rem;
}

.content__item {
  width: 100%;
  background: #fff;
  opacity: 0.3;
  &:focus {
    opacity: 1;
  }
}
.content__item:not(.content__item--highlight) > * {
  pointer-events: none;
}

.content__item--highlight {
  opacity: 1;
}

.content__item--highlight + .content__item__addButton {
  opacity: 1;
}

.content__item__addButton {
  opacity: 0.3;
  &:has(+ .content__item--highlight) {
    opacity: 1;
  }
}
