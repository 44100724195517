@import "../../../assets/designSystem";

.Checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-left: 2px;

  &:hover:not(.disabled) {
    background-color: $effects-hover-onLight;
    .box svg path {
      fill: $decoration-blue-lighter !important;
    }
    border-radius: 2px;
  }
  &.focused {
    outline: 2px solid $action-onLight-default;
    outline-offset: 1px;
    border-radius: 2px;
    background-color: $effects-hover-onLight;
  }

  .box {
    position: relative;
    display: flex;
    align-items: center;
    align-self: flex-start;
    border: 1px solid $plainText-onLight-lighter;
    border-radius: 2px;
    width: 1rem;
    height: 1rem;
    padding-left: 1px;
    margin-top: 0.2rem;
    cursor: pointer;
    &.checked,
    &.indeterminate {
      background: $brand-blue;
      border-color: $brand-blue;
      svg path {
        fill: $surface-light;
      }
      &.disabled {
        background: $plainText-onDark-darker;
      }
      &:hover {
        svg path {
          fill: $decoration-blue-lighter !important;
        }
      }
    }
    &.disabled {
      border-color: $plainText-onDark-darker;
      cursor: initial;
    }
    svg {
      z-index: 0;
      path {
        fill: transparent;
      }
    }
    &.indeterminate svg {
      top: 6px;
      left: 2px;
    }
  }

  input {
    position: absolute;
    left: 0;
    opacity: 0;
  }

  .textWrapper {
    margin-left: 0.375rem;
    margin-right: 0.125rem;
    display: flex;
    flex-direction: column;
    &.checked,
    &.indeterminate {
      .label {
        font-weight: 600;
      }
    }
    &.disabled {
      .label {
        opacity: 0.3;
        cursor: initial;
      }
      .helpText {
        cursor: initial;
      }
    }
    .label {
      cursor: pointer;
      color: $plainText-onLight-default;
    }
    .helpText {
      cursor: pointer;
      color: $plainText-onLight-lighter;
    }
  }

  &.espace-collab {
    @include espace-collab-components-input-value;
    .helpText {
      @include espace-collab-components-input-help-text;
    }
  }

  &.espace-rh {
    @include espace-rh-components-input-value;
    .helpText {
      @include espace-rh-components-input-help-text;
    }
  }
}
