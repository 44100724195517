@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.RequiredSkill {
  padding: 12px 20px 15px 20px;
  width: 100%;
  height: auto;
  background: #fff;
  border: 1px solid #eaeaf1;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(234, 234, 241, 0.45);
  border-radius: 10px;
  margin: 14px 0px;
  position: relative;

  > .actionsToggle {
    position: absolute;
    top: 11px;
    right: 14px;
    cursor: pointer;
  }

  > .actions {
    position: absolute;
    width: 202px;
    background: #fff;
    top: 28px;
    right: 20px;
    border: 1px solid $pale-lilac;
    border-radius: 8px;
    padding: 15px;
    cursor: pointer;

    > span {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 12px;
      font-weight: 600;
      color: $slate;

      &:hover {
        color: $b-w-black;
      }

      > svg {
        margin-right: 11px;
        margin-top: 2px;
      }
    }
  }

  > .separator {
    height: 1px;
    width: 100%;
    background: #eaeaf1;
    margin: 10px 0px;
  }

  > .header {
    display: flex;
    flex-direction: column;
    cursor: pointer;

    > .label {
      color: $b-w-black;
      font-weight: 600;
      line-height: 17px;
      font-size: 14px;
    }

    > .description {
      color: $slate;
      font-size: 12px;
      font-weight: normal;
      line-height: 15px;
      margin-top: 3px;
    }
  }

  > .body {
    font-family: "Segoe UI";
    font-size: 12px;
    line-height: 16px;
    color: $slate;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    > .expectedLevel_label {
      margin-bottom: 10px;
      font-weight: 600;

      > span {
        font-size: 13px;
        color: $blueDarker;
      }
    }
  }
}
