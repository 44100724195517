@import "uiAssets/ColorsVars.scss";

.view {
  align-items: center;
  background: #fafafa;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  justify-content: center;
  min-height: 100vh;

  > form {
    background: #fff;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    display: block;
    max-width: 475px;
    overflow: hidden;
    padding-top: 10px;
    position: relative;
    width: calc(100% - 20px);

    &:before {
      background: $gradientBlue;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      content: "";
      display: block;
      height: 10px;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    > header {
      align-items: center;
      border-bottom: 1px solid #eee;
      display: flex;
      flex-direction: column;
      justify-content: center;

      > svg {
        fill: #666 !important;
        height: 76px;
        padding-top: 30px;
        width: 115px;
      }

      > h1 {
        color: $blue;
        font-size: 22px;
        padding: 10px 0 30px;
        text-align: center;
      }
    }

    > .formContainer {
      padding: 40px 45px;

      > p {
        margin-bottom: 20px;
        text-align: left;
      }

      .authLabel {
        margin-top: 15px;
        text-align: center;
      }
    }

    > footer {
      background: $gradientBlue;
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
      padding: 20px;
    }
  }
}
