@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.modalContent {
  padding-top: 1rem;

  .leftPanel {
    width: 70%;
    padding: 0 0.1rem;
    overflow-y: auto;
    z-index: 0;

    .trainees {
      display: inline-block;

      &.fullUsersList {
        height: 5.25rem;
      }
    }

    .trainers {
      display: inline-block;
      height: 2.5rem;

      &.fullUsersList {
        height: 4rem;
      }
    }

    .customTextarea {
      width: 100%;
      resize: none;
      background-color: $surface-light-darker;
      border: 1px solid rgba(105, 121, 140, 0.2);
      border-radius: 4px;
      color: #919eae;
      padding: 0.5rem 1rem;
      margin-bottom: 1rem;

      &.fullUsersList {
        overflow: auto;
      }
      .moreUsersLink {
        color: $primary-blue;
        &:hover {
          cursor: pointer;
        }
      }
    }

    .textArea {
      height: 45vh;
      overflow-y: auto;
    }
  }

  .rightPanel {
    width: 30%;
    justify-content: space-between;
    padding-top: 1rem;
    padding-left: 1rem;
    min-height: 20%;

    .sendParams {
      padding-bottom: 2rem;
      overflow-y: auto;

      background-image: linear-gradient(to top, white, white), linear-gradient(to top, white, white),
        linear-gradient(to top, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0)),
        linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0));
      background-position: bottom center, top center, bottom center, top center;
      background-color: white;
      background-repeat: no-repeat;
      background-size: 100% 1rem, 100% 1rem, 100% 0.5rem, 100% 0.5rem;
      background-attachment: local, local, scroll, scroll;

      .sendParamsTitle {
        @include espace-rh-h6;
        color: $plainText-onLight-default;
      }

      .checkboxWithTooltip {
        align-items: center;
      }

      .checkbox {
        > div {
          min-height: $skui-spacing-s;
          min-width: $skui-spacing-s;
        }
        margin-top: $skui-spacing-xs;
        margin-left: $skui-spacing-xs;
        margin-right: $skui-spacing-xs;
      }
    }

    .attachments {
      max-height: 30vh;
      flex-direction: column-reverse;

      overflow-y: auto;
      overflow-x: hidden;

      background-image: linear-gradient(to top, white, white), linear-gradient(to top, white, white),
        linear-gradient(to top, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0)),
        linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0));
      background-position: bottom center, top center, bottom center, top center;
      background-color: white;
      background-repeat: no-repeat;
      background-size: 100% 1rem, 100% 1rem, 100% 0.5rem, 100% 0.5rem;
      background-attachment: local, local, scroll, scroll;

      .fileItem {
        align-items: center;
        justify-content: space-between;
        margin: $skui-spacing-xs $skui-spacing-xxs;

        .infoLabel {
          align-items: center;

          .infoOutline {
            padding-top: $skui-spacing-xxs;
            padding-right: $skui-spacing-xs;
          }
          .fileName {
            @include espace-rh-body2-regular;
            color: $plainText-onLight-default;
            padding-right: $skui-spacing-xs;
          }
        }

        .actions {
          display: flex;

          > p {
            padding: 0 $skui-spacing-xs;
          }

          .fileLoader {
            width: auto;
          }
        }
      }
    }

    .attachmentLink {
      span {
        color: $action-onLight-darker !important;
      }
      svg {
        color: $action-onLight-darker !important;
        margin-right: 0.4em;
        margin-left: -0.2em;
      }
    }
  }

  .contentBottom {
    padding-top: $skui-spacing-xs;

    .alert {
      &:not(:last-of-type) {
        padding-bottom: 1rem;
      }
    }

    .moreUsersLink {
      color: $primary-blue;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
