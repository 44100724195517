@import "@skillup/ui";

.DashboardChildCard {
  width: 100%;

  margin-bottom: $skui-spacing-l;
  .card {
    padding-bottom: 0;
    .header {
      display: flex;
      align-items: flex-start;
      .title {
        flex: 1;
        h2 {
          @include espace-rh-components-card-title;
          color: $plainText-onLight-lighter;
        }
        h3 {
          @include espace-rh-caption-regular;
        }
      }
      .icon {
        background-color: $surface-dark-darker;
        color: $plainText-onDark-darker;
        border-radius: 0.25rem;
        width: 2rem;
        height: 2rem;
        font-size: 1.5rem;
        margin-right: 0.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        .iconWrapper {
          display: flex;
        }
      }
    }
    .cardContent {
      padding: 0;
      position: relative;
    }
    .filters {
      display: flex;
      margin-bottom: $skui-spacing-s;
      .filter {
        .label {
          font-size: 0.75rem;
          color: $plainText-onLight-lighter;
          font-weight: bold;
        }
        .options {
          width: 250px;
        }
      }
    }
    .body {
      overflow: auto;
      &.scrollTop {
        &::before {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 1rem;
          width: 100%;
          content: "";
          background: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.1));

          z-index: 10;
          border-radius: 0 0 4px 4px;
        }
      }
      &.scrollBottom,
      &.scroll {
        &::before {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 1rem;
          width: 100%;
          content: "";
          background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0));
          background-repeat: no-repeat;
          background-size: 100% 1rem, 100% 1rem, 100% 0.5rem, 100% 0.5rem;
          z-index: 10;
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }
      &.hasFilter {
        max-height: 350px;
      }
      max-height: inherit;
      overflow-y: scroll;
      padding: $skui-spacing-s;

      scrollbar-width: none;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        width: 0;
      }

      &.loading {
        height: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .loadingLogo {
          transform: scale(1.25);
          margin-bottom: 1rem;
        }
        p {
          color: $plainText-onLight-lighter;
          animation: loadingText 0.6s infinite;
          font-size: 0.875rem;
        }
      }
    }
  }
}

@keyframes loadingText {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.6;
  }
}
