@import "@skillup/ui";

.AccountPortalManagement {
  flex-grow: 1;
  font-size: 13px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1;

  &.dark {
    background: #ededed;
  }

  > nav {
    border-bottom: 1px solid #e9e9e9;
    display: flex;
    flex-wrap: wrap;
    background: $surface-light;

    > a {
      align-items: center;
      color: #666;
      display: flex;
      justify-content: center;
      padding: 10px 15px;
      text-decoration: none;
      transition: color 0.2s ease-out, background 0.2s ease-out;
      white-space: pre;

      &.active,
      &:hover {
        background: #f7f7f7;
        color: #171717;
      }

      &.active {
        border-bottom: 2px solid #2782ff;
        font-weight: 500;
        margin-bottom: -1px;
      }
    }
  }

  > .errorContainer {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 100px 20px;
    text-align: center;
  }
}
