.Skills {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;

  li {
    background: #eee;
    border-radius: 5px;
    display: block;
    margin-right: 10px;
    margin-bottom: 10px;

    a {
      color: #444;
      display: block;
      text-decoration: none;
      padding: 7px 10px;
      font-size: 12px;
    }

    &:hover {
      background: #ccc;
    }
  }
}
