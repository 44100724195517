@import "@skillup/ui";

.Dashboard {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $surface-light-darker;
  overflow: auto;

  .content {
    display: flex;
    padding: 1.5rem $skui-spacing-l;
    @media (min-width: 768px) {
      padding: 2rem $skui-spacing-l;
    }

    .main {
      max-width: 75rem;
      margin: auto;

      .DashboardChildren {
        max-width: 40rem;
        margin: auto;
        > h2 {
          @include espace-rh-h2;
          color: $plainText-onLight-lighter;
          margin-bottom: 0;
          &.aloneTitle {
            margin-bottom: $skui-spacing-s;
            margin-top: $skui-spacing-s;
          }
        }
        > h3 {
          @include espace-rh-h4;
          color: $plainText-onLight-lighter;
          margin-bottom: $skui-spacing-xs;
          margin-top: $skui-spacing-s;
        }
      }
    }

    .secondary {
      @media (max-width: 960px) {
        display: none;
      }
    }
  }
  background-color: $surface-light-darker;

  .noInterviews {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 100%;
    height: calc(100vh - 180px);
    > svg {
      margin-top: 2rem;
      width: 21rem;
      height: 21rem;
      mix-blend-mode: luminosity;
    }

    p {
      @include espace-rh-body1-regular;
      max-width: 45rem;
      color: $plainText-onLight-default;
      text-align: center;
    }

    .campaignButton {
      margin-top: 2rem;
    }
  }
}
