@import "uiAssets/ColorsVars.scss";

button.DeveloperEnvironment {
  align-items: center;
  display: flex;
  padding: 4px 20px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: calc(1000 * 1000 * 1000);

  border: none;
  user-select: none;

  background-color: $warning;
  &:hover {
    cursor: pointer;
    background-color: lighten($warning, 3%);
  }
  &:global(.env-development) {
    background-color: $info;
    &:hover {
      background-color: lighten($info, 3%);
    }
  }
  &:global(.env-staging) {
    background-color: $infoD;
    &:hover {
      background-color: lighten($infoD, 3%);
    }
  }
  &:global(.env-demo) {
    background-color: $warningD;
    &:hover {
      background-color: lighten($warningD, 3%);
    }
  }

  span.env {
    color: $mainColorL;
    font-variant: small-caps;
    font-weight: 700;
    text-align: center;
    user-select: none;
    width: 100%;
  }
}
