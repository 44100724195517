@import "uiAssets/ColorsVars.scss";

.containerCheckbox {
  align-items: center;
  display: flex;
  font-size: 14px;
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }

  > div {
    cursor: pointer;
  }

  > div:last-child {
    display: flex;

    > span {
      margin-left: 8px;
    }
  }

  > .checkbox {
    align-items: center;
    background: transparent;
    background-color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    justify-content: left;
    left: 0;
    padding-top: 0;
    position: sticky;
    user-select: none;
    width: 20px;
    z-index: 9;

    > div {
      align-items: center;
      border: 2px solid #ccc;
      border-radius: 2px;
      display: flex;
      height: 16px;
      justify-content: center;
      transition: background 0.2s ease-out;
      width: 16px;

      > svg {
        opacity: 0;
        pointer-events: none;
        stroke: $blue !important;
        stroke-width: 10px !important;
      }
    }

    &.selected {
      > div {
        background-color: $blue;
        border-color: $blue;

        > svg {
          opacity: 1;
          stroke: #fff !important;
        }
      }

      &.disabled {
        > div {
          background-color: $borderColor;
          border-color: $borderColor;
        }
      }
    }

    &.disabled {
      // color: $altScoreColor;
      // cursor: not-allowed;
    }
  }
}
