@import "@skillup/ui";

.renewTracking {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  padding: $skui-spacing-m;

  .inputLine {
    margin-bottom: $skui-spacing-xs;

    .label {
      @include espace-rh-components-input-label;
      color: $plainText-onLight-lighter;
    }

    .input {
      @include espace-rh-components-input-value;
      color: $plainText-onLight-default;
      margin-bottom: 10px;
    }

    &.inline {
      display: inline-block;
      .label {
        display: inline-flex;
      }
      .input {
        display: inline-flex;
        &.inputDate {
          width: 200px;
        }
      }
    }

    .fileDrop {
      margin-top: $skui-spacing-xs;
    }

    .radioBoxes {
      display: flex;
      padding-top: 0.5rem;
      .radioBoxElement {
        margin-right: 1.5rem;
      }
    }

    .warningText {
      margin-top: 0.5rem;
      color: $plainText-onLight-default;
      font-family: "Noto Sans Display";
    }
  }

  .disclaimer {
    margin-top: $skui-spacing-s;
    margin-bottom: $skui-spacing-l;
  }

  .buttonsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .button {
      margin-right: $skui-spacing-xs;
      box-sizing: border-box;

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .trackingItem {
    .trackingHabilitation {
      @include espace-rh-h5;
      color: $plainText-onLight-default;
      margin-bottom: $skui-spacing-xs;
    }
    margin-bottom: $skui-spacing-s;
  }
}
