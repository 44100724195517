@import "uiAssets/ColorsVars.scss";

.editInput {
  align-items: center;
  display: flex;
  height: auto;
  justify-content: space-between;
  min-height: 32px;
  padding: 3px;
  position: relative;

  > label {
    width: 165px;
  }

  > p {
    margin-top: 4px;
  }

  .editPanel {
    flex-grow: 1;
    flex-basis: 0;
    &:not(.editPanelDropdown) {
      overflow: hidden;
      p.dropdownOption {
        white-space: nowrap;
      }
    }
    padding: 0 5px;

    > input {
      border: 1px solid #ccc;
      height: 30px;
      padding-left: 10px;
      width: 100%;
    }

    > textarea {
      border: 1px solid #ccc;
      height: 60px;
      padding-left: 10px;
      width: 100%;
      resize: none;
    }

    > div.dropdown {
      background: #fff;
      border: 1px solid #ccc;
      display: flex;
      flex-direction: column;
      position: relative;

      &.open {
        > div.options {
          display: flex;
        }
      }

      > div:first-of-type {
        align-items: center;
        display: flex;
        height: 30px;

        > p {
          flex-grow: 1;
          flex-basis: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-left: 10px;
          padding-right: 5px;
          max-height: 1.4em;
        }

        > span {
          align-items: center;
          display: flex;
          width: 25px;
        }
      }

      > div.options {
        align-items: center;
        background: #fff;
        border: 1px solid #ccc;
        flex-direction: column;
        height: auto;
        position: absolute;
        top: 30px;
        width: 100%;
        z-index: 1001;

        > div {
          align-items: center;
          display: flex;
          padding: 4px 10px;
          width: 100%;
          &:hover {
            background: $blue;
            color: #fff;
            cursor: pointer;
          }
        }
      }
    }

    > .buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 5px;

      > div {
        border-radius: 4px;
        cursor: pointer;
        padding: 2px 5px;
      }

      > div:first-of-type {
        background: #fff;
        border: 1px solid #ccc;
        margin-right: 5px;
      }

      > div:last-of-type {
        background: $gradientBlue;
        color: #fff;
      }
    }
  }
  > div.editButton {
    align-items: center;
    border: 1px solid #000;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-top: 1px;
    padding: 3px;
    position: absolute;
    right: 10px;
  }
}
