@import "uiAssets/ColorsVars.scss";

.DropDown {
  margin: 15px 0;
  position: relative;
  width: 100%;

  &.small {
    > .borderBottom {
      height: 2px;
    }

    > button {
      > div.iconContainer {
        > svg.icon {
          height: 75%;
          width: 75%;
        }
      }
      font-size: 12px;
      padding: 10px 20px;
    }
  }

  &.hasIcon {
    > button {
      padding: 15px 20px 15px 50px;
    }

    &.small {
      > button {
        padding: 10px 20px 10px 45px;
      }
    }
  }

  > input {
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
  }

  > span {
    display: block;
    font-size: 13px;
    z-index: 9;

    &.label {
      height: 17px;

      > span {
        color: $error;
      }
    }
  }

  .description {
    font-size: 12px;
    margin: 5px 0 10px;
    white-space: pre-line;
  }

  > button {
    align-items: center;
    border: none;
    border-radius: 7px 7px 0 0;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    letter-spacing: 0.5px;
    padding: 15px 20px;
    position: relative;
    width: 100%;

    > span {
      display: block;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      white-space: pre;
      width: calc(100% - 20px);
    }

    > div.iconContainer {
      display: flex;
      height: 100%;
      left: 14px;
      position: absolute;
      top: 0;
      width: 20px;

      > svg.icon {
        display: block;
        height: 100%;
        margin: auto;
        width: 100%;
      }
    }
  }

  > .borderBottom {
    bottom: 0;
    height: 3px;
    position: absolute;
    width: 100%;
  }

  > .optionsContainer {
    background: #fff;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.15) 0 2px 10px;
    display: none;
    max-height: 300px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 75px;
    width: 100%;
    z-index: 9;

    > .optionsInnerContainer {
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;

      > .option {
        align-items: center;
        background: none;
        border: none;
        display: flex;
        justify-content: space-between;
        opacity: 0;
        padding: 20px;
        position: relative;
        transform: transLateX(150px);
        transition: 0.2s color ease-out;
        width: 100%;

        > span {
          display: block;
          font-size: 14px;
          overflow: hidden;
          text-align: left;
          text-overflow: ellipsis;
          white-space: pre;
          width: calc(100% - 20px);
        }

        &:hover {
          background: $gradientBlue;
          color: #fff;

          > svg {
            stroke: #fff !important;
          }
        }
      }

      > .border {
        background: #eee;
        height: 1px;
        width: 100%;
      }
    }
  }
}
