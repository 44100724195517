@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.positioningModal {
  > header {
    align-items: stretch;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 10px;
    color: $blue;
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 18px;
    padding-top: 10px;
    z-index: 9;

    &:before {
      background: $gradientBlue;
      content: "";
      display: block;
      height: 10px;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    > span {
      display: block;
      flex-grow: 1;
      padding: 20px 25px;
    }

    > button {
      align-items: center;
      background: none;
      border: none;
      border-radius: 50px;
      display: flex;
      flex-grow: 0;
      flex-shrink: 0;
      justify-content: center;
      padding: 10px 15px;
      transition: transform 0.2s ease-out;
    }

    > button:hover {
      transform: rotate(180deg);
    }
  }

  > .content {
    display: flex;
    flex-direction: column;
    padding: 20px;

    > span {
      margin: 15px auto;
    }

    .activity {
      padding-bottom: 8px;
    }
  }

  .pleaseWait {
    text-align: center;
  }

  form {
    display: block;
  }
}
