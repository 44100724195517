@import "uiAssets/ColorsVars.scss";

.AppModalContainer {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  padding: 100px 0 20px 0;

  &.extends {
    overflow: auto;
    min-height: 100vh;
    background: rgba(250, 250, 250, 0.9);

    > .background {
      background: transparent;
    }
  }

  > .background {
    background: rgba(250, 250, 250, 0.9);
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  > .container {
    background: #fff;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 5px 20px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 30px);
    opacity: 0;
    overflow: hidden;
    position: relative;
    transform: translateY(300px);
    width: calc(100% - 30px);
    max-height: 100%;

    &.showOverflow {
      > header {
        &:before {
          border-top-left-radius: 7px;
          border-top-right-radius: 7px;
        }
      }
    }

    &.border {
      border: 1px solid #ccc;
    }

    &.alert > main {
      padding: 20px;
      text-align: center;

      > button {
        margin-top: 15px !important;
      }
    }

    &.confirm > main {
      padding: 20px;
      text-align: center;

      > div {
        display: flex;
        margin-top: 20px;
      }
    }

    &.small {
      height: auto;
      max-width: 450px;
      width: calc(100% - 30px);
    }

    &.medium {
      height: auto;
      max-height: calc(100% - 30px);
      max-width: 600px;
      width: calc(100% - 30px);
    }

    &.large {
      height: auto;
      max-height: calc(100% - 30px);
      max-width: 750px;
      width: calc(100% - 30px);
    }

    &.xLarge {
      height: auto;
      max-height: calc(100% - 30px);
      max-width: 950px;
      width: calc(100% - 30px);
    }

    &.xxLarge {
      height: auto;
      max-height: calc(100% - 30px);
      max-width: 1100px;
      width: calc(100% - 30px);
    }

    &.auto {
      height: auto;
      width: auto;
    }

    > header {
      align-items: stretch;
      box-shadow: rgba(0, 0, 0, 0.1) 0 4px 10px;
      color: $blue;
      display: flex;
      flex-direction: row;
      flex-grow: 0;
      flex-shrink: 0;
      font-size: 18px;
      padding-top: 10px;
      z-index: 9;

      &:before {
        background: $gradientBlue;
        content: "";
        display: block;
        height: 10px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }

      > span {
        display: block;
        flex-grow: 1;
        padding: 20px 25px;
      }

      > button {
        align-items: center;
        background: none;
        border: none;
        border-radius: 50px;
        display: flex;
        flex-grow: 0;
        flex-shrink: 0;
        justify-content: center;
        padding: 10px 15px;
        transition: transform 0.2s ease-out;
      }

      > button:hover {
        transform: rotate(180deg);
      }
    }

    > main {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}
