@import "uiAssets/ColorsVars.scss";

.SearchInput {
  align-items: center;
  border: 0;
  border-radius: 0;
  display: flex;
  flex-grow: 1;
  position: relative;
  transition: background 0.2s ease-out;

  > input {
    background: #eaeaea;
    border: none;
    display: block;
    padding: 7px 0 6px 15px;
    width: 100%;
  }

  > button {
    align-items: center;
    background: #2782ff;
    border: 0;
    border-radius: 0 7px 7px 0;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
    padding: 10px;
    transition: background 0.2s ease-out;

    &:hover svg {
      transform: scale(1.2);
    }
  }

  &:hover input {
    background-color: #e0e0e0;
  }

  &.disabled {
    > input {
      background: #ccc !important;
    }

    > button {
      background: #888;
    }
  }
}
