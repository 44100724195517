.FileUpload {
  .filesContainer {
    padding: 20px 20px 15px;

    .deleteFile {
      background: none;
      border: none;
      cursor: pointer;
    }

    .button {
      border: none;
      background: none;
      cursor: pointer;
    }

    .disabled {
      cursor: not-allowed;
    }

    > p {
      color: #666;
      font-style: italic;
    }

    .file {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin: 0 10px;
      padding: 2px 0;

      p:first-child {
        font-size: 13px;
      }

      > div:last-child {
        a,
        button {
          padding: 5px;
        }
      }
    }

    .file:not(:last-child) {
      border-bottom: 1px dashed #ddd;
    }

    h2 {
      padding-bottom: 8px;
    }

    h3 {
      color: #2782ff;
      font-weight: 600;
      text-transform: uppercase;
    }

    h3.titleCategory {
      color: #2782ff;
      font-weight: 600;
      padding-top: 16px;
      text-transform: capitalize;
    }

    .dropZone {
      border: 2px dashed #2782ff;
      border-radius: 8px;
      color: #666;
      cursor: pointer;
      font-style: italic;
      margin-top: 24px;
      position: relative;
      text-align: center;

      > div {
        padding: 25px 30px;
      }
    }
  }
}
