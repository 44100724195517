@use "sass:map";
@use "sass:list";
@use "sass:color";
@import "../../assets/designSystem";
@import "./variables";

.AvatarWrapper {
  display: inline-flex;
}

.Avatar {
  border-radius: 50%;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @include decoration-colors;

  @each $value in $sizes {
    &.#{list.nth($value, 1)} {
      width: list.nth($value, 2);
      height: list.nth($value, 2);
      font-size: map.get($fontSizes, list.nth($value, 1));
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  img {
    border-radius: 50%;
    object-fit: cover;
  }

  label {
    overflow: hidden;
    white-space: nowrap;
    font-weight: 600;
    line-height: normal;
  }

  .editButton {
    position: absolute;
    bottom: 0;
    right: 0;
    translate: -50%;
    z-index: 99999999;
    background-color: $surface-dark;
    border-radius: 50%;
  }

  &.pointer {
    cursor: pointer;
    label {
      cursor: pointer;
    }
  }
  
  &.clickable {
    border: 0.125rem solid transparent;
    &:hover {
      border: 0.125rem solid transparent;
    }
    &:focus {
      outline: 0.125rem solid transparent;
      outline-offset: 0.125rem;
    }
    &:active {
      border: 0.125rem solid transparent;
    }
    &:not(.darkMode) {
      border-color: $border-onLight;
      &:hover,
      &:focus {
        border-color: $plainText-onLight-lighter;
      }
      &:focus {
        outline: 0.125rem solid $action-onLight-default;
      }
      &:active {
        border-color: $action-onLight-default;
      }
    }
    &.darkMode {
      border-color: $border-onDark;
      &:hover,
      &:focus {
        border-color: $plainText-onDark-default;
      }
      &:focus {
        outline: 0.125rem solid $action-onDark-default;
      }
      &:active {
        border-color: $action-onDark-default;
      }
    }
  }

  &.espace-collab {
    label {
      @include espace-collab-font;
    }
  }

  &.espace-rh {
    label {
      @include espace-rh-font;
    }
  }
}
