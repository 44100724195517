@import "../../assets/colors";
@import "../../assets/mixins";

.CatalogSearch {
  width: 100%;
  max-width: 36.125rem;
  position: relative;
  min-height: 2.75rem;

  .searchWithSuggests {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 200px;
    background-color: white;
    border: 1px solid $pale-lilac;
    z-index: 2;

    &.withShadow {
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
    }

    &.isOpenSuggests {
      border-radius: 20px;

      .suggestsTitle,
      .suggests {
        display: flex;
        flex-grow: 1;
      }

      .divider {
        display: flex;
      }

      .suggestionsBox:first-of-type {
        border-top: 1px solid $border-on-light;
      }

      @include isMobile {
        border-radius: 20px;
      }
    }

    .searchInputContainer {
      display: flex;
      flex-grow: 1;
      padding: 0 0.875rem 0 1.25rem;
      width: 100%;
      align-items: center;
      height: 2.75rem;

      input.searchInput {
        width: 100%;
        border: none;
        outline: none;
        padding: 0.25rem 0.625rem;
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.14;
        letter-spacing: 0.15px;

        @include placeholder {
          font-weight: 400;
          color: $bluey-grey;
        }
      }

      .clearBtn:hover {
        cursor: pointer;
      }

      @include isMobile {
        height: 2.25rem;
      }
    }

    .suggestsTitle {
      max-height: 16.625rem;
      align-content: space-between;
      margin-left: 1.625rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      display: none;
      width: 49%;

      &:hover {
        cursor: pointer;

        span.title {
          text-decoration: underline;
        }
      }

      span.title {
        font-size: 0.75rem;
        font-weight: 700;
        color: $b-w-black;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .countContainer {
        margin-left: 0.625rem;
        min-width: 2.125rem;
        border-radius: 2px;
        height: 1rem;
        background-color: rgba($secondary-blue, 0.2);

        span.count {
          font-size: 0.6875rem;
          color: $primary-blue;
          font-weight: 500;
        }
      }

      @include isMobile {
        flex-wrap: nowrap;
        max-height: none;
        padding: 1.25rem 0;
      }
    }

    .suggests {
      max-height: 16.625rem;
      align-content: space-between;
      padding: 1.625rem;
      padding-bottom: 1rem;
      padding-top: 0;
      display: none;

      span.title {
        font-size: 0.75rem;
        font-weight: 700;
        color: $b-w-black;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .countContainer {
        margin-left: 0.625rem;
        min-width: 2.125rem;
        border-radius: 2px;
        height: 1rem;
        background-color: rgba($secondary-blue, 0.2);

        span.count {
          font-size: 0.6875rem;
          color: $primary-blue;
          font-weight: 500;
        }
      }

      .suggest {
        align-items: center;
        margin-bottom: 0.365rem;
        width: 49%;

        span.label {
          font-size: 0.75rem;
          color: $b-w-black;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &:hover {
          cursor: pointer;

          span.label {
            text-decoration: underline;
          }
        }

        .countContainer {
          margin-left: 0.625rem;
          min-width: 2.125rem;
          border-radius: 2px;
          height: 1rem;
          padding: 0 0.375rem;
          background-color: rgba($secondary-blue, 0.2);

          span.count {
            font-size: 0.6875rem;
            color: $primary-blue;
            font-weight: 500;
          }
        }
      }

      @include isMobile {
        flex-wrap: nowrap;
        max-height: none;
        padding: 1.25rem 0;

        .suggest {
          max-width: 100%;
        }
      }
    }

    .divider {
      display: none;
      margin-left: 1.25rem;
      margin-right: 1.25rem;
      width: auto;
    }
  }
}
