@import "../../assets/designSystem";

.Filters {
  align-items: center;
  align-content: center;
  gap: $skui-spacing-xs;
  z-index: 1;

  .FilterContainer {
    align-items: center;
    align-content: center;
    gap: $skui-spacing-xs;
    align-self: stretch;
    flex-wrap: wrap;

    > * {
      min-width: 16rem;
    }
  }

  .GroupLabel {
    align-items: center;
    justify-content: space-between;
  }

  margin-bottom: $skui-spacing-xs;

  &.espace-rh {
    @include espace-rh-filters;
  }

  &.espace-collab {
    @include espace-collab-filters;
  }

  &.darkMode {
    > div > div > div:first-child, button span {
      color: $plainText-onDark-darker;
    }
  }

  .ActionContainer {
    align-items: center;
    align-content: center;
    padding-top: 5px;

    .RefreshButton {
      padding: 3px;
    }
  }
}

.FilterSearchInput {
  border: none;
  border-bottom: 1px solid $border-onLight;
  border-radius: 0;
  padding: 0.5rem 1rem;
  width: 100%;

  &::placeholder {
    font-style: italic;
  }
}

.FilterCategory {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
}

.CategoryLabel {
  padding: $skui-spacing-xs $skui-spacing-s;
  font-weight: 600;
  color: $plainText-onLight-lighter;
  font-size: 0.875rem;
}
