@import "@skillup/ui";

.TrainingOrganization {
  max-height: 100%;
  overflow: hidden;
  color: $plainText-onLight-lighter;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.9375rem;
}
