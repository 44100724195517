@import "../../../assets/colors";
@import "../../../assets/mixins";
@import "../../../assets/designSystem";

.Rating {
  align-items: center;

  .star:not(:first-of-type) {
    margin-left: -0.25rem;
  }

  .reviews {
    margin-left: $skui-spacing-xxs;
    color: $plainText-onLight-lighter;
  }

  .grade {
    margin-left: $skui-spacing-xxs;
    color: $plainText-onLight-default;
  }

  p.dividend {
    color: $action-onLight-default;
  }

  &.espace-collab {
    .grade,
    .reviews {
      @include espace-collab-rating-reviews;
    }
    p.dividend {
      @include espace-collab-rating-grade;
    }
  }

  &.espace-rh {
    .grade,
    .reviews {
      @include espace-rh-rating-reviews;
    }
    p.dividend {
      @include espace-rh-rating-grade;
    }
  }

  &.darkMode {
    background-color: $surface-dark;

    .reviews,
    .grade {
      color: $plainText-onDark-darker;
    }
    p.dividend {
      color: $plainText-onDark-default;
    }
  }

  &.vertical {
    .reviews,
    .grade {
      margin-left: 0;
      margin-top: $skui-spacing-xxs;
    }
  }
}
