@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.headLine {
  @include espace-rh-h6;
  color: $plainText-onLight-default;
  > p {
    padding: $skui-spacing-s 0 $skui-spacing-xs 0;
  }
}

.alert {
  margin: $skui-spacing-s 0;
}

.dateSettingsContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 $skui-spacing-m;
  width: fit-content;
}

.alignDateSettingsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
