.AskEstimateModal {
  > form {
    padding: 20px;

    > p {
      color: #666;
      padding: 20px 0 0;
      text-align: center;
    }
  }
}
