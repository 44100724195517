@import "@skillup/ui";

.linkUsers {
  &.loading {
    height: 490px;
  }

  display: flex;
  flex-direction: column;
  justify-content: stretch;
  padding: $skui-spacing-s;

  .buttonsWrapper {
    margin-top: $skui-spacing-s;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .button {
      margin-right: $skui-spacing-xs;
      box-sizing: border-box;

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}

.alertAction {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: $skui-spacing-s;

  .alertButton button {
    color: $surface-light;
    border-color: $surface-light;
  }
}
