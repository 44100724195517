@import "uiAssets/ColorsVars.scss";

.SessionTypeFilter {
  cursor: pointer;
  display: flex;
  font-size: 13px;
  justify-content: space-between;
  margin-top: 15px;

  > .checkbox {
    align-items: center;
    color: #333;
    display: flex;

    > div:first-of-type {
      align-items: center;
      background: #fff;
      border: 1px solid #ccc;
      border-radius: 3px;
      display: flex;
      flex-shrink: 0;
      height: 14px;
      justify-content: center;
      margin-right: 10px;
      width: 14px;
    }
  }

  > span {
    color: #999;
  }
}
