@import "@skillup/ui";

.SupervisorTrainingScheduleLoader {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include espace-rh-body2-regular;

  .loader {
    padding: $skui-spacing-l;
  }
}
