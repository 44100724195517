@import "@skillup/ui";

.inputLine {
  margin-bottom: $skui-spacing-xs;

  .label {
    @include espace-rh-components-input-label;
    color: $plainText-onLight-lighter;
  }

  .input {
    @include espace-rh-components-input-value;
    color: $plainText-onLight-default;
    margin-bottom: 10px;
  }

  .select {
    width: 100%;
    input {
      padding: 5px 0px !important;
    }
  }

  .switch {
    justify-content: left;
    margin: 8px 0px;
  }
  .duration {
    display: flex;
    column-gap: 16px;
    .select {
      width: 100%;
    }
    .select,
    .input {
      max-width: 128px;
    }
  }

  .radioBox {
    .radioBoxElement {
      margin-top: $skui-spacing-xs;
    }
  }
}
