@import "@skillup/ui";

.BannersList {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0px;
  gap: $skui-spacing-s;
  margin-bottom: $skui-spacing-l;
}

.Banner {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: $skui-spacing-xs;
  gap: $skui-spacing-s;
  background: #ffffff;
  border: 1px solid $border-onLight;
  box-shadow: $elevation-actionableCard;
  border-radius: 4px;

  width: 100%;
  &.small {
    width: calc(50% - 8px);
  }

  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    flex-grow: 1;
    .drag {
      svg {
        cursor: grab;
        fill: $greyscale-medium;
        width: 24px;
        height: 24px;
        &:active {
          cursor: grabbing;
        }
      }
    }
    .preview {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 8px;
      gap: 8px;
      border-radius: 16px;
      flex-grow: 1;
      &.small {
        flex-direction: column;
        width: 100%;
      }
      .logo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 8px;
        gap: 8px;
        align-self: center;
        img {
          width: 100%;
          height: auto;
          max-height: 107px;
          object-fit: contain;
        }
      }
      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 8px;
        gap: 16px;
        align-self: center;
        flex-grow: 1;
        p {
          margin: 0;
          font-size: 1.125rem;
          text-align: center;
        }
      }
    }

    .DSButton {
      background-color: white;
      span {
        color: black;
      }
      &.dark {
        background-color: rgba(255, 255, 255, 0.4);
        span {
          color: white;
        }
      }
      &.light:hover button {
        background-color: white;
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
}
