@import "uiAssets/ColorsVars.scss";

.TrainingCard {
  margin: 5px;
  padding: 10px;
  background-color: #fff;
  box-shadow: rgba(2, 8, 20, 0.15) 0px 0.175em 0.5em, rgba(2, 8, 20, 0.01) 0px 0.085em 0.175em;
  border: 1px solid #dfdfdf;
  color: #73849a;
  display: flex;
  flex-direction: column;
  font-size: inherit;
  font-weight: 400;
  height: auto;
  justify-content: space-between;
  line-height: 1.6;
  width: 340px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;

  &.editable {
    cursor: grab;
    padding: 20px;
    font-size: 15px;
  }

  > div:first-of-type {
    display: flex;
    margin-bottom: 10px;
    flex-grow: 1;

    > aside {
      align-items: center;
      display: flex;
      flex-grow: 0;
      flex-shrink: 0;
      height: 50px;
      justify-content: center;
      margin-right: 10px;
      width: 50px;

      > img {
        display: block;
        flex-basis: 100%;
        max-height: 100%;
        max-width: 100%;
      }
    }

    > div {
      padding-left: 10px;
      > a {
        color: $blue;
        text-decoration: none;
      }

      > p {
        font-size: 13px;
      }
    }
  }

  &:hover > footer {
    opacity: 1;
    transform: translateX(0);
  }

  > .tag {
    border: 1px solid #7d96a8;
    border-radius: 3px;
    color: #1f6389;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    margin: 5px 3px 0;
    padding: 2px 5px;
  }

  > footer {
    background: #eee;
    border-left: 1px solid #ddd;
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
    opacity: 0;
    position: absolute;
    right: 0;
    transform: translateX(30px);
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;

    > button {
      align-items: center;
      background: none;
      border: none;
      display: flex;
      justify-content: center;
      padding: 8px;

      > svg {
        display: block;
      }
    }
  }
}
