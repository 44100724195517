.choices {
  height: auto;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 0 20px;
  background-color: #ffffff;

  button {
    cursor: pointer;
    align-items: center;
    background: none;
    border: 1px solid #73adff;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 10px;
    display: flex;
    margin-bottom: 20px;
    padding: 15px;
    transition: transform 0.2s ease-out, box-shadow 0.2s ease-out;
    width: 100%;

    &.disabled {
      cursor: auto;
      border: 1px solid #999;

      h2 {
        text-decoration: line-through;
        color: #999;
      }

      p {
        color: #999;
      }
    }

    button:hover {
      background: #fafafa;
      box-shadow: 0 5px 18px rgba(40, 139, 255, 0.5);
      transform: scale(1.025);
    }

    button:last-child {
      margin-bottom: 0;
    }

    svg {
      margin-right: 30px;
    }

    div.label {
      flex-grow: 1;
      text-align: left;
    }

    h2 {
      color: #2782ff;
      font-weight: 700;
      margin-bottom: 10px;
    }

    p {
      color: #555;
      font-size: 12px;
    }
  }
}
