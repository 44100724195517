@import "@skillup/ui";

.HotReviewSidePanel {
  header {
    padding-bottom: 0;
    
    .subtitleWrapper {
      margin-bottom: $skui-spacing-s;
      h6 {
        -webkit-line-clamp: 2 !important;
      }
    }
    p {
      @include espace-rh-body2-regular;
    }
    .actionMenu {
      justify-content: flex-end;
    }
    > div {
      max-height: none !important;
      overflow: unset !important;
    }
  }

  footer {
    display: none;
  }

  .content {
    .categoryTitle {
      @include espace-rh-body2-bold;
      margin: $skui-spacing-s 0 $skui-spacing-xs 0;
      p:first-of-type {
        margin-left: $skui-spacing-xxs;
        color: $action-onLight-default;
      }
    }
    .question {
      align-items: start;
      margin-bottom: $skui-spacing-xxs;
      @include espace-rh-body2-regular;
      .rating {
        margin: $skui-spacing-xxs $skui-spacing-xs 0 0;
      }
    }
    .info {
      @include espace-rh-caption-regular;
      color: $plainText-onLight-lighter;
      margin: $skui-spacing-m 0;
    }
  }
}
