@import "@skillup/ui";

.TemplateBuilder {
  width: 100%;
  .structure {
    width: 19rem;
    padding: 0;
    background-color: $surface-light;
    height: 100%;
    overflow: auto;
    scroll-behavior: smooth;
  }
  .content {
    width: calc(100% - 19rem);
    height: 100%;
    overflow: auto;
    background-color: $surface-dark;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $skui-spacing-xxl;
  }
}

.header__buttons__undoRedo {
  display: flex;
  flex-direction: row;
  gap: $skui-spacing-s;
}

.toaster__button {
  padding-left: $skui-spacing-l;

  > span {
    &:first-of-type {
      padding-right: $skui-spacing-xs;
    }
    button {
      border: 1px solid white !important;
      > div {
        > span {
          color: white;
        }
      }
    }
  }
}
