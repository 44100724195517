.childGenerator {
  // background: #f0f0f0;
  background: #fff;
  border-radius: 2px;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 0.7rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }

  // .item {}
}
