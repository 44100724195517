@import "@skillup/ui";


.body {
  border: 1px solid $border-onLight;
  border-radius: $skui-spacing-xxs;

  .searchInput {
    width: 100%;
  }
  
  .fieldConfigurationContainer {
    border: 1px solid $border-onLight;
    border-radius: $skui-spacing-xxs;
    margin-top: $skui-spacing-xxs;
    padding: $skui-spacing-s;
    display: flex;
    flex: 1;
    flex-direction: column;

    .fieldConfigurationItem {
      padding: $skui-spacing-xxs;
      display: flex;
      gap: $skui-spacing-xs;
      align-items: center;
      justify-content: space-between;
    }
  }
}
