@import "@skillup/ui";

.TargetsChild {
  @include espace-rh-body1-regular;
  padding: $skui-spacing-s;
  gap: $skui-spacing-s;

  .button--underline {
    text-decoration: underline;
    color: $action-onLight-darkest;
    button {
      font-weight: normal !important;
    }
  }
  .button--underline--errored {
    color: red;
    button {
      color: red;
    }
  }

  .switch {
    display: flex;
    justify-content: flex-start;
  }

  .childDescription {
    color: $plainText-onLight-lighter;
  }

  .labelAndButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
  }

  .radioBlock {
    margin-top: $skui-spacing-s;
  }
}
