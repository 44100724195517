@import "@skillup/ui";

.State {
  padding: 8px 12px;
  border-radius: 22px;
}

.stateWrap {
  display: flex;
  align-items: flex-end;

  .yellow {
    background: #fef5ed;

    p {
      color: #f2994a;
    }
  }

  .green {
    background: #e9f7ef;

    p {
      color: #0daf85;
    }
  }

  .red {
    background: #ffd0d0;

    p {
      color: #de4343;
    }
  }

  .icon {
    color: $status-warning-default;
    padding-left: 0.4rem;
  }
}
