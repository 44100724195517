@import "uiAssets/ColorsVars.scss";

.OnboardingSequenceModal {
  background-color: #fff;
  border-radius: 7px;
  color: #000;
  font-size: 13px;
  font-weight: 400;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  > div {
    height: 100%;
    left: 0;
    padding-bottom: 30px;
    position: absolute;
    top: 0;
    transition: transform 0.2s ease-out;
    width: 100%;

    > section {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      left: 0;
      padding-top: 90px;
      position: absolute;
      top: 0;
      width: 100%;

      > h1,
      > h2 {
        color: $blue;
        margin: 5px auto;
        margin-bottom: 10px;
        text-transform: uppercase;
      }

      img {
        border-radius: 5px;
        display: block;
        margin: 5px auto;
        max-height: 280px;
        max-width: 280px;
      }

      > p {
        margin: 12px auto;
        max-width: 400px;
        text-align: center;
        width: calc(100% - 20px);
      }
    }
  }

  > nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 20px 5px 5px;
    position: relative;
    z-index: 9;

    > button {
      background: none;
      border: none;
      border-radius: 30px;
      color: $blue;
      display: block;
      overflow: visible;
      padding: 18px;
      position: relative;

      > span {
        display: block;
        transition: font-weight 0.2s ease-out;

        &:after {
          color: transparent;
          content: attr(title);
          display: block;
          font-weight: 700;
          height: 1px;
          overflow: hidden;
          visibility: hidden;
        }
      }

      > b {
        background: #ccc;
        border-radius: 20px;
        content: "";
        display: block;
        height: 12px;
        left: calc(50% - 6px);
        position: absolute;
        top: 70px;
        transition: background 0.2s ease-out;
        width: 12px;
        z-index: 9;

        @media (min-width: 750px) {
          top: 49px;
        }
      }

      &:before {
        background: #ccc;
        content: "";
        display: block;
        height: 3px;
        left: 0;
        position: absolute;
        top: 75px;
        transition: background 0.2s ease-out;
        width: 50%;

        @media (min-width: 750px) {
          top: 54px;
        }
      }

      &:after {
        background: #ccc;
        content: "";
        display: block;
        height: 3px;
        left: 50%;
        position: absolute;
        top: 75px;
        transition: background 0.2s ease-out;
        width: 50%;

        @media (min-width: 750px) {
          top: 54px;
        }
      }

      &:first-of-type:before {
        display: none;
      }

      &:last-of-type:after {
        display: none;
      }

      &.current {
        font-weight: 700;

        > b,
        &:before {
          background: $blue;
        }
      }

      &.passed {
        > b,
        &:after,
        &:before {
          background: $blue;
        }
      }
    }
  }

  > .skipButton {
    background: none;
    border: none;
    color: $blue;
    font-size: 13px;
    padding: 15px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;
  }

  > .buttonNext,
  > .buttonPrevious {
    background: none;
    border: none;
    left: 0;
    padding: 10px;
    position: absolute;
    top: calc(50% - 20px);
    z-index: 9;
  }

  > .buttonNext {
    left: auto;
    right: 0;
  }

  > .closeButton {
    background: none;
    border: none;
    bottom: 0;
    color: $blue;
    padding: 15px;
    position: absolute;
    right: 0;
  }
}
