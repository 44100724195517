@import "uiAssets/ColorsVars.scss";

.PortalFAQ {
  display: flex;
  justify-content: space-around;
  padding: 10px;

  > div {
    border-radius: 7px;
    box-shadow: $bigBoxShadow;
    overflow: hidden;
    width: calc(50% - 20px);

    &:before {
      background: $gradientBlue;
      border-radius: 7px 7px 0 0;
      content: "";
      display: block;
      height: 10px;
      width: 100%;
    }

    > header {
      box-shadow: $bigBoxShadow;
      color: $blue;
      font-size: 24px;
      font-weight: 100;
      padding: 10px 15px;
    }

    &:first-of-type > div {
      background: #f6f6f6;
      color: #333;
      font-family: monospace;
      margin: 10px;
      padding: 10px;
      white-space: pre-wrap;
    }

    &:last-of-type > div {
      padding: 15px;

      > h2 {
        background: $mainColorL;
        color: $blue;
        display: block;
        font-size: 18px;
        font-weight: 300;
        margin-bottom: 15px;
        margin-top: 20px;
        padding: 5px 8px;

        &:first-of-type {
          margin-top: 0;
        }
      }

      > p {
        padding-left: 20px;
      }
      > ul,
      > ol {
        padding-left: 35px;
      }
      > p,
      > ul,
      > ol {
        margin-bottom: 10px;
      }
    }
  }
}
