@import "@skillup/ui";

.modal {
  width: 100%;
  max-width: 560px;
}

.columnsText {
  margin-left: $skui-spacing-m;

  li::marker {
    color: $plainText-onLight-default
  }
}

.getTemplateLink {
  @include espace-rh-caption-link;
  color: $action-onLight-darkest;
}

.fileDrop {
  @include espace-rh-body1-regular;
}

.retryLink {
  cursor: pointer;
  color: inherit;
}

.invalidCollabManagerPair {
  text-decoration: line-through !important;
  color: $plainText-onLight-lighter;
}
