.RevertToApprovedHrModal {
  height: auto;
  padding: 15px 35px 25px;
}

.buttonsContainer {
  display: flex;
  margin-top: 15px;

  button:first-of-type {
    margin-right: 5px;
  }
}
