.manualTrainingDataModal {
  height: auto;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 0 20px;
  background-color: #ffffff;

  > form {
    width: 100%;
    padding: 20px;
    padding-top: 0;

    > input {
      width: 100%;
    }

    .nextStep {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      > button {
        margin: inherit;
      }
    }
  }
}
