@import "../../../assets/designSystem";

.Label {
  color: $plainText-onLight-lighter;
  &.darkMode {
    color: $plainText-onDark-darker;
  }
  .required {
    color: $status-error-darker;
  }

  &.espace-collab {
    @include espace-collab-components-input-label;
    .required {
      @include espace-collab-caption-bold;
    }
  }

  &.espace-rh {
    @include espace-rh-components-input-label;
    .required {
      @include espace-rh-caption-bold;
    }
  }
}
