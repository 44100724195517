@import "@skillup/ui";

.fileList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $skui-spacing-s;
  .documentName {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    overflow-wrap: anywhere;

    svg {
      height: 16px;
      width: 24px;
      margin-right: 2rem;
      font-size: 1.2rem;
      margin-left: $skui-spacing-xs;
      color: $plainText-onLight-default;
    }
  }
  .documentType {
    font-weight: 100;
  }
  .fileListBtnSection {
    display: flex;
    align-items: center;
    .fileBtn:first-child {
      margin-right: $skui-spacing-s;
    }
    .fileDropdown {
      display: inline-flex;
      margin-left: $skui-spacing-s;
    }
  }
}
