@import "@skillup/ui";

.ErrorPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $skui-spacing-l;
  margin: $skui-spacing-l auto;
  img {
    max-height: 50rem;
    max-width: 24rem;
    margin-bottom: $skui-spacing-l;
  }
  p {
    text-align: center;
    max-width: 50rem;
  }
}
