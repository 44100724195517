@import "../../../assets/designSystem";

.formGroupSwitch {
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .toggle {
    margin-right: $skui-spacing-xs;
  }

  .label {
    @include espace-rh-label;
    color: $plainText-onLight-default;
  }

  .assistiveText {
    margin: $skui-spacing-xxs 0 0 calc($skui-spacing-xl + $skui-spacing-xxs);

    span {
      margin-left: 0;
    }
  }
}
