@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.layoutHeader {
  position: relative;
  background-color: $surface-dark;
  padding: 0 $skui-spacing-l $skui-spacing-xs $skui-spacing-xl;

  .filters {
    display: none;
  }
  > p {
    @include espace-rh-caption-bold;
    color: $plainText-onDark-darker;
    padding-bottom: $skui-spacing-xs;
  }

  &.isExpanded {
    .filters {
      display: block;
    }
    > p {
      display: none;
    }
  }

  .expandButton {
    position: absolute;
    right: $skui-spacing-s;
    bottom: $skui-spacing-xs;
  }
}

.header:last-child {
  position: fixed;
  z-index: 1;
}

.dashboard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: $skui-spacing-l;
  padding: $skui-spacing-s $skui-spacing-xl;
  background-color: $surface-light-darker;
  width: 100%;

  .panels {
    width: 100%;
    gap: $skui-spacing-l;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: $skui-spacing-s;
    width: 100%;
    p {
      @include espace-rh-caption-regular;
    }
  }
}

.mobileView {
  background-color: #0a4d71;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: fixed;
}

.dashboardRefreshAlertMessage {
  @include espace-rh-body1-regular;

  color: $plainText-onDark-default;
}
