.ListModal {
  > p {
    font-size: 13px;
    margin: 20px auto 0 auto;
    width: 90%;

    &.centered {
      text-align: center;
    }
  }
  > div.actions {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
  }
}
