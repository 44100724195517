@import "../../assets/designSystem";

.NavigationHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  height: 3.5rem;
  width: 100%;

  .primary,
  .secondary {
    display: flex;
    align-items: center;
    min-width: 0;
  }

  .primary {
    flex: 1;
    overflow: hidden;
    padding-left: $skui-spacing-s;
  }

  .secondary {
    padding-right: $skui-spacing-s;
  }

  .title {
    display: flex;
    flex-direction: column;
    min-width: 0;
    &:not(.showMenu) {
      padding-left: $skui-spacing-m;
      margin-right: $skui-spacing-s;
    }

    .childTitle {
      display: flex;
      align-items: baseline;
      h1.mainTitle {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        small {
          @include espace-rh-body1-regular;
        }
      }

      h2.subTitle {
        @include espace-rh-body1-regular;
        margin-left: $skui-spacing-xs;
      }
    }
  }

  .item {
    max-width: 60%;
  }

  .spacingLeft {
    margin-left: $skui-spacing-s;
    @media (max-width: 768px) {
      margin-left: $skui-spacing-xs;
    }
  }
  .spacingRight {
    margin-right: $skui-spacing-s;
    @media (max-width: 768px) {
      margin-right: $skui-spacing-xs;
    }
  }

  .parentLink {
    text-decoration: none;

    label {
      cursor: pointer;
    }
  }

  &:not(.darkMode) {
    background-color: $surface-light;
    color: $plainText-onLight-default;
    label.parentTitle {
      color: $plainText-onLight-lighter;
    }
  }

  &.darkMode {
    background-color: $surface-dark;
    color: $plainText-onDark-default;
    label.parentTitle {
      color: $plainText-onDark-darker;
    }
  }

  &.espace-rh {
    h1.mainTitle {
      @include espace-rh-header-pageTitle;
    }
    label.parentTitle {
      @include espace-rh-header-parentPageTitle;
    }
  }

  &.espace-collab {
    h1.mainTitle {
      @include espace-collab-header-pageTitle;
    }
    label.parentTitle {
      @include espace-collab-header-parentPageTitle;
    }
  }

  .hidden {
    display: none;
  }
}
