@import "uiAssets/ColorsVars.scss";

.PlaceFilter {
  position: relative;
  width: 100%;

  h3 {
    color: #333;
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  > .predictions {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 85px;
    width: 100%;

    > button {
      align-items: center;
      background: #fff;
      border: none;
      border-bottom: 1px solid #ccc;
      color: $mainColor;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px 15px;
      text-align: left;
      text-decoration: none;
      transition: color 0.5s, background 0.5s;
      width: 100%;

      > svg {
        flex-shrink: 0;
        margin-left: 10px;
        transition: fill 0.5s;
      }

      &:last-of-type {
        border-bottom: none;
      }

      &:hover {
        background: $mainColor;
        color: #fff;

        > svg {
          fill: #fff;
        }
      }
    }
  }

  > a.addFilterLink {
    align-items: center;
    color: #333;
    display: flex;
    flex-direction: row;
    font-size: 13px;
    justify-content: space-between;
    margin-bottom: 5px;
    text-decoration: none;
    width: 100%;
    margin-top: 15px;

    > div {
      align-items: center;
      display: flex;
      flex-direction: row;

      > div {
        align-items: center;
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 3px;
        display: flex;
        flex-shrink: 0;
        height: 14px;
        justify-content: center;
        margin-right: 10px;
        width: 14px;
      }
    }

    > span {
      color: #999;
    }
  }
}
