.DataLines {
  background-color: #fff;
  border: 2px solid #eee;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin: 20px 20px 10px;
  padding: 5px 0;

  > li {
    display: flex;
    flex-direction: row;
    margin: 3px 0;

    &:first-of-type {
      align-items: flex-end;

      > div:first-of-type {
        font-size: 22px;
      }
    }

    &.header {
      font-weight: 700;
    }

    &.total {
      background: #f6f6f6;
    }

    > div {
      flex-basis: 17%;
      padding: 5px 10px;
      text-align: right;

      &:first-of-type {
        flex-basis: auto;
        flex-grow: 1;
        padding-left: 15px;
        text-align: left;
      }

      &:last-of-type {
        font-weight: 700;
        padding-right: 15px;
      }

      > svg {
        display: inline-block;
        margin-right: 8px;
        vertical-align: middle;
      }
    }
  }
}
