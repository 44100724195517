@import "@skillup/ui";

.PlaceholderCard {
  .placeholder {
    text-align: center;
  }
  .placeholderImg {
    width: 14rem;
    margin: 0 auto;
  }
  .message {
    @include espace-rh-body1-bold;
    color: $plainText-onLight-default;
    margin: 1rem auto;
  }
  .details {
    @include espace-rh-caption-regular;
    color: $plainText-onLight-default;
    width: 30rem;
    margin: 1rem auto;
  }
  .download {
    margin-bottom: 4px;
  }
}
