@import "uiAssets/ColorsVars.scss";

.Summary {
  padding: 15px;

  > div {
    &.error {
      font-style: italic;
    }

    &.loader {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: center;
      min-height: 105px;
    }

    &.multipleLines {
      > div {
        color: #666;
        font-weight: 500;

        &:first-of-type {
          margin-bottom: 10px;
        }

        > strong {
          font-size: 18px;
        }
      }
    }

    &.singleLine {
      > div:first-of-type {
        > a {
          color: $blue;
          font-size: 16px;
          font-weight: 600;
          text-decoration: none;

          &:hover:not(p) {
            text-decoration: underline;
          }
        }
      }

      > div:last-of-type {
        align-items: flex-end;
        display: flex;

        > span:first-of-type {
          flex-grow: 1;
          > div:first-of-type {
            font-style: italic;
            padding: 5px 0 10px;
            text-transform: capitalize;
          }
          > div:last-of-type {
            align-items: center;
            display: flex;
            justify-content: flex-start;

            > span {
              margin-right: 8px;
              padding: 3px 7px;

              &.duration {
                color: #666;
                padding: 3px 0;
              }

              &.modality {
                background-color: #eaeaea;
                border-radius: 15px;
                color: #777;
                font-weight: 600;
              }

              &.types {
                background-color: #bde48f;
                border-radius: 3px;
                color: #345a18;
                font-weight: 600;
              }
            }
          }
        }

        > span.price {
          color: #888;
          flex-grow: 0;
          font-size: 18px;
          font-weight: 600;
        }
      }
    }
  }
}
