@import "../../../assets/designSystem";

.AssistiveArea {
  display: flex;
  justify-content: space-between;
  margin: $skui-spacing-xxs 0;
  width: 100%;

  .area {
    margin-left: $skui-spacing-xs;
  }

  &.espace-collab {
    @include espace-collab-components-input-help-text;
  }

  &.espace-rh {
    @include espace-rh-components-input-help-text;
  }

  &.default {
    color: $plainText-onLight-lighter;
  }

  .textAndIcon {
    display: flex;
    gap: $skui-spacing-xs;
    .icon {
      margin-top: 0.2rem;
      min-width: $skui-spacing-s;
      min-height: $skui-spacing-s;
    }
  }

  &.warning {
    color: $status-warning-darker;
    > .textAndIcon {
      .icon {
        color: $status-warning-default;
      }
    }
  }

  &.error {
    color: $status-error-darker;
    > .textAndIcon {
      .icon {
        color: $status-error-default;
      }
    }
  }
}
