@import "uiAssets/ColorsVars.scss";

.StepRegistration {
  margin: auto !important;
  padding-bottom: 0 !important;
  width: auto !important;

  background: #fff;
  border-radius: 7px;
  box-shadow: rgba(40, 139, 255, 0.4) 0 4px 15px;

  > header {
    background: #f6f6f6;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 15px;
    text-align: center;

    > h1 {
      color: $blue;
      font-size: 30px;
      font-weight: 400;
      padding: 14px 50px 0;
      margin: 0;
    }

    > p {
      color: #666;
      font-size: 14px;
      font-style: italic;
    }
  }

  .training {
    flex-flow: row wrap;
    padding: 20px;

    > div:first-child {
      width: 100%;

      img {
        margin: auto;
        width: 100px;
      }

      span {
        display: block;
        font-size: 160px;
        line-height: 1;
        margin: 20px 0 10px;
        text-align: center;
      }
    }

    > .content {
      line-height: 2em;
      margin: auto;
      max-width: 550px;
      padding-top: 16px;
      text-align: center;
    }

    > .buttonsContainer {
      display: flex;
      justify-content: flex-end;
      margin-top: 15px;
    }
  }
}
