@import "../../../assets/designSystem";

.Radio {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  padding: 0 0.125rem;
  min-height: 1.5rem;
  border-radius: $skui-spacing-xxs;

  &:not(.disabled) {
    &:hover {
      background-color: $effects-hover-onLight;
      cursor: pointer;
    }
  }

  &.withLabel:has(input:focus) {
    outline: 2px solid $action-onLight-default;
    outline-offset: 1px;
    background-color: $effects-hover-onLight;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    margin-left: $skui-spacing-xs;

    .helperText {
      line-height: 1.125rem;
      color: $plainText-onLight-lighter;
    }
  }

  &.disabled {
    input, .label{
      opacity: 0.3;
    }
  }

  &:has(> input:checked) {
    .textContainer {
      .label {
        font-weight: 500;
      }
    }
  }
  &:has(> input:not(:checked)) {
    .textContainer {
      .label {
        font-weight: 400;
      }
    }
  }

  input {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    width: 1rem;
    height: 1rem;
    margin-top: $skui-spacing-xxs;
    flex-shrink: 0;
    cursor: pointer;

    background-color: $surface-light;
    border-style: solid;
    border-color: $plainText-onLight-lighter;
    border-radius: $skui-spacing-xs;
    border-width: 1px;

    display: grid;
    place-items: center;

    &:checked {
      border-color: $decoration-blue-base;
    }

    &:checked::before {
      content: "";
      display: block;
      width: 0.44em;
      height: 0.44em;
      border-radius: 50%;
      background-color: $decoration-blue-base;
    }
  }

  &.espace-collab {
    .textContainer {
      .label {
        @include espace-collab-components-input-value;
        color: $plainText-onLight-default;
      }
      .helperText {
        @include espace-collab-components-input-help-text;
      }
    }
  }
  &.espace-rh {
    .textContainer {
      .label {
        @include espace-rh-components-input-value;
        color: $plainText-onLight-default;
      }
      .helperText {
        @include espace-rh-components-input-help-text;
      }
    }
  }
}
