@import "../../assets/designSystem";
.List {
  gap: $skui-spacing-xs;
  .header {
    gap: $skui-spacing-xxs;
    h6 {
      color: $plainText-onLight-lighter;
    }
    p {
      color: $plainText-onLight-lighter;
    }
  }

  &.muted > div {
    background: $surface-light-darker;
  }

  &.espace-rh {
    @include espace-rh-font;
    & > div {
      @include espace-rh-font;
    }
    h6 {
      @include espace-rh-h6;
    }
    p {
      @include espace-rh-caption-regular;
    }
  }
  &.espace-collab {
    @include espace-collab-font;
    & > div {
      @include espace-collab-font;
    }
    h6 {
      @include espace-collab-h6;
    }
    p {
      @include espace-collab-caption-regular;
    }
  }
}

.ListItem {
  gap: $skui-spacing-s;
  align-self: stretch;
  padding: 12px $skui-spacing-s;
  border-radius: $skui-spacing-xxs;
  border: 1px solid $border-onLight;
  background: $surface-light;
}
