@import "@skillup/ui";

.RepliesDetailModal {
  margin: $skui-spacing-xs $skui-spacing-m $skui-spacing-m $skui-spacing-m;
  &.loading {
    height: 400px;
  }

  .options {
    margin-bottom: $skui-spacing-s;
  }

  .listWrapper {
    height: 400px;
    padding-bottom: 1rem;
    overflow: auto;

    .list {
      margin-top: $skui-spacing-s;
      position: relative;
      width: 100%;

      .reply {
        display: flex;
        align-items: top;
        .content {
          margin: $skui-spacing-xxs $skui-spacing-s 0 $skui-spacing-s;
          .user {
            @include espace-rh-body1-regular;
          }
          .role {
            @include espace-rh-caption-regular;
            color: $plainText-onLight-lighter;
          }
          .comment:not(:empty) {
            @include espace-rh-body2-regular;
            padding: 0.25rem 0.5rem;
            background-color: $decoration-blue-lightest;
            border: 1px solid $decoration-blue-lighter;
            border-radius: 0.25rem;
            margin-top: $skui-spacing-xs;
            color: $plainText-onLight-default;
            word-break: break-word;
          }
        }
      }
    }
  }
}
