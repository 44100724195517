@import "@skillup/ui";

.Action {
  border: $skui-spacing-xxs;
  border-color: $border-on-light;
  background: $greyscale-lighter;
  gap: $skui-spacing-s;
  border-radius: $skui-spacing-xxs;
  margin-bottom: $skui-spacing-s;

  &__Label {
    padding-bottom: $skui-spacing-xxs;
  }

  &__Select {
    width: 100%;
  }
}