@import "uiAssets/ColorsVars.scss";
@import "@skillup/ui";

.Actions {
  border-top: 1px solid #eee;
  padding: 10px 0;

  > .labelsContainer {
    padding: 0 15px 5px;
  }
  > .loader {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    min-height: 85px;
  }

  .Alert {
    padding: 0 $skui-spacing-s $skui-spacing-xs;
  }

  > .wrapper {
    align-items: center;
    display: flex;
    padding: 0 15px;
    position: relative;
    transition: height 0.2s ease-in-out;

    > p {
      color: #666;
      font-style: italic;
      padding: 7px 0;
      text-align: left;
    }

    > .buttons {
      flex-grow: 1;
      > button {
        margin: 5px auto 5px 0 !important;

        > div {
          justify-content: flex-start !important;
        }
      }
    }

    > .showMore {
      align-items: center;
      border-radius: 4px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
      cursor: pointer;
      display: flex;
      justify-content: center;
      margin-right: 5px;
      padding: 6px 10px;
      transition: background-color 300ms ease-in-out;
      z-index: 6;

      span {
        color: #444;
        display: block;
        font-size: 12px;
        margin-right: 10px;
      }

      &:hover,
      &.clicked {
        background-color: $blue;

        span {
          color: #fff;
        }

        > svg {
          stroke: #fff !important;
        }
      }

      &.clicked {
        > svg {
          transform: rotate(180deg);
        }
      }
    }

    > .background {
      background: transparent;
      height: 100vh;
      left: 0;
      position: fixed;
      top: 0;
      width: 100vw;
      z-index: 3;
    }
  }
}